import {
    createContext, useState
} from "react";

const AuthContext = createContext({
    token: '',
    userData:{
        userId: '',
        name: '',
        email: '',
        picture: '',
    },
    phoneNumber: '',
    isLoggedIn: false,
    addPhoneNumber: (phoneNumber) => {},
    removePhoneNumber: ()=> {},
    login: (token, userData) => {},
    logout: () => {}
});

export const AuthContextProvider = (props) => {
    const initialToken = localStorage.getItem('token');
    const initialUserData = localStorage.getItem('userData');
    const [token, setToken] = useState(initialToken);
    const [userData, setUserData] = useState(initialUserData ? JSON.parse(initialUserData) : null);
    const [phoneNumber, setPhoneNumber] =  useState(localStorage.getItem('phoneNumber') ? localStorage.getItem('phoneNumber')  : "");
    
    const userIsLoggedIn = !!token;
    
    const loginHandler = (token, userData) => {
        setToken(token);
        setUserData(userData);
        localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(userData));
    };
    
    const logoutHandler = () => {
        setToken(null);
        setUserData(null);
        localStorage.clear();
    }
    const addPhoneNumberHandler = (phoneNumber) => {
        //console.log("phone in context", phoneNumber)
        let temp = "(";
        for(let i = 0; i < phoneNumber.length; i++){
            //console.log(phoneNumber[i])
                temp += phoneNumber[i]
            if(i === 2){
                temp += ")-"
            }
            if(i === 5){
                temp += "-"
            }
        }
        phoneNumber = temp
        //console.log("temp", temp)
        setPhoneNumber(phoneNumber);
        localStorage.setItem('phoneNumber', phoneNumber);
    };
    
    const removePhoneNumberHandler = () => {
        setPhoneNumber("");
        localStorage.removeItem("phoneNumber");
    }
    
    const contextValue = {
        token: token,
        userData: userData,
        phoneNumber: phoneNumber,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        addPhoneNumber: addPhoneNumberHandler,
        removePhoneNumber: removePhoneNumberHandler,
    }
    
    return <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext;