const SubmitVerifRequest = async (code, token) => {
    try {
        const response = await fetch('https://novasnatch.herokuapp.com/api/user/verify-phone', {
        //const response = await fetch('http://localhost:5000/api/user/verify-phone', {
        method: "POST",
        body: new URLSearchParams({
            code: code,
        }),
        headers:{
            'Authorization': "Bearer " + token,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        });
        if(response.status === 401){
            return {"error": true, "data":{Message: "Session is expired, please log in again."}};
        }
        
        //console.log(response);
        const data = await response.json();
        if(response.status > 500){
            return {"error": true, "data":data};
        }
        //console.log(data);
        return data;
    } catch (error) {
        console.log("verif request error 22", error);
        return {"error": true, "data":{Message: "Unknown error, refresh and try again."}};
    }
    //return null;
}

export default SubmitVerifRequest;