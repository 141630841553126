import './About.css';
import AboutDropDown from "./AboutDropDown";
import { how, how2, how3, what, what2, why, why2, blogpost, blogpost2, blogpost3, blogpost4, blogpost5, blogpost6, blogpost7, blogpost8} from "./Paragraphs";


const About = (props) =>{
    return(
        <div className="about">
            <AboutDropDown title = "Why?" text1={why} text2={why2}></AboutDropDown>
            <br></br>
            <AboutDropDown title = "How does it work?" text1={how} text2={how2} text3={how3}></AboutDropDown>
            <br></br>
            <AboutDropDown title = "What did you build the website with?" text1={what} text2={what2}></AboutDropDown>
            <br></br>
            <AboutDropDown title = "Most Recent Update Blog Post" text1={blogpost} text2={blogpost2} text3={blogpost3} text4={blogpost4} text5={blogpost5} text6={blogpost6} text7={blogpost7} text8={blogpost8}></AboutDropDown>
        </div>
    )
}

export default About;