export const UpdatesArray = [
    {
        
        date:'Dev Log 3/09/23 - Another Big Update',
        content:[
            {
                updateTitle:'-Additions and Improvements',
                updateBullets:[
                    'I was ready to release this update 3 days ago but then looking at my code happened.',
                    'Completely re-wrote monitoring logic and database logic for optimized speed, error handling, and notifying.',
                    'Completely re-did logging infastructure (in like 4 different ways before getting it right).',
                    'Removed duplicates from course list.'
                ]
            },
            {
                updateTitle:'-Todos',
                updateBullets:[
                    'Work on something new.',
                ]
            }
        ]
    },
    {
        
        date:'Dev Log 3/05/23 - Massive Update',
        content:[
            {
                updateTitle:'-Additions and Improvements',
                updateBullets:[
                    'Interested in a more in-depth write-up? Go to the "About" tab and read my update blog post.',
                    'Added logic, UI, and handling for adding phone number and receiving text updates (20 hours of work described in one line).',
                    'Updated welcome modal for additional information and recommendations.',
                    'Updates to UI.',
                    'Backend fixes and improvements to database logic and request handling.',
                    'Updated course list to reflect new semester.'
                ]
            },
            {
                updateTitle:'-Todos',
                updateBullets:[
                    'Eventually want the list to automatically update itself',
                ]
            }
        ]
    },
    {
        date:'Dev Log 11/29/22',
        content:[
            {
                updateTitle:'-UI Fixes',
                updateBullets:[
                    'Re-did "Review" section on homepage to be a box of descriptive features of the app to serve as eye-candy',
                    'Changed the color of the delete button on the dashboard so that it contrasts more from the background',
                    'Removed duplicates from the course search list'
                ]
            },
            {
                updateTitle:'-Todos',
                updateBullets:[
                    'Make the "Contact me" Section native instead of through email',
                    'Add section to the search box to send me a message in case a course is missing from the list',
                    'Eventually want the list to automatically update itself',
                    'Make the site more accessible'
                ]
            }
        ]
    },
    {
        date:'Dev Log 11/16/22',
        content:[
            {
                updateTitle:'-Logic Fixes',
                updateBullets:[
                    'Lots of backend updates',
                    'Now properly parses course data',
                    'Fixed bug where scoping was mixing data between objects',
                    'Fixed the monitoring logic to properly properly iterate through old and new data',
                    'Added handling for when the max number of students in a class changes',
                    'Added additional logging for better error finding in the future'
                ]
            },
            {
                updateTitle:'-UI Fixes',
                updateBullets:[
                    'Re-wrote first section of homepage to be fully responsive regardless of device size',
                    
                ]
            },
            {
                updateTitle:'-Todos',
                updateBullets:[
                    'Remove duplicates from course search list',
                    'Make the "Contact me" Section native instead of through email',
                    'Change the color of the delete button so it contrasts with background more'
                    
                ]
            }
        ]
    },
]