import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: {
    xs: '45%',
    sm: '40%',
    md: '40%'
  },
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '80vw',
    sm: '65vw',
    md: '60vw'
  },
  height: "max-content",
  borderRadius: 1,
  bgcolor: '#c4e8eb',
  boxShadow: 24,
  p: 4,
  textAlign:"center"
};

const WelcomeModal = (props) => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  
  const handleClose = () => {
    setOpen(false);
    navigate('/dashboard');
  };
  
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant='h4' 
            sx={{
              fontFamily: "system-ui",
              fontWeight: '650',
            }}
            >Thank you for using my site!</Typography>
            <Typography 
            sx={{
              fontSize: {xs: '.9rem',sm:'1rem', md:'1.5rem'},
              fontFamily: "system-ui",
              fontWeight: '600',
              lineHeight: {xs: '2',sm:'2', md:'3'},
              textAlign: 'left',
            }}
            >{/*Want to connect? Add me on <Typography sx={{
              fontSize: {xs: '.9rem',sm:'1rem', md:'1.5rem'},
              fontFamily: "system-ui",
              fontWeight: '600',
              lineHeight: '3',
              textAlign: 'left',
            }} component="a" target="_blank" href='https://www.linkedin.com/in/dan-bek/'>LinkedIn</Typography><br></br>*/}
             1. Click add a course to track your desired course.
            <br></br>2. Watch your email/texts to see if a seat has become available.
            <br></br>
            </Typography>
            <Typography sx={{
              fontSize: {xs: '.8rem',sm:'.9rem', md:'1.4rem'},
              fontFamily: "system-ui",
              fontWeight: '600',
              lineHeight: {xs: '2',sm:'2', md:'3'},
              textAlign: 'left',
              color: "#ff7702"
            }}>Adding your phone is highly recommended as some emails may go to spam.</Typography>
            {/* <ToolInfo sx={{color:"black", fontWeight:700, fontFamily:'system-ui'}} /> */}
            <Button onClick={()=> {handleClose(); props.handlePhoneOpen()}} variant="contained" sx={{marginTop:{xs: '10px',sm:'0px', md:'0px'}, backgroundColor:'#4592a1', fontWeight:700, fontFamily:'system-ui','&:hover': {backgroundColor: '#11538f'}}}>Add your phone</Button>
            <Button onClick={handleClose} variant="contained" sx={{marginLeft:{xs: '10px',sm:'10px', md:'20px'}, marginTop:{xs: '10px',sm:'0px', md:'0px'}, backgroundColor:'#F07A3D', fontWeight:700, fontFamily:'system-ui','&:hover': {backgroundColor: '#af482d'}}}>Continue</Button>
          </Box>
        </Fade>
      </Modal>
    </div>
    //style={{position:"absolute", bottom:"25px"}}
  );
}
export default WelcomeModal;