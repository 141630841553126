export const why = "I found that classes I really wanted to take were filling up before my registration time. I began checking in daily to see if a seat had opened up; however, being a programmer I decided I could automate this process for ease. This resulted in me creating a program for myself that checked if any seats opened up in classes I wanted and then automatically register me for them.";
export const why2 = "I then realized that if I was facing this issue then so were others, so I decided to make this available for all students. Although it won't automatically register students for their classes, it will at least notify them if a seat opens up and increase the likely hood of them snatching up a seat for the class they want.";

export const how = "To put it very simply, I keep track of which classes are currently requested by users to be monitored. I then check the college's database for their availability. If I see that the availability has gone from full to not full, I notify the relevant students through email (and maybe through text, not sure yet).";
export const how2 = "While this seems fairly trivial, especially when I first decided to make it, it grew pretty complex over time. I wanted to be efficient throughout each process so that I was not causing unhealthy traffic to the college's site. To do this, I spent many hours thinking through how the database should be set up and how each request should work.";
export const how3 = "Additionally, I put forth a great deal of effort to make this site as simple and intuitive as possible while also looking really good and functioning responsively."

export const what ="The front-end of this website (everything you can see) is built in React, a javascript framework that centers itself around the idea of re-usable components to make an SPA (single page application). A lot of the styling comes from Material UI, a react javascript package for good looking, customizable components (things like tables, buttons, etc). There is also a lot of plain CSS styling.";
export const what2 = "The back-end of this website (the things you can't see but dictate the logic of what is happening) is built with Node JS, another javascript framework. The API is a REST API built using Express. The database that stores the content is a Mongo DB database. For authentication, I'm using Microsoft's API coupled with Passport.js, a javascript authentication framework. Through those, I'm using JWTs (JSON web tokens) to transfer authentication data back and forth.";


export const blogpost ="I probably sunk about 20 hours over 2 days into this last update, if you're interested in what I implemented and what issues I ran into, keep reading.";
export const blogpost2= "So I realized last semester that a lot of emails were going to spam, and honestly since the beginning, I wanted to add phone number support, but I just wanted to get the site out initially. The most recent update features the full addition of phone support and all of the issues that come with that.";
export const blogpost3= "First issue: What API to use for sending texts. This was simple, I chose Twilio. Easy to implement and fairly cheap.";
export const blogpost4 = "Next issue: Actually adding the user's phone number. Now while this sounds simple out loud, the logic actually gets fairly complex. Here are some of the things that I had to think about. First: How do we verify that a user has actually added their own phone number? This requires phone verification. While Twilio has it's own solution to this, I wanted to implement this myself so that I can keep track of the logic. While I'm glad I did it, this is what took the majority of the time. Lots of points go into this: Deciding the format of the verification code, limiting the number of times a user can try to submit a code, limiting the number of times a user can change their number, handling the user updating their phone number, and deleting the users phone number. The biggest point: Making sure this all works properly together." 
export const blogpost5 = "Now assuming that we have all of that working, we want to get into the logic of what happens now. Things I had to consider: A user adding a course after adding their phone number, adding a course then adding a phone number, deleting a course with and without a phone number, deleting your phone number, and changing your phone number." 
export const blogpost6 = "You see, what we want to happen is we want that when a user adds their phone number, their phone number is added to all of the courses they are tracking. When a user deletes their phone number, their phone number is deleted from all of the courses they are tracking. When a user changes their phone number, and their old phone number is removed from the courses they are tracking and their new phone number is added."
export const blogpost7 = "In doing all of this, we also want the backend not to break with invalid requests and the UI to be responsive and informative to the user. Additionally, since I haven't touched this codebase since November, I had to re-acclimate myself into the flow of how everything works, as well as deal with all of the weird decisions made by the guy who worked on this before me (my past self)."
export const blogpost8= "Now I didn't get into the finer details as well as the development of the UI and I also forgot to talk about a lot of things but this is getting very long and I'm getting bored. If you have questions, feel free to ask me."