import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import PhoneInput from '../PhoneInput/PhoneInput';

const style = {
  position: 'absolute',
  top: {
    xs: '45%',
    sm: '40%',
    md: '40%'
  },
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '80vw',
    sm: '75vw',
    md: '45vw'
  },
  height: "max-content",
  borderRadius: 1,
  bgcolor: '#d4e2e4',
  boxShadow: 24,
  p: 4,
  textAlign:"center"
};

const PhoneModal = (props) => {
  const handleClose = () => props.setOpen(false);
  
  return (
    <div>
      <Modal
        aria-labelledby="backdrop-modal"
        aria-describedby="backdrop-modal"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        
        <Fade in={props.open}>
          <Box sx={style}>
            <PhoneInput handlePhoneOpen={handleClose}></PhoneInput>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
export default PhoneModal;