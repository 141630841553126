import React from "react";
import "./Reviews.css";
import { Typography } from "@mui/material";

const ReviewItem = (props) => {
  return(
    <div className="item">
        <Typography variant='h4' sx={{fontWeight:900, fontFamily:'system-ui' }} color="white">
            {props.comment}
        </Typography>
        <img alt={props.palt} src={props.srcx} style={{maxWidth:'128px', maxHeight:'128px'}}></img>
    </div>
  )  
};
const Reviews = () => {
    return (
        <div className="main-body">
            <div className="review-box">
                <ReviewItem comment="Easy to use" srcx ="https://cdn-icons-png.flaticon.com/512/6146/6146667.png" palt="guy leaning back in chair to symbolize ease of use"></ReviewItem>
                <ReviewItem comment="Blazingly Fast" srcx="https://cdn-icons-png.flaticon.com/512/2558/2558113.png" palt="clock with speed stripes to symbolize speed"></ReviewItem>
                <ReviewItem comment="Reliable Technology" srcx="https://cdn-icons-png.flaticon.com/512/102/102649.png" palt="shield with checkmark to symbolize reliability"></ReviewItem>
            </div>
        </div>
    )
}

export default Reviews;