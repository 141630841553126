import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Reviews from "../../components/HomepageContent/Reviews/Reviews";
import AuthContext from "../../context/auth-context";
import './Homepage.css';
import starterImage from '../../images/homepage/starterImage.png';

const Homepage = () =>{
    const authCtx = useContext(AuthContext);
    return(
        <div>
            <div className="start-body flex-direction">
                <div className="text-and-button">
                <Typography 
                    variant="h5"
                    color={"white"}
                    width= "fit-content"
                    sx={{ 
                        marginTop:"4.2%",
                        lineHeight: 2,
                    }}>
                    Your solution to being the first to get an available seat.<br></br><br></br>1. Log in with Microsoft.<br></br>2. Enter your course.<br></br>3. Be notified if a seat opens up.<br></br><br></br><>Easy as that.</>
                </Typography>
                <NavLink className="callToAction" to={authCtx.isLoggedIn ? '/dashboard' : '/login'}>{authCtx.isLoggedIn ? "Go to Dashboard" : "Secure your seat now"}</NavLink>
                </div>
                <div className="image-div">
                    <img className="starter-image" src={starterImage} alt="teacher lecturing student in a desk"></img>
                </div>
            </div>
            <Reviews></Reviews>
        </div>
    )    
}

export default Homepage;