import { Typography } from '@mui/material';
import React from 'react';
import './UpdateEntry.css';

const UpdateEntry = (props) =>{
    return(
        <div className="update-entry">
            <Typography variant='h4' sx={{color:"white", fontFamily:'system-ui', fontWeight:'600'}}>{props.date}</Typography>
            <div style={{textAlign:'left'}}>
                {
                    props.content.map((content, i) => {
                        return(
                            <React.Fragment key={i}>
                                <Typography variant='h5' sx={{color:"white", fontFamily:'system-ui', fontWeight:'500', marginTop:'5px', marginBottom:'5px'}}>{content.updateTitle}</Typography>
                                <div style={{paddingBottom:'30px'}}>
                                {content.updateBullets.map((bullet, i) => {
                                    return (
                                        <Typography key={"bullet"+i} variant='h6' sx={{color:"darkgray", fontFamily:'system-ui', fontWeight:'500', lineHeight:'2'}}>&emsp;•{bullet}</Typography>
                                    )
                                })} 
                                </div>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default UpdateEntry;