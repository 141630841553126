const DeletePhoneRequest = async (token) => {
    try {
        const response = await fetch('https://novasnatch.herokuapp.com/api/user/delete-phone', {
        //const response = await fetch('http://localhost:5000/api/user/delete-phone', {
        method: "POST",
        headers:{
            'Authorization': "Bearer " + token,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        });
        //console.log(response);
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.log("delete phone request error 16", error);
        return "Error";
    }
    //return null;
}

export default DeletePhoneRequest;