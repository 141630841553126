import { Typography } from '@mui/material';
import UpdateEntry from '../../components/UpdateComponent/UpdateEntry';
import './Updates.css';
import { UpdatesArray } from './UpdatesText';

const Updates = (props) =>{
    return(
        <div className="updates">
            <Typography variant='h3' sx={{color:"white", fontFamily:'system-ui', fontWeight:'700', marginBottom:'15px'}}>Updates</Typography>
            <div className='updates-table'>
                {UpdatesArray.map((entry, i) =>{
                    return <UpdateEntry key={'entry'+ i} date={entry.date} content={entry.content} ></UpdateEntry>
                })}
            </div>
        </div>
    )
}

export default Updates;