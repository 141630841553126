import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Button, Typography } from '@mui/material';
import SubmitPhoneRequest from '../phoneRequest/PhoneRequest';
import AuthContext from '../../../context/auth-context';
import SubmitVerifRequest from '../phoneRequest/VerifRequest';
import GetPhoneRequest from '../phoneRequest/GetPhoneRequest';
import { useSnackbar } from 'notistack';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default function PhoneInput(props) {
  const authCtx = React.useContext(AuthContext); 
  const [values, setValues] = React.useState({
    textmask: '',
  });
  const [submitError, setSubmitError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [needVerification, setNeedVerification] = React.useState(false); 
  const [verificationCode, setVerificationCode] = React.useState("");
  const [verifError, setVerifError] = React.useState("");
  const [submitVerifError, setSubmitVerifError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setSubmitError(false);
    setErrorMessage("");
  };
  const handleVerificationCode = (event) => {
    setVerificationCode(event.target.value);
    setSubmitError(false);
    setVerifError("");
    setErrorMessage("");
    setSubmitVerifError(false);
  };
  
  const resetHandler = () => {
    setNeedVerification(false);
  }
  
  const verificationCodeHandler = async () => {
    if(!isNaN(verificationCode) && verificationCode.toString().length !== 4){
      setSubmitVerifError(true);
      return
    }
    const response = await SubmitVerifRequest(verificationCode, authCtx.token);
    console.log(response)
    if(response.error){
      setVerifError(response.data.Message);
      enqueueSnackbar('Error submitting code, please refresh and try again.', {variant:'error'});
    } else {
      setVerifError("");
      setNeedVerification(false);
      enqueueSnackbar('Successfully added phone number!', {variant:'success'});
      props.handlePhoneOpen();
      const phoneNumber = await GetPhoneRequest(authCtx.token);
      if(phoneNumber.Status === "Good1"){
        //console.log(phoneNumber.Message)
        authCtx.addPhoneNumber(phoneNumber.Message)
      } else {
        authCtx.addPhoneNumber(null)
      }
    }
  }
  
  const phoneNumberHandler = async () => {
    if(values.textmask.length === 14){
      let phoneNumber = values.textmask.replace(/\D/g, "")
      if(!isNaN(phoneNumber) && phoneNumber.length === 10){
        //Being here means the phone number is good
        const response = await SubmitPhoneRequest(phoneNumber, authCtx.token);
        
        if(response === "Error1"){
          setErrorMessage("Unknown error, please refresh and try again.");
          enqueueSnackbar('Error adding phone number, please refresh and try again.', {variant:'error'});
          return
        }
        if(response === "Error2"){
          setErrorMessage("Number is not in a valid format.");
          return
        }
        if(response === "Error3"){
          setErrorMessage("You have swapped your phone number too many times and are likely doing something malicious. Contact me if you think this is a mistake.");
          return
        }
        if(response === "Error4"){
          setErrorMessage("You already have this phone number added.");
          return
        }
        if(response.Status === "Good1"){
          setNeedVerification(true);
          setSubmitError(false);
          setErrorMessage("");
          return
        }
        //console.log(response);
        setSubmitError(false);
        setErrorMessage("");
      } else {
        setSubmitError(true)
        return
      }
    } else{
      setSubmitError(true)
      return
    }
  }

  return (
    <Box
      sx={{
        '& > :not(style)': {
          m: 1,
        },
      }}
    >
      {needVerification && <><Typography variant='h7' sx={{marginTop:"25px", fontFamily:"system-ui", fontWeight:"600", color:"black"}}>You have been texted a code. If you did not receive it, click <span onClick={resetHandler} style={{textDecoration:"underline", cursor:"pointer", color:"blue"}}>here</span>.</Typography><br></br></>}
      {needVerification ? 
      <>
      <FormControl variant="standard">
        <InputLabel>Verfication Code</InputLabel>
        <Input
        style={{width:"200px"}}
          autoComplete="off"
          value={verificationCode}
          onChange={handleVerificationCode}
          placeholder=''
          inputProps={{ maxLength: 4 }}
          error={submitVerifError}
        />
      </FormControl>
      
      {submitVerifError && <>
        <br></br>
        <Typography variant='h7' sx={{marginTop:"25px", fontFamily:"system-ui", fontWeight:"600", color:"#F07A3D"}}>Code should be a 4 digit number.</Typography>
        <br></br>
      </>}
      {verifError && <>
        <br></br>
        <Typography variant='h7' sx={{marginTop:"25px", fontFamily:"system-ui", fontWeight:"600", color:"#F07A3D"}}>{verifError}</Typography>
        <br></br>
      </>}
      
      <Button style={{marginTop:"20px"}} variant="outlined" onClick={verificationCodeHandler}>Verify</Button>
      </> 
      : 
      <>
      <FormControl variant="standard">
        <InputLabel htmlFor="formatted-text-mask-input">Phone Number (US ONLY)</InputLabel>
        <Input
        style={{width:"200px"}}
          autoComplete="off"
          value={values.textmask}
          onChange={handleChange}
          name="textmask"
          id="formatted-text-mask-input"
          placeholder='(000) 000-0000'
          inputComponent={TextMaskCustom}
          error={submitError}
        />
      </FormControl>
      
      {submitError && <>
        <br></br>
        <Typography variant='h7' sx={{marginTop:"25px", fontFamily:"system-ui", fontWeight:"600", color:"#F07A3D"}}>Must be a valid 10 digit US Phone Number</Typography>
        <br></br>
      </>}
      {errorMessage && <>
        <br></br>
        <Typography variant='h7' sx={{marginTop:"25px", fontFamily:"system-ui", fontWeight:"600", color:"#F07A3D"}}>{errorMessage}</Typography>
        <br></br>
      </>}
      
      <Button style={{marginTop:"20px"}} variant="outlined" onClick={phoneNumberHandler} >Submit</Button>
      </>}
    </Box>
  );
}