export const courseArrayFall2024 = [{"label":"ACC 2020 - 100 TOP:IntroForensicsforACC CRN: 26404","subject":"ACC","courseNumber":"2020","CRN":"26404"},
{"label":"ACC 2310 - 001 Intermediate Accounting I CRN: 23859","subject":"ACC","courseNumber":"2310","CRN":"23859"},
{"label":"ACC 2310 - 002 Intermediate Accounting I CRN: 23860","subject":"ACC","courseNumber":"2310","CRN":"23860"},
{"label":"ACC 2310 - 003 Intermediate Accounting I CRN: 23861","subject":"ACC","courseNumber":"2310","CRN":"23861"},
{"label":"ACC 2320 - 002 Intermediate Accounting II CRN: 23863","subject":"ACC","courseNumber":"2320","CRN":"23863"},
{"label":"ACC 2320 - 003 Intermediate Accounting II CRN: 23864","subject":"ACC","courseNumber":"2320","CRN":"23864"},
{"label":"ACC 2320 - 004 Intermediate Accounting II CRN: 23865","subject":"ACC","courseNumber":"2320","CRN":"23865"},
{"label":"ACC 2340 - DL1 Accounting Information Systems CRN: 23866","subject":"ACC","courseNumber":"2340","CRN":"23866"},
{"label":"ACC 2360 - 001 Federal Income Tax CRN: 23867","subject":"ACC","courseNumber":"2360","CRN":"23867"},
{"label":"ACC 2360 - 002 Federal Income Tax CRN: 23868","subject":"ACC","courseNumber":"2360","CRN":"23868"},
{"label":"ACC 2360 - 004 Federal Income Tax CRN: 23870","subject":"ACC","courseNumber":"2360","CRN":"23870"},
{"label":"ACC 2410 - 001 Accounting for Real Estate CRN: 23871","subject":"ACC","courseNumber":"2410","CRN":"23871"},
{"label":"ACC 2430 - 001 Auditing CRN: 23872","subject":"ACC","courseNumber":"2430","CRN":"23872"},
{"label":"ACC 2430 - 002 Auditing CRN: 23873","subject":"ACC","courseNumber":"2430","CRN":"23873"},
{"label":"ACC 2450 - 001 Advanced Accounting CRN: 23874","subject":"ACC","courseNumber":"2450","CRN":"23874"},
{"label":"ACC 2020 - 100 TOP:IntroForensicsforACC CRN: 26404","subject":"ACC","courseNumber":"2020","CRN":"26404"},
{"label":"ACC 2020 - 101 TOP:IntroDealsAdvisoryforACC CRN: 26450","subject":"ACC","courseNumber":"2020","CRN":"26450"},
{"label":"ACC 2020 - 102 TOP:IntroDigital&CyberSecurity CRN: 26451","subject":"ACC","courseNumber":"2020","CRN":"26451"},
{"label":"ACC 2320 - 001 Intermediate Accounting II CRN: 23862","subject":"ACC","courseNumber":"2320","CRN":"23862"},
{"label":"ACC 2360 - 003 Federal Income Tax CRN: 23869","subject":"ACC","courseNumber":"2360","CRN":"23869"},
{"label":"ACC 2430 - 003 Auditing CRN: 26838","subject":"ACC","courseNumber":"2430","CRN":"26838"},
{"label":"ACC 2450 - 002 Advanced Accounting CRN: 23875","subject":"ACC","courseNumber":"2450","CRN":"23875"},
{"label":"ACC 3350 - 001 Fraud Examination CRN: 23876","subject":"ACC","courseNumber":"3350","CRN":"23876"},
{"label":"ACC 3430 - 001 Accounting Internship CRN: 23877","subject":"ACC","courseNumber":"3430","CRN":"23877"},
{"label":"ACC 3470 - 001 Accounting Co-Op CRN: 23878","subject":"ACC","courseNumber":"3470","CRN":"23878"},
{"label":"ACC 3500 - 001 Ind Study:Accounting CRN: 23879","subject":"ACC","courseNumber":"3500","CRN":"23879"},
{"label":"HIS 1150 - 002 TOP:Slavery in Modern World CRN: 25104","subject":"AFR","courseNumber":"1150","CRN":"25104"},
{"label":"HIS 1150 - 003 TOP:Global Brazil CRN: 25105","subject":"AFR","courseNumber":"1150","CRN":"25105"},
{"label":"HIS 1155 - 001 TOP:Black Freedom Struggle CRN: 25106","subject":"AFR","courseNumber":"1155","CRN":"25106"},
{"label":"HIS 1155 - 002 TOP:Black Freedom Struggle CRN: 25107","subject":"AFR","courseNumber":"1155","CRN":"25107"},
{"label":"HIS 1155 - 003 TOP:Black Freedom Struggle CRN: 25108","subject":"AFR","courseNumber":"1155","CRN":"25108"},
{"label":"HIS 1165 - 003 TOP:Global Africa IV CRN: 25115","subject":"AFR","courseNumber":"1165","CRN":"25115"},
{"label":"SOC 3600 - 001 Sociology of Race & Racism CRN: 25910","subject":"AFR","courseNumber":"3600","CRN":"25910"},
{"label":"THL 5004 - 001 THM:RaceGendr&ChrstnBioethics CRN: 26127","subject":"AFR","courseNumber":"5004","CRN":"26127"},
{"label":"THL 5004 - 003 THM:Black Theology&Black Power CRN: 26129","subject":"AFR","courseNumber":"5004","CRN":"26129"},
{"label":"SWA 1111 - 001 Intro Swahili Language l CRN: 26041","subject":"AFR","courseNumber":"1111","CRN":"26041"},
{"label":"HIS 1155 - 008 TOP:Black Freedom Movement CRN: 26575","subject":"AFR","courseNumber":"1155","CRN":"26575"},
{"label":"BIO 1950 - 001 TOP:Race Science in Biology CRN: 24065","subject":"AFR","courseNumber":"1950","CRN":"24065"},
{"label":"HIS 2291 - 001 African Amer His during Slaver CRN: 25130","subject":"AFR","courseNumber":"2291","CRN":"25130"},
{"label":"PJ 2800 - 001 Race, Class, & Gender CRN: 25725","subject":"AFR","courseNumber":"2800","CRN":"25725"},
{"label":"PJ 2800 - 002 Race, Class, & Gender CRN: 25726","subject":"AFR","courseNumber":"2800","CRN":"25726"},
{"label":"EDU 3263 - 001 Diversity and Inclusion CRN: 24756","subject":"AFR","courseNumber":"3263","CRN":"24756"},
{"label":"EDU 3263 - 002 Diversity and Inclusion CRN: 24757","subject":"AFR","courseNumber":"3263","CRN":"24757"},
{"label":"COM 3390 - 001 TOP:Black Horror CRN: 24451","subject":"AFR","courseNumber":"3390","CRN":"24451"},
{"label":"HIS 4120 - 001 Emergence Modern Africa CRN: 26574","subject":"AFR","courseNumber":"4120","CRN":"26574"},
{"label":"ENG 4646 - 001 Race & Ethnicity:Amer Novel CRN: 24877","subject":"AFR","courseNumber":"4646","CRN":"24877"},
{"label":"AFR 5000 - 001 Ind. Study Africana Studies CRN: 24011","subject":"AFR","courseNumber":"5000","CRN":"24011"},
{"label":"ENG 5000 - 001 SrSem:Black Diaspora& Identity CRN: 24883","subject":"AFR","courseNumber":"5000","CRN":"24883"},
{"label":"THL 5150 - 001 Islam:History,Thought,Culture CRN: 26139","subject":"AFR","courseNumber":"5150","CRN":"26139"},
{"label":"THL 5180 - 001 Islamic Political Thought CRN: 26765","subject":"AFR","courseNumber":"5180","CRN":"26765"},
{"label":"ASL 1111 - 003 Intro to Amer Sign Language I CRN: 24019","subject":"ASL","courseNumber":"1111","CRN":"24019"},
{"label":"ASL 1111 - 004 Intro to Amer Sign Language I CRN: 24020","subject":"ASL","courseNumber":"1111","CRN":"24020"},
{"label":"ASL 1111 - 005 Intro to Amer Sign Language I CRN: 24021","subject":"ASL","courseNumber":"1111","CRN":"24021"},
{"label":"ASL 1112 - 001 Intro to American Sign Lang II CRN: 24023","subject":"ASL","courseNumber":"1112","CRN":"24023"},
{"label":"ASL 1151 - 100 Intro to ASL 1 Lab CRN: 24030","subject":"ASL","courseNumber":"1151","CRN":"24030"},
{"label":"ASL 1111 - 001 Intro to Amer Sign Language I CRN: 24017","subject":"ASL","courseNumber":"1111","CRN":"24017"},
{"label":"ASL 1111 - 002 Intro to Amer Sign Language I CRN: 24018","subject":"ASL","courseNumber":"1111","CRN":"24018"},
{"label":"ASL 1111 - 100 Intro to Amer Sign Language I CRN: 24022","subject":"ASL","courseNumber":"1111","CRN":"24022"},
{"label":"ASL 1112 - 002 Intro to American Sign Lang II CRN: 24024","subject":"ASL","courseNumber":"1112","CRN":"24024"},
{"label":"ASL 1112 - 003 Intro to American Sign Lang II CRN: 24025","subject":"ASL","courseNumber":"1112","CRN":"24025"},
{"label":"ASL 1151 - 001 Intro to ASL 1 Lab CRN: 24026","subject":"ASL","courseNumber":"1151","CRN":"24026"},
{"label":"ASL 1151 - 002 Intro to ASL 1 Lab CRN: 24027","subject":"ASL","courseNumber":"1151","CRN":"24027"},
{"label":"ASL 1151 - 003 Intro to ASL 1 Lab CRN: 24028","subject":"ASL","courseNumber":"1151","CRN":"24028"},
{"label":"ASL 1151 - 004 Intro to ASL 1 Lab CRN: 24029","subject":"ASL","courseNumber":"1151","CRN":"24029"},
{"label":"ASL 1152 - 001 Intro to ASL 2 Lab CRN: 24031","subject":"ASL","courseNumber":"1152","CRN":"24031"},
{"label":"ASL 1152 - 002 Intro to ASL 2 Lab CRN: 24032","subject":"ASL","courseNumber":"1152","CRN":"24032"},
{"label":"ARB 1111 - 001 Basic Arabic I CRN: 24012","subject":"AIS","courseNumber":"1111","CRN":"24012"},
{"label":"ARB 1121 - 001 Intermediate Arabic I CRN: 24013","subject":"AIS","courseNumber":"1121","CRN":"24013"},
{"label":"ARB 1131 - 001 Intensive Adv Arabic I CRN: 24014","subject":"AIS","courseNumber":"1131","CRN":"24014"},
{"label":"PSC 2340 - 001 Politics of the Arab World CRN: 25755","subject":"AIS","courseNumber":"2340","CRN":"25755"},
{"label":"ARB 3412 - 001 TOP: Egyptians at the Movies CRN: 24015","subject":"AIS","courseNumber":"3412","CRN":"24015"},
{"label":"ARB 5900 - 001 ARB:Independent Study CRN: 24016","subject":"ARB","courseNumber":"5900","CRN":"24016"},
{"label":"AAH 1101 - 001 His West Art:Ancient-Med CRN: 23849","subject":"AAH","courseNumber":"1101","CRN":"23849"},
{"label":"AAH 1102 - 001 His West Art:Renaiss - Cont CRN: 23850","subject":"AAH","courseNumber":"1102","CRN":"23850"},
{"label":"AAH 1903 - 001 Internship Elective CRN: 23851","subject":"AAH","courseNumber":"1903","CRN":"23851"},
{"label":"AAH 3002 - 001 Art of Philadelphia CRN: 23852","subject":"AAH","courseNumber":"3002","CRN":"23852"},
{"label":"AAH 3003 - 001 Romantic to Post-Impress CRN: 23853","subject":"AAH","courseNumber":"3003","CRN":"23853"},
{"label":"AAH 3009 - 001 TOP:NorthernRenaissanceArt CRN: 23854","subject":"AAH","courseNumber":"3009","CRN":"23854"},
{"label":"AAH 3009 - 002 TOP:South Asian Art CRN: 23855","subject":"AAH","courseNumber":"3009","CRN":"23855"},
{"label":"AAH 3009 - H01 Curating Art & Public Engagement CRN: 23856","subject":"AAH","courseNumber":"3009","CRN":"23856"},
{"label":"AAH 4010 - 001 Interpreting Art CRN: 23857","subject":"AAH","courseNumber":"4010","CRN":"23857"},
{"label":"AAH 5515 - 001 Independent Research CRN: 23858","subject":"AAH","courseNumber":"5515","CRN":"23858"},
{"label":"ADV 1000 - 001 Advising:Explore & Experience CRN: 23995","subject":"ADV","courseNumber":"1000","CRN":"23995"},
{"label":"ADV 1000 - 002 Advising:Explore & Experience CRN: 23996","subject":"ADV","courseNumber":"1000","CRN":"23996"},
{"label":"ADV 1000 - 003 Advising:Explore & Experience CRN: 23997","subject":"ADV","courseNumber":"1000","CRN":"23997"},
{"label":"ADV 1000 - 004 Advising:Explore & Experience CRN: 23998","subject":"ADV","courseNumber":"1000","CRN":"23998"},
{"label":"ADV 1000 - 005 Advising:Explore & Experience CRN: 23999","subject":"ADV","courseNumber":"1000","CRN":"23999"},
{"label":"ADV 1000 - 006 Advising:Explore & Experience CRN: 24000","subject":"ADV","courseNumber":"1000","CRN":"24000"},
{"label":"ADV 1000 - 007 Advising:Explore & Experience CRN: 24001","subject":"ADV","courseNumber":"1000","CRN":"24001"},
{"label":"ADV 1000 - 008 Advising:Explore & Experience CRN: 24002","subject":"ADV","courseNumber":"1000","CRN":"24002"},
{"label":"ADV 1000 - 009 Advising:Explore & Experience CRN: 24003","subject":"ADV","courseNumber":"1000","CRN":"24003"},
{"label":"ADV 1000 - 010 Advising:Explore & Experience CRN: 24004","subject":"ADV","courseNumber":"1000","CRN":"24004"},
{"label":"ADV 1000 - 011 Advising:Explore & Experience CRN: 24005","subject":"ADV","courseNumber":"1000","CRN":"24005"},
{"label":"ADV 1000 - 012 Advising:Explore & Experience CRN: 24006","subject":"ADV","courseNumber":"1000","CRN":"24006"},
{"label":"ADV 1000 - 013 Advising:Explore & Experience CRN: 24007","subject":"ADV","courseNumber":"1000","CRN":"24007"},
{"label":"ADV 1000 - 014 Advising:Explore & Experience CRN: 24008","subject":"ADV","courseNumber":"1000","CRN":"24008"},
{"label":"ADV 1000 - 100 Advising:Explore & Experience CRN: 24009","subject":"ADV","courseNumber":"1000","CRN":"24009"},
{"label":"ADV 1001 - 001 Advising: Exploring Sciences CRN: 24010","subject":"ADV","courseNumber":"1001","CRN":"24010"},
{"label":"ASPD 2023 - 001 Navigating Roadblocks CRN: 24037","subject":"ASPD","courseNumber":"2023","CRN":"24037"},
{"label":"ASPD 2029 - 001 Applying to Law School CRN: 24041","subject":"ASPD","courseNumber":"2029","CRN":"24041"},
{"label":"ASPD 2000 - 001 Prof Dev for Arts & Sciences CRN: 24033","subject":"ASPD","courseNumber":"2000","CRN":"24033"},
{"label":"ASPD 2000 - 002 Prof Dev for Arts & Sciences CRN: 24034","subject":"ASPD","courseNumber":"2000","CRN":"24034"},
{"label":"ASPD 2000 - 100 Prof Dev for Arts & Sciences CRN: 26453","subject":"ASPD","courseNumber":"2000","CRN":"26453"},
{"label":"ASPD 2003 - 001 Professional Communication CRN: 24035","subject":"ASPD","courseNumber":"2003","CRN":"24035"},
{"label":"ASPD 2026 - 001 Intergrating Study Abroad Exp CRN: 24038","subject":"ASPD","courseNumber":"2026","CRN":"24038"},
{"label":"ASPD 2028 - 001 Diversity & Incl the Workplace CRN: 24039","subject":"ASPD","courseNumber":"2028","CRN":"24039"},
{"label":"ASPD 2028 - 002 Diversity & Incl the Workplace CRN: 24040","subject":"ASPD","courseNumber":"2028","CRN":"24040"},
{"label":"ASPD 2039 - 100 TOP:Medical Brigades CRN: 26600","subject":"ASPD","courseNumber":"2039","CRN":"26600"},
{"label":"ASPD 2040 - 001 Unpacking and Rebuilding CRN: 26454","subject":"ASPD","courseNumber":"2040","CRN":"26454"},
{"label":"ASPD 2041 - 001 Build Connection & Community CRN: 26455","subject":"ASPD","courseNumber":"2041","CRN":"26455"},
{"label":"ASPD 2101 - 001 The Road Less Traveled CRN: 24043","subject":"ASPD","courseNumber":"2101","CRN":"24043"},
{"label":"ASPD 2200 - 001 Applied Professional Devlp CRN: 24044","subject":"ASPD","courseNumber":"2200","CRN":"24044"},
{"label":"ASPD 3000 - 001 Topics in Prof. Development CRN: 24045","subject":"ASPD","courseNumber":"3000","CRN":"24045"},
{"label":"ASPD 6010 - 001 Business COM for Economists CRN: 26818","subject":"ASPD","courseNumber":"6010","CRN":"26818"},
{"label":"AST 1076 - 001 How Old is the Universe? CRN: 24046","subject":"AST","courseNumber":"1076","CRN":"24046"},
{"label":"MSE 2101 - 001 AST:Life in the Universe CRN: 25438","subject":"AST","courseNumber":"2101","CRN":"25438"},
{"label":"MSE 2101 - 002 AST:Life in the Universe CRN: 25439","subject":"AST","courseNumber":"2101","CRN":"25439"},
{"label":"MSE 2102 - 001 AST:Planetary Skies/Landscapes CRN: 25440","subject":"AST","courseNumber":"2102","CRN":"25440"},
{"label":"MSE 2102 - 100 AST:Planetary Skies/Landscapes CRN: 25441","subject":"AST","courseNumber":"2102","CRN":"25441"},
{"label":"MSE 2150 - 001 AST:Astronomy Lab - Planets CRN: 25443","subject":"AST","courseNumber":"2150","CRN":"25443"},
{"label":"MSE 2150 - 002 AST:Astronomy Lab - Planets CRN: 25444","subject":"AST","courseNumber":"2150","CRN":"25444"},
{"label":"MSE 2150 - 004 AST:Astronomy Lab - Planets CRN: 25446","subject":"AST","courseNumber":"2150","CRN":"25446"},
{"label":"MSE 2150 - 005 AST:Astronomy Lab - Planets CRN: 25447","subject":"AST","courseNumber":"2150","CRN":"25447"},
{"label":"MSE 2150 - 007 AST:Astronomy Lab - Planets CRN: 25449","subject":"AST","courseNumber":"2150","CRN":"25449"},
{"label":"MSE 2150 - 100 AST:Astronomy Lab - Planets CRN: 25450","subject":"AST","courseNumber":"2150","CRN":"25450"},
{"label":"MSE 2150 - 101 AST:Astronomy Lab - Planets CRN: 25451","subject":"AST","courseNumber":"2150","CRN":"25451"},
{"label":"MSE 2152 - 100 AST:Our Habitable World Lab CRN: 25454","subject":"AST","courseNumber":"2152","CRN":"25454"},
{"label":"MSE 2152 - 101 AST:Our Habitable World Lab CRN: 25455","subject":"AST","courseNumber":"2152","CRN":"25455"},
{"label":"MSE 2104 - 001 AST:Earth-Our Habitable World CRN: 25442","subject":"AST","courseNumber":"2104","CRN":"25442"},
{"label":"AST 2120 - 001 Sun and Stars CRN: 24047","subject":"AST","courseNumber":"2120","CRN":"24047"},
{"label":"AST 2122 - 001 Understanding Our Universe CRN: 24048","subject":"AST","courseNumber":"2122","CRN":"24048"},
{"label":"AST 2134 - 001 Observational Lab II CRN: 24049","subject":"AST","courseNumber":"2134","CRN":"24049"},
{"label":"MSE 2150 - 003 AST:Astronomy Lab - Planets CRN: 25445","subject":"AST","courseNumber":"2150","CRN":"25445"},
{"label":"MSE 2150 - 006 AST:Astronomy Lab - Planets CRN: 25448","subject":"AST","courseNumber":"2150","CRN":"25448"},
{"label":"MSE 2152 - 001 AST:Our Habitable World Lab CRN: 25452","subject":"AST","courseNumber":"2152","CRN":"25452"},
{"label":"MSE 2152 - 002 AST:Our Habitable World Lab CRN: 25453","subject":"AST","courseNumber":"2152","CRN":"25453"},
{"label":"AST 3141 - 001 Galactic Astronomy CRN: 24050","subject":"AST","courseNumber":"3141","CRN":"24050"},
{"label":"AST 4121 - 001 Undergrad Research I CRN: 24051","subject":"AST","courseNumber":"4121","CRN":"24051"},
{"label":"AST 4122 - 001 Undergrad Research II CRN: 24052","subject":"AST","courseNumber":"4122","CRN":"24052"},
{"label":"ACS 1000 - 100 Ancients CRN: 23880","subject":"ACS","courseNumber":"1000","CRN":"23880"},
{"label":"ACS 1000 - AC1 Ancients CRN: 23881","subject":"ACS","courseNumber":"1000","CRN":"23881"},
{"label":"ACS 1000 - AC2 Ancients CRN: 23882","subject":"ACS","courseNumber":"1000","CRN":"23882"},
{"label":"ACS 1000 - ALL ACS Placeholder CRN: 26601","subject":"ACS","courseNumber":"1000","CRN":"26601"},
{"label":"ACS 1000 - CA1 Ancients CRN: 23883","subject":"ACS","courseNumber":"1000","CRN":"23883"},
{"label":"ACS 1000 - CA2 Ancients CRN: 23884","subject":"ACS","courseNumber":"1000","CRN":"23884"},
{"label":"ACS 1000 - CR1 Ancients CRN: 23885","subject":"ACS","courseNumber":"1000","CRN":"23885"},
{"label":"ACS 1000 - DL1 Ancients CRN: 26405","subject":"ACS","courseNumber":"1000","CRN":"26405"},
{"label":"ACS 1000 - EN1 Ancients CRN: 23886","subject":"ACS","courseNumber":"1000","CRN":"23886"},
{"label":"ACS 1000 - EN2 Ancients CRN: 23887","subject":"ACS","courseNumber":"1000","CRN":"23887"},
{"label":"ACS 1000 - FR1 Ancients CRN: 23888","subject":"ACS","courseNumber":"1000","CRN":"23888"},
{"label":"ACS 1000 - GL1 Ancients CRN: 23889","subject":"ACS","courseNumber":"1000","CRN":"23889"},
{"label":"ACS 1000 - GL2 Ancients CRN: 23890","subject":"ACS","courseNumber":"1000","CRN":"23890"},
{"label":"ACS 1000 - H01 HON:Ancients(GTB) CRN: 23891","subject":"ACS","courseNumber":"1000","CRN":"23891"},
{"label":"ACS 1000 - H02 HON:Ancients(GTB) CRN: 23892","subject":"ACS","courseNumber":"1000","CRN":"23892"},
{"label":"ACS 1000 - H03 HON:Ancients(GTB) CRN: 23893","subject":"ACS","courseNumber":"1000","CRN":"23893"},
{"label":"ACS 1000 - H04 HON:Ancients(PPE) CRN: 23894","subject":"ACS","courseNumber":"1000","CRN":"23894"},
{"label":"ACS 1000 - H05 HON:Ancients(PPE) CRN: 23895","subject":"ACS","courseNumber":"1000","CRN":"23895"},
{"label":"ACS 1000 - H06 HON:Ancients(BST) CRN: 23896","subject":"ACS","courseNumber":"1000","CRN":"23896"},
{"label":"ACS 1000 - H07 HON:Ancients CRN: 23897","subject":"ACS","courseNumber":"1000","CRN":"23897"},
{"label":"ACS 1000 - H08 HON:Ancients CRN: 23898","subject":"ACS","courseNumber":"1000","CRN":"23898"},
{"label":"ACS 1000 - M01 Ancients CRN: 23899","subject":"ACS","courseNumber":"1000","CRN":"23899"},
{"label":"ACS 1000 - M02 Ancients CRN: 23900","subject":"ACS","courseNumber":"1000","CRN":"23900"},
{"label":"ACS 1000 - M03 Ancients CRN: 23901","subject":"ACS","courseNumber":"1000","CRN":"23901"},
{"label":"ACS 1000 - M04 Ancients CRN: 23902","subject":"ACS","courseNumber":"1000","CRN":"23902"},
{"label":"ACS 1000 - M05 Ancients CRN: 23903","subject":"ACS","courseNumber":"1000","CRN":"23903"},
{"label":"ACS 1000 - M06 Ancients CRN: 23904","subject":"ACS","courseNumber":"1000","CRN":"23904"},
{"label":"ACS 1000 - M07 Ancients CRN: 23905","subject":"ACS","courseNumber":"1000","CRN":"23905"},
{"label":"ACS 1000 - M08 Ancients CRN: 23906","subject":"ACS","courseNumber":"1000","CRN":"23906"},
{"label":"ACS 1000 - M09 Ancients CRN: 23907","subject":"ACS","courseNumber":"1000","CRN":"23907"},
{"label":"ACS 1000 - M10 Ancients CRN: 23908","subject":"ACS","courseNumber":"1000","CRN":"23908"},
{"label":"ACS 1000 - M11 Ancients CRN: 23909","subject":"ACS","courseNumber":"1000","CRN":"23909"},
{"label":"ACS 1000 - M12 Ancients CRN: 23910","subject":"ACS","courseNumber":"1000","CRN":"23910"},
{"label":"ACS 1000 - M13 Ancients CRN: 23911","subject":"ACS","courseNumber":"1000","CRN":"23911"},
{"label":"ACS 1000 - M14 Ancients CRN: 23912","subject":"ACS","courseNumber":"1000","CRN":"23912"},
{"label":"ACS 1000 - M15 Ancients CRN: 23913","subject":"ACS","courseNumber":"1000","CRN":"23913"},
{"label":"ACS 1000 - M16 Ancients CRN: 23914","subject":"ACS","courseNumber":"1000","CRN":"23914"},
{"label":"ACS 1000 - M17 Ancients CRN: 23915","subject":"ACS","courseNumber":"1000","CRN":"23915"},
{"label":"ACS 1000 - M18 Ancients CRN: 23916","subject":"ACS","courseNumber":"1000","CRN":"23916"},
{"label":"ACS 1000 - M19 Ancients CRN: 23917","subject":"ACS","courseNumber":"1000","CRN":"23917"},
{"label":"ACS 1000 - M20 Ancients CRN: 23918","subject":"ACS","courseNumber":"1000","CRN":"23918"},
{"label":"ACS 1000 - M21 Ancients CRN: 23919","subject":"ACS","courseNumber":"1000","CRN":"23919"},
{"label":"ACS 1000 - M22 Ancients CRN: 23920","subject":"ACS","courseNumber":"1000","CRN":"23920"},
{"label":"ACS 1000 - S01 Ancients CRN: 23921","subject":"ACS","courseNumber":"1000","CRN":"23921"},
{"label":"ACS 1000 - S02 Ancients CRN: 23922","subject":"ACS","courseNumber":"1000","CRN":"23922"},
{"label":"ACS 1000 - S03 Ancients CRN: 23923","subject":"ACS","courseNumber":"1000","CRN":"23923"},
{"label":"ACS 1000 - S04 Ancients CRN: 23924","subject":"ACS","courseNumber":"1000","CRN":"23924"},
{"label":"ACS 1000 - S05 Ancients CRN: 23925","subject":"ACS","courseNumber":"1000","CRN":"23925"},
{"label":"ACS 1000 - S06 Ancients CRN: 23926","subject":"ACS","courseNumber":"1000","CRN":"23926"},
{"label":"ACS 1000 - S07 Ancients CRN: 23927","subject":"ACS","courseNumber":"1000","CRN":"23927"},
{"label":"ACS 1000 - S08 Ancients CRN: 23928","subject":"ACS","courseNumber":"1000","CRN":"23928"},
{"label":"ACS 1000 - S09 Ancients CRN: 23929","subject":"ACS","courseNumber":"1000","CRN":"23929"},
{"label":"ACS 1000 - S10 Ancients CRN: 23930","subject":"ACS","courseNumber":"1000","CRN":"23930"},
{"label":"ACS 1000 - S11 Ancients CRN: 23931","subject":"ACS","courseNumber":"1000","CRN":"23931"},
{"label":"ACS 1000 - S12 Ancients CRN: 23932","subject":"ACS","courseNumber":"1000","CRN":"23932"},
{"label":"ACS 1000 - S13 Ancients CRN: 23933","subject":"ACS","courseNumber":"1000","CRN":"23933"},
{"label":"ACS 1000 - S14 Ancients CRN: 23934","subject":"ACS","courseNumber":"1000","CRN":"23934"},
{"label":"ACS 1000 - S15 Ancients CRN: 23935","subject":"ACS","courseNumber":"1000","CRN":"23935"},
{"label":"ACS 1000 - S16 Ancients CRN: 23936","subject":"ACS","courseNumber":"1000","CRN":"23936"},
{"label":"ACS 1000 - S17 Ancients CRN: 23937","subject":"ACS","courseNumber":"1000","CRN":"23937"},
{"label":"ACS 1000 - S18 Ancients CRN: 23938","subject":"ACS","courseNumber":"1000","CRN":"23938"},
{"label":"ACS 1000 - S19 Ancients CRN: 23939","subject":"ACS","courseNumber":"1000","CRN":"23939"},
{"label":"ACS 1000 - S20 Ancients CRN: 23940","subject":"ACS","courseNumber":"1000","CRN":"23940"},
{"label":"ACS 1000 - S21 Ancients CRN: 23941","subject":"ACS","courseNumber":"1000","CRN":"23941"},
{"label":"ACS 1000 - S22 Ancients CRN: 23942","subject":"ACS","courseNumber":"1000","CRN":"23942"},
{"label":"ACS 1000 - S23 Ancients CRN: 23943","subject":"ACS","courseNumber":"1000","CRN":"23943"},
{"label":"ACS 1000 - S24 Ancients CRN: 23944","subject":"ACS","courseNumber":"1000","CRN":"23944"},
{"label":"ACS 1000 - S25 Ancients CRN: 23945","subject":"ACS","courseNumber":"1000","CRN":"23945"},
{"label":"ACS 1000 - S26 Ancients CRN: 23946","subject":"ACS","courseNumber":"1000","CRN":"23946"},
{"label":"ACS 1000 - S27 Ancients CRN: 23947","subject":"ACS","courseNumber":"1000","CRN":"23947"},
{"label":"ACS 1000 - S28 Ancients CRN: 23948","subject":"ACS","courseNumber":"1000","CRN":"23948"},
{"label":"ACS 1000 - S29 Ancients CRN: 23949","subject":"ACS","courseNumber":"1000","CRN":"23949"},
{"label":"ACS 1000 - S30 Ancients CRN: 23950","subject":"ACS","courseNumber":"1000","CRN":"23950"},
{"label":"ACS 1000 - S31 Ancients CRN: 23951","subject":"ACS","courseNumber":"1000","CRN":"23951"},
{"label":"ACS 1000 - S32 Ancients CRN: 23952","subject":"ACS","courseNumber":"1000","CRN":"23952"},
{"label":"ACS 1000 - S33 Ancients CRN: 23953","subject":"ACS","courseNumber":"1000","CRN":"23953"},
{"label":"ACS 1000 - S34 Ancients CRN: 23954","subject":"ACS","courseNumber":"1000","CRN":"23954"},
{"label":"ACS 1000 - S35 Ancients CRN: 23955","subject":"ACS","courseNumber":"1000","CRN":"23955"},
{"label":"ACS 1000 - S36 Ancients CRN: 23956","subject":"ACS","courseNumber":"1000","CRN":"23956"},
{"label":"ACS 1000 - S37 Ancients CRN: 23957","subject":"ACS","courseNumber":"1000","CRN":"23957"},
{"label":"ACS 1000 - S38 Ancients CRN: 23958","subject":"ACS","courseNumber":"1000","CRN":"23958"},
{"label":"ACS 1000 - S39 Ancients CRN: 23959","subject":"ACS","courseNumber":"1000","CRN":"23959"},
{"label":"ACS 1000 - S40 Ancients CRN: 23960","subject":"ACS","courseNumber":"1000","CRN":"23960"},
{"label":"ACS 1000 - S41 Ancients CRN: 23961","subject":"ACS","courseNumber":"1000","CRN":"23961"},
{"label":"ACS 1000 - S42 Ancients CRN: 23962","subject":"ACS","courseNumber":"1000","CRN":"23962"},
{"label":"ACS 1000 - S43 Ancients CRN: 23963","subject":"ACS","courseNumber":"1000","CRN":"23963"},
{"label":"ACS 1000 - S44 Ancients CRN: 23964","subject":"ACS","courseNumber":"1000","CRN":"23964"},
{"label":"ACS 1000 - S45 Ancients CRN: 23965","subject":"ACS","courseNumber":"1000","CRN":"23965"},
{"label":"ACS 1000 - S46 Ancients CRN: 23966","subject":"ACS","courseNumber":"1000","CRN":"23966"},
{"label":"ACS 1000 - S47 Ancients CRN: 23967","subject":"ACS","courseNumber":"1000","CRN":"23967"},
{"label":"ACS 1000 - S48 Ancients CRN: 23968","subject":"ACS","courseNumber":"1000","CRN":"23968"},
{"label":"ACS 1000 - S49 Ancients CRN: 23969","subject":"ACS","courseNumber":"1000","CRN":"23969"},
{"label":"ACS 1000 - SI1 Ancients CRN: 23970","subject":"ACS","courseNumber":"1000","CRN":"23970"},
{"label":"ACS 1000 - SJ1 Ancients CRN: 23971","subject":"ACS","courseNumber":"1000","CRN":"23971"},
{"label":"ACS 1000 - T01 Ancients CRN: 23972","subject":"ACS","courseNumber":"1000","CRN":"23972"},
{"label":"ACS 1000 - T02 Ancients CRN: 23973","subject":"ACS","courseNumber":"1000","CRN":"23973"},
{"label":"ACS 1000 - T03 Ancients CRN: 23974","subject":"ACS","courseNumber":"1000","CRN":"23974"},
{"label":"ACS 1000 - T04 Ancients CRN: 23975","subject":"ACS","courseNumber":"1000","CRN":"23975"},
{"label":"ACS 1000 - V01 Ancients CRN: 23976","subject":"ACS","courseNumber":"1000","CRN":"23976"},
{"label":"ACS 1000 - V02 Ancients CRN: 23977","subject":"ACS","courseNumber":"1000","CRN":"23977"},
{"label":"ACS 1000 - V03 Ancients CRN: 23978","subject":"ACS","courseNumber":"1000","CRN":"23978"},
{"label":"ACS 1000 - V04 Ancients CRN: 23979","subject":"ACS","courseNumber":"1000","CRN":"23979"},
{"label":"ACS 1000 - V05 Ancients CRN: 23980","subject":"ACS","courseNumber":"1000","CRN":"23980"},
{"label":"ACS 1000 - V06 Ancients CRN: 23981","subject":"ACS","courseNumber":"1000","CRN":"23981"},
{"label":"ACS 1000 - V07 Ancients CRN: 23982","subject":"ACS","courseNumber":"1000","CRN":"23982"},
{"label":"ACS 1000 - V08 Ancients CRN: 23983","subject":"ACS","courseNumber":"1000","CRN":"23983"},
{"label":"ACS 1000 - V09 Ancients CRN: 23984","subject":"ACS","courseNumber":"1000","CRN":"23984"},
{"label":"ACS 1000 - V10 Ancients CRN: 23985","subject":"ACS","courseNumber":"1000","CRN":"23985"},
{"label":"ACS 1000 - V11 Ancients CRN: 23986","subject":"ACS","courseNumber":"1000","CRN":"23986"},
{"label":"ACS 1000 - V12 Ancients CRN: 23987","subject":"ACS","courseNumber":"1000","CRN":"23987"},
{"label":"ACS 1000 - V13 Ancients CRN: 23988","subject":"ACS","courseNumber":"1000","CRN":"23988"},
{"label":"ACS 1000 - V14 Ancients CRN: 23989","subject":"ACS","courseNumber":"1000","CRN":"23989"},
{"label":"ACS 1000 - VH1 Ancients CRN: 23990","subject":"ACS","courseNumber":"1000","CRN":"23990"},
{"label":"ACS 1000 - VH2 Ancients CRN: 23991","subject":"ACS","courseNumber":"1000","CRN":"23991"},
{"label":"ACS 1000 - VH3 Ancients CRN: 23992","subject":"ACS","courseNumber":"1000","CRN":"23992"},
{"label":"ACS 1000 - X01 Ancients CRN: 23993","subject":"ACS","courseNumber":"1000","CRN":"23993"},
{"label":"ACS 1001 - DL1 Moderns CRN: 26452","subject":"ACS","courseNumber":"1001","CRN":"26452"},
{"label":"ACS 1001 - S01 Moderns CRN: 23994","subject":"ACS","courseNumber":"1001","CRN":"23994"},
{"label":"BIO 1185 - 001 Human Genetics/Microbiology CRN: 24054","subject":"BIO","courseNumber":"1185","CRN":"24054"},
{"label":"BIO 1205 - 001 Human Anatomy & Physiology I CRN: 24055","subject":"BIO","courseNumber":"1205","CRN":"24055"},
{"label":"BIO 1205 - 003 Human Anatomy & Physiology I CRN: 24057","subject":"BIO","courseNumber":"1205","CRN":"24057"},
{"label":"BIO 1205 - 004 Human Anatomy & Physiology I CRN: 24058","subject":"BIO","courseNumber":"1205","CRN":"24058"},
{"label":"BIO 1205 - 006 Human Anatomy & Physiology I CRN: 24060","subject":"BIO","courseNumber":"1205","CRN":"24060"},
{"label":"BIO 2105 - 001 General Biology I CRN: 24066","subject":"BIO","courseNumber":"2105","CRN":"24066"},
{"label":"BIO 2105 - 002 General Biology I CRN: 24067","subject":"BIO","courseNumber":"2105","CRN":"24067"},
{"label":"BIO 2105 - L02 General Biology I CRN: 24069","subject":"BIO","courseNumber":"2105","CRN":"24069"},
{"label":"BIO 2105 - L04 General Biology I CRN: 24071","subject":"BIO","courseNumber":"2105","CRN":"24071"},
{"label":"BIO 2105 - L06 General Biology I CRN: 24073","subject":"BIO","courseNumber":"2105","CRN":"24073"},
{"label":"BIO 2105 - L07 General Biology I CRN: 24074","subject":"BIO","courseNumber":"2105","CRN":"24074"},
{"label":"BIO 2105 - L10 General Biology I CRN: 24077","subject":"BIO","courseNumber":"2105","CRN":"24077"},
{"label":"BIO 2105 - L12 General Biology I CRN: 24079","subject":"BIO","courseNumber":"2105","CRN":"24079"},
{"label":"BIO 2105 - L13 General Biology I CRN: 24080","subject":"BIO","courseNumber":"2105","CRN":"24080"},
{"label":"MSE 2201 - 001 BIO:How Microbes Rule World CRN: 26625","subject":"BIO","courseNumber":"2201","CRN":"26625"},
{"label":"MSE 2203 - 001 BIO:Heredity & Human Affairs CRN: 25456","subject":"BIO","courseNumber":"2203","CRN":"25456"},
{"label":"MSE 2204 - 001 BIO:How The Body Works CRN: 25457","subject":"BIO","courseNumber":"2204","CRN":"25457"},
{"label":"BIO 3055 - 001 Human Physiology CRN: 24083","subject":"BIO","courseNumber":"3055","CRN":"24083"},
{"label":"BIO 3055 - 002 Human Physiology CRN: 24084","subject":"BIO","courseNumber":"3055","CRN":"24084"},
{"label":"BIO 3055 - 003 Human Physiology CRN: 24085","subject":"BIO","courseNumber":"3055","CRN":"24085"},
{"label":"BIO 3085 - 001 Human Anatomy CRN: 24086","subject":"BIO","courseNumber":"3085","CRN":"24086"},
{"label":"BIO 3105 - 001 Biostatistics & Exp Design CRN: 24087","subject":"BIO","courseNumber":"3105","CRN":"24087"},
{"label":"BIO 3255 - 001 Evolutionary Ecology CRN: 24088","subject":"BIO","courseNumber":"3255","CRN":"24088"},
{"label":"BIO 3255 - 002 Evolutionary Ecology CRN: 24089","subject":"BIO","courseNumber":"3255","CRN":"24089"},
{"label":"BIO 3351 - 001 Genetics CRN: 24090","subject":"BIO","courseNumber":"3351","CRN":"24090"},
{"label":"BIO 3351 - 002 Genetics CRN: 24091","subject":"BIO","courseNumber":"3351","CRN":"24091"},
{"label":"BIO 3351 - 004 Genetics CRN: 24093","subject":"BIO","courseNumber":"3351","CRN":"24093"},
{"label":"BIO 3351 - 005 Genetics CRN: 24094","subject":"BIO","courseNumber":"3351","CRN":"24094"},
{"label":"BIO 3591 - 001 General Microbiology Lecture CRN: 24095","subject":"BIO","courseNumber":"3591","CRN":"24095"},
{"label":"BIO 3595 - 001 General Microbiology CRN: 24096","subject":"BIO","courseNumber":"3595","CRN":"24096"},
{"label":"BIO 3595 - 002 General Microbiology CRN: 24097","subject":"BIO","courseNumber":"3595","CRN":"24097"},
{"label":"BIO 3955 - 001 TOP:Sensory Ecology CRN: 24099","subject":"BIO","courseNumber":"3955","CRN":"24099"},
{"label":"BIO 4205 - 001 Cell Biology CRN: 24101","subject":"BIO","courseNumber":"4205","CRN":"24101"},
{"label":"BIO 4285 - 001 Developmental Biology CRN: 24102","subject":"BIO","courseNumber":"4285","CRN":"24102"},
{"label":"BIO 5300 - 001 CAP:Fermentation & You CRN: 24105","subject":"BIO","courseNumber":"5300","CRN":"24105"},
{"label":"BIO 5300 - 002 CAP:SARS-Cov-2 & Vaccines CRN: 24106","subject":"BIO","courseNumber":"5300","CRN":"24106"},
{"label":"BIO 5300 - 003 CAP:Biology in the Headlines CRN: 24107","subject":"BIO","courseNumber":"5300","CRN":"24107"},
{"label":"BIO 1101 - 001 Biology CRN: 24053","subject":"BIO","courseNumber":"1101","CRN":"24053"},
{"label":"BIO 1205 - 002 Human Anatomy & Physiology I CRN: 24056","subject":"BIO","courseNumber":"1205","CRN":"24056"},
{"label":"BIO 1205 - 005 Human Anatomy & Physiology I CRN: 24059","subject":"BIO","courseNumber":"1205","CRN":"24059"},
{"label":"BIO 1205 - 007 Human Anatomy & Physiology I CRN: 24061","subject":"BIO","courseNumber":"1205","CRN":"24061"},
{"label":"BIO 1205 - NUR NUR BIO Placeholder CRN: 26614","subject":"BIO","courseNumber":"1205","CRN":"26614"},
{"label":"BIO 1206 - 100 Human Anatomy & Physiology II CRN: 24062","subject":"BIO","courseNumber":"1206","CRN":"24062"},
{"label":"BIO 1903 - 001 Internship Elective CRN: 24063","subject":"BIO","courseNumber":"1903","CRN":"24063"},
{"label":"BIO 1906 - 001 Internship Elective CRN: 24064","subject":"BIO","courseNumber":"1906","CRN":"24064"},
{"label":"BIO 2105 - L01 General Biology I CRN: 24068","subject":"BIO","courseNumber":"2105","CRN":"24068"},
{"label":"BIO 2105 - L03 General Biology I CRN: 24070","subject":"BIO","courseNumber":"2105","CRN":"24070"},
{"label":"BIO 2105 - L05 General Biology I CRN: 24072","subject":"BIO","courseNumber":"2105","CRN":"24072"},
{"label":"BIO 2105 - L08 General Biology I CRN: 24075","subject":"BIO","courseNumber":"2105","CRN":"24075"},
{"label":"BIO 2105 - L09 General Biology I CRN: 24076","subject":"BIO","courseNumber":"2105","CRN":"24076"},
{"label":"BIO 2105 - L11 General Biology I CRN: 24078","subject":"BIO","courseNumber":"2105","CRN":"24078"},
{"label":"MSE 2201 - 002 BIO:How Microbes Rule World CRN: 26626","subject":"BIO","courseNumber":"2201","CRN":"26626"},
{"label":"MSE 2201 - 003 BIO:How Microbes Rule World CRN: 26627","subject":"BIO","courseNumber":"2201","CRN":"26627"},
{"label":"BIO 2993 - 001 Internship CRN: 24081","subject":"BIO","courseNumber":"2993","CRN":"24081"},
{"label":"BIO 2996 - 001 Internship CRN: 24082","subject":"BIO","courseNumber":"2996","CRN":"24082"},
{"label":"BIO 3351 - 003 Genetics CRN: 24092","subject":"BIO","courseNumber":"3351","CRN":"24092"},
{"label":"BIO 3615 - 001 Biomechanics CRN: 24098","subject":"BIO","courseNumber":"3615","CRN":"24098"},
{"label":"BIO 4105 - 001 Medical Microbiology CRN: 24100","subject":"BIO","courseNumber":"4105","CRN":"24100"},
{"label":"BIO 4505 - 001 Molecular Biology CRN: 24103","subject":"BIO","courseNumber":"4505","CRN":"24103"},
{"label":"BIO 4950 - 001 TOP:CellSignaling Hlth&Disease CRN: 24104","subject":"BIO","courseNumber":"4950","CRN":"24104"},
{"label":"BIO 6100 - 001 Affiliation Study CRN: 24108","subject":"BIO","courseNumber":"6100","CRN":"24108"},
{"label":"BIO 6101 - 001 Leadership & Mentoring in Bio CRN: 24109","subject":"BIO","courseNumber":"6101","CRN":"24109"},
{"label":"BIO 6102 - 001 TOP:Parasitology Res Meth CRN: 24110","subject":"BIO","courseNumber":"6102","CRN":"24110"},
{"label":"BIO 6102 - 002 TOP:Fly Neurogenetics CRN: 24111","subject":"BIO","courseNumber":"6102","CRN":"24111"},
{"label":"BIO 6102 - 003 TOP:Ecosystem Experimentation CRN: 24112","subject":"BIO","courseNumber":"6102","CRN":"24112"},
{"label":"BIO 6102 - 004 TOP:Principles Form & Function CRN: 26588","subject":"BIO","courseNumber":"6102","CRN":"26588"},
{"label":"BIO 6102 - 100 TOP:Herpetology Research CRN: 24113","subject":"BIO","courseNumber":"6102","CRN":"24113"},
{"label":"BIO 6401 - 001 BS/MS Independent Study 1 CRN: 24114","subject":"BIO","courseNumber":"6401","CRN":"24114"},
{"label":"BIO 6403 - 001 BS/MS Independent Study 3 CRN: 24115","subject":"BIO","courseNumber":"6403","CRN":"24115"},
{"label":"BIO 6509 - 001 Directed Research l CRN: 24116","subject":"BIO","courseNumber":"6509","CRN":"24116"},
{"label":"BIO 6510 - 001 Directed Research ll CRN: 24117","subject":"BIO","courseNumber":"6510","CRN":"24117"},
{"label":"BIO 6511 - 001 Directed Research lll CRN: 24118","subject":"BIO","courseNumber":"6511","CRN":"24118"},
{"label":"BIO 6609 - 001 Thesis Research I CRN: 24119","subject":"BIO","courseNumber":"6609","CRN":"24119"},
{"label":"BIO 6610 - 001 Thesis Proseminar CRN: 24120","subject":"BIO","courseNumber":"6610","CRN":"24120"},
{"label":"BIO 7201 - 001 Ecosystem Ecology CRN: 24121","subject":"BIO","courseNumber":"7201","CRN":"24121"},
{"label":"BIO 7805 - 001 Biostatistics & Exper. Design CRN: 24122","subject":"BIO","courseNumber":"7805","CRN":"24122"},
{"label":"BIO 7960 - 001 TOP:CellSignaling Hlth&Disease CRN: 24123","subject":"BIO","courseNumber":"7960","CRN":"24123"},
{"label":"BIO 7960 - 002 TOP:Virology CRN: 24124","subject":"BIO","courseNumber":"7960","CRN":"24124"},
{"label":"BIO 7980 - 001 TOP:Sensory Ecology CRN: 24125","subject":"BIO","courseNumber":"7980","CRN":"24125"},
{"label":"BIO 7982 - 001 TOP:Sensory Ecology Lab CRN: 24126","subject":"BIO","courseNumber":"7982","CRN":"24126"},
{"label":"BIO 8610 - 001 BS/MS Thesis Proseminar CRN: 24127","subject":"BIO","courseNumber":"8610","CRN":"24127"},
{"label":"BIO 8920 - 001 Research Prospectus CRN: 24128","subject":"BIO","courseNumber":"8920","CRN":"24128"},
{"label":"BIO 9007 - 001 Directed Research I CRN: 24129","subject":"BIO","courseNumber":"9007","CRN":"24129"},
{"label":"BIO 9008 - 001 Directed Research II CRN: 24130","subject":"BIO","courseNumber":"9008","CRN":"24130"},
{"label":"BIO 9080 - 001 Thesis Continuation CRN: 24131","subject":"BIO","courseNumber":"9080","CRN":"24131"},
{"label":"BIO 9102 - 001 Graduate Biology Topics I CRN: 24132","subject":"BIO","courseNumber":"9102","CRN":"24132"},
{"label":"BIO 9103 - 001 Graduate Biology Topics II CRN: 24133","subject":"BIO","courseNumber":"9103","CRN":"24133"},
{"label":"BIO 9104 - 001 Graduate Biology Topics III CRN: 24134","subject":"BIO","courseNumber":"9104","CRN":"24134"},
{"label":"BIO 9104 - 002 Graduate Biology Topics III CRN: 24135","subject":"BIO","courseNumber":"9104","CRN":"24135"},
{"label":"BIO 9303 - 001 Thesis Dir for BS/MS students CRN: 24136","subject":"BIO","courseNumber":"9303","CRN":"24136"},
{"label":"BIO 9305 - 001 Thesis Dir for BS/MS students CRN: 24137","subject":"BIO","courseNumber":"9305","CRN":"24137"},
{"label":"BIO 9307 - 001 Thesis Research I CRN: 24138","subject":"BIO","courseNumber":"9307","CRN":"24138"},
{"label":"BIO 9307 - 002 Thesis Research I CRN: 24139","subject":"BIO","courseNumber":"9307","CRN":"24139"},
{"label":"BIO 9308 - 001 Thesis Research II CRN: 24140","subject":"BIO","courseNumber":"9308","CRN":"24140"},
{"label":"BIO 9309 - 001 Thesis Research III CRN: 24141","subject":"BIO","courseNumber":"9309","CRN":"24141"},
{"label":"BL 2160 - 001 International Business Law CRN: 24143","subject":"BL","courseNumber":"2160","CRN":"24143"},
{"label":"BL 2165 - 001 Employment Law CRN: 26831","subject":"BL","courseNumber":"2165","CRN":"26831"},
{"label":"BL 2185 - 001 Law of Contracts & Sales CRN: 24144","subject":"BL","courseNumber":"2185","CRN":"24144"},
{"label":"CHE 3201 - 001 Mass Transfer CRN: 24206","subject":"CHE","courseNumber":"3201","CRN":"24206"},
{"label":"CHE 3202 - 002 Reactor Design CRN: 24209","subject":"CHE","courseNumber":"3202","CRN":"24209"},
{"label":"CHE 3401 - 001 Unit Operations Lab 1 CRN: 24210","subject":"CHE","courseNumber":"3401","CRN":"24210"},
{"label":"CHE 3401 - 002 Unit Operations Lab 1 CRN: 24211","subject":"CHE","courseNumber":"3401","CRN":"24211"},
{"label":"CHE 4201 - 001 Process Design CRN: 24214","subject":"CHE","courseNumber":"4201","CRN":"24214"},
{"label":"CHE 4201 - 002 Process Design CRN: 24215","subject":"CHE","courseNumber":"4201","CRN":"24215"},
{"label":"CHE 4831 - 001 Senior Project Studio I CRN: 24216","subject":"CHE","courseNumber":"4831","CRN":"24216"},
{"label":"CHE 5002 - 001 Prin of Air Pol Control CRN: 24218","subject":"CHE","courseNumber":"5002","CRN":"24218"},
{"label":"CHE 5332 - 001 TOP:Synthetic Biology CRN: 24219","subject":"CHE","courseNumber":"5332","CRN":"24219"},
{"label":"CHE 5534 - 001 Biomaterials CRN: 24221","subject":"CHE","courseNumber":"5534","CRN":"24221"},
{"label":"CHE 5715 - 001 Alternative Energy CRN: 24222","subject":"CHE","courseNumber":"5715","CRN":"24222"},
{"label":"CHE 2101 - 001 Thermodynamics 1 CRN: 24200","subject":"CHE","courseNumber":"2101","CRN":"24200"},
{"label":"CHE 2101 - 002 Thermodynamics 1 CRN: 24201","subject":"CHE","courseNumber":"2101","CRN":"24201"},
{"label":"CHE 2201 - 001 Fluid Dynamics CRN: 24202","subject":"CHE","courseNumber":"2201","CRN":"24202"},
{"label":"CHE 2201 - 002 Fluid Dynamics CRN: 24203","subject":"CHE","courseNumber":"2201","CRN":"24203"},
{"label":"CHE 2301 - 001 ChE Computational Methods CRN: 24204","subject":"CHE","courseNumber":"2301","CRN":"24204"},
{"label":"CHE 2301 - 002 ChE Computational Methods CRN: 24205","subject":"CHE","courseNumber":"2301","CRN":"24205"},
{"label":"CHE 3201 - 002 Mass Transfer CRN: 24207","subject":"CHE","courseNumber":"3201","CRN":"24207"},
{"label":"CHE 3202 - 001 Reactor Design CRN: 24208","subject":"CHE","courseNumber":"3202","CRN":"24208"},
{"label":"CHE 3401 - 003 Unit Operations Lab 1 CRN: 24212","subject":"CHE","courseNumber":"3401","CRN":"24212"},
{"label":"CHE 3401 - 004 Unit Operations Lab 1 CRN: 24213","subject":"CHE","courseNumber":"3401","CRN":"24213"},
{"label":"CHE 4831 - 002 Senior Project Studio I CRN: 24217","subject":"CHE","courseNumber":"4831","CRN":"24217"},
{"label":"CHE 5332 - 002 TOP: CRN: 24220","subject":"CHE","courseNumber":"5332","CRN":"24220"},
{"label":"CHE 6000 - 001 CBE Co-Op CRN: 24223","subject":"CHE","courseNumber":"6000","CRN":"24223"},
{"label":"CHE 7580 - 001 CHE Economics CRN: 24224","subject":"CHE","courseNumber":"7580","CRN":"24224"},
{"label":"CHE 7580 - DL1 CHE Economics CRN: 24225","subject":"CHE","courseNumber":"7580","CRN":"24225"},
{"label":"CHE 8563 - 001 Transport Phenomena CRN: 24226","subject":"CHE","courseNumber":"8563","CRN":"24226"},
{"label":"CHE 8563 - DL1 Transport Phenomena CRN: 24227","subject":"CHE","courseNumber":"8563","CRN":"24227"},
{"label":"CHE 8565 - 001 Heat Transfer CRN: 24228","subject":"CHE","courseNumber":"8565","CRN":"24228"},
{"label":"CHE 8565 - DL1 Heat Transfer CRN: 24229","subject":"CHE","courseNumber":"8565","CRN":"24229"},
{"label":"CHE 8588 - 001 Biochemical Engineering I CRN: 24230","subject":"CHE","courseNumber":"8588","CRN":"24230"},
{"label":"CHE 8588 - DL1 Biochemical Engineering I CRN: 24231","subject":"CHE","courseNumber":"8588","CRN":"24231"},
{"label":"CHE 8591 - 001 Gene & Cell Therapy CRN: 24232","subject":"CHE","courseNumber":"8591","CRN":"24232"},
{"label":"CHE 8591 - DL1 Gene & Cell Therapy CRN: 24233","subject":"CHE","courseNumber":"8591","CRN":"24233"},
{"label":"CHE 9020 - 001 CHE Independent Study CRN: 24234","subject":"CHE","courseNumber":"9020","CRN":"24234"},
{"label":"CHE 9024 - 001 CHE Thesis I CRN: 24235","subject":"CHE","courseNumber":"9024","CRN":"24235"},
{"label":"CHE 9025 - 001 CHE Thesis II CRN: 24236","subject":"CHE","courseNumber":"9025","CRN":"24236"},
{"label":"CHE 9080 - 001 Thesis Continuation CRN: 24237","subject":"CHE","courseNumber":"9080","CRN":"24237"},
{"label":"CHM 1103 - 008 General Chemistry Lab I CRN: 24259","subject":"CHM","courseNumber":"1103","CRN":"24259"},
{"label":"CHM 1151 - 002 General Chemistry I CRN: 24267","subject":"CHM","courseNumber":"1151","CRN":"24267"},
{"label":"CHM 1151 - 003 General Chemistry I CRN: 24268","subject":"CHM","courseNumber":"1151","CRN":"24268"},
{"label":"CHM 1151 - 007 General Chemistry I CRN: 24272","subject":"CHM","courseNumber":"1151","CRN":"24272"},
{"label":"CHM 1151 - 008 General Chemistry I CRN: 24273","subject":"CHM","courseNumber":"1151","CRN":"24273"},
{"label":"CHM 1151 - 009 General Chemistry I CRN: 24274","subject":"CHM","courseNumber":"1151","CRN":"24274"},
{"label":"CHM 1151 - 012 General Chemistry I CRN: 24277","subject":"CHM","courseNumber":"1151","CRN":"24277"},
{"label":"CHM 1151 - 013 General Chemistry I CRN: 24278","subject":"CHM","courseNumber":"1151","CRN":"24278"},
{"label":"CHM 1151 - 014 General Chemistry I CRN: 24279","subject":"CHM","courseNumber":"1151","CRN":"24279"},
{"label":"CHM 1151 - 015 General Chemistry I CRN: 24280","subject":"CHM","courseNumber":"1151","CRN":"24280"},
{"label":"CHM 1151 - 018 General Chemistry I CRN: 24283","subject":"CHM","courseNumber":"1151","CRN":"24283"},
{"label":"CHM 1151 - 022 General Chemistry I CRN: 24287","subject":"CHM","courseNumber":"1151","CRN":"24287"},
{"label":"CHM 1151 - 023 General Chemistry I CRN: 24288","subject":"CHM","courseNumber":"1151","CRN":"24288"},
{"label":"CHM 1151 - 024 General Chemistry I CRN: 24289","subject":"CHM","courseNumber":"1151","CRN":"24289"},
{"label":"CHM 1151 - 100 General Chemistry I CRN: 24290","subject":"CHM","courseNumber":"1151","CRN":"24290"},
{"label":"CHM 2201 - 001 Organic Chemistry Lab I CRN: 24299","subject":"CHM","courseNumber":"2201","CRN":"24299"},
{"label":"CHM 2201 - 002 Organic Chemistry Lab I CRN: 24300","subject":"CHM","courseNumber":"2201","CRN":"24300"},
{"label":"CHM 2201 - 003 Organic Chemistry Lab I CRN: 24301","subject":"CHM","courseNumber":"2201","CRN":"24301"},
{"label":"CHM 2201 - 102 Organic Chemistry Lab I CRN: 24309","subject":"CHM","courseNumber":"2201","CRN":"24309"},
{"label":"CHM 2211 - 001 Organic Chemistry I CRN: 24310","subject":"CHM","courseNumber":"2211","CRN":"24310"},
{"label":"CHM 2211 - 002 Organic Chemistry I CRN: 24311","subject":"CHM","courseNumber":"2211","CRN":"24311"},
{"label":"CHM 2211 - 003 Organic Chemistry I CRN: 24312","subject":"CHM","courseNumber":"2211","CRN":"24312"},
{"label":"CHM 2211 - 004 Organic Chemistry I CRN: 24313","subject":"CHM","courseNumber":"2211","CRN":"24313"},
{"label":"CHM 2211 - 007 Organic Chemistry I CRN: 24316","subject":"CHM","courseNumber":"2211","CRN":"24316"},
{"label":"CHM 2211 - 008 Organic Chemistry I CRN: 24317","subject":"CHM","courseNumber":"2211","CRN":"24317"},
{"label":"CHM 2211 - 009 Organic Chemistry I CRN: 24318","subject":"CHM","courseNumber":"2211","CRN":"24318"},
{"label":"MSE 2301 - 001 CHM:Water CRN: 25461","subject":"CHM","courseNumber":"2301","CRN":"25461"},
{"label":"MSE 2301 - 002 CHM:Water CRN: 25462","subject":"CHM","courseNumber":"2301","CRN":"25462"},
{"label":"MSE 2301 - 003 CHM:Water CRN: 25463","subject":"CHM","courseNumber":"2301","CRN":"25463"},
{"label":"CHM 3301 - 001 Inorganic Chem Lab II CRN: 24328","subject":"CHM","courseNumber":"3301","CRN":"24328"},
{"label":"CHM 3301 - 002 Inorganic Chem Lab II CRN: 24329","subject":"CHM","courseNumber":"3301","CRN":"24329"},
{"label":"CHM 3405 - 001 Physical Chem Lab II CRN: 24331","subject":"CHM","courseNumber":"3405","CRN":"24331"},
{"label":"CHM 3501 - 001 Instrumental Analysis Lab CRN: 24335","subject":"CHM","courseNumber":"3501","CRN":"24335"},
{"label":"CHM 3503 - 001 Bioanalytical Chem Lab CRN: 24337","subject":"CHM","courseNumber":"3503","CRN":"24337"},
{"label":"CHM 4603 - 001 Biochem Tech. and Pract. CRN: 24342","subject":"CHM","courseNumber":"4603","CRN":"24342"},
{"label":"CHM 4603 - 002 Biochem Tech. and Pract. CRN: 24343","subject":"CHM","courseNumber":"4603","CRN":"24343"},
{"label":"CHM 4611 - 001 Survey of Biochemistry CRN: 24345","subject":"CHM","courseNumber":"4611","CRN":"24345"},
{"label":"CHM 4621 - 001 Biochemistry I: Structure CRN: 24347","subject":"CHM","courseNumber":"4621","CRN":"24347"},
{"label":"CHM 1000 - 001 Profesl Development Sem CRN: 24251","subject":"CHM","courseNumber":"1000","CRN":"24251"},
{"label":"CHM 1103 - 001 General Chemistry Lab I CRN: 24252","subject":"CHM","courseNumber":"1103","CRN":"24252"},
{"label":"CHM 1103 - 002 General Chemistry Lab I CRN: 24253","subject":"CHM","courseNumber":"1103","CRN":"24253"},
{"label":"CHM 1103 - 003 General Chemistry Lab I CRN: 24254","subject":"CHM","courseNumber":"1103","CRN":"24254"},
{"label":"CHM 1103 - 004 General Chemistry Lab I CRN: 24255","subject":"CHM","courseNumber":"1103","CRN":"24255"},
{"label":"CHM 1103 - 005 General Chemistry Lab I CRN: 24256","subject":"CHM","courseNumber":"1103","CRN":"24256"},
{"label":"CHM 1103 - 006 General Chemistry Lab I CRN: 24257","subject":"CHM","courseNumber":"1103","CRN":"24257"},
{"label":"CHM 1103 - 007 General Chemistry Lab I CRN: 24258","subject":"CHM","courseNumber":"1103","CRN":"24258"},
{"label":"CHM 1103 - 009 General Chemistry Lab I CRN: 24260","subject":"CHM","courseNumber":"1103","CRN":"24260"},
{"label":"CHM 1103 - 010 General Chemistry Lab I CRN: 24261","subject":"CHM","courseNumber":"1103","CRN":"24261"},
{"label":"CHM 1103 - 100 General Chemistry Lab I CRN: 24262","subject":"CHM","courseNumber":"1103","CRN":"24262"},
{"label":"CHM 1103 - EGR EG Chem Lab Placeholder CRN: 26607","subject":"CHM","courseNumber":"1103","CRN":"26607"},
{"label":"CHM 1105 - 100 General, Organic & BioChem Lab CRN: 24263","subject":"CHM","courseNumber":"1105","CRN":"24263"},
{"label":"CHM 1135 - 001 General, Organic & BioChem CRN: 24264","subject":"CHM","courseNumber":"1135","CRN":"24264"},
{"label":"CHM 1135 - 002 General, Organic & BioChem CRN: 24265","subject":"CHM","courseNumber":"1135","CRN":"24265"},
{"label":"CHM 1151 - 001 General Chemistry I CRN: 24266","subject":"CHM","courseNumber":"1151","CRN":"24266"},
{"label":"CHM 1151 - 004 General Chemistry I CRN: 24269","subject":"CHM","courseNumber":"1151","CRN":"24269"},
{"label":"CHM 1151 - 005 General Chemistry I CRN: 24270","subject":"CHM","courseNumber":"1151","CRN":"24270"},
{"label":"CHM 1151 - 006 General Chemistry I CRN: 24271","subject":"CHM","courseNumber":"1151","CRN":"24271"},
{"label":"CHM 1151 - 010 General Chemistry I CRN: 24275","subject":"CHM","courseNumber":"1151","CRN":"24275"},
{"label":"CHM 1151 - 011 General Chemistry I CRN: 24276","subject":"CHM","courseNumber":"1151","CRN":"24276"},
{"label":"CHM 1151 - 016 General Chemistry I CRN: 24281","subject":"CHM","courseNumber":"1151","CRN":"24281"},
{"label":"CHM 1151 - 017 General Chemistry I CRN: 24282","subject":"CHM","courseNumber":"1151","CRN":"24282"},
{"label":"CHM 1151 - 019 General Chemistry I CRN: 24284","subject":"CHM","courseNumber":"1151","CRN":"24284"},
{"label":"CHM 1151 - 020 General Chemistry I CRN: 24285","subject":"CHM","courseNumber":"1151","CRN":"24285"},
{"label":"CHM 1151 - 021 General Chemistry I CRN: 24286","subject":"CHM","courseNumber":"1151","CRN":"24286"},
{"label":"CHM 1151 - EGR EG Chem Placeholder CRN: 26608","subject":"CHM","courseNumber":"1151","CRN":"26608"},
{"label":"CHM 1301 - 001 Inorganic Chemistry Lab I CRN: 24291","subject":"CHM","courseNumber":"1301","CRN":"24291"},
{"label":"CHM 1301 - 002 Inorganic Chemistry Lab I CRN: 24292","subject":"CHM","courseNumber":"1301","CRN":"24292"},
{"label":"CHM 1311 - 001 Inorganic Chemistry I CRN: 24293","subject":"CHM","courseNumber":"1311","CRN":"24293"},
{"label":"CHM 1311 - 002 Inorganic Chemistry I CRN: 24294","subject":"CHM","courseNumber":"1311","CRN":"24294"},
{"label":"CHM 1611 - 001 Gen'l Chem l for BIOC Majors CRN: 24295","subject":"CHM","courseNumber":"1611","CRN":"24295"},
{"label":"CHM 1903 - 001 Internship Elective CRN: 24296","subject":"CHM","courseNumber":"1903","CRN":"24296"},
{"label":"CHM 1906 - 001 Internship Elective CRN: 24297","subject":"CHM","courseNumber":"1906","CRN":"24297"},
{"label":"CHM 1909 - 001 Internship Elective CRN: 24298","subject":"CHM","courseNumber":"1909","CRN":"24298"},
{"label":"CHM 2201 - 004 Organic Chemistry Lab I CRN: 24302","subject":"CHM","courseNumber":"2201","CRN":"24302"},
{"label":"CHM 2201 - 005 Organic Chemistry Lab I CRN: 24303","subject":"CHM","courseNumber":"2201","CRN":"24303"},
{"label":"CHM 2201 - 006 Organic Chemistry Lab I CRN: 24304","subject":"CHM","courseNumber":"2201","CRN":"24304"},
{"label":"CHM 2201 - 007 Organic Chemistry Lab I CRN: 24305","subject":"CHM","courseNumber":"2201","CRN":"24305"},
{"label":"CHM 2201 - 008 Organic Chemistry Lab I CRN: 24306","subject":"CHM","courseNumber":"2201","CRN":"24306"},
{"label":"CHM 2201 - 100 Organic Chemistry Lab I CRN: 24307","subject":"CHM","courseNumber":"2201","CRN":"24307"},
{"label":"CHM 2201 - 101 Organic Chemistry Lab I CRN: 24308","subject":"CHM","courseNumber":"2201","CRN":"24308"},
{"label":"CHM 2211 - 005 Organic Chemistry I CRN: 24314","subject":"CHM","courseNumber":"2211","CRN":"24314"},
{"label":"CHM 2211 - 006 Organic Chemistry I CRN: 24315","subject":"CHM","courseNumber":"2211","CRN":"24315"},
{"label":"CHM 2211 - 010 Organic Chemistry I CRN: 24319","subject":"CHM","courseNumber":"2211","CRN":"24319"},
{"label":"CHM 2211 - 011 Organic Chemistry I CRN: 24320","subject":"CHM","courseNumber":"2211","CRN":"24320"},
{"label":"CHM 2211 - 012 Organic Chemistry I CRN: 24321","subject":"CHM","courseNumber":"2211","CRN":"24321"},
{"label":"CHM 2211 - 013 Organic Chemistry I CRN: 24322","subject":"CHM","courseNumber":"2211","CRN":"24322"},
{"label":"CHM 2211 - 014 Organic Chemistry I CRN: 24323","subject":"CHM","courseNumber":"2211","CRN":"24323"},
{"label":"CHM 2211 - 015 Organic Chemistry I CRN: 24324","subject":"CHM","courseNumber":"2211","CRN":"24324"},
{"label":"CHM 3201 - 001 Organic Chemistry Lab I CRN: 24325","subject":"CHM","courseNumber":"3201","CRN":"24325"},
{"label":"CHM 3201 - 002 Organic Chemistry Lab I CRN: 24326","subject":"CHM","courseNumber":"3201","CRN":"24326"},
{"label":"CHM 3211 - 001 Organic Chemistry I CRN: 24327","subject":"CHM","courseNumber":"3211","CRN":"24327"},
{"label":"CHM 3311 - 001 Inorganic Chem II CRN: 24330","subject":"CHM","courseNumber":"3311","CRN":"24330"},
{"label":"CHM 3405 - 002 Physical Chem Lab II CRN: 24332","subject":"CHM","courseNumber":"3405","CRN":"24332"},
{"label":"CHM 3413 - 001 Molecular Thermodynamics CRN: 24333","subject":"CHM","courseNumber":"3413","CRN":"24333"},
{"label":"CHM 3417 - 001 Biophysical Chemistry CRN: 24334","subject":"CHM","courseNumber":"3417","CRN":"24334"},
{"label":"CHM 3501 - 002 Instrumental Analysis Lab CRN: 24336","subject":"CHM","courseNumber":"3501","CRN":"24336"},
{"label":"CHM 3503 - 002 Bioanalytical Chem Lab CRN: 24338","subject":"CHM","courseNumber":"3503","CRN":"24338"},
{"label":"CHM 3503 - 003 Bioanalytical Chem Lab CRN: 24339","subject":"CHM","courseNumber":"3503","CRN":"24339"},
{"label":"CHM 3511 - 001 Instrumental Analysis CRN: 24340","subject":"CHM","courseNumber":"3511","CRN":"24340"},
{"label":"CHM 3514 - 001 Bioanalytical Chemistry CRN: 24341","subject":"CHM","courseNumber":"3514","CRN":"24341"},
{"label":"CHM 4603 - 003 Biochem Tech. and Pract. CRN: 24344","subject":"CHM","courseNumber":"4603","CRN":"24344"},
{"label":"CHM 4611 - 002 Survey of Biochemistry CRN: 24346","subject":"CHM","courseNumber":"4611","CRN":"24346"},
{"label":"CHM 4800 - 001 Research CRN: 24348","subject":"CHM","courseNumber":"4800","CRN":"24348"},
{"label":"CHM 4801 - 001 Research I CRN: 24349","subject":"CHM","courseNumber":"4801","CRN":"24349"},
{"label":"CHM 4802 - 001 Research II CRN: 24350","subject":"CHM","courseNumber":"4802","CRN":"24350"},
{"label":"CHM 4803 - 001 Research III CRN: 24351","subject":"CHM","courseNumber":"4803","CRN":"24351"},
{"label":"CHM 4851 - 001 Thesis Research I CRN: 24352","subject":"CHM","courseNumber":"4851","CRN":"24352"},
{"label":"CHM 4852 - 001 Thesis Research II CRN: 24353","subject":"CHM","courseNumber":"4852","CRN":"24353"},
{"label":"CHM 7292 - 001 Core Organic Chemistry CRN: 24354","subject":"CHM","courseNumber":"7292","CRN":"24354"},
{"label":"CHM 7391 - 001 Core Inorganic Chemistry CRN: 24355","subject":"CHM","courseNumber":"7391","CRN":"24355"},
{"label":"CHM 7517 - 001 Environmental Chemistry CRN: 24356","subject":"CHM","courseNumber":"7517","CRN":"24356"},
{"label":"CHM 7807 - 001 MS Research I CRN: 24357","subject":"CHM","courseNumber":"7807","CRN":"24357"},
{"label":"CHM 7808 - 001 MS Research II CRN: 24358","subject":"CHM","courseNumber":"7808","CRN":"24358"},
{"label":"CHM 7809 - 001 MS Research III CRN: 24359","subject":"CHM","courseNumber":"7809","CRN":"24359"},
{"label":"CHM 7810 - 001 MS Thesis CRN: 24360","subject":"CHM","courseNumber":"7810","CRN":"24360"},
{"label":"CHM 8622 - 001 Biochemical Basis of Disease CRN: 24361","subject":"CHM","courseNumber":"8622","CRN":"24361"},
{"label":"CHM 9080 - 001 Thesis Continuation CRN: 24362","subject":"CHM","courseNumber":"9080","CRN":"24362"},
{"label":"CHM 9221 - 001 TOP:Pharmaceutical Chem CRN: 24363","subject":"CHM","courseNumber":"9221","CRN":"24363"},
{"label":"CHI 1111 - 001 Basic Chinese I CRN: 24238","subject":"CHI","courseNumber":"1111","CRN":"24238"},
{"label":"CHI 1111 - 002 Basic Chinese I CRN: 24239","subject":"CHI","courseNumber":"1111","CRN":"24239"},
{"label":"CHI 1111 - 003 Basic Chinese I CRN: 24240","subject":"CHI","courseNumber":"1111","CRN":"24240"},
{"label":"CHI 1111 - 004 Basic Chinese I CRN: 24241","subject":"CHI","courseNumber":"1111","CRN":"24241"},
{"label":"CHI 1121 - 001 Intermediate Chinese I CRN: 24242","subject":"CHI","courseNumber":"1121","CRN":"24242"},
{"label":"CHI 1121 - 002 Intermediate Chinese I CRN: 24243","subject":"CHI","courseNumber":"1121","CRN":"24243"},
{"label":"CHI 1121 - 003 Intermediate Chinese I CRN: 24244","subject":"CHI","courseNumber":"1121","CRN":"24244"},
{"label":"CHI 1131 - 001 Advanced Chinese I CRN: 24245","subject":"CHI","courseNumber":"1131","CRN":"24245"},
{"label":"CHI 1133 - 001 Advanced Chinese III CRN: 24246","subject":"CHI","courseNumber":"1133","CRN":"24246"},
{"label":"CHI 1151 - 001 TOP:Translation in History CRN: 24247","subject":"CHI","courseNumber":"1151","CRN":"24247"},
{"label":"CHI 3412 - 001 TOP:Classical Chinese Lit CRN: 24248","subject":"CHI","courseNumber":"3412","CRN":"24248"},
{"label":"CHI 3412 - 100 TOP:Chinese Culinary Culture CRN: 24249","subject":"CHI","courseNumber":"3412","CRN":"24249"},
{"label":"CHI 5900 - 001 CHI:Independent Study CRN: 24250","subject":"CHI","courseNumber":"5900","CRN":"24250"},
{"label":"MSCM 8615 - 001 Financial Reporting CRN: 26397","subject":"MSCM","courseNumber":"8615","CRN":"26397"},
{"label":"MSCM 8625 - 001 Organizational Management CRN: 26398","subject":"MSCM","courseNumber":"8625","CRN":"26398"},
{"label":"MSCM 8625 - 002 Organizational Management CRN: 26399","subject":"MSCM","courseNumber":"8625","CRN":"26399"},
{"label":"MSCM 8630 - 001 Information Technology CRN: 26400","subject":"MSCM","courseNumber":"8630","CRN":"26400"},
{"label":"MSCM 8630 - 002 Information Technology CRN: 26401","subject":"MSCM","courseNumber":"8630","CRN":"26401"},
{"label":"MSCM 8635 - 001 Church Teaching and Belief CRN: 26402","subject":"MSCM","courseNumber":"8635","CRN":"26402"},
{"label":"MSCM 8670 - 001 Ind. Study in Church Mgmt. CRN: 26403","subject":"MSCM","courseNumber":"8670","CRN":"26403"},
{"label":"CEE 2211 - 002 Transportation Engineering CRN: 24152","subject":"CEE","courseNumber":"2211","CRN":"24152"},
{"label":"CEE 3107 - 001 Mechanics III: Fluid Behavior CRN: 24155","subject":"CEE","courseNumber":"3107","CRN":"24155"},
{"label":"CEE 3107 - 002 Mechanics III: Fluid Behavior CRN: 24156","subject":"CEE","courseNumber":"3107","CRN":"24156"},
{"label":"CEE 3301 - 001 Unit Operations/Pro in Env Eng CRN: 24157","subject":"CEE","courseNumber":"3301","CRN":"24157"},
{"label":"CEE 3301 - 002 Unit Operations/Pro in Env Eng CRN: 24158","subject":"CEE","courseNumber":"3301","CRN":"24158"},
{"label":"CEE 3602 - 100 AutoCAD/Civil 3d for Engineers CRN: 24160","subject":"CEE","courseNumber":"3602","CRN":"24160"},
{"label":"CEE 3802 - 001 Soil Mechanics CRN: 24161","subject":"CEE","courseNumber":"3802","CRN":"24161"},
{"label":"CEE 3802 - 002 Soil Mechanics CRN: 24162","subject":"CEE","courseNumber":"3802","CRN":"24162"},
{"label":"CEE 3802 - 003 Soil Mechanics CRN: 24163","subject":"CEE","courseNumber":"3802","CRN":"24163"},
{"label":"CEE 3802 - 004 Soil Mechanics CRN: 24164","subject":"CEE","courseNumber":"3802","CRN":"24164"},
{"label":"CEE 2105 - 001 Mechanics I:Fund. Behavior CRN: 24149","subject":"CEE","courseNumber":"2105","CRN":"24149"},
{"label":"CEE 2105 - 002 Mechanics I:Fund. Behavior CRN: 24150","subject":"CEE","courseNumber":"2105","CRN":"24150"},
{"label":"CEE 2211 - 001 Transportation Engineering CRN: 24151","subject":"CEE","courseNumber":"2211","CRN":"24151"},
{"label":"CEE 2701 - 001 CE Project Development CRN: 24153","subject":"CEE","courseNumber":"2701","CRN":"24153"},
{"label":"CEE 2701 - 002 CE Project Development CRN: 24154","subject":"CEE","courseNumber":"2701","CRN":"24154"},
{"label":"CEE 3402 - 001 Structural Steel Design CRN: 24159","subject":"CEE","courseNumber":"3402","CRN":"24159"},
{"label":"CEE 4226 - 001 Transportation System Design CRN: 24165","subject":"CEE","courseNumber":"4226","CRN":"24165"},
{"label":"CEE 4331 - 001 Solid & Hazardous Waste CRN: 24166","subject":"CEE","courseNumber":"4331","CRN":"24166"},
{"label":"CEE 4404 - 001 Reinforced Concrete Design CRN: 24167","subject":"CEE","courseNumber":"4404","CRN":"24167"},
{"label":"CEE 4521 - 001 Water Resources Egr Design CRN: 24168","subject":"CEE","courseNumber":"4521","CRN":"24168"},
{"label":"CEE 4601 - 001 CEE Capstone Design 1 CRN: 24169","subject":"CEE","courseNumber":"4601","CRN":"24169"},
{"label":"CEE 4612 - 001 CEE Undergraduate Research CRN: 24170","subject":"CEE","courseNumber":"4612","CRN":"24170"},
{"label":"EGR 7012 - 001 Management for Engineers CRN: 24808","subject":"CEE","courseNumber":"7012","CRN":"24808"},
{"label":"EGR 7012 - DL1 Management for Engineers CRN: 24809","subject":"CEE","courseNumber":"7012","CRN":"24809"},
{"label":"CEE 7405 - 001 Wood Design CRN: 24173","subject":"CEE","courseNumber":"7405","CRN":"24173"},
{"label":"CEE 7405 - DL1 Wood Design CRN: 24174","subject":"CEE","courseNumber":"7405","CRN":"24174"},
{"label":"CEE 7412 - 001 Modern Structural Analysis CRN: 24175","subject":"CEE","courseNumber":"7412","CRN":"24175"},
{"label":"CEE 7412 - DL1 Modern Structural Analysis CRN: 24176","subject":"CEE","courseNumber":"7412","CRN":"24176"},
{"label":"CEE 7511 - 001 Microbiology Enviro EGR CRN: 24177","subject":"CEE","courseNumber":"7511","CRN":"24177"},
{"label":"CEE 7511 - DL1 Microbiology Enviro EGR CRN: 24178","subject":"CEE","courseNumber":"7511","CRN":"24178"},
{"label":"CEE 7520 - 001 Environmental Compliance Sys. CRN: 24179","subject":"CEE","courseNumber":"7520","CRN":"24179"},
{"label":"CEE 7520 - DL1 Environmental Compliance Sys. CRN: 24180","subject":"CEE","courseNumber":"7520","CRN":"24180"},
{"label":"CEE 8104 - 001 Geoenvironmental Engineering CRN: 24181","subject":"CEE","courseNumber":"8104","CRN":"24181"},
{"label":"CEE 8104 - DL1 Geoenvironmental Engineering CRN: 24182","subject":"CEE","courseNumber":"8104","CRN":"24182"},
{"label":"CEE 8203 - 001 Traffic Engineering CRN: 26757","subject":"CEE","courseNumber":"8203","CRN":"26757"},
{"label":"CEE 8203 - DL1 Traffic Engineering CRN: 26758","subject":"CEE","courseNumber":"8203","CRN":"26758"},
{"label":"CEE 8437 - 001 Structural Steel CRN: 24185","subject":"CEE","courseNumber":"8437","CRN":"24185"},
{"label":"CEE 8437 - DL1 Structural Steel CRN: 24186","subject":"CEE","courseNumber":"8437","CRN":"24186"},
{"label":"CEE 8461 - 001 Design of Bridge Superstructur CRN: 24187","subject":"CEE","courseNumber":"8461","CRN":"24187"},
{"label":"CEE 8461 - DL1 Design of Bridge Superstructur CRN: 24188","subject":"CEE","courseNumber":"8461","CRN":"24188"},
{"label":"CEE 8501 - 001 Surface Water Hydrology CRN: 24189","subject":"CEE","courseNumber":"8501","CRN":"24189"},
{"label":"CEE 8501 - DL1 Surface Water Hydrology CRN: 24190","subject":"CEE","courseNumber":"8501","CRN":"24190"},
{"label":"CEE 8503 - 001 Open Channel Hydraulics CRN: 24191","subject":"CEE","courseNumber":"8503","CRN":"24191"},
{"label":"CEE 8503 - DL1 Open Channel Hydraulics CRN: 24192","subject":"CEE","courseNumber":"8503","CRN":"24192"},
{"label":"CEE 8603 - 001 CEE Graduate Practicum CRN: 24193","subject":"CEE","courseNumber":"8603","CRN":"24193"},
{"label":"CEE 9030 - 001 CE Resrch/Investigation CRN: 24194","subject":"CEE","courseNumber":"9030","CRN":"24194"},
{"label":"CEE 9031 - 001 Thesis I CRN: 24195","subject":"CEE","courseNumber":"9031","CRN":"24195"},
{"label":"CEE 9032 - 001 Thesis II CRN: 24196","subject":"CEE","courseNumber":"9032","CRN":"24196"},
{"label":"CEE 9080 - 001 Thesis Continuation CRN: 24197","subject":"CEE","courseNumber":"9080","CRN":"24197"},
{"label":"CLA 1250 - 001 Enemies of Rome CRN: 24386","subject":"CLA","courseNumber":"1250","CRN":"24386"},
{"label":"HIS 1250 - 001 TOP:Ancient Near East CRN: 25122","subject":"CLA","courseNumber":"1250","CRN":"25122"},
{"label":"HIS 2450 - 001 Archeology: Age of Pyramids CRN: 25132","subject":"CLA","courseNumber":"2450","CRN":"25132"},
{"label":"CLA 2032 - 100 Classical Mythology CRN: 24387","subject":"CLA","courseNumber":"2032","CRN":"24387"},
{"label":"CLA 5900 - 001 CLA:  Independent Study CRN: 24388","subject":"CLA","courseNumber":"5900","CRN":"24388"},
{"label":"CLA 6001 - 001 Senior Classics Capstone CRN: 24389","subject":"CLA","courseNumber":"6001","CRN":"24389"},
{"label":"CLA 7260 - DL1 History of Latin Literature CRN: 24390","subject":"CLA","courseNumber":"7260","CRN":"24390"},
{"label":"CLA 7301 - DL1 TOP:Roman Archaeology CRN: 24391","subject":"CLA","courseNumber":"7301","CRN":"24391"},
{"label":"CLA 7301 - DL2 TOP:Roman Archaeology CRN: 24392","subject":"CLA","courseNumber":"7301","CRN":"24392"},
{"label":"CLA 8000 - DL1 TOP:Seneca's Letters CRN: 24393","subject":"CLA","courseNumber":"8000","CRN":"24393"},
{"label":"CLA 8000 - DL2 TOP:Homer CRN: 24394","subject":"CLA","courseNumber":"8000","CRN":"24394"},
{"label":"PSY 2900 - 001 TOP:Drugs & Behavior CRN: 25810","subject":"CBN","courseNumber":"2900","CRN":"25810"},
{"label":"PSY 3200 - 001 Human Development CRN: 25816","subject":"CBN","courseNumber":"3200","CRN":"25816"},
{"label":"PSY 3200 - 002 Human Development CRN: 25817","subject":"CBN","courseNumber":"3200","CRN":"25817"},
{"label":"PSY 3200 - 004 Human Development CRN: 25819","subject":"CBN","courseNumber":"3200","CRN":"25819"},
{"label":"PSY 3300 - 001 Perception CRN: 25821","subject":"CBN","courseNumber":"3300","CRN":"25821"},
{"label":"PSY 3500 - 100 Psych of Personality CRN: 25822","subject":"CBN","courseNumber":"3500","CRN":"25822"},
{"label":"PSY 3600 - 001 Social Psychology CRN: 25823","subject":"CBN","courseNumber":"3600","CRN":"25823"},
{"label":"PSY 3600 - 002 Social Psychology CRN: 25824","subject":"CBN","courseNumber":"3600","CRN":"25824"},
{"label":"PSY 3800 - 001 Clinical Psychology CRN: 25826","subject":"CBN","courseNumber":"3800","CRN":"25826"},
{"label":"CBN 4000 - 001 Cell & Behavioral Neuroscience CRN: 24145","subject":"CBN","courseNumber":"4000","CRN":"24145"},
{"label":"PSY 4200 - 001 Biopsychology CRN: 25827","subject":"CBN","courseNumber":"4200","CRN":"25827"},
{"label":"PSY 4500 - 001 Cognitive Psychology CRN: 25829","subject":"CBN","courseNumber":"4500","CRN":"25829"},
{"label":"PSY 4500 - 002 Cognitive Psychology CRN: 25830","subject":"CBN","courseNumber":"4500","CRN":"25830"},
{"label":"PSY 4500 - 003 Cognitive Psychology CRN: 25831","subject":"CBN","courseNumber":"4500","CRN":"25831"},
{"label":"PSY 2000 - 001 Intro Statistics CRN: 25797","subject":"CBN","courseNumber":"2000","CRN":"25797"},
{"label":"PSY 2000 - 002 Intro Statistics CRN: 25798","subject":"CBN","courseNumber":"2000","CRN":"25798"},
{"label":"PSY 2000 - 003 Intro Statistics CRN: 25799","subject":"CBN","courseNumber":"2000","CRN":"25799"},
{"label":"PSY 2050 - 001 Research Methods in Psy. CRN: 25800","subject":"CBN","courseNumber":"2050","CRN":"25800"},
{"label":"PSY 2050 - 002 Research Methods in Psy. CRN: 25801","subject":"CBN","courseNumber":"2050","CRN":"25801"},
{"label":"PSY 2050 - 003 Research Methods in Psy. CRN: 25802","subject":"CBN","courseNumber":"2050","CRN":"25802"},
{"label":"PSY 2050 - 004 Research Methods in Psy. CRN: 25803","subject":"CBN","courseNumber":"2050","CRN":"25803"},
{"label":"PSY 2100 - 001 Sem Professional Development CRN: 25804","subject":"CBN","courseNumber":"2100","CRN":"25804"},
{"label":"PSY 2100 - 002 Sem Professional Development CRN: 25805","subject":"CBN","courseNumber":"2100","CRN":"25805"},
{"label":"PSY 2100 - 003 Sem Professional Development CRN: 25806","subject":"CBN","courseNumber":"2100","CRN":"25806"},
{"label":"PSY 3200 - 003 Human Development CRN: 25818","subject":"CBN","courseNumber":"3200","CRN":"25818"},
{"label":"PSY 3200 - 005 Human Development CRN: 26660","subject":"CBN","courseNumber":"3200","CRN":"26660"},
{"label":"PSY 3200 - 100 Human Development CRN: 25820","subject":"CBN","courseNumber":"3200","CRN":"25820"},
{"label":"PSY 3700 - 001 Psychopathology CRN: 25825","subject":"CBN","courseNumber":"3700","CRN":"25825"},
{"label":"CBN 4000 - 002 Cell & Behavioral Neuroscience CRN: 24146","subject":"CBN","courseNumber":"4000","CRN":"24146"},
{"label":"PSY 4200 - 002 Biopsychology CRN: 25828","subject":"CBN","courseNumber":"4200","CRN":"25828"},
{"label":"PSY 4600 - 001 Animal Learning & Cognition CRN: 26713","subject":"CBN","courseNumber":"4600","CRN":"26713"},
{"label":"CBN 5900 - 001 Undergraduate Research in CBN CRN: 24147","subject":"CBN","courseNumber":"5900","CRN":"24147"},
{"label":"CBN 6001 - 001 Thesis Research I CRN: 24148","subject":"CBN","courseNumber":"6001","CRN":"24148"},
{"label":"CSC 1010 - 001 Programming for All CRN: 24522","subject":"CGS","courseNumber":"1010","CRN":"24522"},
{"label":"CSC 1010 - 100 Programming for All CRN: 24523","subject":"CGS","courseNumber":"1010","CRN":"24523"},
{"label":"CSC 1010 - 101 Programming for All CRN: 24524","subject":"CGS","courseNumber":"1010","CRN":"24524"},
{"label":"CSC 1051 - 001 Algorithms & Data Struc I CRN: 24525","subject":"CGS","courseNumber":"1051","CRN":"24525"},
{"label":"CSC 1051 - 002 Algorithms & Data Struc I CRN: 24526","subject":"CGS","courseNumber":"1051","CRN":"24526"},
{"label":"CSC 1051 - 006 Algorithms & Data Struc I CRN: 24530","subject":"CGS","courseNumber":"1051","CRN":"24530"},
{"label":"MSE 2701 - 001 PSY:The Sounds of Human Lang. CRN: 25472","subject":"CGS","courseNumber":"2701","CRN":"25472"},
{"label":"MSE 2701 - 002 PSY:The Sounds of Human Lang. CRN: 25473","subject":"CGS","courseNumber":"2701","CRN":"25473"},
{"label":"PSY 2900 - 003 TOP: Devel. of Imagination CRN: 25812","subject":"CGS","courseNumber":"2900","CRN":"25812"},
{"label":"CSC 4170 - 001 Theory of Computation CRN: 24558","subject":"CGS","courseNumber":"4170","CRN":"24558"},
{"label":"CSC 5930 - 100 TOP:Large Language Models CRN: 24570","subject":"CGS","courseNumber":"5930","CRN":"24570"},
{"label":"CSC 1051 - 003 Algorithms & Data Struc I CRN: 24527","subject":"CGS","courseNumber":"1051","CRN":"24527"},
{"label":"CSC 1051 - 004 Algorithms & Data Struc I CRN: 24528","subject":"CGS","courseNumber":"1051","CRN":"24528"},
{"label":"CSC 1051 - 005 Algorithms & Data Struc I CRN: 24529","subject":"CGS","courseNumber":"1051","CRN":"24529"},
{"label":"CSC 1051 - 100 Algorithms & Data Struc I CRN: 24531","subject":"CGS","courseNumber":"1051","CRN":"24531"},
{"label":"PHI 2020 - 001 Symbolic Logic CRN: 25619","subject":"CGS","courseNumber":"2020","CRN":"25619"},
{"label":"SPA 2310 - 001 Intro to Spanish Linguistics CRN: 25973","subject":"CGS","courseNumber":"2310","CRN":"25973"},
{"label":"CSC 4170 - 002 Theory of Computation CRN: 24559","subject":"CGS","courseNumber":"4170","CRN":"24559"},
{"label":"CSC 4170 - 003 Theory of Computation CRN: 24560","subject":"CGS","courseNumber":"4170","CRN":"24560"},
{"label":"PHI 4200 - 001 Philosophy of Language CRN: 25643","subject":"CGS","courseNumber":"4200","CRN":"25643"},
{"label":"CSC 4505 - 001 Applied Machine Learning CRN: 24565","subject":"CGS","courseNumber":"4505","CRN":"24565"},
{"label":"CGS 5950 - 001 Ind. Res. in Cognitive Science CRN: 24198","subject":"CGS","courseNumber":"5950","CRN":"24198"},
{"label":"CGS 5990 - 001 TOP:Phonetics CRN: 24199","subject":"CGS","courseNumber":"5990","CRN":"24199"},
{"label":"COM 1000 - 002 Surv of Communication Studies CRN: 24396","subject":"COM","courseNumber":"1000","CRN":"24396"},
{"label":"COM 1100 - 005 Public Speaking CRN: 24407","subject":"COM","courseNumber":"1100","CRN":"24407"},
{"label":"COM 1100 - 007 Public Speaking CRN: 24409","subject":"COM","courseNumber":"1100","CRN":"24409"},
{"label":"COM 2300 - 002 Theories of Mass Communication CRN: 24424","subject":"COM","courseNumber":"2300","CRN":"24424"},
{"label":"COM 2340 - 001 Theories of Visual Com & Cultu CRN: 24425","subject":"COM","courseNumber":"2340","CRN":"24425"},
{"label":"COM 2400 - 001 Theories of Interpersonal Com CRN: 24427","subject":"COM","courseNumber":"2400","CRN":"24427"},
{"label":"COM 2400 - 002 Theories of Interpersonal Com CRN: 24428","subject":"COM","courseNumber":"2400","CRN":"24428"},
{"label":"COM 3308 - 002 Digital Image Production CRN: 24441","subject":"COM","courseNumber":"3308","CRN":"24441"},
{"label":"COM 3321 - 001 Interactive Media Design CRN: 24442","subject":"COM","courseNumber":"3321","CRN":"24442"},
{"label":"COM 3321 - 002 Interactive Media Design CRN: 24443","subject":"COM","courseNumber":"3321","CRN":"24443"},
{"label":"COM 3353 - 001 Media & Politics CRN: 24444","subject":"COM","courseNumber":"3353","CRN":"24444"},
{"label":"COM 3360 - 001 Introduction to Journalism CRN: 24447","subject":"COM","courseNumber":"3360","CRN":"24447"},
{"label":"COM 3460 - 001 Public Relations CRN: 24457","subject":"COM","courseNumber":"3460","CRN":"24457"},
{"label":"COM 3461 - 100 Advertising CRN: 24460","subject":"COM","courseNumber":"3461","CRN":"24460"},
{"label":"COM 3462 - 001 Public Relations Writing CRN: 24461","subject":"COM","courseNumber":"3462","CRN":"24461"},
{"label":"COM 3462 - 002 Public Relations Writing CRN: 26742","subject":"COM","courseNumber":"3462","CRN":"26742"},
{"label":"COM 3600 - 001 Social Justice Documentary CRN: 24465","subject":"COM","courseNumber":"3600","CRN":"24465"},
{"label":"COM 4001 - 001 Qualitative Research in COM CRN: 24468","subject":"COM","courseNumber":"4001","CRN":"24468"},
{"label":"COM 5050 - 003 Senior Project CRN: 24474","subject":"COM","courseNumber":"5050","CRN":"24474"},
{"label":"COM 5464 - 001 Public Relations Campaigns CRN: 24477","subject":"COM","courseNumber":"5464","CRN":"24477"},
{"label":"COM 1000 - 001 Surv of Communication Studies CRN: 24395","subject":"COM","courseNumber":"1000","CRN":"24395"},
{"label":"COM 1000 - 003 Surv of Communication Studies CRN: 24397","subject":"COM","courseNumber":"1000","CRN":"24397"},
{"label":"COM 1000 - 004 Surv of Communication Studies CRN: 24398","subject":"COM","courseNumber":"1000","CRN":"24398"},
{"label":"COM 1000 - 005 Surv of Communication Studies CRN: 24399","subject":"COM","courseNumber":"1000","CRN":"24399"},
{"label":"COM 1000 - 006 Surv of Communication Studies CRN: 24400","subject":"COM","courseNumber":"1000","CRN":"24400"},
{"label":"COM 1000 - 007 Surv of Communication Studies CRN: 24401","subject":"COM","courseNumber":"1000","CRN":"24401"},
{"label":"COM 1000 - 008 Surv of Communication Studies CRN: 24402","subject":"COM","courseNumber":"1000","CRN":"24402"},
{"label":"COM 1100 - 001 Public Speaking CRN: 24403","subject":"COM","courseNumber":"1100","CRN":"24403"},
{"label":"COM 1100 - 002 Public Speaking CRN: 24404","subject":"COM","courseNumber":"1100","CRN":"24404"},
{"label":"COM 1100 - 003 Public Speaking CRN: 24405","subject":"COM","courseNumber":"1100","CRN":"24405"},
{"label":"COM 1100 - 004 Public Speaking CRN: 24406","subject":"COM","courseNumber":"1100","CRN":"24406"},
{"label":"COM 1100 - 006 Public Speaking CRN: 24408","subject":"COM","courseNumber":"1100","CRN":"24408"},
{"label":"COM 1100 - 008 Public Speaking CRN: 24410","subject":"COM","courseNumber":"1100","CRN":"24410"},
{"label":"COM 1101 - DL1 Business & Prof Communication CRN: 26459","subject":"COM","courseNumber":"1101","CRN":"26459"},
{"label":"COM 1102 - 100 COM Foundations for Engrs CRN: 24411","subject":"COM","courseNumber":"1102","CRN":"24411"},
{"label":"COM 1300 - 001 Film Analysis CRN: 24412","subject":"COM","courseNumber":"1300","CRN":"24412"},
{"label":"COM 1400 - 001 Introduction to Deaf Studies CRN: 24413","subject":"COM","courseNumber":"1400","CRN":"24413"},
{"label":"COM 1903 - 001 Communication Internship CRN: 24414","subject":"COM","courseNumber":"1903","CRN":"24414"},
{"label":"COM 1903 - VAB Communication Internship CRN: 24415","subject":"COM","courseNumber":"1903","CRN":"24415"},
{"label":"COM 1906 - 001 Communication Internship CRN: 24416","subject":"COM","courseNumber":"1906","CRN":"24416"},
{"label":"COM 1909 - 001 Communication Internship CRN: 24417","subject":"COM","courseNumber":"1909","CRN":"24417"},
{"label":"COM 1910 - 001 Journalism CRN: 24418","subject":"COM","courseNumber":"1910","CRN":"24418"},
{"label":"COM 1920 - 001 Production CRN: 24419","subject":"COM","courseNumber":"1920","CRN":"24419"},
{"label":"COM 1930 - 001 PR CRN: 24420","subject":"COM","courseNumber":"1930","CRN":"24420"},
{"label":"COM 2200 - 001 Theories of Rhetoric CRN: 24421","subject":"COM","courseNumber":"2200","CRN":"24421"},
{"label":"COM 2240 - 001 Theories of Perform Studies CRN: 24422","subject":"COM","courseNumber":"2240","CRN":"24422"},
{"label":"COM 2340 - 002 Theories of Visual Com & Cultu CRN: 24426","subject":"COM","courseNumber":"2340","CRN":"24426"},
{"label":"COM 2440 - 001 Theories of Organizational Com CRN: 24429","subject":"COM","courseNumber":"2440","CRN":"24429"},
{"label":"COM 2440 - 002 Theories of Organizational Com CRN: 24430","subject":"COM","courseNumber":"2440","CRN":"24430"},
{"label":"COM 2993 - 001 Communication Internship CRN: 24431","subject":"COM","courseNumber":"2993","CRN":"24431"},
{"label":"COM 2996 - 001 Communication Internship CRN: 24432","subject":"COM","courseNumber":"2996","CRN":"24432"},
{"label":"COM 3247 - 001 Storytelling CRN: 24433","subject":"COM","courseNumber":"3247","CRN":"24433"},
{"label":"COM 3290 - 001 TOP:Rhetorics Hate&Free Speech CRN: 24434","subject":"COM","courseNumber":"3290","CRN":"24434"},
{"label":"COM 3290 - 002 TOP:RhetoricandDigitalActivsm CRN: 24435","subject":"COM","courseNumber":"3290","CRN":"24435"},
{"label":"COM 3291 - 001 TOP:Performance and Media Art CRN: 24436","subject":"COM","courseNumber":"3291","CRN":"24436"},
{"label":"COM 3301 - 001 Intro to Film & Video Prod CRN: 24437","subject":"COM","courseNumber":"3301","CRN":"24437"},
{"label":"COM 3301 - 002 Intro to Film & Video Prod CRN: 24438","subject":"COM","courseNumber":"3301","CRN":"24438"},
{"label":"COM 3303 - 001 Screenwriting CRN: 24439","subject":"COM","courseNumber":"3303","CRN":"24439"},
{"label":"COM 3308 - 001 Digital Image Production CRN: 24440","subject":"COM","courseNumber":"3308","CRN":"24440"},
{"label":"COM 3353 - 002 Media & Politics CRN: 26839","subject":"COM","courseNumber":"3353","CRN":"26839"},
{"label":"COM 3354 - 001 Media Criticism CRN: 24445","subject":"COM","courseNumber":"3354","CRN":"24445"},
{"label":"COM 3357 - 001 Critical Advertising CRN: 24446","subject":"COM","courseNumber":"3357","CRN":"24446"},
{"label":"COM 3360 - 002 Introduction to Journalism CRN: 24448","subject":"COM","courseNumber":"3360","CRN":"24448"},
{"label":"COM 3363 - 001 Broadcast Journalism CRN: 24449","subject":"COM","courseNumber":"3363","CRN":"24449"},
{"label":"COM 3367 - 001 TOP:Social Media Journalism CRN: 24450","subject":"COM","courseNumber":"3367","CRN":"24450"},
{"label":"COM 3390 - 100 TOP:SearchForSelf inAmercnFilm CRN: 24452","subject":"COM","courseNumber":"3390","CRN":"24452"},
{"label":"COM 3402 - 001 Family Communication CRN: 24453","subject":"COM","courseNumber":"3402","CRN":"24453"},
{"label":"COM 3404 - 001 Communication & Conflict CRN: 24454","subject":"COM","courseNumber":"3404","CRN":"24454"},
{"label":"COM 3443 - 001 Work Life Negotiation CRN: 24455","subject":"COM","courseNumber":"3443","CRN":"24455"},
{"label":"COM 3449 - 001 TOP:Org forReproductiveJustice CRN: 24456","subject":"COM","courseNumber":"3449","CRN":"24456"},
{"label":"COM 3460 - 002 Public Relations CRN: 24458","subject":"COM","courseNumber":"3460","CRN":"24458"},
{"label":"COM 3461 - 001 Advertising CRN: 24459","subject":"COM","courseNumber":"3461","CRN":"24459"},
{"label":"COM 3465 - 001 TOP:Race,Justice&Advertising CRN: 24463","subject":"COM","courseNumber":"3465","CRN":"24463"},
{"label":"COM 3490 - 001 TOP:Facilitating Dialogue CRN: 24464","subject":"COM","courseNumber":"3490","CRN":"24464"},
{"label":"COM 3600 - 002 Social Justice Documentary CRN: 24466","subject":"COM","courseNumber":"3600","CRN":"24466"},
{"label":"COM 3993 - VAB WFI Internship CRN: 24467","subject":"COM","courseNumber":"3993","CRN":"24467"},
{"label":"COM 4001 - 002 Qualitative Research in COM CRN: 24469","subject":"COM","courseNumber":"4001","CRN":"24469"},
{"label":"COM 4002 - 001 Quantitative Research in COM CRN: 24470","subject":"COM","courseNumber":"4002","CRN":"24470"},
{"label":"COM 4002 - 002 Quantitative Research in COM CRN: 24471","subject":"COM","courseNumber":"4002","CRN":"24471"},
{"label":"GIS 5011 - 001 TOP: U.S. and Soviet War Films CRN: 25083","subject":"COM","courseNumber":"5011","CRN":"25083"},
{"label":"COM 5050 - 001 Senior Project CRN: 24472","subject":"COM","courseNumber":"5050","CRN":"24472"},
{"label":"COM 5050 - 002 Senior Project CRN: 24473","subject":"COM","courseNumber":"5050","CRN":"24473"},
{"label":"COM 5100 - 001 Directed Study CRN: 24475","subject":"COM","courseNumber":"5100","CRN":"24475"},
{"label":"COM 5100 - 002 Directed Study CRN: 24476","subject":"COM","courseNumber":"5100","CRN":"24476"},
{"label":"COM 5200 - 100 TOP:Social Media Strategy CRN: 26408","subject":"COM","courseNumber":"5200","CRN":"26408"},
{"label":"COM 5200 - 101 TOP:Crisis Communication CRN: 26409","subject":"COM","courseNumber":"5200","CRN":"26409"},
{"label":"COM 5300 - 100 IGRDialogueIdentity&SociaJusti CRN: 26410","subject":"COM","courseNumber":"5300","CRN":"26410"},
{"label":"COM 5300 - 112 DialogueIdentity&SociaJust:SJD CRN: 26803","subject":"COM","courseNumber":"5300","CRN":"26803"},
{"label":"COM 5300 - 113 DialogueIdentity&SociaJust:SJD CRN: 26804","subject":"COM","courseNumber":"5300","CRN":"26804"},
{"label":"COM 5464 - 002 Public Relations Campaigns CRN: 24478","subject":"COM","courseNumber":"5464","CRN":"24478"},
{"label":"COM 5600 - 100 ProductionWorkshp:Interviewing CRN: 26411","subject":"COM","courseNumber":"5600","CRN":"26411"},
{"label":"COM 8002 - 001 Quantitative Research Methods CRN: 24479","subject":"COM","courseNumber":"8002","CRN":"24479"},
{"label":"COM 8003 - 001 Internship in Communication CRN: 24480","subject":"COM","courseNumber":"8003","CRN":"24480"},
{"label":"COM 8004 - 001 Communication Directed Study CRN: 24481","subject":"COM","courseNumber":"8004","CRN":"24481"},
{"label":"COM 8004 - 002 Communication Directed Study CRN: 24482","subject":"COM","courseNumber":"8004","CRN":"24482"},
{"label":"COM 8005 - 001 TOP:Space&Time in Organizatns CRN: 24483","subject":"COM","courseNumber":"8005","CRN":"24483"},
{"label":"COM 8006 - 001 Thesis Direction I CRN: 24484","subject":"COM","courseNumber":"8006","CRN":"24484"},
{"label":"COM 8007 - 001 Thesis Direction II CRN: 24485","subject":"COM","courseNumber":"8007","CRN":"24485"},
{"label":"COM 8008 - 001 Orientation to Graduate Study CRN: 24486","subject":"COM","courseNumber":"8008","CRN":"24486"},
{"label":"COM 8014 - 001 LeveragingAIResearch&Writing CRN: 26764","subject":"COM","courseNumber":"8014","CRN":"26764"},
{"label":"COM 8100 - 001 The Communication Perspective CRN: 24487","subject":"COM","courseNumber":"8100","CRN":"24487"},
{"label":"COM 8208 - 001 Public Communication Campaigns CRN: 24489","subject":"COM","courseNumber":"8208","CRN":"24489"},
{"label":"COM 8313 - 001 Communication Directed Study CRN: 24490","subject":"COM","courseNumber":"8313","CRN":"24490"},
{"label":"COM 9080 - 001 Thesis Continuation CRN: 24491","subject":"COM","courseNumber":"9080","CRN":"24491"},
{"label":"SCI 5300 - 001 CAP:Readings in Epidemiology CRN: 25885","subject":"SCI","courseNumber":"5300","CRN":"25885"},
{"label":"SCI 1903 - 001 Internship Elective CRN: 25881","subject":"SCI","courseNumber":"1903","CRN":"25881"},
{"label":"SCI 1906 - 001 Internship Elective CRN: 25882","subject":"SCI","courseNumber":"1906","CRN":"25882"},
{"label":"SCI 2993 - 001 Internship Elective CRN: 25883","subject":"SCI","courseNumber":"2993","CRN":"25883"},
{"label":"SCI 2996 - 001 Internship Elective CRN: 25884","subject":"SCI","courseNumber":"2996","CRN":"25884"},
{"label":"SCI 5900 - 001 Independent Research CRN: 25886","subject":"SCI","courseNumber":"5900","CRN":"25886"},
{"label":"CSC 1052 - 001 Algorithms & Data Struc II CRN: 24532","subject":"CSC","courseNumber":"1052","CRN":"24532"},
{"label":"CSC 1300 - 003 Discrete Structures CRN: 24536","subject":"CSC","courseNumber":"1300","CRN":"24536"},
{"label":"CSC 1700 - 002 Analysis of Algorithms CRN: 24539","subject":"CSC","courseNumber":"1700","CRN":"24539"},
{"label":"CSC 1800 - 100 Organ of Prog Languages CRN: 24543","subject":"CSC","courseNumber":"1800","CRN":"24543"},
{"label":"PHI 2180 - 001 Computer Ethics CRN: 25627","subject":"CSC","courseNumber":"2180","CRN":"25627"},
{"label":"PHI 2180 - 002 Computer Ethics CRN: 25628","subject":"CSC","courseNumber":"2180","CRN":"25628"},
{"label":"CSC 2400 - 002 Computer Systems I CRN: 24551","subject":"CSC","courseNumber":"2400","CRN":"24551"},
{"label":"CSC 3010 - 001 Overview of Cybersecurity CRN: 24555","subject":"CSC","courseNumber":"3010","CRN":"24555"},
{"label":"CSC 4450 - 100 Digital Forensics CRN: 24561","subject":"CSC","courseNumber":"4450","CRN":"24561"},
{"label":"CSC 4480 - 001 Principles of Database Systems CRN: 24562","subject":"CSC","courseNumber":"4480","CRN":"24562"},
{"label":"CSC 4790 - 001 Senior Projects CRN: 24566","subject":"CSC","courseNumber":"4790","CRN":"24566"},
{"label":"CSC 4790 - 002 Senior Projects CRN: 24567","subject":"CSC","courseNumber":"4790","CRN":"24567"},
{"label":"CSC 4790 - 100 Senior Projects CRN: 24568","subject":"CSC","courseNumber":"4790","CRN":"24568"},
{"label":"CSC 9010 - 002 TOP:DevOps:Tools & Techniques CRN: 24588","subject":"CSC","courseNumber":"9010","CRN":"24588"},
{"label":"CSC 1020 - DL1 Computing and the Web CRN: 26461","subject":"CSC","courseNumber":"1020","CRN":"26461"},
{"label":"CSC 1052 - 100 Algorithms & Data Struc II CRN: 24533","subject":"CSC","courseNumber":"1052","CRN":"24533"},
{"label":"CSC 1300 - 001 Discrete Structures CRN: 24534","subject":"CSC","courseNumber":"1300","CRN":"24534"},
{"label":"CSC 1300 - 002 Discrete Structures CRN: 24535","subject":"CSC","courseNumber":"1300","CRN":"24535"},
{"label":"CSC 1300 - 100 Discrete Structures CRN: 24537","subject":"CSC","courseNumber":"1300","CRN":"24537"},
{"label":"CSC 1700 - 001 Analysis of Algorithms CRN: 24538","subject":"CSC","courseNumber":"1700","CRN":"24538"},
{"label":"CSC 1700 - 003 Analysis of Algorithms CRN: 24540","subject":"CSC","courseNumber":"1700","CRN":"24540"},
{"label":"CSC 1800 - 001 Organ of Prog Languages CRN: 24541","subject":"CSC","courseNumber":"1800","CRN":"24541"},
{"label":"CSC 1800 - 002 Organ of Prog Languages CRN: 24542","subject":"CSC","courseNumber":"1800","CRN":"24542"},
{"label":"CSC 1990 - 001 Enrichment Sem in Computing CRN: 24544","subject":"CSC","courseNumber":"1990","CRN":"24544"},
{"label":"CSC 2053 - 001 Platform Based Computing CRN: 24545","subject":"CSC","courseNumber":"2053","CRN":"24545"},
{"label":"CSC 2053 - 002 Platform Based Computing CRN: 24546","subject":"CSC","courseNumber":"2053","CRN":"24546"},
{"label":"CSC 2053 - 100 Platform Based Computing CRN: 24547","subject":"CSC","courseNumber":"2053","CRN":"24547"},
{"label":"CSC 2053 - 101 Platform Based Computing CRN: 24548","subject":"CSC","courseNumber":"2053","CRN":"24548"},
{"label":"CSC 2300 - 001 Statistics for Computing CRN: 24549","subject":"CSC","courseNumber":"2300","CRN":"24549"},
{"label":"CSC 2400 - 001 Computer Systems I CRN: 24550","subject":"CSC","courseNumber":"2400","CRN":"24550"},
{"label":"CSC 2400 - 100 Computer Systems I CRN: 24552","subject":"CSC","courseNumber":"2400","CRN":"24552"},
{"label":"CSC 2993 - 001 Internship in Computing CRN: 24553","subject":"CSC","courseNumber":"2993","CRN":"24553"},
{"label":"CSC 2993 - VAB Internship Computing-Vatican CRN: 24554","subject":"CSC","courseNumber":"2993","CRN":"24554"},
{"label":"CSC 3010 - 100 Overview of Cybersecurity CRN: 24556","subject":"CSC","courseNumber":"3010","CRN":"24556"},
{"label":"CSC 3150 - 001 Game Development CRN: 24557","subject":"CSC","courseNumber":"3150","CRN":"24557"},
{"label":"CSC 4480 - 002 Principles of Database Systems CRN: 24563","subject":"CSC","courseNumber":"4480","CRN":"24563"},
{"label":"CSC 4480 - 100 Principles of Database Systems CRN: 24564","subject":"CSC","courseNumber":"4480","CRN":"24564"},
{"label":"CSC 5930 - 001 TOP:Sec&Priv CyberPhysSys CRN: 24569","subject":"CSC","courseNumber":"5930","CRN":"24569"},
{"label":"CSC 5993 - 001 Independent Study CRN: 24571","subject":"CSC","courseNumber":"5993","CRN":"24571"},
{"label":"CSC 5993 - 002 Independent Study CRN: 24572","subject":"CSC","courseNumber":"5993","CRN":"24572"},
{"label":"CSC 5993 - 003 Independent Study CRN: 24573","subject":"CSC","courseNumber":"5993","CRN":"24573"},
{"label":"CSC 5993 - 100 Independent Study CRN: 24574","subject":"CSC","courseNumber":"5993","CRN":"24574"},
{"label":"CSC 5993 - 101 Independent Study CRN: 24575","subject":"CSC","courseNumber":"5993","CRN":"24575"},
{"label":"CSC 8000 - 001 Found of Algor & Data Struc CRN: 24576","subject":"CSC","courseNumber":"8000","CRN":"24576"},
{"label":"CSC 8200 - DL1 Hlth Informatics & EHealth Sys CRN: 26412","subject":"CSC","courseNumber":"8200","CRN":"26412"},
{"label":"CSC 8210 - DL1 Hlthcare Safety Secur Law&Eth CRN: 26462","subject":"CSC","courseNumber":"8210","CRN":"26462"},
{"label":"CSC 8220 - DL1 Software Engr&Hlth Informatic CRN: 26413","subject":"CSC","courseNumber":"8220","CRN":"26413"},
{"label":"CSC 8230 - DL1 Database System&Hlth Infomatic CRN: 26463","subject":"CSC","courseNumber":"8230","CRN":"26463"},
{"label":"CSC 8301 - 001 Design and Ana of Algs CRN: 24577","subject":"CSC","courseNumber":"8301","CRN":"24577"},
{"label":"CSC 8310 - 001 Programming Languages CRN: 24578","subject":"CSC","courseNumber":"8310","CRN":"24578"},
{"label":"CSC 8490 - 001 Database Systems CRN: 24579","subject":"CSC","courseNumber":"8490","CRN":"24579"},
{"label":"CSC 8515 - 001 Machine Learning CRN: 24580","subject":"CSC","courseNumber":"8515","CRN":"24580"},
{"label":"CSC 8540 - DL1 Software Engineering CRN: 24581","subject":"CSC","courseNumber":"8540","CRN":"24581"},
{"label":"CSC 8542 - 001 Software Design and Evolution CRN: 24582","subject":"CSC","courseNumber":"8542","CRN":"24582"},
{"label":"CSC 8544 - 001 Software Project Management CRN: 24583","subject":"CSC","courseNumber":"8544","CRN":"24583"},
{"label":"CSC 8990 - 001 Graduate Computing Practicum CRN: 24584","subject":"CSC","courseNumber":"8990","CRN":"24584"},
{"label":"CSC 8991 - 001 Grad Practicum Continuation CRN: 24585","subject":"CSC","courseNumber":"8991","CRN":"24585"},
{"label":"CSC 9000 - 001 Guided Study CRN: 24586","subject":"CSC","courseNumber":"9000","CRN":"24586"},
{"label":"CSC 9010 - 001 TOP:Large Language Models CRN: 24587","subject":"CSC","courseNumber":"9010","CRN":"24587"},
{"label":"CSC 9010 - 003 TOP:Networks and Security CRN: 24589","subject":"CSC","courseNumber":"9010","CRN":"24589"},
{"label":"CSC 9025 - 001 Grand Challenges of Computing CRN: 24590","subject":"CSC","courseNumber":"9025","CRN":"24590"},
{"label":"CSC 9026 - 001 Grand Challenges of Comp Cont CRN: 24591","subject":"CSC","courseNumber":"9026","CRN":"24591"},
{"label":"CSC 9030 - 001 Thesis Direction CRN: 24592","subject":"CSC","courseNumber":"9030","CRN":"24592"},
{"label":"CSC 9080 - 001 Thesis Continuation CRN: 24593","subject":"CSC","courseNumber":"9080","CRN":"24593"},
{"label":"COU 3400 - 001 Culturally Competent Counselng CRN: 24493","subject":"COU","courseNumber":"3400","CRN":"24493"},
{"label":"COU 2000 - 001 Introduction to Counseling CRN: 24492","subject":"COU","courseNumber":"2000","CRN":"24492"},
{"label":"COU 3500 - 100 Devlp Perspective to Diagnosis CRN: 24494","subject":"COU","courseNumber":"3500","CRN":"24494"},
{"label":"CHR 8850 - 002 Intern. I Clin. Mental Health CRN: 24374","subject":"CHR","courseNumber":"8850","CRN":"24374"},
{"label":"CHR 8880 - 001 Psychopathology CRN: 24378","subject":"CHR","courseNumber":"8880","CRN":"24378"},
{"label":"CHR 8880 - 002 Psychopathology CRN: 24379","subject":"CHR","courseNumber":"8880","CRN":"24379"},
{"label":"CHR 8884 - 002 Human Growth and Development CRN: 24383","subject":"CHR","courseNumber":"8884","CRN":"24383"},
{"label":"CHR 7235 - 001 Mindfulness&Emotion Regulation CRN: 26456","subject":"CHR","courseNumber":"7235","CRN":"26456"},
{"label":"CHR 7601 - 001 Eating Disorders CRN: 26406","subject":"CHR","courseNumber":"7601","CRN":"26406"},
{"label":"CHR 7816 - 001 Homeless and College Bound CRN: 26457","subject":"CHR","courseNumber":"7816","CRN":"26457"},
{"label":"CHR 7820 - 001 FinancialAid College Admission CRN: 26407","subject":"CHR","courseNumber":"7820","CRN":"26407"},
{"label":"CHR 8604 - 001 Research & Evaluation CRN: 24364","subject":"CHR","courseNumber":"8604","CRN":"24364"},
{"label":"CHR 8605 - 001 Lab in Counseling Skills CRN: 24365","subject":"CHR","courseNumber":"8605","CRN":"24365"},
{"label":"CHR 8605 - 002 Lab in Counseling Skills CRN: 24366","subject":"CHR","courseNumber":"8605","CRN":"24366"},
{"label":"CHR 8605 - 003 Lab in Counseling Skills CRN: 24367","subject":"CHR","courseNumber":"8605","CRN":"24367"},
{"label":"CHR 8680 - 001 School Counseling Orientation CRN: 24368","subject":"CHR","courseNumber":"8680","CRN":"24368"},
{"label":"CHR 8687 - 001 Counseling Theory & Prac CRN: 24369","subject":"CHR","courseNumber":"8687","CRN":"24369"},
{"label":"CHR 8687 - 002 Counseling Theory & Prac CRN: 24370","subject":"CHR","courseNumber":"8687","CRN":"24370"},
{"label":"CHR 8688 - 001 Clinical Mental Health Orient CRN: 24371","subject":"CHR","courseNumber":"8688","CRN":"24371"},
{"label":"CHR 8692 - 001 Internship I School Counseling CRN: 24372","subject":"CHR","courseNumber":"8692","CRN":"24372"},
{"label":"CHR 8850 - 001 Intern. I Clin. Mental Health CRN: 24373","subject":"CHR","courseNumber":"8850","CRN":"24373"},
{"label":"CHR 8854 - 001 Counseling Children&Adolescent CRN: 24375","subject":"CHR","courseNumber":"8854","CRN":"24375"},
{"label":"CHR 8855 - 001 Appraisal in Counseling CRN: 24376","subject":"CHR","courseNumber":"8855","CRN":"24376"},
{"label":"CHR 8855 - 002 Appraisal in Counseling CRN: 24377","subject":"CHR","courseNumber":"8855","CRN":"24377"},
{"label":"CHR 8883 - 001 Ethics & Prof. Orientation CRN: 24380","subject":"CHR","courseNumber":"8883","CRN":"24380"},
{"label":"CHR 8883 - 002 Ethics & Prof. Orientation CRN: 24381","subject":"CHR","courseNumber":"8883","CRN":"24381"},
{"label":"CHR 8884 - 001 Human Growth and Development CRN: 24382","subject":"CHR","courseNumber":"8884","CRN":"24382"},
{"label":"CHR 8910 - 001 College Admissions Process CRN: 24384","subject":"CHR","courseNumber":"8910","CRN":"24384"},
{"label":"CHR 8915 - 001 Foundations of College Counsel CRN: 26458","subject":"CHR","courseNumber":"8915","CRN":"26458"},
{"label":"CHR 9034 - 001 Thesis I CRN: 24385","subject":"CHR","courseNumber":"9034","CRN":"24385"},
{"label":"CRM 1001 - 001 Introduction to Criminology CRN: 24500","subject":"CRM","courseNumber":"1001","CRN":"24500"},
{"label":"CRM 1001 - 005 Introduction to Criminology CRN: 24504","subject":"CRM","courseNumber":"1001","CRN":"24504"},
{"label":"CRM 3001 - 003 Justice and Society CRN: 24510","subject":"CRM","courseNumber":"3001","CRN":"24510"},
{"label":"CRM 3100 - 001 Juvenile Delinquency CRN: 24511","subject":"CRM","courseNumber":"3100","CRN":"24511"},
{"label":"CRM 3850 - 001 Drugs and Society CRN: 24514","subject":"CRM","courseNumber":"3850","CRN":"24514"},
{"label":"CRM 4000 - 001 TOP:Crime&ViolenceinLatinAmrca CRN: 24517","subject":"CRM","courseNumber":"4000","CRN":"24517"},
{"label":"CRM 5100 - 001 Criminological Theory CRN: 24519","subject":"CRM","courseNumber":"5100","CRN":"24519"},
{"label":"SOC 5300 - 001 Data Analysis-Social Scientist CRN: 25915","subject":"CRM","courseNumber":"5300","CRN":"25915"},
{"label":"SOC 5400 - 001 Applied Research in Soc CRN: 25916","subject":"CRM","courseNumber":"5400","CRN":"25916"},
{"label":"CRM 1001 - 002 Introduction to Criminology CRN: 24501","subject":"CRM","courseNumber":"1001","CRN":"24501"},
{"label":"CRM 1001 - 003 Introduction to Criminology CRN: 24502","subject":"CRM","courseNumber":"1001","CRN":"24502"},
{"label":"CRM 1001 - 004 Introduction to Criminology CRN: 24503","subject":"CRM","courseNumber":"1001","CRN":"24503"},
{"label":"CRM 1001 - 006 Introduction to Criminology CRN: 24505","subject":"CRM","courseNumber":"1001","CRN":"24505"},
{"label":"CRM 1001 - 007 Introduction to Criminology CRN: 24506","subject":"CRM","courseNumber":"1001","CRN":"24506"},
{"label":"CRM 1001 - 008 Introduction to Criminology CRN: 24507","subject":"CRM","courseNumber":"1001","CRN":"24507"},
{"label":"SOC 2200 - 001 Sociology of Deviance CRN: 25905","subject":"CRM","courseNumber":"2200","CRN":"25905"},
{"label":"CRM 3001 - 001 Justice and Society CRN: 24508","subject":"CRM","courseNumber":"3001","CRN":"24508"},
{"label":"CRM 3001 - 002 Justice and Society CRN: 24509","subject":"CRM","courseNumber":"3001","CRN":"24509"},
{"label":"CRM 3300 - 100 Criminal Courts CRN: 24512","subject":"CRM","courseNumber":"3300","CRN":"24512"},
{"label":"CRM 3350 - 001 The Supreme Court CRN: 24513","subject":"CRM","courseNumber":"3350","CRN":"24513"},
{"label":"CRM 3850 - 002 Drugs and Society CRN: 24515","subject":"CRM","courseNumber":"3850","CRN":"24515"},
{"label":"CRM 3900 - 001 Race, Crime, and Justice CRN: 24516","subject":"CRM","courseNumber":"3900","CRN":"24516"},
{"label":"CRM 5000 - 001 Criminology Independent Study CRN: 24518","subject":"CRM","courseNumber":"5000","CRN":"24518"},
{"label":"SOC 5400 - 002 Applied Research in Soc CRN: 25917","subject":"CRM","courseNumber":"5400","CRN":"25917"},
{"label":"CRM 6003 - 001 Internship CRN: 24520","subject":"CRM","courseNumber":"6003","CRN":"24520"},
{"label":"CRM 6500 - 001 Senior Seminar CRN: 24521","subject":"CRM","courseNumber":"6500","CRN":"24521"},
{"label":"PJ 4000 - 001 TOP:The Nature of Genocide CRN: 25730","subject":"CST","courseNumber":"4000","CRN":"25730"},
{"label":"ENG 2043 - 001 Worldwide Popular Culture CRN: 24863","subject":"CST","courseNumber":"2043","CRN":"24863"},
{"label":"CST 2100 - 001 Intro. to Cultural Studies CRN: 24594","subject":"CST","courseNumber":"2100","CRN":"24594"},
{"label":"ENG 2304 - 001 Cont World Lit & Environment CRN: 24866","subject":"CST","courseNumber":"2304","CRN":"24866"},
{"label":"PHI 2410 - DL1 Philosophy of Sex & Love CRN: 26487","subject":"CST","courseNumber":"2410","CRN":"26487"},
{"label":"PHI 2420 - 001 Philosophy of Women CRN: 25630","subject":"CST","courseNumber":"2420","CRN":"25630"},
{"label":"HIS 2998 - DL1 TOP:Super Power America CRN: 26465","subject":"CST","courseNumber":"2998","CRN":"26465"},
{"label":"ENG 4651 - 001 Lives of the Undocumented CRN: 24878","subject":"CST","courseNumber":"4651","CRN":"24878"},
{"label":"PJ 5000 - 002 TOP:Growing Justice Agricultre CRN: 25732","subject":"CST","courseNumber":"5000","CRN":"25732"},
{"label":"CST 5900 - 001 Independent Study CRN: 24595","subject":"CST","courseNumber":"5900","CRN":"24595"},
{"label":"ECO 1001 - 004 Intro to Micro CRN: 24682","subject":"ECO","courseNumber":"1001","CRN":"24682"},
{"label":"ECO 1001 - 005 Intro to Micro CRN: 24683","subject":"ECO","courseNumber":"1001","CRN":"24683"},
{"label":"ECO 1001 - 007 Intro to Micro CRN: 24685","subject":"ECO","courseNumber":"1001","CRN":"24685"},
{"label":"ECO 1001 - 008 Intro to Micro CRN: 24686","subject":"ECO","courseNumber":"1001","CRN":"24686"},
{"label":"ECO 1001 - 009 Intro to Micro CRN: 24687","subject":"ECO","courseNumber":"1001","CRN":"24687"},
{"label":"ECO 1001 - 010 Intro to Micro CRN: 24688","subject":"ECO","courseNumber":"1001","CRN":"24688"},
{"label":"ECO 1001 - 011 Intro to Micro CRN: 24689","subject":"ECO","courseNumber":"1001","CRN":"24689"},
{"label":"ECO 1001 - 012 Intro to Micro CRN: 24690","subject":"ECO","courseNumber":"1001","CRN":"24690"},
{"label":"ECO 1001 - 013 Intro to Micro CRN: 24691","subject":"ECO","courseNumber":"1001","CRN":"24691"},
{"label":"ECO 1001 - 014 Intro to Micro CRN: 24692","subject":"ECO","courseNumber":"1001","CRN":"24692"},
{"label":"ECO 1001 - 015 Intro to Micro CRN: 24693","subject":"ECO","courseNumber":"1001","CRN":"24693"},
{"label":"ECO 1001 - 021 Intro to Micro CRN: 24699","subject":"ECO","courseNumber":"1001","CRN":"24699"},
{"label":"ECO 1002 - 001 Intro to Macro CRN: 24701","subject":"ECO","courseNumber":"1002","CRN":"24701"},
{"label":"ECO 1002 - 002 Intro to Macro CRN: 24702","subject":"ECO","courseNumber":"1002","CRN":"24702"},
{"label":"ECO 1002 - 003 Intro to Macro CRN: 24703","subject":"ECO","courseNumber":"1002","CRN":"24703"},
{"label":"ECO 2101 - 001 Macroeconomic Theory CRN: 24706","subject":"ECO","courseNumber":"2101","CRN":"24706"},
{"label":"ECO 2101 - 004 Macroeconomic Theory CRN: 24709","subject":"ECO","courseNumber":"2101","CRN":"24709"},
{"label":"ECO 2101 - 005 Macroeconomic Theory CRN: 24710","subject":"ECO","courseNumber":"2101","CRN":"24710"},
{"label":"ECO 2102 - 003 Microeconomic Theory CRN: 24713","subject":"ECO","courseNumber":"2102","CRN":"24713"},
{"label":"ECO 2102 - 004 Microeconomic Theory CRN: 24714","subject":"ECO","courseNumber":"2102","CRN":"24714"},
{"label":"ECO 3108 - 003 Global Political Econ CRN: 24720","subject":"ECO","courseNumber":"3108","CRN":"24720"},
{"label":"ECO 3108 - 004 Global Political Econ CRN: 24721","subject":"ECO","courseNumber":"3108","CRN":"24721"},
{"label":"ECO 3108 - 005 Global Political Econ CRN: 24722","subject":"ECO","courseNumber":"3108","CRN":"24722"},
{"label":"ECO 3108 - 006 Global Political Econ CRN: 24723","subject":"ECO","courseNumber":"3108","CRN":"24723"},
{"label":"ECO 3108 - 007 Global Political Econ CRN: 24724","subject":"ECO","courseNumber":"3108","CRN":"24724"},
{"label":"ECO 3118 - 001 Women in the Economy CRN: 24725","subject":"ECO","courseNumber":"3118","CRN":"24725"},
{"label":"ECO 3120 - 001 Financial Economics CRN: 24726","subject":"ECO","courseNumber":"3120","CRN":"24726"},
{"label":"ECO 3120 - 002 Financial Economics CRN: 24727","subject":"ECO","courseNumber":"3120","CRN":"24727"},
{"label":"ECO 3125 - 001 Industr Org & Public Pol CRN: 24728","subject":"ECO","courseNumber":"3125","CRN":"24728"},
{"label":"ECO 3127 - 001 Development Economics CRN: 24729","subject":"ECO","courseNumber":"3127","CRN":"24729"},
{"label":"ECO 3137 - 001 Intro to Econometrics CRN: 24731","subject":"ECO","courseNumber":"3137","CRN":"24731"},
{"label":"ECO 3137 - 002 Intro to Econometrics CRN: 24732","subject":"ECO","courseNumber":"3137","CRN":"24732"},
{"label":"ECO 3137 - 003 Intro to Econometrics CRN: 24733","subject":"ECO","courseNumber":"3137","CRN":"24733"},
{"label":"ECO 3200 - 005 TOP:ContemporaryMonetaryPolicy CRN: 26656","subject":"ECO","courseNumber":"3200","CRN":"26656"},
{"label":"ECO 4200 - 001 TOP:Firms&Industry CRN: 24742","subject":"ECO","courseNumber":"4200","CRN":"24742"},
{"label":"ECO 1001 - 001 Intro to Micro CRN: 24679","subject":"ECO","courseNumber":"1001","CRN":"24679"},
{"label":"ECO 1001 - 002 Intro to Micro CRN: 24680","subject":"ECO","courseNumber":"1001","CRN":"24680"},
{"label":"ECO 1001 - 003 Intro to Micro CRN: 24681","subject":"ECO","courseNumber":"1001","CRN":"24681"},
{"label":"ECO 1001 - 006 Intro to Micro CRN: 24684","subject":"ECO","courseNumber":"1001","CRN":"24684"},
{"label":"ECO 1001 - 016 Intro to Micro CRN: 24694","subject":"ECO","courseNumber":"1001","CRN":"24694"},
{"label":"ECO 1001 - 017 Intro to Micro CRN: 24695","subject":"ECO","courseNumber":"1001","CRN":"24695"},
{"label":"ECO 1001 - 018 Intro to Micro CRN: 24696","subject":"ECO","courseNumber":"1001","CRN":"24696"},
{"label":"ECO 1001 - 019 Intro to Micro CRN: 24697","subject":"ECO","courseNumber":"1001","CRN":"24697"},
{"label":"ECO 1001 - 020 Intro to Micro CRN: 24698","subject":"ECO","courseNumber":"1001","CRN":"24698"},
{"label":"ECO 1001 - H01 HON:Intro to Micro CRN: 24700","subject":"ECO","courseNumber":"1001","CRN":"24700"},
{"label":"ECO 1001 - VSB SB Econ Placeholder CRN: 26617","subject":"ECO","courseNumber":"1001","CRN":"26617"},
{"label":"ECO 1903 - AR Internship Elective CRN: 24704","subject":"ECO","courseNumber":"1903","CRN":"24704"},
{"label":"ECO 1903 - SB Internship Elective CRN: 24705","subject":"ECO","courseNumber":"1903","CRN":"24705"},
{"label":"ECO 2101 - 002 Macroeconomic Theory CRN: 24707","subject":"ECO","courseNumber":"2101","CRN":"24707"},
{"label":"ECO 2101 - 003 Macroeconomic Theory CRN: 24708","subject":"ECO","courseNumber":"2101","CRN":"24708"},
{"label":"ECO 2102 - 001 Microeconomic Theory CRN: 24711","subject":"ECO","courseNumber":"2102","CRN":"24711"},
{"label":"ECO 2102 - 002 Microeconomic Theory CRN: 24712","subject":"ECO","courseNumber":"2102","CRN":"24712"},
{"label":"ECO 2993 - AR Internship CRN: 24715","subject":"ECO","courseNumber":"2993","CRN":"24715"},
{"label":"ECO 3106 - 001 Econ of Money & Banking CRN: 24716","subject":"ECO","courseNumber":"3106","CRN":"24716"},
{"label":"ECO 3107 - 001 FED Challenge CRN: 24717","subject":"ECO","courseNumber":"3107","CRN":"24717"},
{"label":"ECO 3108 - 001 Global Political Econ CRN: 24718","subject":"ECO","courseNumber":"3108","CRN":"24718"},
{"label":"ECO 3108 - 002 Global Political Econ CRN: 24719","subject":"ECO","courseNumber":"3108","CRN":"24719"},
{"label":"ECO 3128 - 001 Intro Mathematical Econ CRN: 24730","subject":"ECO","courseNumber":"3128","CRN":"24730"},
{"label":"ECO 3137 - 004 Intro to Econometrics CRN: 24734","subject":"ECO","courseNumber":"3137","CRN":"24734"},
{"label":"ECO 3137 - 005 Intro to Econometrics CRN: 24735","subject":"ECO","courseNumber":"3137","CRN":"24735"},
{"label":"ECO 3137 - 006 Intro to Econometrics CRN: 24736","subject":"ECO","courseNumber":"3137","CRN":"24736"},
{"label":"ECO 3138 - 001 Game Theory CRN: 24737","subject":"ECO","courseNumber":"3138","CRN":"24737"},
{"label":"ECO 3200 - 001 TOP:Economic Rights CRN: 24738","subject":"ECO","courseNumber":"3200","CRN":"24738"},
{"label":"ECO 3200 - 002 TOP:Eco&BusinessPostRformChina CRN: 24739","subject":"ECO","courseNumber":"3200","CRN":"24739"},
{"label":"ECO 3200 - 003 TOP:ManagerialEconomics CRN: 24740","subject":"ECO","courseNumber":"3200","CRN":"24740"},
{"label":"ECO 3200 - 004 TOP:TimeSeries&Forecasting CRN: 24741","subject":"ECO","courseNumber":"3200","CRN":"24741"},
{"label":"ECO 4200 - 002 TOP:Firms&Industry CRN: 24743","subject":"ECO","courseNumber":"4200","CRN":"24743"},
{"label":"ECO 4203 - 001 Pol Eco of Development Aid CRN: 24744","subject":"ECO","courseNumber":"4203","CRN":"24744"},
{"label":"ECO 4207 - 001 Economics of Risk&Uncertainty CRN: 24745","subject":"ECO","courseNumber":"4207","CRN":"24745"},
{"label":"ECO 4500 - 001 Independent Study in Eco CRN: 24746","subject":"ECO","courseNumber":"4500","CRN":"24746"},
{"label":"ECO 4500 - 002 Independent Study in Eco CRN: 24747","subject":"ECO","courseNumber":"4500","CRN":"24747"},
{"label":"EDU 2202 - 002 Social Foundation Edu II CRN: 24749","subject":"EDU","courseNumber":"2202","CRN":"24749"},
{"label":"EDU 2253 - 001 History of American Education CRN: 24750","subject":"EDU","courseNumber":"2253","CRN":"24750"},
{"label":"EDU 3100 - 100 Disability & Deaf Studies Semi CRN: 24751","subject":"EDU","courseNumber":"3100","CRN":"24751"},
{"label":"EDU 3258 - 001 Education & Society in 1960s CRN: 24754","subject":"EDU","courseNumber":"3258","CRN":"24754"},
{"label":"EDU 3262 - 001 Edu Pearl Harbor to Sputnik CRN: 24755","subject":"EDU","courseNumber":"3262","CRN":"24755"},
{"label":"EDU 3264 - 001 Intro to Disability Studies CRN: 24758","subject":"EDU","courseNumber":"3264","CRN":"24758"},
{"label":"EDU 3264 - 002 Intro to Disability Studies CRN: 24759","subject":"EDU","courseNumber":"3264","CRN":"24759"},
{"label":"EDU 7031 - 001 Independent Study CRN: 26821","subject":"EDU","courseNumber":"7031","CRN":"26821"},
{"label":"EDU 8500 - 001 Purpose of Higher Education CRN: 24764","subject":"EDU","courseNumber":"8500","CRN":"24764"},
{"label":"EDU 2202 - 001 Social Foundation Edu II CRN: 24748","subject":"EDU","courseNumber":"2202","CRN":"24748"},
{"label":"EDU 3251 - 001 Psych of Teaching & Learning CRN: 24752","subject":"EDU","courseNumber":"3251","CRN":"24752"},
{"label":"EDU 3251 - 002 Psych of Teaching & Learning CRN: 24753","subject":"EDU","courseNumber":"3251","CRN":"24753"},
{"label":"EDU 3253 - 100 Educational Policy Analysis CRN: 26628","subject":"EDU","courseNumber":"3253","CRN":"26628"},
{"label":"EDU 4245 - 001 Literacy & Eng Lang Learning CRN: 24760","subject":"EDU","courseNumber":"4245","CRN":"24760"},
{"label":"EDU 4281 - 100 Meth English Sec School CRN: 24761","subject":"EDU","courseNumber":"4281","CRN":"24761"},
{"label":"EDU 4282 - 100 Meth Fgn Lang Sec School CRN: 24762","subject":"EDU","courseNumber":"4282","CRN":"24762"},
{"label":"EDU 4283 - 100 Meth Math Sec School CRN: 24763","subject":"EDU","courseNumber":"4283","CRN":"24763"},
{"label":"EDU 7119 - 001 Academic Advising Foundations CRN: 26816","subject":"EDU","courseNumber":"7119","CRN":"26816"},
{"label":"EDU 7142 - 001 Parents & Community Partnershp CRN: 26414","subject":"EDU","courseNumber":"7142","CRN":"26414"},
{"label":"EDU 7351 - 001 Campus Physical Environments CRN: 26415","subject":"EDU","courseNumber":"7351","CRN":"26415"},
{"label":"EDU 7562 - 001 TOP:Student Activism CRN: 26416","subject":"EDU","courseNumber":"7562","CRN":"26416"},
{"label":"EDU 8530 - 001 Teaching Methods in Lang Clsrm CRN: 24765","subject":"EDU","courseNumber":"8530","CRN":"24765"},
{"label":"EDU 8540 - 001 Methods of Teaching Math & Sci CRN: 24766","subject":"EDU","courseNumber":"8540","CRN":"24766"},
{"label":"EDU 8550 - 001 Meth of Teaching Eng & Soc Stu CRN: 24767","subject":"EDU","courseNumber":"8550","CRN":"24767"},
{"label":"EDU 8550 - 002 Meth of Teaching Eng & Soc Stu CRN: 24768","subject":"EDU","courseNumber":"8550","CRN":"24768"},
{"label":"EDU 8603 - 001 Educational Research CRN: 24769","subject":"EDU","courseNumber":"8603","CRN":"24769"},
{"label":"EDU 8603 - 002 Educational Research CRN: 24770","subject":"EDU","courseNumber":"8603","CRN":"24770"},
{"label":"EDU 8614 - 001 Curriculum & Assessment CRN: 24771","subject":"EDU","courseNumber":"8614","CRN":"24771"},
{"label":"EDU 8618 - 001 Adolescent Psychology CRN: 24772","subject":"EDU","courseNumber":"8618","CRN":"24772"},
{"label":"EDU 8619 - 001 Children's Literature&Literacy CRN: 24773","subject":"EDU","courseNumber":"8619","CRN":"24773"},
{"label":"EDU 8644 - 001 Leadershp & Admin of Higher Ed CRN: 24774","subject":"EDU","courseNumber":"8644","CRN":"24774"},
{"label":"EDU 8654 - 001 Policy Analysis CRN: 24775","subject":"EDU","courseNumber":"8654","CRN":"24775"},
{"label":"EDU 8670 - 001 History of Higher Education CRN: 24776","subject":"EDU","courseNumber":"8670","CRN":"24776"},
{"label":"EDU 8673 - 001 Philosophy Education CRN: 24777","subject":"EDU","courseNumber":"8673","CRN":"24777"},
{"label":"EDU 8679 - 001 Standards Aligned Sys & Curric CRN: 24778","subject":"EDU","courseNumber":"8679","CRN":"24778"},
{"label":"EDU 8869 - 001 TOP:Culture for Learning CRN: 24779","subject":"EDU","courseNumber":"8869","CRN":"24779"},
{"label":"EDU 9034 - 001 Thesis I CRN: 24780","subject":"EDU","courseNumber":"9034","CRN":"24780"},
{"label":"ECE 2030 - 002 Electric Circuits Fundamentals CRN: 24597","subject":"ECE","courseNumber":"2030","CRN":"24597"},
{"label":"ECE 2030 - 003 Electric Circuits Fundamentals CRN: 24598","subject":"ECE","courseNumber":"2030","CRN":"24598"},
{"label":"ECE 2031 - 001 Elect Circuit Fundamentals Lab CRN: 24600","subject":"ECE","courseNumber":"2031","CRN":"24600"},
{"label":"ECE 2031 - 002 Elect Circuit Fundamentals Lab CRN: 24601","subject":"ECE","courseNumber":"2031","CRN":"24601"},
{"label":"ECE 2031 - 003 Elect Circuit Fundamentals Lab CRN: 24602","subject":"ECE","courseNumber":"2031","CRN":"24602"},
{"label":"ECE 2031 - 004 Elect Circuit Fundamentals Lab CRN: 24603","subject":"ECE","courseNumber":"2031","CRN":"24603"},
{"label":"ECE 2031 - 100 Elect Circuit Fundamentals Lab CRN: 24604","subject":"ECE","courseNumber":"2031","CRN":"24604"},
{"label":"ECE 2031 - 101 Elect Circuit Fundamentals Lab CRN: 24605","subject":"ECE","courseNumber":"2031","CRN":"24605"},
{"label":"ECE 2173 - 002 Digital Systems Lab CRN: 24618","subject":"ECE","courseNumber":"2173","CRN":"24618"},
{"label":"ECE 2292 - 001 Engineering Probability&Stats CRN: 24619","subject":"ECE","courseNumber":"2292","CRN":"24619"},
{"label":"ECE 3020 - 001 Intro to Electric Energy Syste CRN: 24620","subject":"ECE","courseNumber":"3020","CRN":"24620"},
{"label":"ECE 3170 - 001 Computer Architecture CRN: 24621","subject":"ECE","courseNumber":"3170","CRN":"24621"},
{"label":"ECE 3242 - 001 Fundamentals of Signal Process CRN: 24625","subject":"ECE","courseNumber":"3242","CRN":"24625"},
{"label":"ECE 3450 - 001 Digital Electronics CRN: 24626","subject":"ECE","courseNumber":"3450","CRN":"24626"},
{"label":"ECE 3450 - 002 Digital Electronics CRN: 24627","subject":"ECE","courseNumber":"3450","CRN":"24627"},
{"label":"ECE 3530 - 001 Analog Electronics II CRN: 24628","subject":"ECE","courseNumber":"3530","CRN":"24628"},
{"label":"ECE 3531 - 001 Analog Electronics II Lab CRN: 24629","subject":"ECE","courseNumber":"3531","CRN":"24629"},
{"label":"ECE 3531 - 002 Analog Electronics II Lab CRN: 24630","subject":"ECE","courseNumber":"3531","CRN":"24630"},
{"label":"ECE 3600 - 100 Operating Systems CRN: 24631","subject":"ECE","courseNumber":"3600","CRN":"24631"},
{"label":"ECE 2030 - 001 Electric Circuits Fundamentals CRN: 24596","subject":"ECE","courseNumber":"2030","CRN":"24596"},
{"label":"ECE 2030 - 004 Electric Circuits Fundamentals CRN: 24599","subject":"ECE","courseNumber":"2030","CRN":"24599"},
{"label":"ECE 2160 - 001 C++ Algorithms & Data Struct CRN: 24606","subject":"ECE","courseNumber":"2160","CRN":"24606"},
{"label":"ECE 2160 - 002 C++ Algorithms & Data Struct CRN: 24607","subject":"ECE","courseNumber":"2160","CRN":"24607"},
{"label":"ECE 2161 - 001 C++ Algorithms&Data Struct Lab CRN: 24608","subject":"ECE","courseNumber":"2161","CRN":"24608"},
{"label":"ECE 2161 - 002 C++ Algorithms&Data Struct Lab CRN: 24609","subject":"ECE","courseNumber":"2161","CRN":"24609"},
{"label":"ECE 2170 - 001 Fundamentals of CPE CRN: 24610","subject":"ECE","courseNumber":"2170","CRN":"24610"},
{"label":"ECE 2170 - 002 Fundamentals of CPE CRN: 24611","subject":"ECE","courseNumber":"2170","CRN":"24611"},
{"label":"ECE 2171 - 001 Fundamentals of CPE Lab CRN: 24612","subject":"ECE","courseNumber":"2171","CRN":"24612"},
{"label":"ECE 2171 - 002 Fundamentals of CPE Lab CRN: 24613","subject":"ECE","courseNumber":"2171","CRN":"24613"},
{"label":"ECE 2171 - 003 Fundamentals of CPE Lab CRN: 24614","subject":"ECE","courseNumber":"2171","CRN":"24614"},
{"label":"ECE 2172 - 001 Digital Systems CRN: 24615","subject":"ECE","courseNumber":"2172","CRN":"24615"},
{"label":"ECE 2172 - 002 Digital Systems CRN: 24616","subject":"ECE","courseNumber":"2172","CRN":"24616"},
{"label":"ECE 2173 - 001 Digital Systems Lab CRN: 24617","subject":"ECE","courseNumber":"2173","CRN":"24617"},
{"label":"ECE 3170 - 002 Computer Architecture CRN: 24622","subject":"ECE","courseNumber":"3170","CRN":"24622"},
{"label":"ECE 3171 - 001 Computer Architecture Lab CRN: 24623","subject":"ECE","courseNumber":"3171","CRN":"24623"},
{"label":"ECE 3171 - 002 Computer Architecture Lab CRN: 24624","subject":"ECE","courseNumber":"3171","CRN":"24624"},
{"label":"ECE 4970 - 001 Design Project - EE CRN: 24632","subject":"ECE","courseNumber":"4970","CRN":"24632"},
{"label":"ECE 4971 - 001 Design Project - CPE CRN: 24633","subject":"ECE","courseNumber":"4971","CRN":"24633"},
{"label":"ECE 4972 - 001 Design Project Report - EE CRN: 24634","subject":"ECE","courseNumber":"4972","CRN":"24634"},
{"label":"ECE 4973 - 001 Design Project Report - CPE CRN: 24635","subject":"ECE","courseNumber":"4973","CRN":"24635"},
{"label":"ECE 5250 - 001 Biomedical Instrumentation CRN: 24636","subject":"ECE","courseNumber":"5250","CRN":"24636"},
{"label":"ECE 5450 - 100 Microcontrollers & Applic CRN: 24637","subject":"ECE","courseNumber":"5450","CRN":"24637"},
{"label":"ECE 5450 - 101 Microcontrollers & Applic CRN: 24638","subject":"ECE","courseNumber":"5450","CRN":"24638"},
{"label":"ECE 5991 - 001 Elec Eng Research I CRN: 24640","subject":"ECE","courseNumber":"5991","CRN":"24640"},
{"label":"ECE 5992 - 001 Elec Eng Research II CRN: 24641","subject":"ECE","courseNumber":"5992","CRN":"24641"},
{"label":"ECE 7231 - 001 Applied DSP CRN: 24642","subject":"ECE","courseNumber":"7231","CRN":"24642"},
{"label":"ECE 7231 - DL1 Applied DSP CRN: 24643","subject":"ECE","courseNumber":"7231","CRN":"24643"},
{"label":"ECE 7580 - 001 Intro to Power Electronics CRN: 24644","subject":"ECE","courseNumber":"7580","CRN":"24644"},
{"label":"ECE 7580 - DL1 Intro to Power Electronics CRN: 24645","subject":"ECE","courseNumber":"7580","CRN":"24645"},
{"label":"ECE 7805 - 001 Electric Machinery CRN: 24646","subject":"ECE","courseNumber":"7805","CRN":"24646"},
{"label":"ECE 7805 - DL1 Electric Machinery CRN: 24647","subject":"ECE","courseNumber":"7805","CRN":"24647"},
{"label":"ECE 8001 - 001 Engineering Math I CRN: 24648","subject":"ECE","courseNumber":"8001","CRN":"24648"},
{"label":"ECE 8001 - DL1 Engineering Math I CRN: 24649","subject":"ECE","courseNumber":"8001","CRN":"24649"},
{"label":"ECE 8072 - 001 Stat Signal Processing CRN: 24650","subject":"ECE","courseNumber":"8072","CRN":"24650"},
{"label":"ECE 8072 - DL1 Stat Signal Processing CRN: 24651","subject":"ECE","courseNumber":"8072","CRN":"24651"},
{"label":"ECE 8410 - 001 Trusted Computing CRN: 24652","subject":"ECE","courseNumber":"8410","CRN":"24652"},
{"label":"ECE 8410 - DL1 Trusted Computing CRN: 24653","subject":"ECE","courseNumber":"8410","CRN":"24653"},
{"label":"ECE 8440 - 001 Hardware Sys Des & Modeling CRN: 24654","subject":"ECE","courseNumber":"8440","CRN":"24654"},
{"label":"ECE 8440 - DL1 Hardware Sys Des & Modeling CRN: 24655","subject":"ECE","courseNumber":"8440","CRN":"24655"},
{"label":"ECE 8455 - 001 Adv. Digital Des. Using FPGAs CRN: 24656","subject":"ECE","courseNumber":"8455","CRN":"24656"},
{"label":"ECE 8455 - DL1 Adv. Digital Des. Using FPGAs CRN: 24657","subject":"ECE","courseNumber":"8455","CRN":"24657"},
{"label":"ECE 8476 - 001 Cryptography & Netwk. Security CRN: 24658","subject":"ECE","courseNumber":"8476","CRN":"24658"},
{"label":"ECE 8476 - DL1 Cryptography & Netwk. Security CRN: 24659","subject":"ECE","courseNumber":"8476","CRN":"24659"},
{"label":"ECE 8481 - 001 Post-Quantum Cryptographic ENR CRN: 24660","subject":"ECE","courseNumber":"8481","CRN":"24660"},
{"label":"ECE 8481 - DL1 Post-Quantum Cryptographic ENR CRN: 24661","subject":"ECE","courseNumber":"8481","CRN":"24661"},
{"label":"ECE 8486 - 001 Ethical Hacking CRN: 24662","subject":"ECE","courseNumber":"8486","CRN":"24662"},
{"label":"ECE 8486 - DL1 Ethical Hacking CRN: 24663","subject":"ECE","courseNumber":"8486","CRN":"24663"},
{"label":"ECE 8487 - 001 Advanced Machine Learning CRN: 24664","subject":"ECE","courseNumber":"8487","CRN":"24664"},
{"label":"ECE 8487 - DL1 Advanced Machine Learning CRN: 24665","subject":"ECE","courseNumber":"8487","CRN":"24665"},
{"label":"ECE 8489 - 001 Malware Analysis and Defense CRN: 24666","subject":"ECE","courseNumber":"8489","CRN":"24666"},
{"label":"ECE 8489 - DL1 Malware Analysis and Defense CRN: 24667","subject":"ECE","courseNumber":"8489","CRN":"24667"},
{"label":"ECE 8675 - 001 Antenna Theory I CRN: 24670","subject":"ECE","courseNumber":"8675","CRN":"24670"},
{"label":"ECE 8675 - DL1 Antenna Theory I CRN: 24671","subject":"ECE","courseNumber":"8675","CRN":"24671"},
{"label":"ECE 9032 - 001 Research II CRN: 24672","subject":"ECE","courseNumber":"9032","CRN":"24672"},
{"label":"ECE 9080 - 001 Thesis Continuation CRN: 24673","subject":"ECE","courseNumber":"9080","CRN":"24673"},
{"label":"ECE 9085 - 001 Independent Study Continuation CRN: 24674","subject":"ECE","courseNumber":"9085","CRN":"24674"},
{"label":"ECE 9090 - 001 ECE Project CRN: 24675","subject":"ECE","courseNumber":"9090","CRN":"24675"},
{"label":"ECE 9900 - 001 TOP:Wearable Biosensing CRN: 26754","subject":"ECE","courseNumber":"9900","CRN":"26754"},
{"label":"ECE 9900 - DL1 TOP:Wearable Biosensing CRN: 26755","subject":"ECE","courseNumber":"9900","CRN":"26755"},
{"label":"ECE 9905 - 001 Sup Study Elec & Comp Egr CRN: 24676","subject":"ECE","courseNumber":"9905","CRN":"24676"},
{"label":"ECE 9940 - 001 ECE Graduate Practicum CRN: 24677","subject":"ECE","courseNumber":"9940","CRN":"24677"},
{"label":"ECE 9941 - 001 Grad. Practicum Continuation CRN: 24678","subject":"ECE","courseNumber":"9941","CRN":"24678"},
{"label":"EGR 2020 - 001 Physiology for Engineers CRN: 24798","subject":"EGR","courseNumber":"2020","CRN":"24798"},
{"label":"EGR 1001 - 001 Career Compass lA CRN: 24783","subject":"EGR","courseNumber":"1001","CRN":"24783"},
{"label":"EGR 1001 - 002 Career Compass lA CRN: 24784","subject":"EGR","courseNumber":"1001","CRN":"24784"},
{"label":"EGR 1001 - 003 Career Compass lA CRN: 24785","subject":"EGR","courseNumber":"1001","CRN":"24785"},
{"label":"EGR 1001 - 004 Career Compass lA CRN: 24786","subject":"EGR","courseNumber":"1001","CRN":"24786"},
{"label":"EGR 1001 - 005 Career Compass lA CRN: 24787","subject":"EGR","courseNumber":"1001","CRN":"24787"},
{"label":"EGR 1001 - 006 Career Compass lA CRN: 24788","subject":"EGR","courseNumber":"1001","CRN":"24788"},
{"label":"EGR 1001 - 007 Career Compass lA CRN: 24789","subject":"EGR","courseNumber":"1001","CRN":"24789"},
{"label":"EGR 1001 - EGR EG Career Compass Placeholder CRN: 26612","subject":"EGR","courseNumber":"1001","CRN":"26612"},
{"label":"EGR 1200 - 001 Engineering Design Cornerstone CRN: 24790","subject":"EGR","courseNumber":"1200","CRN":"24790"},
{"label":"EGR 1200 - 002 Engineering Design Cornerstone CRN: 24791","subject":"EGR","courseNumber":"1200","CRN":"24791"},
{"label":"EGR 1200 - 003 Engineering Design Cornerstone CRN: 24792","subject":"EGR","courseNumber":"1200","CRN":"24792"},
{"label":"EGR 1200 - 004 Engineering Design Cornerstone CRN: 24793","subject":"EGR","courseNumber":"1200","CRN":"24793"},
{"label":"EGR 1200 - 005 Engineering Design Cornerstone CRN: 24794","subject":"EGR","courseNumber":"1200","CRN":"24794"},
{"label":"EGR 1200 - EGR EG Project Placeholder CRN: 26609","subject":"EGR","courseNumber":"1200","CRN":"26609"},
{"label":"EGR 1200 - H01 HON:EngineeringDesignCornersto CRN: 24795","subject":"EGR","courseNumber":"1200","CRN":"24795"},
{"label":"EGR 2002 - 001 Intro to Humanitarian Engr CRN: 24796","subject":"EGR","courseNumber":"2002","CRN":"24796"},
{"label":"EGR 2003 - DL1 Career Compass llA CRN: 24797","subject":"EGR","courseNumber":"2003","CRN":"24797"},
{"label":"EGR 2930 - 001 Catholic Soc Teaching for EGRS CRN: 24799","subject":"EGR","courseNumber":"2930","CRN":"24799"},
{"label":"EGR 3005 - DL1 Career Compass IIIA CRN: 24800","subject":"EGR","courseNumber":"3005","CRN":"24800"},
{"label":"EGR 5001 - 001 Engineering Service Learning CRN: 24801","subject":"EGR","courseNumber":"5001","CRN":"24801"},
{"label":"EGR 5001 - 002 Engineering Service Learning CRN: 24802","subject":"EGR","courseNumber":"5001","CRN":"24802"},
{"label":"EGR 5002 - 001 EGR Service Learning Leader CRN: 24803","subject":"EGR","courseNumber":"5002","CRN":"24803"},
{"label":"EGR 5002 - 002 EGR Service Learning Leader CRN: 24804","subject":"EGR","courseNumber":"5002","CRN":"24804"},
{"label":"EGR 5003 - 001 Engineering Svc Learning Capst CRN: 24805","subject":"EGR","courseNumber":"5003","CRN":"24805"},
{"label":"EGR 5003 - 002 Engineering Svc Learning Capst CRN: 24806","subject":"EGR","courseNumber":"5003","CRN":"24806"},
{"label":"EGR 5050 - 001 Professional Practicum CRN: 24807","subject":"EGR","courseNumber":"5050","CRN":"24807"},
{"label":"EGR 7000 - 001 Fundamentals of BME CRN: 26833","subject":"EGR","courseNumber":"7000","CRN":"26833"},
{"label":"EGR 7000 - DL1 Fundamentals of BME CRN: 26834","subject":"EGR","courseNumber":"7000","CRN":"26834"},
{"label":"EGR 8301 - 001 Control Systems Engineering CRN: 24810","subject":"EGR","courseNumber":"8301","CRN":"24810"},
{"label":"EGR 8301 - DL1 Control Systems Engineering CRN: 24811","subject":"EGR","courseNumber":"8301","CRN":"24811"},
{"label":"EGR 9000 - 001 Doctoral Independent Study CRN: 24812","subject":"EGR","courseNumber":"9000","CRN":"24812"},
{"label":"EGR 9100 - 001 Doctoral Research CRN: 24813","subject":"EGR","courseNumber":"9100","CRN":"24813"},
{"label":"EGR 9100 - 002 Doctoral Research CRN: 24814","subject":"EGR","courseNumber":"9100","CRN":"24814"},
{"label":"EGR 9100 - 003 Doctoral Research CRN: 24815","subject":"EGR","courseNumber":"9100","CRN":"24815"},
{"label":"EGR 9110 - 001 Doctoral Research Continuation CRN: 24816","subject":"EGR","courseNumber":"9110","CRN":"24816"},
{"label":"EGR 9150 - 001 Prof Development/CPT CRN: 24817","subject":"EGR","courseNumber":"9150","CRN":"24817"},
{"label":"EGR 9151 - 001 Prof Devel/CPT Continuation CRN: 24818","subject":"EGR","courseNumber":"9151","CRN":"24818"},
{"label":"EGR 9200 - 001 Teaching Engr'ng in Higher Edu CRN: 24819","subject":"EGR","courseNumber":"9200","CRN":"24819"},
{"label":"EGR 9260 - 001 PhD PD Industry Track Seminar CRN: 24820","subject":"EGR","courseNumber":"9260","CRN":"24820"},
{"label":"EGEN 2100 - 001 Engineering a Creative Mindset CRN: 24781","subject":"EGEN","courseNumber":"2100","CRN":"24781"},
{"label":"EGEN 2400 - 100 Int Prop Mngmnt for Eng & Sci CRN: 26822","subject":"EGEN","courseNumber":"2400","CRN":"26822"},
{"label":"EGEN 3100 - 001 Design Thnkng and Cust Discvry CRN: 24782","subject":"EGEN","courseNumber":"3100","CRN":"24782"},
{"label":"EGEN 7002 - 001 I-Corps Research Innovation CRN: 26829","subject":"EGEN","courseNumber":"7002","CRN":"26829"},
{"label":"ENG 1050 - 001 LitEx:Apocalytic Moments CRN: 24821","subject":"ENG","courseNumber":"1050","CRN":"24821"},
{"label":"ENG 1050 - 002 LitEx:AnExistentialJourney CRN: 24822","subject":"ENG","courseNumber":"1050","CRN":"24822"},
{"label":"ENG 1050 - 003 LitEx:ApocalyticMoments CRN: 24823","subject":"ENG","courseNumber":"1050","CRN":"24823"},
{"label":"ENG 1050 - 004 LitEx:AnExistentialJourney CRN: 24824","subject":"ENG","courseNumber":"1050","CRN":"24824"},
{"label":"ENG 1050 - 005 ENG-1050: The Short Story CRN: 24825","subject":"ENG","courseNumber":"1050","CRN":"24825"},
{"label":"ENG 1050 - 007 LitEx:Genre&HybridForms CRN: 24827","subject":"ENG","courseNumber":"1050","CRN":"24827"},
{"label":"ENG 1975 - 001 CoreSem:Law & Modern Lit CRN: 24835","subject":"ENG","courseNumber":"1975","CRN":"24835"},
{"label":"HUM 1975 - 001 CoreSem:Epiphanies of Beauty CRN: 25172","subject":"ENG","courseNumber":"1975","CRN":"25172"},
{"label":"ENG 1975 - 002 CoreSem:Genre&HybridForms CRN: 24836","subject":"ENG","courseNumber":"1975","CRN":"24836"},
{"label":"ENG 1975 - 003 CoreSem:Law & Modern Lit CRN: 24837","subject":"ENG","courseNumber":"1975","CRN":"24837"},
{"label":"ENG 1975 - 004 CoreSem:Echoes in Texts & Film CRN: 24838","subject":"ENG","courseNumber":"1975","CRN":"24838"},
{"label":"ENG 1975 - 005 CoreSem:Environ Catastrophe CRN: 24839","subject":"ENG","courseNumber":"1975","CRN":"24839"},
{"label":"ENG 1975 - 006 CoreSem:Sci-Fi & the Other CRN: 24840","subject":"ENG","courseNumber":"1975","CRN":"24840"},
{"label":"ENG 1975 - 007 CoreSem:Environ Catastrophe CRN: 24841","subject":"ENG","courseNumber":"1975","CRN":"24841"},
{"label":"ENG 1975 - 008 CoreSem:Echoes in Texts & Film CRN: 24842","subject":"ENG","courseNumber":"1975","CRN":"24842"},
{"label":"ENG 1975 - 009 CoreSem:Queerness&theFamily CRN: 24843","subject":"ENG","courseNumber":"1975","CRN":"24843"},
{"label":"ENG 1975 - 010 CoreSem:Sci-Fi & the Other CRN: 24844","subject":"ENG","courseNumber":"1975","CRN":"24844"},
{"label":"ENG 1975 - 011 CoreSem:Saints & Sinners CRN: 24845","subject":"ENG","courseNumber":"1975","CRN":"24845"},
{"label":"ENG 1975 - 012 CoreSem:Queerness&theFamily CRN: 24846","subject":"ENG","courseNumber":"1975","CRN":"24846"},
{"label":"ENG 1975 - 013 CoreSem:Disability in Lit CRN: 24847","subject":"ENG","courseNumber":"1975","CRN":"24847"},
{"label":"ENG 1975 - 014 CoreSem:Saints & Sinners CRN: 24848","subject":"ENG","courseNumber":"1975","CRN":"24848"},
{"label":"ENG 1975 - 015 CoreSem:AliensFreaksNormsOther CRN: 24849","subject":"ENG","courseNumber":"1975","CRN":"24849"},
{"label":"ENG 1975 - 016 CoreSem:Race&EthInAmerLit CRN: 24850","subject":"ENG","courseNumber":"1975","CRN":"24850"},
{"label":"ENG 1975 - 017 CoreSem:The Beat Generation CRN: 24851","subject":"ENG","courseNumber":"1975","CRN":"24851"},
{"label":"ENG 1975 - 018 CoreSem:Borders, Migration CRN: 24852","subject":"ENG","courseNumber":"1975","CRN":"24852"},
{"label":"ENG 1975 - 019 CoreSem:Race&EthInAmerLit CRN: 24853","subject":"ENG","courseNumber":"1975","CRN":"24853"},
{"label":"ENG 1975 - 020 CoreSem:Uses of Horror/Terror CRN: 24854","subject":"ENG","courseNumber":"1975","CRN":"24854"},
{"label":"ENG 2003 - 001 Intro to Creative Writing CRN: 24858","subject":"ENG","courseNumber":"2003","CRN":"24858"},
{"label":"ENG 2017 - 001 Writing Detective Fiction CRN: 24860","subject":"ENG","courseNumber":"2017","CRN":"24860"},
{"label":"ENG 2061 - 001 Editing & Publishing CRN: 24864","subject":"ENG","courseNumber":"2061","CRN":"24864"},
{"label":"ENG 4690 - 001 Motherhood & Reprod. Fictions CRN: 24880","subject":"ENG","courseNumber":"4690","CRN":"24880"},
{"label":"ENG 1050 - 006 ENG-1050: The Short Story CRN: 24826","subject":"ENG","courseNumber":"1050","CRN":"24826"},
{"label":"ENG 1050 - 008 LitEx:TheLiteraryExperience CRN: 24828","subject":"ENG","courseNumber":"1050","CRN":"24828"},
{"label":"ENG 1842 - 001 Literature of Magic CRN: 24829","subject":"ENG","courseNumber":"1842","CRN":"24829"},
{"label":"ENG 1842 - 002 Crime Fiction CRN: 24830","subject":"ENG","courseNumber":"1842","CRN":"24830"},
{"label":"ENG 1842 - 003 Creative Writing CRN: 24831","subject":"ENG","courseNumber":"1842","CRN":"24831"},
{"label":"ENG 1903 - 001 Internship Elective CRN: 24832","subject":"ENG","courseNumber":"1903","CRN":"24832"},
{"label":"ENG 1906 - 001 Internship Elective CRN: 24833","subject":"ENG","courseNumber":"1906","CRN":"24833"},
{"label":"ENG 1909 - 001 Internship Elective CRN: 24834","subject":"ENG","courseNumber":"1909","CRN":"24834"},
{"label":"ENG 1975 - DL1 CoreSem:LeadersandMnagersinLit CRN: 26464","subject":"ENG","courseNumber":"1975","CRN":"26464"},
{"label":"ENG 1975 - H01 HON:CoreSem:Beauty (GTB) CRN: 24855","subject":"ENG","courseNumber":"1975","CRN":"24855"},
{"label":"ENG 1975 - H02 HON:CoreSem:Beauty (GTB) CRN: 24856","subject":"ENG","courseNumber":"1975","CRN":"24856"},
{"label":"ENG 1975 - H03 HON:CoreSem:Family Matters CRN: 24857","subject":"ENG","courseNumber":"1975","CRN":"24857"},
{"label":"ENG 2006 - 001 The Writing of Poetry CRN: 26692","subject":"ENG","courseNumber":"2006","CRN":"26692"},
{"label":"ENG 2013 - 001 Writing of Memoir CRN: 24859","subject":"ENG","courseNumber":"2013","CRN":"24859"},
{"label":"ENG 2018 - 001 Nature Writing Workshop CRN: 24861","subject":"ENG","courseNumber":"2018","CRN":"24861"},
{"label":"ENG 2023 - 001 Journalism CRN: 24862","subject":"ENG","courseNumber":"2023","CRN":"24862"},
{"label":"ENG 2250 - 001 Ways of Reading:Lit Analysis CRN: 24865","subject":"ENG","courseNumber":"2250","CRN":"24865"},
{"label":"ENG 2306 - 001 Harry Potter:Quests/Questions CRN: 24867","subject":"ENG","courseNumber":"2306","CRN":"24867"},
{"label":"ENG 2800 - 001 Teaching Practicum CRN: 24868","subject":"ENG","courseNumber":"2800","CRN":"24868"},
{"label":"ENG 2993 - 001 Internship CRN: 24869","subject":"ENG","courseNumber":"2993","CRN":"24869"},
{"label":"ENG 2996 - 001 Internship CRN: 24870","subject":"ENG","courseNumber":"2996","CRN":"24870"},
{"label":"ENG 2999 - 001 Publishing Co-op CRN: 24871","subject":"ENG","courseNumber":"2999","CRN":"24871"},
{"label":"THE 3030 - 002 TOP: Modern Irish Drama CRN: 26694","subject":"ENG","courseNumber":"3030","CRN":"26694"},
{"label":"ENG 3150 - 001 Chaucer CRN: 24872","subject":"ENG","courseNumber":"3150","CRN":"24872"},
{"label":"ENG 3181 - 001 Irish Epics, Visions&Hauntings CRN: 24873","subject":"ENG","courseNumber":"3181","CRN":"24873"},
{"label":"ENG 3550 - 001 Victorian Publics& Populations CRN: 24874","subject":"ENG","courseNumber":"3550","CRN":"24874"},
{"label":"ENG 3620 - 001 Modernism & Fan Fiction CRN: 24875","subject":"ENG","courseNumber":"3620","CRN":"24875"},
{"label":"ENG 4000 - 001 American Literary Traditions 1 CRN: 24876","subject":"ENG","courseNumber":"4000","CRN":"24876"},
{"label":"ENG 4652 - 001 Letters, Texts, & Twitter CRN: 24879","subject":"ENG","courseNumber":"4652","CRN":"24879"},
{"label":"ENG 4704 - 001 Borders in Latinx Literature CRN: 24881","subject":"ENG","courseNumber":"4704","CRN":"24881"},
{"label":"ENG 4705 - 001 Lit of Addiction and Recovery CRN: 24882","subject":"ENG","courseNumber":"4705","CRN":"24882"},
{"label":"ENG 8000 - 001 Literary Theory CRN: 24884","subject":"ENG","courseNumber":"8000","CRN":"24884"},
{"label":"ENG 8090 - 001 Thesis Direction I CRN: 24885","subject":"ENG","courseNumber":"8090","CRN":"24885"},
{"label":"ENG 8092 - 001 Field Examination CRN: 24886","subject":"ENG","courseNumber":"8092","CRN":"24886"},
{"label":"ENG 8093 - 001 Field Exam Continuation CRN: 24887","subject":"ENG","courseNumber":"8093","CRN":"24887"},
{"label":"ENG 8106 - 001 Sem:Topics in Med Lit CRN: 24888","subject":"ENG","courseNumber":"8106","CRN":"24888"},
{"label":"ENG 8560 - 001 Victorian Publics&Populations CRN: 24889","subject":"ENG","courseNumber":"8560","CRN":"24889"},
{"label":"ENG 8640 - 001 Topics in Mod Brit Lit CRN: 24890","subject":"ENG","courseNumber":"8640","CRN":"24890"},
{"label":"ENG 9031 - 001 Independent Study I CRN: 24891","subject":"ENG","courseNumber":"9031","CRN":"24891"},
{"label":"ENG 9035 - 001 Professional Research Option CRN: 24892","subject":"ENG","courseNumber":"9035","CRN":"24892"},
{"label":"ENG 9080 - 001 Thesis Continuation CRN: 24893","subject":"ENG","courseNumber":"9080","CRN":"24893"},
{"label":"ENG 9720 - 001 Reading Ethnic Canon CRN: 24894","subject":"ENG","courseNumber":"9720","CRN":"24894"},
{"label":"ENG 9800 - 001 Internship in Teaching English CRN: 24895","subject":"ENG","courseNumber":"9800","CRN":"24895"},
{"label":"ENTR 1000 - DL1 The Basics of Entrepreneurship CRN: 26417","subject":"ENTR","courseNumber":"1000","CRN":"26417"},
{"label":"ENTR 5000 - DL1 Entrepreneurship Capstone CRN: 24896","subject":"ENTR","courseNumber":"5000","CRN":"24896"},
{"label":"ENTR 5993 - 100 Entrepreneurship Ind Study CRN: 24897","subject":"ENTR","courseNumber":"5993","CRN":"24897"},
{"label":"ETH 2050 - 001 The Good Life:Eth & Cont Prob CRN: 24898","subject":"ETH","courseNumber":"2050","CRN":"24898"},
{"label":"ETH 2050 - 002 The Good Life:Eth & Cont Prob CRN: 24899","subject":"ETH","courseNumber":"2050","CRN":"24899"},
{"label":"ETH 2050 - 003 The Good Life:Eth & Cont Prob CRN: 24900","subject":"ETH","courseNumber":"2050","CRN":"24900"},
{"label":"ETH 2050 - 004 The Good Life:Eth & Cont Prob CRN: 24901","subject":"ETH","courseNumber":"2050","CRN":"24901"},
{"label":"ETH 2050 - 005 The Good Life:Eth & Cont Prob CRN: 24902","subject":"ETH","courseNumber":"2050","CRN":"24902"},
{"label":"ETH 2050 - 006 The Good Life:Eth & Cont Prob CRN: 24903","subject":"ETH","courseNumber":"2050","CRN":"24903"},
{"label":"ETH 2050 - 007 The Good Life:Eth & Cont Prob CRN: 24904","subject":"ETH","courseNumber":"2050","CRN":"24904"},
{"label":"ETH 2050 - 008 The Good Life:Eth & Cont Prob CRN: 24905","subject":"ETH","courseNumber":"2050","CRN":"24905"},
{"label":"ETH 2050 - 009 The Good Life:Eth & Cont Prob CRN: 24906","subject":"ETH","courseNumber":"2050","CRN":"24906"},
{"label":"ETH 2050 - 010 The Good Life:Eth & Cont Prob CRN: 24907","subject":"ETH","courseNumber":"2050","CRN":"24907"},
{"label":"ETH 2050 - 011 The Good Life:Eth & Cont Prob CRN: 24908","subject":"ETH","courseNumber":"2050","CRN":"24908"},
{"label":"ETH 2050 - 012 The Good Life:Eth & Cont Prob CRN: 24909","subject":"ETH","courseNumber":"2050","CRN":"24909"},
{"label":"ETH 2050 - 013 The Good Life:Eth & Cont Prob CRN: 24910","subject":"ETH","courseNumber":"2050","CRN":"24910"},
{"label":"ETH 2050 - 014 The Good Life:Eth & Cont Prob CRN: 24911","subject":"ETH","courseNumber":"2050","CRN":"24911"},
{"label":"ETH 2050 - 015 The Good Life:Eth & Cont Prob CRN: 24912","subject":"ETH","courseNumber":"2050","CRN":"24912"},
{"label":"ETH 2050 - 016 The Good Life:Eth & Cont Prob CRN: 24913","subject":"ETH","courseNumber":"2050","CRN":"24913"},
{"label":"ETH 2050 - 017 The Good Life:Eth & Cont Prob CRN: 24914","subject":"ETH","courseNumber":"2050","CRN":"24914"},
{"label":"ETH 2050 - 018 The Good Life:Eth & Cont Prob CRN: 24915","subject":"ETH","courseNumber":"2050","CRN":"24915"},
{"label":"ETH 2050 - 019 The Good Life:Eth & Cont Prob CRN: 24916","subject":"ETH","courseNumber":"2050","CRN":"24916"},
{"label":"ETH 2050 - 020 The Good Life:Eth & Cont Prob CRN: 24917","subject":"ETH","courseNumber":"2050","CRN":"24917"},
{"label":"ETH 2050 - 021 The Good Life:Eth & Cont Prob CRN: 24918","subject":"ETH","courseNumber":"2050","CRN":"24918"},
{"label":"ETH 2050 - 022 The Good Life:Eth & Cont Prob CRN: 24919","subject":"ETH","courseNumber":"2050","CRN":"24919"},
{"label":"ETH 2050 - 023 The Good Life:Eth & Cont Prob CRN: 24920","subject":"ETH","courseNumber":"2050","CRN":"24920"},
{"label":"ETH 2050 - 024 The Good Life:Eth & Cont Prob CRN: 24921","subject":"ETH","courseNumber":"2050","CRN":"24921"},
{"label":"ETH 2050 - 025 The Good Life:Eth & Cont Prob CRN: 24922","subject":"ETH","courseNumber":"2050","CRN":"24922"},
{"label":"ETH 2050 - 027 The Good Life:Eth & Cont Prob CRN: 24924","subject":"ETH","courseNumber":"2050","CRN":"24924"},
{"label":"ETH 2050 - 100 The Good Life:Eth & Cont Prob CRN: 24925","subject":"ETH","courseNumber":"2050","CRN":"24925"},
{"label":"ETH 2050 - H01 HON:The GoodLife:Eth&Cont Prob CRN: 24926","subject":"ETH","courseNumber":"2050","CRN":"24926"},
{"label":"ETH 3010 - 001 TOP:Native American Ethics CRN: 24930","subject":"ETH","courseNumber":"3010","CRN":"24930"},
{"label":"ETH 2050 - 026 The Good Life:Eth & Cont Prob CRN: 24923","subject":"ETH","courseNumber":"2050","CRN":"24923"},
{"label":"ETH 2050 - DL1 The Good Life:Eth & Cont Prob CRN: 26418","subject":"ETH","courseNumber":"2050","CRN":"26418"},
{"label":"ETH 2050 - H02 HON:TheGoodLfe:Eth&CntPrb(BST) CRN: 24927","subject":"ETH","courseNumber":"2050","CRN":"24927"},
{"label":"ETH 2050 - H03 HON:TheGoodLfe:Eth&CntPrb(PPE) CRN: 24928","subject":"ETH","courseNumber":"2050","CRN":"24928"},
{"label":"ETH 2050 - H04 HON:TheGoodLfe:Eth&CntPrb(PPE) CRN: 24929","subject":"ETH","courseNumber":"2050","CRN":"24929"},
{"label":"PJ 2700 - H01 HON:Peacemakers & Peacemaking CRN: 25724","subject":"ETH","courseNumber":"2700","CRN":"25724"},
{"label":"ETH 3010 - 002 TOP:Animal Ethics CRN: 24931","subject":"ETH","courseNumber":"3010","CRN":"24931"},
{"label":"ETH 4975 - 001 Independent Study in Ethics CRN: 24932","subject":"ETH","courseNumber":"4975","CRN":"24932"},
{"label":"FIN 2114 - 001 Intermediate Corp Finance CRN: 24950","subject":"FIN","courseNumber":"2114","CRN":"24950"},
{"label":"FIN 2114 - 002 Intermediate Corp Finance CRN: 24951","subject":"FIN","courseNumber":"2114","CRN":"24951"},
{"label":"FIN 2114 - 003 Intermediate Corp Finance CRN: 24952","subject":"FIN","courseNumber":"2114","CRN":"24952"},
{"label":"FIN 2114 - 004 Intermediate Corp Finance CRN: 24953","subject":"FIN","courseNumber":"2114","CRN":"24953"},
{"label":"FIN 2227 - 001 Fixed Inc Markets & Valuation CRN: 24955","subject":"FIN","courseNumber":"2227","CRN":"24955"},
{"label":"FIN 2227 - 002 Fixed Inc Markets & Valuation CRN: 24956","subject":"FIN","courseNumber":"2227","CRN":"24956"},
{"label":"FIN 2227 - 003 Fixed Inc Markets & Valuation CRN: 24957","subject":"FIN","courseNumber":"2227","CRN":"24957"},
{"label":"FIN 2227 - 004 Fixed Inc Markets & Valuation CRN: 24958","subject":"FIN","courseNumber":"2227","CRN":"24958"},
{"label":"FIN 2227 - 005 Fixed Inc Markets & Valuation CRN: 24959","subject":"FIN","courseNumber":"2227","CRN":"24959"},
{"label":"FIN 2230 - 001 Risk Mgmt of Fin Institutions CRN: 24960","subject":"FIN","courseNumber":"2230","CRN":"24960"},
{"label":"FIN 2323 - 001 Equity Markets and Valuation CRN: 24962","subject":"FIN","courseNumber":"2323","CRN":"24962"},
{"label":"FIN 2323 - 002 Equity Markets and Valuation CRN: 24963","subject":"FIN","courseNumber":"2323","CRN":"24963"},
{"label":"FIN 2323 - 003 Equity Markets and Valuation CRN: 24964","subject":"FIN","courseNumber":"2323","CRN":"24964"},
{"label":"FIN 2323 - 004 Equity Markets and Valuation CRN: 24965","subject":"FIN","courseNumber":"2323","CRN":"24965"},
{"label":"FIN 2323 - 005 Equity Markets and Valuation CRN: 24966","subject":"FIN","courseNumber":"2323","CRN":"24966"},
{"label":"FIN 2323 - 006 Equity Markets and Valuation CRN: 24967","subject":"FIN","courseNumber":"2323","CRN":"24967"},
{"label":"FIN 2324 - 100 Portfolio Management CRN: 24968","subject":"FIN","courseNumber":"2324","CRN":"24968"},
{"label":"FIN 2325 - 001 Introduction to Derivatives CRN: 24969","subject":"FIN","courseNumber":"2325","CRN":"24969"},
{"label":"FIN 2326 - 001 Mkt.Struct., Trading&Liquidity CRN: 24970","subject":"FIN","courseNumber":"2326","CRN":"24970"},
{"label":"FIN 2327 - 001 Alternative Investments CRN: 24971","subject":"FIN","courseNumber":"2327","CRN":"24971"},
{"label":"FIN 2330 - 001 Corporate Restructuring CRN: 24972","subject":"FIN","courseNumber":"2330","CRN":"24972"},
{"label":"FIN 2333 - 002 Financial Modeling & Valuation CRN: 24973","subject":"FIN","courseNumber":"2333","CRN":"24973"},
{"label":"FIN 2335 - 002 Intl Financial Management CRN: 24975","subject":"FIN","courseNumber":"2335","CRN":"24975"},
{"label":"FIN 2350 - 001 Real Estate Capital Markets CRN: 24977","subject":"FIN","courseNumber":"2350","CRN":"24977"},
{"label":"FIN 2114 - 100 Intermediate Corp Finance CRN: 24954","subject":"FIN","courseNumber":"2114","CRN":"24954"},
{"label":"FIN 2121 - 001 TOP:Programming Concepts in R CRN: 26419","subject":"FIN","courseNumber":"2121","CRN":"26419"},
{"label":"FIN 2121 - DL1 TOP:Programming Concepts in R CRN: 26420","subject":"FIN","courseNumber":"2121","CRN":"26420"},
{"label":"FIN 2230 - 100 Risk Mgmt of Fin Institutions CRN: 24961","subject":"FIN","courseNumber":"2230","CRN":"24961"},
{"label":"FIN 2335 - 001 Intl Financial Management CRN: 24974","subject":"FIN","courseNumber":"2335","CRN":"24974"},
{"label":"FIN 2343 - 001 Student-Managed Funds II CRN: 24976","subject":"FIN","courseNumber":"2343","CRN":"24976"},
{"label":"FIN 2360 - 001 Applied Financial Statistics CRN: 24978","subject":"FIN","courseNumber":"2360","CRN":"24978"},
{"label":"FIN 3350 - 001 Finance Internship CRN: 24979","subject":"FIN","courseNumber":"3350","CRN":"24979"},
{"label":"FIN 3360 - 001 Independent Study - Finance CRN: 24980","subject":"FIN","courseNumber":"3360","CRN":"24980"},
{"label":"FIN 3470 - 001 Finance Co-Op CRN: 24981","subject":"FIN","courseNumber":"3470","CRN":"24981"},
{"label":"FFS 1111 - 001 Introductory French I CRN: 24933","subject":"FFS","courseNumber":"1111","CRN":"24933"},
{"label":"FFS 1111 - 002 Introductory French I CRN: 24934","subject":"FFS","courseNumber":"1111","CRN":"24934"},
{"label":"FFS 1121 - 001 Intermediate French I CRN: 24935","subject":"FFS","courseNumber":"1121","CRN":"24935"},
{"label":"FFS 1121 - 002 Intermediate French I CRN: 24936","subject":"FFS","courseNumber":"1121","CRN":"24936"},
{"label":"FFS 1121 - 003 Intermediate French I CRN: 24937","subject":"FFS","courseNumber":"1121","CRN":"24937"},
{"label":"FFS 1121 - 004 Intermediate French I CRN: 24938","subject":"FFS","courseNumber":"1121","CRN":"24938"},
{"label":"FFS 1121 - 005 Intermediate French I CRN: 24939","subject":"FFS","courseNumber":"1121","CRN":"24939"},
{"label":"FFS 1122 - 001 Intermediate French II CRN: 24940","subject":"FFS","courseNumber":"1122","CRN":"24940"},
{"label":"FFS 1122 - 002 Intermediate French II CRN: 24941","subject":"FFS","courseNumber":"1122","CRN":"24941"},
{"label":"FFS 1131 - 001 Conversation and Composition I CRN: 24942","subject":"FFS","courseNumber":"1131","CRN":"24942"},
{"label":"FFS 1131 - 002 Conversation and Composition I CRN: 24943","subject":"FFS","courseNumber":"1131","CRN":"24943"},
{"label":"FFS 1132 - 001 Conversation & Composition II CRN: 24944","subject":"FFS","courseNumber":"1132","CRN":"24944"},
{"label":"FFS 1140 - 001 Writing & Stylistics in French CRN: 24945","subject":"FFS","courseNumber":"1140","CRN":"24945"},
{"label":"FFS 2220 - 001 Lit and Culture of France CRN: 24946","subject":"FFS","courseNumber":"2220","CRN":"24946"},
{"label":"FFS 3412 - 001 TOP:Social Peripheries CRN: 24947","subject":"FFS","courseNumber":"3412","CRN":"24947"},
{"label":"FFS 3971 - 001 Directed Research in FFS CRN: 24948","subject":"FFS","courseNumber":"3971","CRN":"24948"},
{"label":"FFS 5900 - 001 Independent Study CRN: 24949","subject":"FFS","courseNumber":"5900","CRN":"24949"},
{"label":"GWS 2050 - 002 Introduction to Gender Studies CRN: 25092","subject":"GWS","courseNumber":"2050","CRN":"25092"},
{"label":"HIS 2296 - 100 History of American Women CRN: 25131","subject":"GWS","courseNumber":"2296","CRN":"25131"},
{"label":"PSC 3130 - 001 Women and Politics in the U.S. CRN: 25757","subject":"GWS","courseNumber":"3130","CRN":"25757"},
{"label":"GWS 2050 - 001 Introduction to Gender Studies CRN: 25091","subject":"GWS","courseNumber":"2050","CRN":"25091"},
{"label":"GWS 2060 - 001 Introduction to LGBTQ Studies CRN: 25094","subject":"GWS","courseNumber":"2060","CRN":"25094"},
{"label":"GWS 2993 - 001 GWS Internship CRN: 25095","subject":"GWS","courseNumber":"2993","CRN":"25095"},
{"label":"GWS 3000 - 001 Independent Study CRN: 25096","subject":"GWS","courseNumber":"3000","CRN":"25096"},
{"label":"THE 3030 - 001 TOP:Performing Shakespeare CRN: 26055","subject":"GWS","courseNumber":"3030","CRN":"26055"},
{"label":"GWS 9000 - 001 Independent Study CRN: 25097","subject":"GWS","courseNumber":"9000","CRN":"25097"},
{"label":"GEV 1002 - 001 Geo. of a Globalizing World CRN: 24982","subject":"GEV","courseNumber":"1002","CRN":"24982"},
{"label":"GEV 1003 - 001 Geo. of Earth's Environments CRN: 24983","subject":"GEV","courseNumber":"1003","CRN":"24983"},
{"label":"GEV 1052 - 001 Environmental Studies CRN: 24987","subject":"GEV","courseNumber":"1052","CRN":"24987"},
{"label":"GEV 1750 - 001 Geo-Techniques CRN: 24988","subject":"GEV","courseNumber":"1750","CRN":"24988"},
{"label":"GEV 2500 - 001 Global Change in Local Places CRN: 24991","subject":"GEV","courseNumber":"2500","CRN":"24991"},
{"label":"MSE 2500 - 001 GEV: Env. Sustainability CRN: 25464","subject":"GEV","courseNumber":"2500","CRN":"25464"},
{"label":"MSE 2500 - 002 GEV: Env. Sustainability CRN: 26766","subject":"GEV","courseNumber":"2500","CRN":"26766"},
{"label":"MSE 2501 - 002 GEV: Our Warming Planet CRN: 26596","subject":"GEV","courseNumber":"2501","CRN":"26596"},
{"label":"GEV 2525 - 001 Population Geography CRN: 24992","subject":"GEV","courseNumber":"2525","CRN":"24992"},
{"label":"GEV 3550 - 001 Natural Hazards CRN: 24998","subject":"GEV","courseNumber":"3550","CRN":"24998"},
{"label":"GEV 3580 - 001 Natural Res and Conservation CRN: 24999","subject":"GEV","courseNumber":"3580","CRN":"24999"},
{"label":"GEV 3750 - 100 Remote Sensing CRN: 25001","subject":"GEV","courseNumber":"3750","CRN":"25001"},
{"label":"GEV 3760 - 001 Geospatial Appl in Env Sci CRN: 25002","subject":"GEV","courseNumber":"3760","CRN":"25002"},
{"label":"GEV 4301 - 001 TOP:Drones CRN: 25003","subject":"GEV","courseNumber":"4301","CRN":"25003"},
{"label":"GEV 4301 - 002 TOP:Dashboarding CRN: 25004","subject":"GEV","courseNumber":"4301","CRN":"25004"},
{"label":"GEV 4363 - 001 GIS for Conservation Mngmnt CRN: 25012","subject":"GEV","courseNumber":"4363","CRN":"25012"},
{"label":"GEV 4518 - 001 COVID-19 Pandemic CRN: 25013","subject":"GEV","courseNumber":"4518","CRN":"25013"},
{"label":"GEV 1004 - DL1 Climate Change CRN: 26421","subject":"GEV","courseNumber":"1004","CRN":"26421"},
{"label":"GEV 1050 - 001 Environmental Science I CRN: 24984","subject":"GEV","courseNumber":"1050","CRN":"24984"},
{"label":"GEV 1050 - 002 Environmental Science I CRN: 24985","subject":"GEV","courseNumber":"1050","CRN":"24985"},
{"label":"GEV 1050 - 003 Environmental Science I CRN: 24986","subject":"GEV","courseNumber":"1050","CRN":"24986"},
{"label":"GEV 1750 - 002 Geo-Techniques CRN: 24989","subject":"GEV","courseNumber":"1750","CRN":"24989"},
{"label":"GEV 2001 - 001 Prof Dev in Geo and Env CRN: 24990","subject":"GEV","courseNumber":"2001","CRN":"24990"},
{"label":"MSE 2504 - 001 GEV: Beaches and Coasts CRN: 26826","subject":"GEV","courseNumber":"2504","CRN":"26826"},
{"label":"MSE 2504 - 002 GEV: Beaches and Coasts CRN: 26827","subject":"GEV","courseNumber":"2504","CRN":"26827"},
{"label":"GEV 2893 - 001 Independent Study CRN: 24993","subject":"GEV","courseNumber":"2893","CRN":"24993"},
{"label":"GEV 2993 - 001 Internship CRN: 24994","subject":"GEV","courseNumber":"2993","CRN":"24994"},
{"label":"GEV 2996 - 001 Internship CRN: 24995","subject":"GEV","courseNumber":"2996","CRN":"24995"},
{"label":"GEV 3001 - 001 Intro to Sustainability Study CRN: 24996","subject":"GEV","courseNumber":"3001","CRN":"24996"},
{"label":"GEV 3001 - 002 Intro to Sustainability Study CRN: 24997","subject":"GEV","courseNumber":"3001","CRN":"24997"},
{"label":"GEV 3590 - 001 Topical Research Problems CRN: 25000","subject":"GEV","courseNumber":"3590","CRN":"25000"},
{"label":"GEV 4301 - 100 TOP:Google Earth Engine CRN: 25005","subject":"GEV","courseNumber":"4301","CRN":"25005"},
{"label":"GEV 4322 - 001 Ocean Environments CRN: 25006","subject":"GEV","courseNumber":"4322","CRN":"25006"},
{"label":"GEV 4330 - 001 TOP:Environmental Education CRN: 25008","subject":"GEV","courseNumber":"4330","CRN":"25008"},
{"label":"GEV 4330 - 002 TOP: Environmental Law CRN: 26597","subject":"GEV","courseNumber":"4330","CRN":"26597"},
{"label":"GEV 4331 - 100 Env. Policy & Management CRN: 25009","subject":"GEV","courseNumber":"4331","CRN":"25009"},
{"label":"GEV 4350 - 001 TOP:Applied Env Science CRN: 25010","subject":"GEV","courseNumber":"4350","CRN":"25010"},
{"label":"GEV 4350 - 002 TOP:Global Emerging Infections CRN: 25011","subject":"GEV","courseNumber":"4350","CRN":"25011"},
{"label":"GEV 4700 - 100 Geographic Information Systems CRN: 26817","subject":"GEV","courseNumber":"4700","CRN":"26817"},
{"label":"GEV 6005 - 001 Senior Research Seminar CRN: 25014","subject":"GEV","courseNumber":"6005","CRN":"25014"},
{"label":"GEV 6006 - 100 Research Colloquium CRN: 25015","subject":"GEV","courseNumber":"6006","CRN":"25015"},
{"label":"GEV 6200 - 001 Independent Study CRN: 25016","subject":"GEV","courseNumber":"6200","CRN":"25016"},
{"label":"GEV 6200 - 002 Independent Study CRN: 25017","subject":"GEV","courseNumber":"6200","CRN":"25017"},
{"label":"GEV 6200 - 003 Independent Study CRN: 25018","subject":"GEV","courseNumber":"6200","CRN":"25018"},
{"label":"GEV 6200 - 004 Independent Study CRN: 25019","subject":"GEV","courseNumber":"6200","CRN":"25019"},
{"label":"GEV 6200 - 005 Independent Study CRN: 25020","subject":"GEV","courseNumber":"6200","CRN":"25020"},
{"label":"GEV 6200 - 006 Independent Study CRN: 25021","subject":"GEV","courseNumber":"6200","CRN":"25021"},
{"label":"GEV 6200 - 007 Independent Study CRN: 25022","subject":"GEV","courseNumber":"6200","CRN":"25022"},
{"label":"GEV 6200 - 008 Independent Study CRN: 25023","subject":"GEV","courseNumber":"6200","CRN":"25023"},
{"label":"GEV 6200 - 009 Independent Study CRN: 25024","subject":"GEV","courseNumber":"6200","CRN":"25024"},
{"label":"GEV 6200 - 010 Independent Study CRN: 25025","subject":"GEV","courseNumber":"6200","CRN":"25025"},
{"label":"GEV 6200 - 011 Independent Study CRN: 25026","subject":"GEV","courseNumber":"6200","CRN":"25026"},
{"label":"GEV 6200 - 012 Independent Study CRN: 25027","subject":"GEV","courseNumber":"6200","CRN":"25027"},
{"label":"GEV 6200 - 013 Independent Study CRN: 25028","subject":"GEV","courseNumber":"6200","CRN":"25028"},
{"label":"GEV 6200 - 014 Independent Study CRN: 25029","subject":"GEV","courseNumber":"6200","CRN":"25029"},
{"label":"GEV 6210 - 001 Senior Thesis Research I CRN: 25031","subject":"GEV","courseNumber":"6210","CRN":"25031"},
{"label":"GEV 6210 - 002 Senior Thesis Research I CRN: 25032","subject":"GEV","courseNumber":"6210","CRN":"25032"},
{"label":"GEV 6210 - 003 Senior Thesis Research I CRN: 25033","subject":"GEV","courseNumber":"6210","CRN":"25033"},
{"label":"GEV 6210 - 004 Senior Thesis Research I CRN: 25034","subject":"GEV","courseNumber":"6210","CRN":"25034"},
{"label":"GEV 6210 - 005 Senior Thesis Research I CRN: 25035","subject":"GEV","courseNumber":"6210","CRN":"25035"},
{"label":"GEV 6210 - 006 Senior Thesis Research I CRN: 25036","subject":"GEV","courseNumber":"6210","CRN":"25036"},
{"label":"GEV 6210 - 007 Senior Thesis Research I CRN: 25037","subject":"GEV","courseNumber":"6210","CRN":"25037"},
{"label":"GEV 6210 - 008 Senior Thesis Research I CRN: 25038","subject":"GEV","courseNumber":"6210","CRN":"25038"},
{"label":"GEV 6210 - 009 Senior Thesis Research I CRN: 25039","subject":"GEV","courseNumber":"6210","CRN":"25039"},
{"label":"GEV 6210 - 010 Senior Thesis Research I CRN: 25040","subject":"GEV","courseNumber":"6210","CRN":"25040"},
{"label":"GEV 6210 - 011 Senior Thesis Research I CRN: 25041","subject":"GEV","courseNumber":"6210","CRN":"25041"},
{"label":"GEV 6210 - 012 Senior Thesis Research I CRN: 25042","subject":"GEV","courseNumber":"6210","CRN":"25042"},
{"label":"GEV 6210 - 013 Senior Thesis Research I CRN: 25043","subject":"GEV","courseNumber":"6210","CRN":"25043"},
{"label":"GEV 7000 - 001 Research Methods in Env Sci CRN: 25044","subject":"GEV","courseNumber":"7000","CRN":"25044"},
{"label":"GEV 7010 - 001 Environmental Colloquium CRN: 25045","subject":"GEV","courseNumber":"7010","CRN":"25045"},
{"label":"GEV 7030 - 001 Proposal Development CRN: 25046","subject":"GEV","courseNumber":"7030","CRN":"25046"},
{"label":"GEV 7030 - 002 Proposal Development CRN: 25047","subject":"GEV","courseNumber":"7030","CRN":"25047"},
{"label":"GEV 7030 - 003 Proposal Development CRN: 25048","subject":"GEV","courseNumber":"7030","CRN":"25048"},
{"label":"GEV 7030 - 004 Proposal Development CRN: 25049","subject":"GEV","courseNumber":"7030","CRN":"25049"},
{"label":"GEV 7030 - 005 Proposal Development CRN: 25050","subject":"GEV","courseNumber":"7030","CRN":"25050"},
{"label":"GEV 7030 - 006 Proposal Development CRN: 25051","subject":"GEV","courseNumber":"7030","CRN":"25051"},
{"label":"GEV 7030 - 007 Proposal Development CRN: 25052","subject":"GEV","courseNumber":"7030","CRN":"25052"},
{"label":"GEV 7030 - 008 Proposal Development CRN: 25053","subject":"GEV","courseNumber":"7030","CRN":"25053"},
{"label":"GEV 7030 - 009 Proposal Development CRN: 25054","subject":"GEV","courseNumber":"7030","CRN":"25054"},
{"label":"GEV 7040 - 001 Intro to GIS CRN: 25055","subject":"GEV","courseNumber":"7040","CRN":"25055"},
{"label":"GEV 7042 - 001 Adv Geospatial Appl in Env Sci CRN: 25056","subject":"GEV","courseNumber":"7042","CRN":"25056"},
{"label":"GEV 7100 - 001 Remote Sensing-Env Analysis CRN: 25057","subject":"GEV","courseNumber":"7100","CRN":"25057"},
{"label":"GEV 7103 - 001 Env. Policy and Law CRN: 26598","subject":"GEV","courseNumber":"7103","CRN":"26598"},
{"label":"GEV 7206 - 001 Applied Environmental Science CRN: 25058","subject":"GEV","courseNumber":"7206","CRN":"25058"},
{"label":"GEV 8000 - 001 TOP:Natural Hazards CRN: 25059","subject":"GEV","courseNumber":"8000","CRN":"25059"},
{"label":"GEV 8003 - 001 TOP:Environ Policy & Mngmt CRN: 25060","subject":"GEV","courseNumber":"8003","CRN":"25060"},
{"label":"GEV 8030 - 001 Independent Study CRN: 25061","subject":"GEV","courseNumber":"8030","CRN":"25061"},
{"label":"GEV 8300 - 001 TOP:Drones CRN: 25062","subject":"GEV","courseNumber":"8300","CRN":"25062"},
{"label":"GEV 8300 - 002 TOP:Dashboarding CRN: 25063","subject":"GEV","courseNumber":"8300","CRN":"25063"},
{"label":"GEV 8300 - 003 TOP:Google Earth Engine CRN: 25064","subject":"GEV","courseNumber":"8300","CRN":"25064"},
{"label":"GEV 8322 - 001 Ocean and Coastal Environ CRN: 25065","subject":"GEV","courseNumber":"8322","CRN":"25065"},
{"label":"GEV 8342 - 001 GIS for Conservation Mngmnt CRN: 25067","subject":"GEV","courseNumber":"8342","CRN":"25067"},
{"label":"GEV 9000 - 001 Directed Research CRN: 25068","subject":"GEV","courseNumber":"9000","CRN":"25068"},
{"label":"GEV 9001 - 001 Thesis Continuation CRN: 25069","subject":"GEV","courseNumber":"9001","CRN":"25069"},
{"label":"GEV 9002 - 001 Portfolio CRN: 25070","subject":"GEV","courseNumber":"9002","CRN":"25070"},
{"label":"GEV 9005 - 001 Capstone Project CRN: 25071","subject":"GEV","courseNumber":"9005","CRN":"25071"},
{"label":"GEV 9006 - 001 Capstone Research I CRN: 25072","subject":"GEV","courseNumber":"9006","CRN":"25072"},
{"label":"GEV 9010 - 001 Thesis Research I CRN: 25073","subject":"GEV","courseNumber":"9010","CRN":"25073"},
{"label":"GEV 9020 - 001 Thesis Research II CRN: 25074","subject":"GEV","courseNumber":"9020","CRN":"25074"},
{"label":"GEV 9030 - 001 Thesis Research III CRN: 25075","subject":"GEV","courseNumber":"9030","CRN":"25075"},
{"label":"GEV 9040 - 001 Thesis Research IV CRN: 25076","subject":"GEV","courseNumber":"9040","CRN":"25076"},
{"label":"GEV 9050 - 001 Thesis Writing CRN: 25077","subject":"GEV","courseNumber":"9050","CRN":"25077"},
{"label":"GIS 2000 - 001 Intro to Global Interd Studies CRN: 25078","subject":"GIS","courseNumber":"2000","CRN":"25078"},
{"label":"GIS 2000 - 002 Intro to Global Interd Studies CRN: 25079","subject":"GIS","courseNumber":"2000","CRN":"25079"},
{"label":"GIS 2000 - 003 Intro to Global Interd Studies CRN: 25080","subject":"GIS","courseNumber":"2000","CRN":"25080"},
{"label":"GIS 6500 - 002 Capstone I: Research CRN: 25086","subject":"GIS","courseNumber":"6500","CRN":"25086"},
{"label":"GIS 6600 - 001 Capstone 2:Thesis CRN: 26820","subject":"GIS","courseNumber":"6600","CRN":"26820"},
{"label":"GIS 2993 - 001 Global Studies Internship CRN: 25081","subject":"GIS","courseNumber":"2993","CRN":"25081"},
{"label":"GIS 3992 - 001 TOP: Chinese Culture  CRN: 25082","subject":"GIS","courseNumber":"3992","CRN":"25082"},
{"label":"GIS 3992 - 002 TOP: Step-by-Step Japanese CRN: 26658","subject":"GIS","courseNumber":"3992","CRN":"26658"},
{"label":"GIS 3992 - 003 TOP:Japanese Conversation CRN: 26659","subject":"GIS","courseNumber":"3992","CRN":"26659"},
{"label":"GIS 5011 - 002 TOP:Power,Marginality,&Voices CRN: 26657","subject":"GIS","courseNumber":"5011","CRN":"26657"},
{"label":"GIS 6000 - 001 Independent Study CRN: 25084","subject":"GIS","courseNumber":"6000","CRN":"25084"},
{"label":"GIS 6500 - 001 Capstone I: Research CRN: 25085","subject":"GIS","courseNumber":"6500","CRN":"25085"},
{"label":"GRK 1111 - 001 Introductory Ancient Greek I CRN: 25087","subject":"GRK","courseNumber":"1111","CRN":"25087"},
{"label":"GRK 2001 - DL1 Readings in Ancient Greek CRN: 25088","subject":"GRK","courseNumber":"2001","CRN":"25088"},
{"label":"GRK 7200 - DL1 Readings in Ancient Greek CRN: 25089","subject":"GRK","courseNumber":"7200","CRN":"25089"},
{"label":"GRK 8450 - DL1 Homer CRN: 25090","subject":"GRK","courseNumber":"8450","CRN":"25090"},
{"label":"HIS 1065 - 001 Global Themes in Epidemic Disease CRN: 25099","subject":"HHMA","courseNumber":"1065","CRN":"25099"},
{"label":"PHI 2115 - 002 Ethics for Health Care Prof CRN: 25621","subject":"HHMA","courseNumber":"2115","CRN":"25621"},
{"label":"SOC 3450 - 001 Soc of Mental Health & Illness CRN: 25909","subject":"HHMA","courseNumber":"3450","CRN":"25909"},
{"label":"HHMA 2050 - 100 Intro to Health Humanities CRN: 26760","subject":"HHMA","courseNumber":"2050","CRN":"26760"},
{"label":"PHI 2115 - 001 Ethics for Health Care Prof CRN: 25620","subject":"HHMA","courseNumber":"2115","CRN":"25620"},
{"label":"PHI 2115 - 003 Ethics for Health Care Prof CRN: 25622","subject":"HHMA","courseNumber":"2115","CRN":"25622"},
{"label":"PHI 2115 - 004 Ethics for Health Care Prof CRN: 25623","subject":"HHMA","courseNumber":"2115","CRN":"25623"},
{"label":"PHI 2117 - H01 HON:The Good Doctor CRN: 25624","subject":"HHMA","courseNumber":"2117","CRN":"25624"},
{"label":"HIS 2274 - 001 The History of AMerican Medicine CRN: 25128","subject":"HHMA","courseNumber":"2274","CRN":"25128"},
{"label":"SOC 3400 - 001 Health, Medicine & Society CRN: 25907","subject":"HHMA","courseNumber":"3400","CRN":"25907"},
{"label":"SOC 3400 - 002 Health, Medicine & Society CRN: 25908","subject":"HHMA","courseNumber":"3400","CRN":"25908"},
{"label":"PHI 4125 - 001 Bioethics CRN: 25642","subject":"HHMA","courseNumber":"4125","CRN":"25642"},
{"label":"HIS 1065 - 002 TOP:EnvirChngNorAmer1500-1850 CRN: 25100","subject":"HIS","courseNumber":"1065","CRN":"25100"},
{"label":"HIS 1065 - 003 TOP:Global Environ Hist CRN: 25101","subject":"HIS","courseNumber":"1065","CRN":"25101"},
{"label":"HIS 1070 - 001 TOP:History of British Empire CRN: 25102","subject":"HIS","courseNumber":"1070","CRN":"25102"},
{"label":"HIS 1070 - 002 TOP:EarlyModrnEurope and World CRN: 26707","subject":"HIS","courseNumber":"1070","CRN":"26707"},
{"label":"HIS 1150 - 001 TOP:TransatlanticRevs Mod Wrld CRN: 25103","subject":"HIS","courseNumber":"1150","CRN":"25103"},
{"label":"HIS 1155 - 004 TOP:AmerAgeofRev,1776-1848 CRN: 25109","subject":"HIS","courseNumber":"1155","CRN":"25109"},
{"label":"HIS 1155 - 005 TOP:AmerAgeofRev,1776-1848 CRN: 25110","subject":"HIS","courseNumber":"1155","CRN":"25110"},
{"label":"HIS 1155 - 006 TOP:American Legal History CRN: 25111","subject":"HIS","courseNumber":"1155","CRN":"25111"},
{"label":"HIS 1165 - 001 TOP:Soccer & Making Mod World CRN: 25113","subject":"HIS","courseNumber":"1165","CRN":"25113"},
{"label":"HIS 1165 - 002 Global Themes in the History of Migration CRN: 25114","subject":"HIS","courseNumber":"1165","CRN":"25114"},
{"label":"HIS 1165 - 005 TOP:Commoditites&GlobalCapital CRN: 25117","subject":"HIS","courseNumber":"1165","CRN":"25117"},
{"label":"HIS 1165 - 006 TOP:HisofPhildlphia:GlobalCity CRN: 25118","subject":"HIS","courseNumber":"1165","CRN":"25118"},
{"label":"HIS 1165 - 007 TOP:NatureTech&BeliefPreModEur CRN: 25119","subject":"HIS","courseNumber":"1165","CRN":"25119"},
{"label":"HIS 2000 - 001 Investigating U.S. History I CRN: 25124","subject":"HIS","courseNumber":"2000","CRN":"25124"},
{"label":"HIS 2271 - 001 US Const. & Legal History CRN: 25127","subject":"HIS","courseNumber":"2271","CRN":"25127"},
{"label":"HIS 2537 - 001 Rome: Emperors, Popes & Saints CRN: 25133","subject":"HIS","courseNumber":"2537","CRN":"25133"},
{"label":"HUM 2900 - 004 TOP:The State & The Soul CRN: 25180","subject":"HIS","courseNumber":"2900","CRN":"25180"},
{"label":"HIS 3801 - 001 History Topics Abroad CRN: 25137","subject":"HIS","courseNumber":"3801","CRN":"25137"},
{"label":"HIS 1060 - 001 TOP:Medieval Spain CRN: 25098","subject":"HIS","courseNumber":"1060","CRN":"25098"},
{"label":"HIS 1165 - 004 TOP:War&SocietyPreModernWrl CRN: 25116","subject":"HIS","courseNumber":"1165","CRN":"25116"},
{"label":"HIS 1165 - DL1 TOP:Global Mkts, Equal&Inequal CRN: 25120","subject":"HIS","courseNumber":"1165","CRN":"25120"},
{"label":"HIS 1165 - H01 HON:Suffering&Progressin 20 C CRN: 25121","subject":"HIS","courseNumber":"1165","CRN":"25121"},
{"label":"HIS 1903 - 001 Internship Elective CRN: 25123","subject":"HIS","courseNumber":"1903","CRN":"25123"},
{"label":"HIS 2161 - 001 Revolutionary & Fed Amer CRN: 25125","subject":"HIS","courseNumber":"2161","CRN":"25125"},
{"label":"HIS 2278 - 001 Native American History CRN: 25129","subject":"HIS","courseNumber":"2278","CRN":"25129"},
{"label":"HIS 2641 - 001 Imperial Russia, 1762-1917 CRN: 25134","subject":"HIS","courseNumber":"2641","CRN":"25134"},
{"label":"HIS 2993 - 001 Hist Internship CRN: 25135","subject":"HIS","courseNumber":"2993","CRN":"25135"},
{"label":"HIS 3006 - 001 Medieval Europe 500-1500 CRN: 26573","subject":"HIS","courseNumber":"3006","CRN":"26573"},
{"label":"HIS 3216 - 001 Ireland since 1800 CRN: 25136","subject":"HIS","courseNumber":"3216","CRN":"25136"},
{"label":"HIS 3995 - 001 Weimar Germany, 1918-1933 CRN: 25138","subject":"HIS","courseNumber":"3995","CRN":"25138"},
{"label":"HIS 4660 - 001 The World at War, 1939-1945 CRN: 26576","subject":"HIS","courseNumber":"4660","CRN":"26576"},
{"label":"HIS 5001 - 001 Junior Research Seminar CRN: 25139","subject":"HIS","courseNumber":"5001","CRN":"25139"},
{"label":"HIS 5501 - 001 Sem in Historical Methodology CRN: 25140","subject":"HIS","courseNumber":"5501","CRN":"25140"},
{"label":"HIS 5515 - 001 Independent Research CRN: 25141","subject":"HIS","courseNumber":"5515","CRN":"25141"},
{"label":"HIS 8278 - 001 Nazi Germany CRN: 25142","subject":"HIS","courseNumber":"8278","CRN":"25142"},
{"label":"HIS 8454 - 001 Empire & Decolonization CRN: 25143","subject":"HIS","courseNumber":"8454","CRN":"25143"},
{"label":"HIS 8682 - 001 The Cold War as History CRN: 25144","subject":"HIS","courseNumber":"8682","CRN":"25144"},
{"label":"HIS 8704 - 001 Material Culture CRN: 25145","subject":"HIS","courseNumber":"8704","CRN":"25145"},
{"label":"HIS 8850 - 001 Theory and Methods in History CRN: 25146","subject":"HIS","courseNumber":"8850","CRN":"25146"},
{"label":"HIS 9006 - 001 Grad Internship in Public Hist CRN: 25147","subject":"HIS","courseNumber":"9006","CRN":"25147"},
{"label":"HIS 9012 - 001 Directed Readings in History CRN: 25148","subject":"HIS","courseNumber":"9012","CRN":"25148"},
{"label":"HIS 9022 - 001 Thesis Direction I CRN: 25149","subject":"HIS","courseNumber":"9022","CRN":"25149"},
{"label":"HIS 9032 - 001 Thesis Direction II CRN: 25150","subject":"HIS","courseNumber":"9032","CRN":"25150"},
{"label":"HIS 9042 - 001 Internship in Teach of History CRN: 25151","subject":"HIS","courseNumber":"9042","CRN":"25151"},
{"label":"VSB 2009 - H01 HON:Principles of Finance CRN: 26264","subject":"HON","courseNumber":"2009","CRN":"26264"},
{"label":"NUR 3108 - H01 HON:Pathophysiology CRN: 25518","subject":"HON","courseNumber":"3108","CRN":"25518"},
{"label":"VSB 3900 - H01 HON:Innovation&DesignPracticum CRN: 26299","subject":"HON","courseNumber":"3900","CRN":"26299"},
{"label":"HON 4800 - 001 THL:Shaping Adult Life CRN: 25152","subject":"HON","courseNumber":"4800","CRN":"25152"},
{"label":"PHI 1000 - H01 HON:Knowledge, Reality, Self CRN: 25615","subject":"HON","courseNumber":"1000","CRN":"25615"},
{"label":"PSY 1000 - H01 HON:General Psychology CRN: 25795","subject":"HON","courseNumber":"1000","CRN":"25795"},
{"label":"THL 1000 - H01 HON:Catholic Studies CRN: 26110","subject":"HON","courseNumber":"1000","CRN":"26110"},
{"label":"PHI 1000 - H02 HON:Knowledge, Reality, Self CRN: 25616","subject":"HON","courseNumber":"1000","CRN":"25616"},
{"label":"THL 1000 - H02 HON:Faith, Reason & Culture CRN: 26111","subject":"HON","courseNumber":"1000","CRN":"26111"},
{"label":"PHI 1000 - H03 HON:Knowledge, Reality, Self CRN: 25617","subject":"HON","courseNumber":"1000","CRN":"25617"},
{"label":"THL 1000 - H03 HON:Faith, Reason & Culture CRN: 26112","subject":"HON","courseNumber":"1000","CRN":"26112"},
{"label":"THL 1000 - H04 HON:Catholic Studies CRN: 26113","subject":"HON","courseNumber":"1000","CRN":"26113"},
{"label":"VSB 1015 - H01 HON:Business Dynamics CRN: 26205","subject":"HON","courseNumber":"1015","CRN":"26205"},
{"label":"VSB 1015 - H02 HON:Business Dynamics CRN: 26206","subject":"HON","courseNumber":"1015","CRN":"26206"},
{"label":"PSC 1200 - H01 HON:International Relations CRN: 25741","subject":"HON","courseNumber":"1200","CRN":"25741"},
{"label":"VSB 2007 - H01 HON:Corp Respon & Regulation CRN: 26243","subject":"HON","courseNumber":"2007","CRN":"26243"},
{"label":"VSB 2008 - H01 HON:Business Analytics CRN: 26251","subject":"HON","courseNumber":"2008","CRN":"26251"},
{"label":"VSB 2014 - H01 HON:Princ of Managerial Acct CRN: 26277","subject":"HON","courseNumber":"2014","CRN":"26277"},
{"label":"VSB 2014 - H02 HON:Princ of Managerial Acct CRN: 26278","subject":"HON","courseNumber":"2014","CRN":"26278"},
{"label":"PHI 2990 - H01 HON:Tech, Humanity & Divinity CRN: 25635","subject":"HON","courseNumber":"2990","CRN":"25635"},
{"label":"VSB 3900 - H02 HON:Innovation&DesignPracticum CRN: 26300","subject":"HON","courseNumber":"3900","CRN":"26300"},
{"label":"PSC 4275 - H01 HON:Terror & Terroism CRN: 25765","subject":"HON","courseNumber":"4275","CRN":"25765"},
{"label":"HON 5001 - 001 Shaping A College Life CRN: 25153","subject":"HON","courseNumber":"5001","CRN":"25153"},
{"label":"HON 5001 - 002 Shaping A College Life CRN: 25154","subject":"HON","courseNumber":"5001","CRN":"25154"},
{"label":"HON 5001 - 003 Shaping A College Life CRN: 25155","subject":"HON","courseNumber":"5001","CRN":"25155"},
{"label":"HON 5001 - 004 Shaping A College Life CRN: 25156","subject":"HON","courseNumber":"5001","CRN":"25156"},
{"label":"HON 5001 - 005 Shaping A College Life CRN: 25157","subject":"HON","courseNumber":"5001","CRN":"25157"},
{"label":"HON 5300 - 100 TOP:Handyman University CRN: 25158","subject":"HON","courseNumber":"5300","CRN":"25158"},
{"label":"HON 5305 - 001 COLL: StandUp Comedy CRN: 25159","subject":"HON","courseNumber":"5305","CRN":"25159"},
{"label":"HON 5410 - 001 Intro to Guitar I CRN: 25160","subject":"HON","courseNumber":"5410","CRN":"25160"},
{"label":"HON 5410 - 002 Intro to Guitar II CRN: 25161","subject":"HON","courseNumber":"5410","CRN":"25161"},
{"label":"HON 5410 - 100 Intro to Guitar I CRN: 25162","subject":"HON","courseNumber":"5410","CRN":"25162"},
{"label":"HON 5410 - 101 Intro to Guitar II CRN: 25163","subject":"HON","courseNumber":"5410","CRN":"25163"},
{"label":"HON 5440 - 100 COLL:PoetsInGalleryAtPMoA CRN: 25164","subject":"HON","courseNumber":"5440","CRN":"25164"},
{"label":"HON 5460 - 100 Art of Science & Nature CRN: 25165","subject":"HON","courseNumber":"5460","CRN":"25165"},
{"label":"HON 5460 - 101 Celtic Landscapes CRN: 26599","subject":"HON","courseNumber":"5460","CRN":"26599"},
{"label":"HON 5490 - 001 COLL: Cultural Ldrship 4StdyAb CRN: 25166","subject":"HON","courseNumber":"5490","CRN":"25166"},
{"label":"HON 5501 - 001 Ind Study & Research CRN: 25167","subject":"HON","courseNumber":"5501","CRN":"25167"},
{"label":"HON 5599 - 001 Teaching Practicum CRN: 25168","subject":"HON","courseNumber":"5599","CRN":"25168"},
{"label":"HON 6000 - 001 Senior Thesis I CRN: 25169","subject":"HON","courseNumber":"6000","CRN":"25169"},
{"label":"HON 6002 - 001 Senior Thesis II CRN: 25170","subject":"HON","courseNumber":"6002","CRN":"25170"},
{"label":"HON 6003 - 001 Integrative Capstone CRN: 25171","subject":"HON","courseNumber":"6003","CRN":"25171"},
{"label":"HRD 7998 - 031 HRD Experiential Project CRN: 26544","subject":"HRD","courseNumber":"7998","CRN":"26544"},
{"label":"HRD 7999 - 031 Comprehensive Exam CRN: 26545","subject":"HRD","courseNumber":"7999","CRN":"26545"},
{"label":"HRD 8003 - D21 Financial & Human Capital CRN: 26522","subject":"HRD","courseNumber":"8003","CRN":"26522"},
{"label":"HRD 8003 - D22 Financial & Human Capital CRN: 26523","subject":"HRD","courseNumber":"8003","CRN":"26523"},
{"label":"HRD 8006 - 011 People Analytics CRN: 26498","subject":"HRD","courseNumber":"8006","CRN":"26498"},
{"label":"HRD 8006 - D11 People Analytics CRN: 26499","subject":"HRD","courseNumber":"8006","CRN":"26499"},
{"label":"HRD 8006 - D12 People Analytics CRN: 26500","subject":"HRD","courseNumber":"8006","CRN":"26500"},
{"label":"HRD 8100 - D12 Evidence-Based HR Primer CRN: 26490","subject":"HRD","courseNumber":"8100","CRN":"26490"},
{"label":"HRD 8100 - D21 Evidence-Based HR Primer CRN: 26493","subject":"HRD","courseNumber":"8100","CRN":"26493"},
{"label":"HRD 8101 - D11 Intro to HR & Org. Dynamics CRN: 26501","subject":"HRD","courseNumber":"8101","CRN":"26501"},
{"label":"HRD 8210 - D21 Strategic Workforce Planning CRN: 26524","subject":"HRD","courseNumber":"8210","CRN":"26524"},
{"label":"HRD 8210 - D22 Strategic Workforce Planning CRN: 26525","subject":"HRD","courseNumber":"8210","CRN":"26525"},
{"label":"HRD 8212 - D11 Total Rewards:Compensation CRN: 26502","subject":"HRD","courseNumber":"8212","CRN":"26502"},
{"label":"HRD 8212 - D12 Total Rewards:Compensation CRN: 26503","subject":"HRD","courseNumber":"8212","CRN":"26503"},
{"label":"HRD 8213 - D21 Total Rewards:Benefits CRN: 26526","subject":"HRD","courseNumber":"8213","CRN":"26526"},
{"label":"HRD 8214 - D21 HR Technology CRN: 26527","subject":"HRD","courseNumber":"8214","CRN":"26527"},
{"label":"HRD 8215 - D21 Employment Law CRN: 26528","subject":"HRD","courseNumber":"8215","CRN":"26528"},
{"label":"HRD 8304 - D11 Emotional Intelligence CRN: 26491","subject":"HRD","courseNumber":"8304","CRN":"26491"},
{"label":"HRD 8305 - D11 Cultrl Compet, Equity&SocJusti CRN: 26492","subject":"HRD","courseNumber":"8305","CRN":"26492"},
{"label":"HRD 8306 - D21 Measure I&D Initiatives&Effect CRN: 26494","subject":"HRD","courseNumber":"8306","CRN":"26494"},
{"label":"HRD 8307 - D21 Work, Family&Career Considerat CRN: 26496","subject":"HRD","courseNumber":"8307","CRN":"26496"},
{"label":"HRD 8330 - D21 Human Resource Ethics CRN: 26495","subject":"HRD","courseNumber":"8330","CRN":"26495"},
{"label":"HRD 8345 - D21 Relational Capital CRN: 26497","subject":"HRD","courseNumber":"8345","CRN":"26497"},
{"label":"HRD 8419 - 021 Foundations of Strategic HR CRN: 26529","subject":"HRD","courseNumber":"8419","CRN":"26529"},
{"label":"HRD 8419 - D21 Foundations of Strategic HR CRN: 26530","subject":"HRD","courseNumber":"8419","CRN":"26530"},
{"label":"HRD 8419 - D22 Foundations of Strategic HR CRN: 26531","subject":"HRD","courseNumber":"8419","CRN":"26531"},
{"label":"HRD 8420 - D12 Strategic & Global HR Ldrship CRN: 26504","subject":"HRD","courseNumber":"8420","CRN":"26504"},
{"label":"HRD 8421 - D21 Diversity in a Global Economy CRN: 26532","subject":"HRD","courseNumber":"8421","CRN":"26532"},
{"label":"PSY 8425 - D11 Organizational Behavior CRN: 26517","subject":"HRD","courseNumber":"8425","CRN":"26517"},
{"label":"PSY 8475 - D11 Learning and Development CRN: 26518","subject":"HRD","courseNumber":"8475","CRN":"26518"},
{"label":"PSY 8475 - D12 Learning and Development CRN: 26519","subject":"HRD","courseNumber":"8475","CRN":"26519"},
{"label":"PSY 8875 - D11 Organization Development CRN: 26520","subject":"HRD","courseNumber":"8875","CRN":"26520"},
{"label":"PSY 8875 - D12 Organization Development CRN: 26521","subject":"HRD","courseNumber":"8875","CRN":"26521"},
{"label":"HRMG 2030 - DL1 Managing Compensation&Benefit CRN: 26422","subject":"HRMG","courseNumber":"2030","CRN":"26422"},
{"label":"HRMG 5000 - DL1 HR Management Resrch Capstone CRN: 26467","subject":"HRMG","courseNumber":"5000","CRN":"26467"},
{"label":"HUM 2001 - 001 THL:God CRN: 25173","subject":"HUM","courseNumber":"2001","CRN":"25173"},
{"label":"HUM 2002 - 001 Human Person CRN: 25174","subject":"HUM","courseNumber":"2002","CRN":"25174"},
{"label":"HUM 2004 - 001 PSC:Society CRN: 25176","subject":"HUM","courseNumber":"2004","CRN":"25176"},
{"label":"HUM 2900 - 001 TOP:WisdomFaith&Reason CRN: 25177","subject":"HUM","courseNumber":"2900","CRN":"25177"},
{"label":"HUM 2900 - 002 TOP:American Empire CRN: 25178","subject":"HUM","courseNumber":"2900","CRN":"25178"},
{"label":"HUM 2900 - 005 TOP:Dante&Theology CRN: 25181","subject":"HUM","courseNumber":"2900","CRN":"25181"},
{"label":"HUM 2003 - 001 PHI:World CRN: 25175","subject":"HUM","courseNumber":"2003","CRN":"25175"},
{"label":"HUM 2900 - 003 TOP:Lincoln&Douglas(s) CRN: 25179","subject":"HUM","courseNumber":"2900","CRN":"25179"},
{"label":"HUM 2900 - 006 TOP:Music & Higher Meaning CRN: 25182","subject":"HUM","courseNumber":"2900","CRN":"25182"},
{"label":"HUM 2993 - 001 Internship CRN: 25183","subject":"HUM","courseNumber":"2993","CRN":"25183"},
{"label":"HUM 2996 - 001 Internship CRN: 25184","subject":"HUM","courseNumber":"2996","CRN":"25184"},
{"label":"HUM 6950 - 001 Ind Study & Research CRN: 25186","subject":"HUM","courseNumber":"6950","CRN":"25186"},
{"label":"ISYT 1000 - DL1 Intro to Info Sys & Tech CRN: 26423","subject":"ISYT","courseNumber":"1000","CRN":"26423"},
{"label":"ISYT 5993 - 100 Info System&Tech Indp Study CRN: 25191","subject":"ISYT","courseNumber":"5993","CRN":"25191"},
{"label":"PSC 6900 - 004 SEM:SocialMoveDividedSociety CRN: 25775","subject":"IS","courseNumber":"6900","CRN":"25775"},
{"label":"IS 1111 - 001 Introductory Irish Language I CRN: 25187","subject":"IS","courseNumber":"1111","CRN":"25187"},
{"label":"IS 1111 - 002 Introductory Irish Language I CRN: 25188","subject":"IS","courseNumber":"1111","CRN":"25188"},
{"label":"IS 1121 - 001 Intermediate Irish Language I CRN: 25189","subject":"IS","courseNumber":"1121","CRN":"25189"},
{"label":"PHI 2470 - 001 Irish Thought and Literature CRN: 25631","subject":"IS","courseNumber":"2470","CRN":"25631"},
{"label":"IS 3100 - 001 TOP:ThreatenedLiteraryCultures CRN: 25190","subject":"IS","courseNumber":"3100","CRN":"25190"},
{"label":"ITA 1121 - 002 Intermediate Italian I CRN: 25197","subject":"ITA","courseNumber":"1121","CRN":"25197"},
{"label":"ITA 1111 - 001 Introductory Italian I CRN: 25192","subject":"ITA","courseNumber":"1111","CRN":"25192"},
{"label":"ITA 1111 - 002 Introductory Italian I CRN: 25193","subject":"ITA","courseNumber":"1111","CRN":"25193"},
{"label":"ITA 1111 - 003 Introductory Italian I CRN: 25194","subject":"ITA","courseNumber":"1111","CRN":"25194"},
{"label":"ITA 1111 - 004 Introductory Italian I CRN: 25195","subject":"ITA","courseNumber":"1111","CRN":"25195"},
{"label":"ITA 1121 - 001 Intermediate Italian I CRN: 25196","subject":"ITA","courseNumber":"1121","CRN":"25196"},
{"label":"ITA 1121 - 003 Intermediate Italian I CRN: 25198","subject":"ITA","courseNumber":"1121","CRN":"25198"},
{"label":"ITA 1136 - 001 Italy by Vespa CRN: 25199","subject":"ITA","courseNumber":"1136","CRN":"25199"},
{"label":"ITA 1136 - 002 Italy by Vespa CRN: 25200","subject":"ITA","courseNumber":"1136","CRN":"25200"},
{"label":"ITA 2340 - 001 Boccaccio: Sex Lies Silence CRN: 25201","subject":"ITA","courseNumber":"2340","CRN":"25201"},
{"label":"ITA 3100 - 001 Teaching Practicum CRN: 25202","subject":"ITA","courseNumber":"3100","CRN":"25202"},
{"label":"ITA 3480 - 002 Ecstasy Depression Healing CRN: 25203","subject":"ITA","courseNumber":"3480","CRN":"25203"},
{"label":"ITA 3971 - 001 Directed Research in Italian CRN: 25204","subject":"ITA","courseNumber":"3971","CRN":"25204"},
{"label":"ITA 3971 - 002 Directed Research in Italian CRN: 25205","subject":"ITA","courseNumber":"3971","CRN":"25205"},
{"label":"ITA 5900 - 001 Independent Study CRN: 25206","subject":"ITA","courseNumber":"5900","CRN":"25206"},
{"label":"ITA 5900 - 002 Independent Study CRN: 25207","subject":"ITA","courseNumber":"5900","CRN":"25207"},
{"label":"JPN 1111 - 001 Basic Japanese I CRN: 25208","subject":"JPN","courseNumber":"1111","CRN":"25208"},
{"label":"JPN 1111 - 002 Basic Japanese I CRN: 25209","subject":"JPN","courseNumber":"1111","CRN":"25209"},
{"label":"JPN 1111 - 003 Basic Japanese I CRN: 25210","subject":"JPN","courseNumber":"1111","CRN":"25210"},
{"label":"JPN 1111 - 004 Basic Japanese I CRN: 25211","subject":"JPN","courseNumber":"1111","CRN":"25211"},
{"label":"JPN 1121 - 001 Intermediate Japanese I CRN: 25212","subject":"JPN","courseNumber":"1121","CRN":"25212"},
{"label":"JPN 1121 - 002 Intermediate Japanese I CRN: 25213","subject":"JPN","courseNumber":"1121","CRN":"25213"},
{"label":"JPN 1131 - 001 Advanced Japanese I CRN: 25214","subject":"JPN","courseNumber":"1131","CRN":"25214"},
{"label":"JPN 1133 - 001 Advanced Japanese III CRN: 25215","subject":"JPN","courseNumber":"1133","CRN":"25215"},
{"label":"JPN 3412 - 001 TOP: Digital Literacies&Cultre CRN: 25217","subject":"JPN","courseNumber":"3412","CRN":"25217"},
{"label":"JPN 5900 - 001 JPN:Independent Study CRN: 25218","subject":"JPN","courseNumber":"5900","CRN":"25218"},
{"label":"LAT 1111 - 001 Introductory Latin I CRN: 25225","subject":"LAT","courseNumber":"1111","CRN":"25225"},
{"label":"LAT 1121 - 001 Intermediate Latin I CRN: 25226","subject":"LAT","courseNumber":"1121","CRN":"25226"},
{"label":"LAT 1121 - 002 Intermediate Latin I CRN: 25227","subject":"LAT","courseNumber":"1121","CRN":"25227"},
{"label":"LAT 3001 - 001 Readings in Authors CRN: 25228","subject":"LAT","courseNumber":"3001","CRN":"25228"},
{"label":"LAT 7260 - DL1 History of Latin Literature CRN: 25229","subject":"LAT","courseNumber":"7260","CRN":"25229"},
{"label":"LAT 9850 - DL1 TOP:Seneca'sLetters CRN: 25230","subject":"LAT","courseNumber":"9850","CRN":"25230"},
{"label":"SPA 3412 - 001 TOP:SustainabilityinLatinAmrca CRN: 26708","subject":"LAS","courseNumber":"3412","CRN":"26708"},
{"label":"THL 4490 - 001 THM:Stewardship of Creation CRN: 26119","subject":"LAS","courseNumber":"4490","CRN":"26119"},
{"label":"THL 5003 - 003 THM:HispUSCathChurchLecture CRN: 26125","subject":"LAS","courseNumber":"5003","CRN":"26125"},
{"label":"SPA 2993 - 100 Community Interpre. Internship CRN: 25974","subject":"LAS","courseNumber":"2993","CRN":"25974"},
{"label":"LAW 5906 - 01 Clinic:CARES CRN: 26733","subject":"LAW","courseNumber":"5906","CRN":"26733"},
{"label":"LAW 5908 - 01 Clinic: Law and Entrepreneur CRN: 26732","subject":"LAW","courseNumber":"5908","CRN":"26732"},
{"label":"LAW 5909 - 01 Clinic: Caritas Clemency CRN: 26729","subject":"LAW","courseNumber":"5909","CRN":"26729"},
{"label":"LAW 5910 - 01 Clinic: Intell Prop CRN: 26731","subject":"LAW","courseNumber":"5910","CRN":"26731"},
{"label":"LAW 0158 - 01 Professional Development II CRN: 26724","subject":"LAW","courseNumber":"0158","CRN":"26724"},
{"label":"LAW 0159 - 01 Professional Development III CRN: 26725","subject":"LAW","courseNumber":"0159","CRN":"26725"},
{"label":"LAW 0160 - 01 Villanova Law Inn of Court CRN: 26719","subject":"LAW","courseNumber":"0160","CRN":"26719"},
{"label":"LAW 1001 - 01 Constitutional Law II CRN: 26716","subject":"LAW","courseNumber":"1001","CRN":"26716"},
{"label":"LAW 1002 - 01 Legal Profession CRN: 26643","subject":"LAW","courseNumber":"1002","CRN":"26643"},
{"label":"LAW 1003 - 01 Moot Court Competition Reimel CRN: 26748","subject":"LAW","courseNumber":"1003","CRN":"26748"},
{"label":"LAW 1004 - 01 Moot Court Board I CRN: 26747","subject":"LAW","courseNumber":"1004","CRN":"26747"},
{"label":"LAW 1005 - 01 Moot Court Board II CRN: 26743","subject":"LAW","courseNumber":"1005","CRN":"26743"},
{"label":"LAW 1007 - 01A Legal Writing 3: Litigation CRN: 26714","subject":"LAW","courseNumber":"1007","CRN":"26714"},
{"label":"LAW 1007 - 01B Legal Writing 3: Litigation CRN: 26715","subject":"LAW","courseNumber":"1007","CRN":"26715"},
{"label":"LAW 1010 - 01A Legal Writing 3: Transactional CRN: 26644","subject":"LAW","courseNumber":"1010","CRN":"26644"},
{"label":"LAW 1010 - 01B Legal Writing 3: Transactional CRN: 26645","subject":"LAW","courseNumber":"1010","CRN":"26645"},
{"label":"LAW 5002 - 01 Business Planning CRN: 26581","subject":"LAW","courseNumber":"5002","CRN":"26581"},
{"label":"LAW 5003 - 01A Civil Pretrial Practice CRN: 26585","subject":"LAW","courseNumber":"5003","CRN":"26585"},
{"label":"LAW 5003 - 01B Civil Pretrial Practice CRN: 26586","subject":"LAW","courseNumber":"5003","CRN":"26586"},
{"label":"LAW 5003 - 01C Civil Pretrial Practice CRN: 26823","subject":"LAW","courseNumber":"5003","CRN":"26823"},
{"label":"LAW 5004 - 01 Contract Drafting CRN: 26590","subject":"LAW","courseNumber":"5004","CRN":"26590"},
{"label":"LAW 5011 - 01 International Commercial Arb CRN: 26635","subject":"LAW","courseNumber":"5011","CRN":"26635"},
{"label":"LAW 5036 - 01 Judicial Clerkships CRN: 26638","subject":"LAW","courseNumber":"5036","CRN":"26638"},
{"label":"LAW 5040 - 01 Legislative Drafting CRN: 26646","subject":"LAW","courseNumber":"5040","CRN":"26646"},
{"label":"LAW 5041 - 01 Crime Survivors CRN: 26592","subject":"LAW","courseNumber":"5041","CRN":"26592"},
{"label":"LAW 5042 - 01 Cannabis Law CRN: 26583","subject":"LAW","courseNumber":"5042","CRN":"26583"},
{"label":"LAW 5046 - 01 Representing Veterans in Admin CRN: 26840","subject":"LAW","courseNumber":"5046","CRN":"26840"},
{"label":"LAW 5047 - 01 Amicus Brief CRN: 26824","subject":"LAW","courseNumber":"5047","CRN":"26824"},
{"label":"LAW 5049 - 01 Higher Education Law CRN: 26767","subject":"LAW","courseNumber":"5049","CRN":"26767"},
{"label":"LAW 5901 - 01 Clinic:Tax CRN: 26736","subject":"LAW","courseNumber":"5901","CRN":"26736"},
{"label":"LAW 5902 - 01 Clinic:Civil Justice CRN: 26734","subject":"LAW","courseNumber":"5902","CRN":"26734"},
{"label":"LAW 5905 - 01 Clinic:Farmworker Legal Aid CRN: 26735","subject":"LAW","courseNumber":"5905","CRN":"26735"},
{"label":"LAW 5907 - 01 Clinic:Interdis Health Law CRN: 26750","subject":"LAW","courseNumber":"5907","CRN":"26750"},
{"label":"LAW 6015 - 01 Journal:Law Review CRN: 26745","subject":"LAW","courseNumber":"6015","CRN":"26745"},
{"label":"LAW 6016 - 01 Journal:Environmental Law CRN: 26744","subject":"LAW","courseNumber":"6016","CRN":"26744"},
{"label":"LAW 6017 - 01 Journal:Moorad Sports CRN: 26746","subject":"LAW","courseNumber":"6017","CRN":"26746"},
{"label":"LAW 6027 - 01 Privacy Law Seminar CRN: 26649","subject":"LAW","courseNumber":"6027","CRN":"26649"},
{"label":"LAW 6047 - 01 Adv. Intel Prop & Cyberlaw CRN: 26579","subject":"LAW","courseNumber":"6047","CRN":"26579"},
{"label":"LAW 6275 - 01 Scholarly Topics in Sports Law CRN: 26718","subject":"LAW","courseNumber":"6275","CRN":"26718"},
{"label":"LAW 7001 - 01 Accounting For Lawyers CRN: 26577","subject":"LAW","courseNumber":"7001","CRN":"26577"},
{"label":"LAW 7002 - 01 Administrative Law CRN: 26578","subject":"LAW","courseNumber":"7002","CRN":"26578"},
{"label":"LAW 7004 - 01 Criminal Procedur:Adjudication CRN: 26593","subject":"LAW","courseNumber":"7004","CRN":"26593"},
{"label":"LAW 7010 - 01 Internet Law CRN: 26701","subject":"LAW","courseNumber":"7010","CRN":"26701"},
{"label":"LAW 7021 - 01 Environmental Law CRN: 26706","subject":"LAW","courseNumber":"7021","CRN":"26706"},
{"label":"LAW 7024 - 01 Evidence CRN: 26702","subject":"LAW","courseNumber":"7024","CRN":"26702"},
{"label":"LAW 7028 - 01 Health Care and the Law CRN: 26630","subject":"LAW","courseNumber":"7028","CRN":"26630"},
{"label":"LAW 7033 - 01 Interviewing/Counseling CRN: 26637","subject":"LAW","courseNumber":"7033","CRN":"26637"},
{"label":"LAW 7034 - 01 Introduction to Federal Taxati CRN: 26703","subject":"LAW","courseNumber":"7034","CRN":"26703"},
{"label":"LAW 7036 - 01 Labor Law I CRN: 26640","subject":"LAW","courseNumber":"7036","CRN":"26640"},
{"label":"LAW 7047 - 01 Wealth Tax CRN: 26696","subject":"LAW","courseNumber":"7047","CRN":"26696"},
{"label":"LAW 7048 - 01 Intellectual Property CRN: 26634","subject":"LAW","courseNumber":"7048","CRN":"26634"},
{"label":"LAW 7050 - 01 Trial Advocacy - Basic CRN: 26697","subject":"LAW","courseNumber":"7050","CRN":"26697"},
{"label":"LAW 7050 - 01A Trial Advocacy - Basic CRN: 26653","subject":"LAW","courseNumber":"7050","CRN":"26653"},
{"label":"LAW 7050 - 01B Trial Advocacy-Basic Competit CRN: 26654","subject":"LAW","courseNumber":"7050","CRN":"26654"},
{"label":"LAW 7057 - 01 Negotiation & Mediation Advoca CRN: 26648","subject":"LAW","courseNumber":"7057","CRN":"26648"},
{"label":"LAW 7063 - 01 Corporate & White Collar Crime CRN: 26591","subject":"LAW","courseNumber":"7063","CRN":"26591"},
{"label":"LAW 7069 - 01 Immigration Law CRN: 26632","subject":"LAW","courseNumber":"7069","CRN":"26632"},
{"label":"LAW 7074 - 01 Regulation of Financial Insts CRN: 26651","subject":"LAW","courseNumber":"7074","CRN":"26651"},
{"label":"LAW 7082 - 01 Bankruptcy & Corp Reorg CRN: 26580","subject":"LAW","courseNumber":"7082","CRN":"26580"},
{"label":"LAW 7083 - 01 Comparative Law CRN: 26587","subject":"LAW","courseNumber":"7083","CRN":"26587"},
{"label":"LAW 7086 - 01 Decedents' Estates & Trusts CRN: 26595","subject":"LAW","courseNumber":"7086","CRN":"26595"},
{"label":"LAW 7087 - 01 Real Estate Transactions CRN: 26650","subject":"LAW","courseNumber":"7087","CRN":"26650"},
{"label":"LAW 7101 - 01 Poverty & Policy Law CRN: 26756","subject":"LAW","courseNumber":"7101","CRN":"26756"},
{"label":"LAW 7106 - 01 Trial Advocacy - Competition CRN: 26720","subject":"LAW","courseNumber":"7106","CRN":"26720"},
{"label":"LAW 7110 - 01 Business Organizations CRN: 26582","subject":"LAW","courseNumber":"7110","CRN":"26582"},
{"label":"LAW 7116 - 01 Jewish Law CRN: 26712","subject":"LAW","courseNumber":"7116","CRN":"26712"},
{"label":"LAW 7125 - 01 Trial Advocacy - Adv Comp CRN: 26721","subject":"LAW","courseNumber":"7125","CRN":"26721"},
{"label":"LAW 7128 - 01 Trial Advoc -Basic Intensive CRN: 26655","subject":"LAW","courseNumber":"7128","CRN":"26655"},
{"label":"LAW 7129 - 01 International Criminal Law CRN: 26636","subject":"LAW","courseNumber":"7129","CRN":"26636"},
{"label":"LAW 7131 - 01 Justice and Rights CRN: 26639","subject":"LAW","courseNumber":"7131","CRN":"26639"},
{"label":"LAW 7132 - 01 Public International Law CRN: 26741","subject":"LAW","courseNumber":"7132","CRN":"26741"},
{"label":"LAW 7136 - 01 Criminal Procedure: Invest CRN: 26705","subject":"LAW","courseNumber":"7136","CRN":"26705"},
{"label":"LAW 7148 - 01 Human Trafficking CRN: 26631","subject":"LAW","courseNumber":"7148","CRN":"26631"},
{"label":"LAW 7150 - 01 Insurance Law CRN: 26633","subject":"LAW","courseNumber":"7150","CRN":"26633"},
{"label":"LAW 7159 - 01 Civ & Crim Health Care Fraud CRN: 26584","subject":"LAW","courseNumber":"7159","CRN":"26584"},
{"label":"LAW 7160 - 01 Sports Law: Adv Leg Bus Asp CRN: 26695","subject":"LAW","courseNumber":"7160","CRN":"26695"},
{"label":"LAW 7164 - 01 Employment Law CRN: 26629","subject":"LAW","courseNumber":"7164","CRN":"26629"},
{"label":"LAW 7167 - 01 Economic Analysis CRN: 26699","subject":"LAW","courseNumber":"7167","CRN":"26699"},
{"label":"LAW 7169 - 01 Election Law CRN: 26711","subject":"LAW","courseNumber":"7169","CRN":"26711"},
{"label":"LAW 7171 - 01 Law, Economics, Tech: Privacy CRN: 26749","subject":"LAW","courseNumber":"7171","CRN":"26749"},
{"label":"LAW 7173 - 01 Deposition Strategy & Tactics CRN: 26726","subject":"LAW","courseNumber":"7173","CRN":"26726"},
{"label":"LAW 7183 - 01 Law & Strat Entrep Attorn CRN: 26641","subject":"LAW","courseNumber":"7183","CRN":"26641"},
{"label":"LAW 7184 - 01 Trial Ad - Basic Plus CRN: 26698","subject":"LAW","courseNumber":"7184","CRN":"26698"},
{"label":"LAW 7209 - 01 Condo & Homeowner Assoc. Law CRN: 26589","subject":"LAW","courseNumber":"7209","CRN":"26589"},
{"label":"LAW 7212 - 01 Legal & Comp Iss in Amat Athl CRN: 26642","subject":"LAW","courseNumber":"7212","CRN":"26642"},
{"label":"LAW 7213 - 01 Corp Compliance Risk Mngmnt CRN: 26700","subject":"LAW","courseNumber":"7213","CRN":"26700"},
{"label":"LAW 7220 - 01 Leverag Tech to Promote Just CRN: 26647","subject":"LAW","courseNumber":"7220","CRN":"26647"},
{"label":"LAW 7233 - 01A Corp and Trans Legal Research CRN: 26751","subject":"LAW","courseNumber":"7233","CRN":"26751"},
{"label":"LAW 7233 - 01B Corp and Trans Legal Research CRN: 26752","subject":"LAW","courseNumber":"7233","CRN":"26752"},
{"label":"LAW 7236 - 01 Writing for Publication CRN: 26812","subject":"LAW","courseNumber":"7236","CRN":"26812"},
{"label":"LAW 7238 - 01 Public Interest Lawyering CRN: 26759","subject":"LAW","courseNumber":"7238","CRN":"26759"},
{"label":"LAW 8046 - 01 Externship: Phila Defender CRN: 26762","subject":"LAW","courseNumber":"8046","CRN":"26762"},
{"label":"LAW 8065 - 01 Externship:PA Innocence Proj. CRN: 26761","subject":"LAW","courseNumber":"8065","CRN":"26761"},
{"label":"LAW 8068 - 01 CSE Inst: Internal Field Place CRN: 26763","subject":"LAW","courseNumber":"8068","CRN":"26763"},
{"label":"LAW 9102 - 01 Scarpa Center Fellow CRN: 26723","subject":"LAW","courseNumber":"9102","CRN":"26723"},
{"label":"LAW 9104 - 01 Sports Law Negotiation Team CRN: 26722","subject":"LAW","courseNumber":"9104","CRN":"26722"},
{"label":"LDR 2000 - DL1 Foundations of Leadership CRN: 26424","subject":"LDR","courseNumber":"2000","CRN":"26424"},
{"label":"LDR 2010 - DL1 Strategic Planning for Leaders CRN: 26468","subject":"LDR","courseNumber":"2010","CRN":"26468"},
{"label":"LDR 2040 - DL1 Ethics & Leadership CRN: 26425","subject":"LDR","courseNumber":"2040","CRN":"26425"},
{"label":"LDR 2050 - DL1 History of Leadership CRN: 26469","subject":"LDR","courseNumber":"2050","CRN":"26469"},
{"label":"LDR 5940 - 100 TOP:Leadership Symposium CRN: 26426","subject":"LDR","courseNumber":"5940","CRN":"26426"},
{"label":"LDR 5990 - 100 Leadership Independent Study CRN: 25231","subject":"LDR","courseNumber":"5990","CRN":"25231"},
{"label":"LA 1903 - 001 Internship Elective CRN: 25219","subject":"LA","courseNumber":"1903","CRN":"25219"},
{"label":"LA 1904 - 001 Global Internship CRN: 25220","subject":"LA","courseNumber":"1904","CRN":"25220"},
{"label":"LA 1906 - 001 Internship Elective CRN: 25221","subject":"LA","courseNumber":"1906","CRN":"25221"},
{"label":"LA 1909 - 001 Internship Elective CRN: 25222","subject":"LA","courseNumber":"1909","CRN":"25222"},
{"label":"LA 2993 - 001 Internship CRN: 25223","subject":"LA","courseNumber":"2993","CRN":"25223"},
{"label":"LA 2996 - 001 Internship CRN: 25224","subject":"LA","courseNumber":"2996","CRN":"25224"},
{"label":"MGT 2206 - 001 TOP:AdvancedDataMining &Ai CRN: 25390","subject":"MGT","courseNumber":"2206","CRN":"25390"},
{"label":"MGT 2350 - 001 Global Business Management CRN: 25391","subject":"MGT","courseNumber":"2350","CRN":"25391"},
{"label":"MGT 3170 - 001 Data Mining and AI CRN: 25394","subject":"MGT","courseNumber":"3170","CRN":"25394"},
{"label":"MGT 3170 - 002 Data Mining and AI CRN: 25395","subject":"MGT","courseNumber":"3170","CRN":"25395"},
{"label":"MGT 4170 - 001 Advanced Analytics CRN: 25399","subject":"MGT","courseNumber":"4170","CRN":"25399"},
{"label":"MGT 4170 - 002 Advanced Analytics CRN: 25400","subject":"MGT","courseNumber":"4170","CRN":"25400"},
{"label":"MGT 2360 - 002 Global Leadership CRN: 25392","subject":"MGT","courseNumber":"2360","CRN":"25392"},
{"label":"MGT 3070 - 001 Solving Complex Bus Problems CRN: 25393","subject":"MGT","courseNumber":"3070","CRN":"25393"},
{"label":"MGT 3300 - 001 Business Analytics Internship CRN: 25396","subject":"MGT","courseNumber":"3300","CRN":"25396"},
{"label":"MGT 3320 - 001 Ind Study - Management CRN: 25397","subject":"MGT","courseNumber":"3320","CRN":"25397"},
{"label":"MGT 3340 - 001 Ind Study-Internatl Busn CRN: 25398","subject":"MGT","courseNumber":"3340","CRN":"25398"},
{"label":"MIS 2020 - 001 Prog for Adaptive Prob Solving CRN: 25401","subject":"MIS","courseNumber":"2020","CRN":"25401"},
{"label":"MIS 2020 - 002 Prog for Adaptive Prob Solving CRN: 25402","subject":"MIS","courseNumber":"2020","CRN":"25402"},
{"label":"MIS 2030 - 001 Database Management CRN: 25403","subject":"MIS","courseNumber":"2030","CRN":"25403"},
{"label":"MIS 2030 - 002 Database Management CRN: 25404","subject":"MIS","courseNumber":"2030","CRN":"25404"},
{"label":"MIS 3060 - 002 Bus Intelligence and Perf Mgmt CRN: 25408","subject":"MIS","courseNumber":"3060","CRN":"25408"},
{"label":"MIS 3060 - 100 Bus Intelligence and Perf Mgmt CRN: 25409","subject":"MIS","courseNumber":"3060","CRN":"25409"},
{"label":"MIS 2040 - 001 Systems Analysis & Design CRN: 25405","subject":"MIS","courseNumber":"2040","CRN":"25405"},
{"label":"MIS 3020 - 001 Enterprise Systems & Appl CRN: 25406","subject":"MIS","courseNumber":"3020","CRN":"25406"},
{"label":"MIS 3060 - 101 Bus Intelligence and Perf Mgmt CRN: 25410","subject":"MIS","courseNumber":"3060","CRN":"25410"},
{"label":"MIS 3060 - 102 Bus Intelligence and Perf Mgmt CRN: 26753","subject":"MIS","courseNumber":"3060","CRN":"26753"},
{"label":"MIS 3070 - 001 Emerging Business Technologies CRN: 25411","subject":"MIS","courseNumber":"3070","CRN":"25411"},
{"label":"MIS 3300 - 100 AI & Machine Learning for Bus CRN: 25412","subject":"MIS","courseNumber":"3300","CRN":"25412"},
{"label":"MIS 3310 - 001 MIS Internship CRN: 25413","subject":"MIS","courseNumber":"3310","CRN":"25413"},
{"label":"MIS 3500 - 001 MIS Co-Op CRN: 25414","subject":"MIS","courseNumber":"3500","CRN":"25414"},
{"label":"MKT 1137 - 100 Principles of Marketing CRN: 26572","subject":"MKT","courseNumber":"1137","CRN":"26572"},
{"label":"MKT 2197 - 002 Marketing Research CRN: 25416","subject":"MKT","courseNumber":"2197","CRN":"25416"},
{"label":"MKT 2224 - 001 Professional Selling CRN: 25417","subject":"MKT","courseNumber":"2224","CRN":"25417"},
{"label":"MKT 2224 - 002 Professional Selling CRN: 25418","subject":"MKT","courseNumber":"2224","CRN":"25418"},
{"label":"MKT 2240 - 002 Marketing Analytics CRN: 25420","subject":"MKT","courseNumber":"2240","CRN":"25420"},
{"label":"MKT 2285 - 001 Social Media Marketing CRN: 25422","subject":"MKT","courseNumber":"2285","CRN":"25422"},
{"label":"MKT 2290 - 001 Digital Marketing CRN: 25423","subject":"MKT","courseNumber":"2290","CRN":"25423"},
{"label":"MKT 2375 - 001 Marketing Management CRN: 25425","subject":"MKT","courseNumber":"2375","CRN":"25425"},
{"label":"MKT 2197 - 001 Marketing Research CRN: 25415","subject":"MKT","courseNumber":"2197","CRN":"25415"},
{"label":"MKT 2240 - 001 Marketing Analytics CRN: 25419","subject":"MKT","courseNumber":"2240","CRN":"25419"},
{"label":"MKT 2280 - 001 Global Marketing CRN: 25421","subject":"MKT","courseNumber":"2280","CRN":"25421"},
{"label":"MKT 2290 - 002 Digital Marketing CRN: 25424","subject":"MKT","courseNumber":"2290","CRN":"25424"},
{"label":"MKT 3350 - 001 Independent Study - Marketing CRN: 25426","subject":"MKT","courseNumber":"3350","CRN":"25426"},
{"label":"MKT 3450 - 001 Internship Marketing CRN: 25427","subject":"MKT","courseNumber":"3450","CRN":"25427"},
{"label":"MKT 3450 - 100 Internship Marketing CRN: 25428","subject":"MKT","courseNumber":"3450","CRN":"25428"},
{"label":"MKT 3470 - 001 Marketing Co-Op CRN: 25429","subject":"MKT","courseNumber":"3470","CRN":"25429"},
{"label":"MAC 8100 - T01 Emerging Technologies CRN: 26307","subject":"MAC","courseNumber":"8100","CRN":"26307"},
{"label":"MAC 8200 - V01 Ldrship for the New Acct Prof CRN: 26308","subject":"MAC","courseNumber":"8200","CRN":"26308"},
{"label":"MAC 8201 - T01 Taxes & Business Strategy CRN: 26309","subject":"MAC","courseNumber":"8201","CRN":"26309"},
{"label":"MAC 8201 - V01 Taxes & Business Strategy CRN: 26310","subject":"MAC","courseNumber":"8201","CRN":"26310"},
{"label":"MAC 8209 - T01 Advanced Topics in Auditing CRN: 26381","subject":"MAC","courseNumber":"8209","CRN":"26381"},
{"label":"MAC 8209 - V01 Advanced Topics in Auditing CRN: 26382","subject":"MAC","courseNumber":"8209","CRN":"26382"},
{"label":"MAC 8217 - T01 Accounting Analytics CRN: 26311","subject":"MAC","courseNumber":"8217","CRN":"26311"},
{"label":"MAC 8217 - V01 Accounting Analytics CRN: 26312","subject":"MAC","courseNumber":"8217","CRN":"26312"},
{"label":"MAC 8225 - T01 Advanced Tax Topics CRN: 26351","subject":"MAC","courseNumber":"8225","CRN":"26351"},
{"label":"MAC 8226 - T01 State & Local Taxation CRN: 26352","subject":"MAC","courseNumber":"8226","CRN":"26352"},
{"label":"MAC 8227 - T01 International Tax CRN: 26353","subject":"MAC","courseNumber":"8227","CRN":"26353"},
{"label":"MAC 8228 - T01 Tax Issues Mergers&Acq Trans CRN: 26354","subject":"MAC","courseNumber":"8228","CRN":"26354"},
{"label":"MAC 8231 - T01 Corporate Tax CRN: 26355","subject":"MAC","courseNumber":"8231","CRN":"26355"},
{"label":"MAC 8300 - T01 Negotiations CRN: 26356","subject":"MAC","courseNumber":"8300","CRN":"26356"},
{"label":"MAC 8300 - V01 Negotiations CRN: 26357","subject":"MAC","courseNumber":"8300","CRN":"26357"},
{"label":"MAC 8307 - T01 Adv Bus & Technical Writing CRN: 26358","subject":"MAC","courseNumber":"8307","CRN":"26358"},
{"label":"MAC 8307 - V01 Adv Bus & Technical Writing CRN: 26359","subject":"MAC","courseNumber":"8307","CRN":"26359"},
{"label":"MAC 8329 - T01 Tax Research, Policy & Ethics CRN: 26383","subject":"MAC","courseNumber":"8329","CRN":"26383"},
{"label":"MSA 8100 - 101 Intro to Business Analytics CRN: 26510","subject":"MSA","courseNumber":"8100","CRN":"26510"},
{"label":"MSA 8105 - 201 Programming in R & Python CRN: 26537","subject":"MSA","courseNumber":"8105","CRN":"26537"},
{"label":"MSA 8220 - 101 Analytical Meth for Data Mng CRN: 26511","subject":"MSA","courseNumber":"8220","CRN":"26511"},
{"label":"MSA 8225 - 201 Analytical Meth Txt/Web Mng CRN: 26538","subject":"MSA","courseNumber":"8225","CRN":"26538"},
{"label":"MSA 8240 - 201 Business Intelligence CRN: 26539","subject":"MSA","courseNumber":"8240","CRN":"26539"},
{"label":"MSA 8310 - 101 Advanced Business Applications CRN: 26512","subject":"MSA","courseNumber":"8310","CRN":"26512"},
{"label":"MSA 8350 - 301 Analytics Practicum CRN: 26540","subject":"MSA","courseNumber":"8350","CRN":"26540"},
{"label":"MSF 8612 - 001 Fixed Income Markets CRN: 25476","subject":"MSF","courseNumber":"8612","CRN":"25476"},
{"label":"MSF 8615 - 001 Derivatives & Risk Management CRN: 25477","subject":"MSF","courseNumber":"8615","CRN":"25477"},
{"label":"MSF 8620 - 001 Investment Banking CRN: 25478","subject":"MSF","courseNumber":"8620","CRN":"25478"},
{"label":"MSF 8640 - 001 Portfolio Theory & Applic. CRN: 25479","subject":"MSF","courseNumber":"8640","CRN":"25479"},
{"label":"MSF 8650 - 001 Prof. Development Program I CRN: 25480","subject":"MSF","courseNumber":"8650","CRN":"25480"},
{"label":"MSF 8650 - 002 Prof. Development Program I CRN: 25481","subject":"MSF","courseNumber":"8650","CRN":"25481"},
{"label":"MBA 8920 - 001 Global Practicum-TravelSection CRN: 26393","subject":"MBA","courseNumber":"8920","CRN":"26393"},
{"label":"MBA 8100 - 001 Leadership Challenge CRN: 26313","subject":"MBA","courseNumber":"8100","CRN":"26313"},
{"label":"MBA 8100 - 002 Leadership Challenge CRN: 26314","subject":"MBA","courseNumber":"8100","CRN":"26314"},
{"label":"MBA 8100 - 003 Leadership Challenge CRN: 26315","subject":"MBA","courseNumber":"8100","CRN":"26315"},
{"label":"MBA 8136 - 001 AI & ML for Business CRN: 26316","subject":"MBA","courseNumber":"8136","CRN":"26316"},
{"label":"MBA 8136 - 002 AI & ML for Business CRN: 26317","subject":"MBA","courseNumber":"8136","CRN":"26317"},
{"label":"MBA 8141 - 001 Dashboard Analytics CRN: 26318","subject":"MBA","courseNumber":"8141","CRN":"26318"},
{"label":"MBA 8149 - 001 TOP:Generative AI for Business CRN: 26360","subject":"MBA","courseNumber":"8149","CRN":"26360"},
{"label":"MBA 8149 - 002 TOP:Ethics in AI & ML CRN: 26361","subject":"MBA","courseNumber":"8149","CRN":"26361"},
{"label":"MBA 8149 - 003 TOP:Data Transformation CRN: 26362","subject":"MBA","courseNumber":"8149","CRN":"26362"},
{"label":"MBA 8151 - 001 Financial Accounting&Reporting CRN: 26384","subject":"MBA","courseNumber":"8151","CRN":"26384"},
{"label":"MBA 8151 - 002 Financial Accounting&Reporting CRN: 26385","subject":"MBA","courseNumber":"8151","CRN":"26385"},
{"label":"MBA 8151 - 003 Financial Accounting&Reporting CRN: 26386","subject":"MBA","courseNumber":"8151","CRN":"26386"},
{"label":"MBA 8152 - 001 Management Accounting CRN: 26319","subject":"MBA","courseNumber":"8152","CRN":"26319"},
{"label":"MBA 8152 - 002 Management Accounting CRN: 26320","subject":"MBA","courseNumber":"8152","CRN":"26320"},
{"label":"MBA 8152 - 003 Management Accounting CRN: 26363","subject":"MBA","courseNumber":"8152","CRN":"26363"},
{"label":"MBA 8180 - 001 Business Intelligence CRN: 26364","subject":"MBA","courseNumber":"8180","CRN":"26364"},
{"label":"MBA 8250 - 001 Bus Opera & Supply Chain Mgmt CRN: 26321","subject":"MBA","courseNumber":"8250","CRN":"26321"},
{"label":"MBA 8250 - 002 Bus Opera & Supply Chain Mgmt CRN: 26322","subject":"MBA","courseNumber":"8250","CRN":"26322"},
{"label":"MBA 8360 - 001 Essential Economics CRN: 26323","subject":"MBA","courseNumber":"8360","CRN":"26323"},
{"label":"MBA 8360 - 002 Essential Economics CRN: 26365","subject":"MBA","courseNumber":"8360","CRN":"26365"},
{"label":"MBA 8370 - 001 Essential Business Statistics CRN: 26324","subject":"MBA","courseNumber":"8370","CRN":"26324"},
{"label":"MBA 8370 - 002 Essential Business Statistics CRN: 26325","subject":"MBA","courseNumber":"8370","CRN":"26325"},
{"label":"MBA 8370 - 003 Essential Business Statistics CRN: 26326","subject":"MBA","courseNumber":"8370","CRN":"26326"},
{"label":"MBA 8370 - 004 Essential Business Statistics CRN: 26327","subject":"MBA","courseNumber":"8370","CRN":"26327"},
{"label":"MBA 8439 - 001 TOP:CorpRestructg-Valuatn&Anly CRN: 26366","subject":"MBA","courseNumber":"8439","CRN":"26366"},
{"label":"MBA 8445 - 001 Corporate Restructuring CRN: 26328","subject":"MBA","courseNumber":"8445","CRN":"26328"},
{"label":"MBA 8448 - 001 Corporate Governance CRN: 26329","subject":"MBA","courseNumber":"8448","CRN":"26329"},
{"label":"MBA 8450 - 001 Corporate Finance CRN: 26387","subject":"MBA","courseNumber":"8450","CRN":"26387"},
{"label":"MBA 8450 - 002 Corporate Finance CRN: 26388","subject":"MBA","courseNumber":"8450","CRN":"26388"},
{"label":"MBA 8450 - 003 Corporate Finance CRN: 26389","subject":"MBA","courseNumber":"8450","CRN":"26389"},
{"label":"MBA 8522 - 001 Talent Management CRN: 26367","subject":"MBA","courseNumber":"8522","CRN":"26367"},
{"label":"MBA 8529 - 001 TOP:Intro to R Programming CRN: 26330","subject":"MBA","courseNumber":"8529","CRN":"26330"},
{"label":"MBA 8529 - 002 TOP:Leading Inclusively CRN: 26331","subject":"MBA","courseNumber":"8529","CRN":"26331"},
{"label":"MBA 8529 - 003 TOP:Workplace Spirituality CRN: 26368","subject":"MBA","courseNumber":"8529","CRN":"26368"},
{"label":"MBA 8530 - 001 International Bus Management CRN: 26369","subject":"MBA","courseNumber":"8530","CRN":"26369"},
{"label":"MBA 8538 - 001 Power and Politics CRN: 26332","subject":"MBA","courseNumber":"8538","CRN":"26332"},
{"label":"MBA 8538 - 002 Power and Politics CRN: 26333","subject":"MBA","courseNumber":"8538","CRN":"26333"},
{"label":"MBA 8550 - 001 Team Leadership & Grp Dynamics CRN: 26334","subject":"MBA","courseNumber":"8550","CRN":"26334"},
{"label":"MBA 8550 - 002 Team Leadership & Grp Dynamics CRN: 26335","subject":"MBA","courseNumber":"8550","CRN":"26335"},
{"label":"MBA 8550 - 003 Team Leadership & Grp Dynamics CRN: 26370","subject":"MBA","courseNumber":"8550","CRN":"26370"},
{"label":"MBA 8561 - 001 Negotiations CRN: 26336","subject":"MBA","courseNumber":"8561","CRN":"26336"},
{"label":"MBA 8561 - 002 Negotiations CRN: 26371","subject":"MBA","courseNumber":"8561","CRN":"26371"},
{"label":"MBA 8565 - 001 Business Risk Management CRN: 26810","subject":"MBA","courseNumber":"8565","CRN":"26810"},
{"label":"MBA 8570 - 001 Intro to Data & Bus Analytics CRN: 26337","subject":"MBA","courseNumber":"8570","CRN":"26337"},
{"label":"MBA 8570 - 002 Intro to Data & Bus Analytics CRN: 26338","subject":"MBA","courseNumber":"8570","CRN":"26338"},
{"label":"MBA 8583 - 001 Analytics for Decision Making CRN: 26339","subject":"MBA","courseNumber":"8583","CRN":"26339"},
{"label":"MBA 8631 - 001 Exec Level Selling C-Suite CRN: 26372","subject":"MBA","courseNumber":"8631","CRN":"26372"},
{"label":"MBA 8643 - 001 Mgmt & Mkt of Services-Part I CRN: 26340","subject":"MBA","courseNumber":"8643","CRN":"26340"},
{"label":"MBA 8644 - 001 Mgmt & Mkt of Services-Part II CRN: 26373","subject":"MBA","courseNumber":"8644","CRN":"26373"},
{"label":"MBA 8650 - 001 Strategic Marketing Mgmt. CRN: 26374","subject":"MBA","courseNumber":"8650","CRN":"26374"},
{"label":"MBA 8650 - 002 Strategic Marketing Mgmt. CRN: 26375","subject":"MBA","courseNumber":"8650","CRN":"26375"},
{"label":"MBA 8650 - 003 Strategic Marketing Mgmt. CRN: 26376","subject":"MBA","courseNumber":"8650","CRN":"26376"},
{"label":"MBA 8650 - 004 Strategic Marketing Mgmt. CRN: 26377","subject":"MBA","courseNumber":"8650","CRN":"26377"},
{"label":"MBA 8680 - 001 Bldg Brands via Promo CRN: 26341","subject":"MBA","courseNumber":"8680","CRN":"26341"},
{"label":"MBA 8680 - 002 Bldg Brands via Promo CRN: 26342","subject":"MBA","courseNumber":"8680","CRN":"26342"},
{"label":"MBA 8710 - 001 Info Tech as Strategic Lever CRN: 26343","subject":"MBA","courseNumber":"8710","CRN":"26343"},
{"label":"MBA 8710 - 002 Info Tech as Strategic Lever CRN: 26344","subject":"MBA","courseNumber":"8710","CRN":"26344"},
{"label":"MBA 8720 - 001 Ethical Business Practices CRN: 26378","subject":"MBA","courseNumber":"8720","CRN":"26378"},
{"label":"MBA 8720 - 002 Ethical Business Practices CRN: 26379","subject":"MBA","courseNumber":"8720","CRN":"26379"},
{"label":"MBA 8720 - 003 Ethical Business Practices CRN: 26345","subject":"MBA","courseNumber":"8720","CRN":"26345"},
{"label":"MBA 8720 - 004 Ethical Business Practices CRN: 26346","subject":"MBA","courseNumber":"8720","CRN":"26346"},
{"label":"MBA 8730 - 001 Mgmt. for Innov. & Creativity CRN: 26347","subject":"MBA","courseNumber":"8730","CRN":"26347"},
{"label":"MBA 8730 - 002 Mgmt. for Innov. & Creativity CRN: 26380","subject":"MBA","courseNumber":"8730","CRN":"26380"},
{"label":"MBA 8740 - 001 Global Political Economy CRN: 26348","subject":"MBA","courseNumber":"8740","CRN":"26348"},
{"label":"MBA 8910 - 001 Soc. Enterp. Consulting Prac. CRN: 26390","subject":"MBA","courseNumber":"8910","CRN":"26390"},
{"label":"MBA 8910 - 002 Soc. Enterp. Consulting Prac. CRN: 26391","subject":"MBA","courseNumber":"8910","CRN":"26391"},
{"label":"MBA 8910 - 003 Soc. Enterp. Consulting Prac. CRN: 26392","subject":"MBA","courseNumber":"8910","CRN":"26392"},
{"label":"MBA 8920 - 002 Global Practicum CRN: 26349","subject":"MBA","courseNumber":"8920","CRN":"26349"},
{"label":"MBA 8920 - 003 Global Practicum CRN: 26350","subject":"MBA","courseNumber":"8920","CRN":"26350"},
{"label":"MBA 8930 - 001 Global Strategic Management CRN: 26394","subject":"MBA","courseNumber":"8930","CRN":"26394"},
{"label":"MBA 8930 - 002 Global Strategic Management CRN: 26395","subject":"MBA","courseNumber":"8930","CRN":"26395"},
{"label":"MBA 8930 - 003 Global Strategic Management CRN: 26396","subject":"MBA","courseNumber":"8930","CRN":"26396"},
{"label":"MAT 1220 - 001 Discrete Math Social Sci CRN: 25234","subject":"MAT","courseNumber":"1220","CRN":"25234"},
{"label":"MAT 1220 - 002 Discrete Math Social Sci CRN: 25235","subject":"MAT","courseNumber":"1220","CRN":"25235"},
{"label":"MAT 1280 - 002 Mathematics of Fairness CRN: 25237","subject":"MAT","courseNumber":"1280","CRN":"25237"},
{"label":"MAT 1290 - 001 TOP:Playful Intro to Math CRN: 25238","subject":"MAT","courseNumber":"1290","CRN":"25238"},
{"label":"MAT 1290 - 002 TOP:Math Modeling CRN: 26570","subject":"MAT","courseNumber":"1290","CRN":"26570"},
{"label":"MAT 1312 - 001 Biocalculus CRN: 25239","subject":"MAT","courseNumber":"1312","CRN":"25239"},
{"label":"STAT 1313 - 001 Statistics for Life Sciences CRN: 25988","subject":"MAT","courseNumber":"1313","CRN":"25988"},
{"label":"STAT 1313 - 002 Statistics for Life Sciences CRN: 25989","subject":"MAT","courseNumber":"1313","CRN":"25989"},
{"label":"MAT 1320 - 001 Calculus I for Liberal Arts CRN: 25241","subject":"MAT","courseNumber":"1320","CRN":"25241"},
{"label":"MAT 1320 - 002 Calculus I for Liberal Arts CRN: 25242","subject":"MAT","courseNumber":"1320","CRN":"25242"},
{"label":"MAT 1320 - 003 Calculus I for Liberal Arts CRN: 25243","subject":"MAT","courseNumber":"1320","CRN":"25243"},
{"label":"MAT 1320 - 004 Calculus I for Liberal Arts CRN: 25244","subject":"MAT","courseNumber":"1320","CRN":"25244"},
{"label":"STAT 1430 - 001 Business Statistics CRN: 25992","subject":"MAT","courseNumber":"1430","CRN":"25992"},
{"label":"STAT 1430 - 002 Business Statistics CRN: 25993","subject":"MAT","courseNumber":"1430","CRN":"25993"},
{"label":"STAT 1430 - 004 Business Statistics CRN: 25995","subject":"MAT","courseNumber":"1430","CRN":"25995"},
{"label":"STAT 1430 - 005 Business Statistics CRN: 25996","subject":"MAT","courseNumber":"1430","CRN":"25996"},
{"label":"STAT 1430 - 006 Business Statistics CRN: 25997","subject":"MAT","courseNumber":"1430","CRN":"25997"},
{"label":"STAT 1430 - 007 Business Statistics CRN: 25998","subject":"MAT","courseNumber":"1430","CRN":"25998"},
{"label":"STAT 1430 - 008 Business Statistics CRN: 25999","subject":"MAT","courseNumber":"1430","CRN":"25999"},
{"label":"STAT 1430 - 009 Business Statistics CRN: 26000","subject":"MAT","courseNumber":"1430","CRN":"26000"},
{"label":"STAT 1430 - 010 Business Statistics CRN: 26001","subject":"MAT","courseNumber":"1430","CRN":"26001"},
{"label":"MAT 2500 - 001 Calculus III CRN: 25274","subject":"MAT","courseNumber":"2500","CRN":"25274"},
{"label":"MAT 2500 - 004 Calculus III CRN: 25277","subject":"MAT","courseNumber":"2500","CRN":"25277"},
{"label":"MAT 2500 - 006 Calculus III CRN: 25279","subject":"MAT","courseNumber":"2500","CRN":"25279"},
{"label":"MAT 2600 - 001 Mathematical Reasoning & Proof CRN: 25282","subject":"MAT","courseNumber":"2600","CRN":"25282"},
{"label":"MAT 2600 - 003 Mathematical Reasoning & Proof CRN: 25284","subject":"MAT","courseNumber":"2600","CRN":"25284"},
{"label":"MAT 2600 - 004 Mathematical Reasoning & Proof CRN: 25285","subject":"MAT","courseNumber":"2600","CRN":"25285"},
{"label":"MAT 2705 - 002 Diff Equation with Linear Alg CRN: 25287","subject":"MAT","courseNumber":"2705","CRN":"25287"},
{"label":"MAT 2705 - 003 Diff Equation with Linear Alg CRN: 25288","subject":"MAT","courseNumber":"2705","CRN":"25288"},
{"label":"MAT 2705 - 004 Diff Equation with Linear Alg CRN: 25289","subject":"MAT","courseNumber":"2705","CRN":"25289"},
{"label":"MAT 2705 - 005 Diff Equation with Linear Alg CRN: 25290","subject":"MAT","courseNumber":"2705","CRN":"25290"},
{"label":"MAT 3500 - 001 Modern Algebra I CRN: 25298","subject":"MAT","courseNumber":"3500","CRN":"25298"},
{"label":"STAT 4310 - 001 Stat Methods CRN: 26003","subject":"MAT","courseNumber":"4310","CRN":"26003"},
{"label":"STAT 4310 - 002 Stat Methods CRN: 26004","subject":"MAT","courseNumber":"4310","CRN":"26004"},
{"label":"STAT 4315 - 001 Applied Statistical Models CRN: 26006","subject":"MAT","courseNumber":"4315","CRN":"26006"},
{"label":"STAT 4315 - 002 Applied Statistical Models CRN: 26007","subject":"MAT","courseNumber":"4315","CRN":"26007"},
{"label":"STAT 4380 - 001 Data Science CRN: 26008","subject":"MAT","courseNumber":"4380","CRN":"26008"},
{"label":"STAT 4380 - 002 Data Science CRN: 26009","subject":"MAT","courseNumber":"4380","CRN":"26009"},
{"label":"MAT 4550 - 001 Math of Financial Derivatives CRN: 25301","subject":"MAT","courseNumber":"4550","CRN":"25301"},
{"label":"STAT 5700 - 001 Probability CRN: 26010","subject":"MAT","courseNumber":"5700","CRN":"26010"},
{"label":"STAT 5700 - 002 Probability CRN: 26011","subject":"MAT","courseNumber":"5700","CRN":"26011"},
{"label":"MAT 5900 - 002 SEM:Projects in Applied Math CRN: 25305","subject":"MAT","courseNumber":"5900","CRN":"25305"},
{"label":"STAT 5905 - 001 Seminar in Statistics CRN: 26012","subject":"MAT","courseNumber":"5905","CRN":"26012"},
{"label":"STAT 5905 - 002 Seminar in Statistics CRN: 26013","subject":"MAT","courseNumber":"5905","CRN":"26013"},
{"label":"STAT 5910 - 001 TOP:Time Series CRN: 26014","subject":"MAT","courseNumber":"5910","CRN":"26014"},
{"label":"MAT 1000 - 001 Math and Stat Communities CRN: 25232","subject":"MAT","courseNumber":"1000","CRN":"25232"},
{"label":"MAT 1000 - 002 Math and Stat Communities CRN: 25233","subject":"MAT","courseNumber":"1000","CRN":"25233"},
{"label":"STAT 1230 - 001 Intro Statistics I CRN: 25979","subject":"MAT","courseNumber":"1230","CRN":"25979"},
{"label":"STAT 1230 - 002 Intro Statistics I CRN: 25980","subject":"MAT","courseNumber":"1230","CRN":"25980"},
{"label":"STAT 1230 - 003 Intro Statistics I CRN: 25981","subject":"MAT","courseNumber":"1230","CRN":"25981"},
{"label":"STAT 1230 - 004 Intro Statistics I CRN: 25982","subject":"MAT","courseNumber":"1230","CRN":"25982"},
{"label":"STAT 1230 - 005 Intro Statistics I CRN: 25983","subject":"MAT","courseNumber":"1230","CRN":"25983"},
{"label":"STAT 1230 - 006 Intro Statistics I CRN: 25984","subject":"MAT","courseNumber":"1230","CRN":"25984"},
{"label":"STAT 1230 - 007 Intro Statistics I CRN: 25985","subject":"MAT","courseNumber":"1230","CRN":"25985"},
{"label":"STAT 1250 - 001 Stats in Health Care Research CRN: 25986","subject":"MAT","courseNumber":"1250","CRN":"25986"},
{"label":"STAT 1250 - 002 Stats in Health Care Research CRN: 25987","subject":"MAT","courseNumber":"1250","CRN":"25987"},
{"label":"MAT 1312 - 002 Biocalculus CRN: 25240","subject":"MAT","courseNumber":"1312","CRN":"25240"},
{"label":"STAT 1313 - 003 Statistics for Life Sciences CRN: 25990","subject":"MAT","courseNumber":"1313","CRN":"25990"},
{"label":"STAT 1313 - 004 Statistics for Life Sciences CRN: 25991","subject":"MAT","courseNumber":"1313","CRN":"25991"},
{"label":"STAT 1430 - 003 Business Statistics CRN: 25994","subject":"MAT","courseNumber":"1430","CRN":"25994"},
{"label":"MAT 1500 - 001 Calculus I CRN: 25245","subject":"MAT","courseNumber":"1500","CRN":"25245"},
{"label":"MAT 1500 - 002 Calculus I CRN: 25246","subject":"MAT","courseNumber":"1500","CRN":"25246"},
{"label":"MAT 1500 - 003 Calculus I CRN: 25247","subject":"MAT","courseNumber":"1500","CRN":"25247"},
{"label":"MAT 1500 - 004 Calculus I CRN: 25248","subject":"MAT","courseNumber":"1500","CRN":"25248"},
{"label":"MAT 1500 - 005 Calculus I CRN: 25249","subject":"MAT","courseNumber":"1500","CRN":"25249"},
{"label":"MAT 1500 - 006 Calculus I CRN: 25250","subject":"MAT","courseNumber":"1500","CRN":"25250"},
{"label":"MAT 1500 - 007 Calculus I CRN: 25251","subject":"MAT","courseNumber":"1500","CRN":"25251"},
{"label":"MAT 1500 - 008 Calculus I CRN: 25252","subject":"MAT","courseNumber":"1500","CRN":"25252"},
{"label":"MAT 1500 - 009 Calculus I CRN: 25253","subject":"MAT","courseNumber":"1500","CRN":"25253"},
{"label":"MAT 1500 - 010 Calculus I CRN: 25254","subject":"MAT","courseNumber":"1500","CRN":"25254"},
{"label":"MAT 1500 - 011 Calculus I CRN: 25255","subject":"MAT","courseNumber":"1500","CRN":"25255"},
{"label":"MAT 1500 - 012 Calculus I CRN: 25256","subject":"MAT","courseNumber":"1500","CRN":"25256"},
{"label":"MAT 1500 - 013 Calculus I CRN: 25257","subject":"MAT","courseNumber":"1500","CRN":"25257"},
{"label":"MAT 1500 - 014 Calculus I CRN: 25258","subject":"MAT","courseNumber":"1500","CRN":"25258"},
{"label":"MAT 1500 - 015 Calculus I CRN: 25259","subject":"MAT","courseNumber":"1500","CRN":"25259"},
{"label":"MAT 1500 - 016 Calculus I CRN: 25260","subject":"MAT","courseNumber":"1500","CRN":"25260"},
{"label":"MAT 1500 - 017 Calculus I CRN: 25261","subject":"MAT","courseNumber":"1500","CRN":"25261"},
{"label":"MAT 1500 - 018 Calculus I CRN: 25262","subject":"MAT","courseNumber":"1500","CRN":"25262"},
{"label":"MAT 1500 - 019 Calculus I CRN: 25263","subject":"MAT","courseNumber":"1500","CRN":"25263"},
{"label":"MAT 1500 - 100 Calculus I CRN: 25264","subject":"MAT","courseNumber":"1500","CRN":"25264"},
{"label":"MAT 1500 - 101 Calculus I CRN: 25265","subject":"MAT","courseNumber":"1500","CRN":"25265"},
{"label":"MAT 1500 - EGR EG Math Placeholder CRN: 26610","subject":"MAT","courseNumber":"1500","CRN":"26610"},
{"label":"MAT 1500 - VSB SB Math Placeholder CRN: 26618","subject":"MAT","courseNumber":"1500","CRN":"26618"},
{"label":"MAT 1505 - 001 Calculus II CRN: 25266","subject":"MAT","courseNumber":"1505","CRN":"25266"},
{"label":"MAT 1505 - 002 Calculus II CRN: 25267","subject":"MAT","courseNumber":"1505","CRN":"25267"},
{"label":"MAT 1505 - 003 Calculus II CRN: 25268","subject":"MAT","courseNumber":"1505","CRN":"25268"},
{"label":"MAT 1505 - 004 Calculus II CRN: 25269","subject":"MAT","courseNumber":"1505","CRN":"25269"},
{"label":"MAT 1505 - 005 Calculus II CRN: 25270","subject":"MAT","courseNumber":"1505","CRN":"25270"},
{"label":"MAT 1505 - 006 Calculus II CRN: 25271","subject":"MAT","courseNumber":"1505","CRN":"25271"},
{"label":"MAT 1505 - 100 Calculus II CRN: 25272","subject":"MAT","courseNumber":"1505","CRN":"25272"},
{"label":"MAT 1903 - 001 Internship Elective CRN: 25273","subject":"MAT","courseNumber":"1903","CRN":"25273"},
{"label":"MAT 2500 - 002 Calculus III CRN: 25275","subject":"MAT","courseNumber":"2500","CRN":"25275"},
{"label":"MAT 2500 - 003 Calculus III CRN: 25276","subject":"MAT","courseNumber":"2500","CRN":"25276"},
{"label":"MAT 2500 - 005 Calculus III CRN: 25278","subject":"MAT","courseNumber":"2500","CRN":"25278"},
{"label":"MAT 2500 - 007 Calculus III CRN: 25280","subject":"MAT","courseNumber":"2500","CRN":"25280"},
{"label":"MAT 2500 - 100 Calculus III CRN: 25281","subject":"MAT","courseNumber":"2500","CRN":"25281"},
{"label":"MAT 2600 - 002 Mathematical Reasoning & Proof CRN: 25283","subject":"MAT","courseNumber":"2600","CRN":"25283"},
{"label":"MAT 2705 - 001 Diff Equation with Linear Alg CRN: 25286","subject":"MAT","courseNumber":"2705","CRN":"25286"},
{"label":"MAT 2993 - 001 Internship CRN: 25291","subject":"MAT","courseNumber":"2993","CRN":"25291"},
{"label":"MAT 3011 - 001 Problem Solving Seminar CRN: 25292","subject":"MAT","courseNumber":"3011","CRN":"25292"},
{"label":"MAT 3100 - 001 Applied Linear Algebra CRN: 25293","subject":"MAT","courseNumber":"3100","CRN":"25293"},
{"label":"MAT 3100 - 002 Applied Linear Algebra CRN: 25294","subject":"MAT","courseNumber":"3100","CRN":"25294"},
{"label":"MAT 3300 - 001 Advanced Calculus CRN: 25295","subject":"MAT","courseNumber":"3300","CRN":"25295"},
{"label":"MAT 3400 - 001 Linear Algebra CRN: 25296","subject":"MAT","courseNumber":"3400","CRN":"25296"},
{"label":"MAT 3400 - 002 Linear Algebra CRN: 25297","subject":"MAT","courseNumber":"3400","CRN":"25297"},
{"label":"MAT 3500 - 002 Modern Algebra I CRN: 25299","subject":"MAT","courseNumber":"3500","CRN":"25299"},
{"label":"STAT 4210 - 001 Bayesian Statistical Analysis CRN: 26002","subject":"MAT","courseNumber":"4210","CRN":"26002"},
{"label":"STAT 4310 - 003 Stat Methods CRN: 26005","subject":"MAT","courseNumber":"4310","CRN":"26005"},
{"label":"MAT 4500 - 001 Mathematics of Games CRN: 25300","subject":"MAT","courseNumber":"4500","CRN":"25300"},
{"label":"MAT 4550 - 100 Math of Financial Derivatives CRN: 25302","subject":"MAT","courseNumber":"4550","CRN":"25302"},
{"label":"MAT 4610 - 001 Stochastic Oper Research CRN: 26571","subject":"MAT","courseNumber":"4610","CRN":"26571"},
{"label":"MAT 5110 - 100 Topics in Geometry CRN: 25303","subject":"MAT","courseNumber":"5110","CRN":"25303"},
{"label":"MAT 5900 - 001 SEM:Cryptology CRN: 25304","subject":"MAT","courseNumber":"5900","CRN":"25304"},
{"label":"MAT 5920 - 001 TOP: Quantum Computing CRN: 25306","subject":"MAT","courseNumber":"5920","CRN":"25306"},
{"label":"MAT 5920 - DL1 TOP:Coding Theory CRN: 25307","subject":"MAT","courseNumber":"5920","CRN":"25307"},
{"label":"MAT 5991 - 001 IS: CRN: 25309","subject":"MAT","courseNumber":"5991","CRN":"25309"},
{"label":"MAT 5993 - 001 IS: CRN: 25310","subject":"MAT","courseNumber":"5993","CRN":"25310"},
{"label":"MAT 7101 - 001 Grad Studies Internship CRN: 25311","subject":"MAT","courseNumber":"7101","CRN":"25311"},
{"label":"MAT 7290 - 001 Geometry CRN: 25312","subject":"MAT","courseNumber":"7290","CRN":"25312"},
{"label":"STAT 7404 - 001 Statistical Methods CRN: 26015","subject":"MAT","courseNumber":"7404","CRN":"26015"},
{"label":"STAT 7500 - 001 Statistical Programming CRN: 26016","subject":"MAT","courseNumber":"7500","CRN":"26016"},
{"label":"MAT 7780 - 001 Financial Mathematics CRN: 25313","subject":"MAT","courseNumber":"7780","CRN":"25313"},
{"label":"STAT 8400 - 001 Probability CRN: 26017","subject":"MAT","courseNumber":"8400","CRN":"26017"},
{"label":"STAT 8412 - 001 Linear Models CRN: 26018","subject":"MAT","courseNumber":"8412","CRN":"26018"},
{"label":"STAT 8448 - 001 Clinical Trials CRN: 26019","subject":"MAT","courseNumber":"8448","CRN":"26019"},
{"label":"STAT 8480 - 001 Data Mining & Predictive Analy CRN: 26020","subject":"MAT","courseNumber":"8480","CRN":"26020"},
{"label":"MAT 8790 - 001 TOP: Stochastic OR CRN: 25314","subject":"MAT","courseNumber":"8790","CRN":"25314"},
{"label":"MAT 8790 - DL1 TOP:Coding Theory CRN: 25315","subject":"MAT","courseNumber":"8790","CRN":"25315"},
{"label":"MAT 8800 - 001 IS: CRN: 25316","subject":"MAT","courseNumber":"8800","CRN":"25316"},
{"label":"MAT 8800 - 002 IS: CRN: 25317","subject":"MAT","courseNumber":"8800","CRN":"25317"},
{"label":"MAT 8800 - 003 IS: CRN: 25318","subject":"MAT","courseNumber":"8800","CRN":"25318"},
{"label":"MAT 8801 - 001 IS: CRN: 25319","subject":"MAT","courseNumber":"8801","CRN":"25319"},
{"label":"ME 2100 - 001 Statics CRN: 25320","subject":"ME","courseNumber":"2100","CRN":"25320"},
{"label":"ME 2100 - 002 Statics CRN: 25321","subject":"ME","courseNumber":"2100","CRN":"25321"},
{"label":"ME 2100 - 003 Statics CRN: 25322","subject":"ME","courseNumber":"2100","CRN":"25322"},
{"label":"ME 2505 - 002 M.E. Analysis & Design CRN: 25324","subject":"ME","courseNumber":"2505","CRN":"25324"},
{"label":"ME 2505 - 004 M.E. Analysis & Design CRN: 25326","subject":"ME","courseNumber":"2505","CRN":"25326"},
{"label":"ME 2505 - 005 M.E. Analysis & Design CRN: 25327","subject":"ME","courseNumber":"2505","CRN":"25327"},
{"label":"ME 2505 - 006 M.E. Analysis & Design CRN: 25328","subject":"ME","courseNumber":"2505","CRN":"25328"},
{"label":"ME 2505 - 008 M.E. Analysis & Design CRN: 25330","subject":"ME","courseNumber":"2505","CRN":"25330"},
{"label":"ME 2505 - 009 M.E. Analysis & Design CRN: 25331","subject":"ME","courseNumber":"2505","CRN":"25331"},
{"label":"ME 2505 - 010 M.E. Analysis & Design CRN: 25332","subject":"ME","courseNumber":"2505","CRN":"25332"},
{"label":"ME 2505 - 011 M.E. Analysis & Design CRN: 25333","subject":"ME","courseNumber":"2505","CRN":"25333"},
{"label":"ME 2505 - 012 M.E. Analysis & Design CRN: 25334","subject":"ME","courseNumber":"2505","CRN":"25334"},
{"label":"ME 3102 - 001 Dynamic Systems II CRN: 25335","subject":"ME","courseNumber":"3102","CRN":"25335"},
{"label":"ME 3102 - 002 Dynamic Systems II CRN: 25336","subject":"ME","courseNumber":"3102","CRN":"25336"},
{"label":"ME 3102 - 003 Dynamic Systems II CRN: 25337","subject":"ME","courseNumber":"3102","CRN":"25337"},
{"label":"ME 3402 - 001 Solid Mechanics & Design I CRN: 25338","subject":"ME","courseNumber":"3402","CRN":"25338"},
{"label":"ME 3402 - 002 Solid Mechanics & Design I CRN: 25339","subject":"ME","courseNumber":"3402","CRN":"25339"},
{"label":"ME 3402 - 003 Solid Mechanics & Design I CRN: 25340","subject":"ME","courseNumber":"3402","CRN":"25340"},
{"label":"ME 3600 - 003 Fluid Mechanics CRN: 25343","subject":"ME","courseNumber":"3600","CRN":"25343"},
{"label":"ME 3950 - 002 Heat Transfer I CRN: 25345","subject":"ME","courseNumber":"3950","CRN":"25345"},
{"label":"ME 3950 - 003 Heat Transfer I CRN: 25346","subject":"ME","courseNumber":"3950","CRN":"25346"},
{"label":"ME 4002 - 001 Solid Mechanics Lab CRN: 25349","subject":"ME","courseNumber":"4002","CRN":"25349"},
{"label":"ME 4002 - 002 Solid Mechanics Lab CRN: 25350","subject":"ME","courseNumber":"4002","CRN":"25350"},
{"label":"ME 5101 - 001 Elements of Aerodynamics CRN: 25356","subject":"ME","courseNumber":"5101","CRN":"25356"},
{"label":"ME 5201 - 100 Intro to Finite Elements CRN: 25358","subject":"ME","courseNumber":"5201","CRN":"25358"},
{"label":"ME 5207 - 001 Orbital Mechanics CRN: 25359","subject":"ME","courseNumber":"5207","CRN":"25359"},
{"label":"ME 5421 - 001 Introduction to Robotics CRN: 25360","subject":"ME","courseNumber":"5421","CRN":"25360"},
{"label":"ME 2505 - 001 M.E. Analysis & Design CRN: 25323","subject":"ME","courseNumber":"2505","CRN":"25323"},
{"label":"ME 2505 - 003 M.E. Analysis & Design CRN: 25325","subject":"ME","courseNumber":"2505","CRN":"25325"},
{"label":"ME 2505 - 007 M.E. Analysis & Design CRN: 25329","subject":"ME","courseNumber":"2505","CRN":"25329"},
{"label":"ME 3600 - 001 Fluid Mechanics CRN: 25341","subject":"ME","courseNumber":"3600","CRN":"25341"},
{"label":"ME 3600 - 002 Fluid Mechanics CRN: 25342","subject":"ME","courseNumber":"3600","CRN":"25342"},
{"label":"ME 3950 - 001 Heat Transfer I CRN: 25344","subject":"ME","courseNumber":"3950","CRN":"25344"},
{"label":"ME 4001 - 001 Dynamic Systems Lab CRN: 25347","subject":"ME","courseNumber":"4001","CRN":"25347"},
{"label":"ME 4001 - 002 Dynamic Systems Lab CRN: 25348","subject":"ME","courseNumber":"4001","CRN":"25348"},
{"label":"ME 4003 - 001 Thermal Fluids Lab CRN: 25351","subject":"ME","courseNumber":"4003","CRN":"25351"},
{"label":"ME 5000 - 001 TOP: CRN: 25352","subject":"ME","courseNumber":"5000","CRN":"25352"},
{"label":"ME 5001 - 001 ME Undergraduate Research I CRN: 25353","subject":"ME","courseNumber":"5001","CRN":"25353"},
{"label":"ME 5002 - 001 ME Undergraduate Research II CRN: 25354","subject":"ME","courseNumber":"5002","CRN":"25354"},
{"label":"ME 5005 - 001 Capstone Design I CRN: 25355","subject":"ME","courseNumber":"5005","CRN":"25355"},
{"label":"ME 5130 - 001 Intro to Sustainable Energy CRN: 25357","subject":"ME","courseNumber":"5130","CRN":"25357"},
{"label":"ME 5500 - 001 Biomechanics CRN: 25361","subject":"ME","courseNumber":"5500","CRN":"25361"},
{"label":"ME 7000 - 001 Advanced Engineering Analysis CRN: 25362","subject":"ME","courseNumber":"7000","CRN":"25362"},
{"label":"ME 7000 - DL1 Advanced Engineering Analysis CRN: 25363","subject":"ME","courseNumber":"7000","CRN":"25363"},
{"label":"ME 7103 - 001 Advanced Engrg Thermodynamics CRN: 25364","subject":"ME","courseNumber":"7103","CRN":"25364"},
{"label":"ME 7103 - DL1 Advanced Engrg Thermodynamics CRN: 25365","subject":"ME","courseNumber":"7103","CRN":"25365"},
{"label":"ME 7150 - 001 Sustainable Energy CRN: 25366","subject":"ME","courseNumber":"7150","CRN":"25366"},
{"label":"ME 7150 - DL1 Sustainable Energy CRN: 25367","subject":"ME","courseNumber":"7150","CRN":"25367"},
{"label":"ME 7207 - 001 Simulation of Multibody System CRN: 25368","subject":"ME","courseNumber":"7207","CRN":"25368"},
{"label":"ME 7207 - DL1 Simulation of Multibody System CRN: 25369","subject":"ME","courseNumber":"7207","CRN":"25369"},
{"label":"ME 7270 - 001 Polymer Engineering CRN: 25370","subject":"ME","courseNumber":"7270","CRN":"25370"},
{"label":"ME 7270 - DL1 Polymer Engineering CRN: 25371","subject":"ME","courseNumber":"7270","CRN":"25371"},
{"label":"ME 7502 - 001 Fiber Composite Structures CRN: 25372","subject":"ME","courseNumber":"7502","CRN":"25372"},
{"label":"ME 7502 - DL1 Fiber Composite Structures CRN: 25373","subject":"ME","courseNumber":"7502","CRN":"25373"},
{"label":"ME 8010 - 001 TOP:Logistic&SupplyChainENG CRN: 25374","subject":"ME","courseNumber":"8010","CRN":"25374"},
{"label":"ME 8010 - 002 TOP:Fusion Energy CRN: 25375","subject":"ME","courseNumber":"8010","CRN":"25375"},
{"label":"ME 8010 - DL1 TOP:Logistic&SupplyChainENG CRN: 25376","subject":"ME","courseNumber":"8010","CRN":"25376"},
{"label":"ME 8010 - DL2 TOP:Fusion Energy CRN: 25377","subject":"ME","courseNumber":"8010","CRN":"25377"},
{"label":"ME 8103 - 001 Advanced Fluid Mechanics CRN: 26825","subject":"ME","courseNumber":"8103","CRN":"26825"},
{"label":"ME 8103 - DL1 Advanced Fluid Mechanics CRN: 25378","subject":"ME","courseNumber":"8103","CRN":"25378"},
{"label":"ME 8207 - 001 Vibration Analysis CRN: 25379","subject":"ME","courseNumber":"8207","CRN":"25379"},
{"label":"ME 8207 - DL1 Vibration Analysis CRN: 25380","subject":"ME","courseNumber":"8207","CRN":"25380"},
{"label":"ME 9000 - 001 Independent Study CRN: 25381","subject":"ME","courseNumber":"9000","CRN":"25381"},
{"label":"ME 9031 - 001 Thesis Research I CRN: 25382","subject":"ME","courseNumber":"9031","CRN":"25382"},
{"label":"ME 9032 - 001 Thesis Research II CRN: 25383","subject":"ME","courseNumber":"9032","CRN":"25383"},
{"label":"ME 9080 - 001 Thesis Continuation CRN: 25384","subject":"ME","courseNumber":"9080","CRN":"25384"},
{"label":"ME 9085 - 001 Independent Study Continuation CRN: 25385","subject":"ME","courseNumber":"9085","CRN":"25385"},
{"label":"ME 9086 - 001 ME Graduate Practicum CRN: 25386","subject":"ME","courseNumber":"9086","CRN":"25386"},
{"label":"ME 9087 - 001 ME Grad Pract Continuation CRN: 25387","subject":"ME","courseNumber":"9087","CRN":"25387"},
{"label":"ME 9100 - 001 ME Graduate Seminar CRN: 25388","subject":"ME","courseNumber":"9100","CRN":"25388"},
{"label":"MSE 2603 - 001 PHY:Big Bang: Forces/Particles CRN: 25466","subject":"MSE","courseNumber":"2603","CRN":"25466"},
{"label":"MSE 2604 - 001 PHY:The Physics of Sports CRN: 25467","subject":"MSE","courseNumber":"2604","CRN":"25467"},
{"label":"MSE 2653 - 001 PHY: Big Bang Lab CRN: 25468","subject":"MSE","courseNumber":"2653","CRN":"25468"},
{"label":"MSE 2653 - 002 PHY: Big Bang Lab CRN: 25469","subject":"MSE","courseNumber":"2653","CRN":"25469"},
{"label":"MSE 2654 - 002 PHY:The Physics of Sports Lab CRN: 25471","subject":"MSE","courseNumber":"2654","CRN":"25471"},
{"label":"MSE 2654 - 001 PHY:The Physics of Sports Lab CRN: 25470","subject":"MSE","courseNumber":"2654","CRN":"25470"},
{"label":"MET 1221 - 001 Severe & Hazardous Weather CRN: 25389","subject":"MET","courseNumber":"1221","CRN":"25389"},
{"label":"MS 0401 - 002 Mission Command & Army Prof. CRN: 25437","subject":"MS","courseNumber":"0401","CRN":"25437"},
{"label":"MS 0101 - 001 Army Critical Thinking Intro CRN: 25430","subject":"MS","courseNumber":"0101","CRN":"25430"},
{"label":"MS 0101 - 002 Army Critical Thinking Intro CRN: 25431","subject":"MS","courseNumber":"0101","CRN":"25431"},
{"label":"MS 0201 - 001 Foundations of Leadership CRN: 25432","subject":"MS","courseNumber":"0201","CRN":"25432"},
{"label":"MS 0201 - 002 Foundations of Leadership CRN: 25433","subject":"MS","courseNumber":"0201","CRN":"25433"},
{"label":"MS 0301 - 001 Traing Mgmt & Warfighting Func CRN: 25434","subject":"MS","courseNumber":"0301","CRN":"25434"},
{"label":"MS 0301 - 002 Traing Mgmt & Warfighting Func CRN: 25435","subject":"MS","courseNumber":"0301","CRN":"25435"},
{"label":"MS 0401 - 001 Mission Command & Army Prof. CRN: 25436","subject":"MS","courseNumber":"0401","CRN":"25436"},
{"label":"NS 0100 - 001 Naval Professional Lab CRN: 25482","subject":"NS","courseNumber":"0100","CRN":"25482"},
{"label":"NS 0100 - 002 Naval Professional Lab CRN: 26832","subject":"NS","courseNumber":"0100","CRN":"26832"},
{"label":"NS 1000 - 001 Intro to Naval Science CRN: 25483","subject":"NS","courseNumber":"1000","CRN":"25483"},
{"label":"NS 1000 - 002 Intro to Naval Science CRN: 25484","subject":"NS","courseNumber":"1000","CRN":"25484"},
{"label":"NS 1050 - 001 Naval Science Orientation CRN: 25485","subject":"NS","courseNumber":"1050","CRN":"25485"},
{"label":"NS 2100 - 001 Naval Ships Systems I CRN: 25486","subject":"NS","courseNumber":"2100","CRN":"25486"},
{"label":"NS 2100 - 002 Naval Ships Systems I CRN: 25487","subject":"NS","courseNumber":"2100","CRN":"25487"},
{"label":"NS 3200 - 001 Naval Operations CRN: 25488","subject":"NS","courseNumber":"3200","CRN":"25488"},
{"label":"NS 3200 - 002 Naval Operations CRN: 25489","subject":"NS","courseNumber":"3200","CRN":"25489"},
{"label":"NS 3500 - 001 Evolution of Warfare CRN: 25490","subject":"NS","courseNumber":"3500","CRN":"25490"},
{"label":"NS 3500 - 002 Evolution of Warfare CRN: 25491","subject":"NS","courseNumber":"3500","CRN":"25491"},
{"label":"NS 4100 - 001 Leadership and Management CRN: 25492","subject":"NS","courseNumber":"4100","CRN":"25492"},
{"label":"NS 4100 - 002 Leadership and Management CRN: 25493","subject":"NS","courseNumber":"4100","CRN":"25493"},
{"label":"NUR 3030 - 001 Basic Conc Pharmacology CRN: 25512","subject":"NUR","courseNumber":"3030","CRN":"25512"},
{"label":"NUR 3114 - 001 Nsg Care Adults & Older Adults CRN: 25519","subject":"NUR","courseNumber":"3114","CRN":"25519"},
{"label":"NUR 3118 - 001 Nsg Care Women & Childbear Fam CRN: 26431","subject":"NUR","courseNumber":"3118","CRN":"26431"},
{"label":"NUR 3118 - 002 Nsg Care Women & Childbear Fam CRN: 26471","subject":"NUR","courseNumber":"3118","CRN":"26471"},
{"label":"NUR 3119 - 001 Prac Nsg Women & Childbear Fam CRN: 26432","subject":"NUR","courseNumber":"3119","CRN":"26432"},
{"label":"NUR 3119 - 002 Prac Nsg Women & Childbear Fam CRN: 26472","subject":"NUR","courseNumber":"3119","CRN":"26472"},
{"label":"NUR 3120 - 001 Psychiatric & Mental Hlth Nsg CRN: 26473","subject":"NUR","courseNumber":"3120","CRN":"26473"},
{"label":"NUR 3120 - 002 Psychiatric & Mental Hlth Nsg CRN: 26433","subject":"NUR","courseNumber":"3120","CRN":"26433"},
{"label":"NUR 3121 - 001 Prac Psych & Mental Hlth Nsg CRN: 26474","subject":"NUR","courseNumber":"3121","CRN":"26474"},
{"label":"NUR 3121 - 002 Prac Psych & Mental Hlth Nsg CRN: 26434","subject":"NUR","courseNumber":"3121","CRN":"26434"},
{"label":"NUR 4104 - 001 Nsg Adults w Comp Hlth Prob CRN: 26435","subject":"NUR","courseNumber":"4104","CRN":"26435"},
{"label":"NUR 4104 - 002 Nsg Adults w Comp Hlth Prob CRN: 26475","subject":"NUR","courseNumber":"4104","CRN":"26475"},
{"label":"NUR 4105 - 001 Prac Adults w Comp Hlth Prob CRN: 26436","subject":"NUR","courseNumber":"4105","CRN":"26436"},
{"label":"NUR 4105 - 002 Prac Adults w Comp Hlth Prob CRN: 26476","subject":"NUR","courseNumber":"4105","CRN":"26476"},
{"label":"NUR 4108 - 001 Nsg Care Child & Adol CRN: 26477","subject":"NUR","courseNumber":"4108","CRN":"26477"},
{"label":"NUR 4108 - 002 Nsg Care Child & Adol CRN: 26437","subject":"NUR","courseNumber":"4108","CRN":"26437"},
{"label":"NUR 4109 - 001 Prac Nsg Care Child & Adol CRN: 26478","subject":"NUR","courseNumber":"4109","CRN":"26478"},
{"label":"NUR 4109 - 002 Prac Nsg Care Child & Adol CRN: 26438","subject":"NUR","courseNumber":"4109","CRN":"26438"},
{"label":"NUR 4200 - 001 Peripheral IV Insertion CRN: 26439","subject":"NUR","courseNumber":"4200","CRN":"26439"},
{"label":"NUR 4200 - 002 Mindfulness Based Meditation CRN: 26440","subject":"NUR","courseNumber":"4200","CRN":"26440"},
{"label":"NUR 4200 - 003 QualityImprovmt&PatientSafety CRN: 26441","subject":"NUR","courseNumber":"4200","CRN":"26441"},
{"label":"NUR 4200 - 004 Pediatric Chronic Illness CRN: 26442","subject":"NUR","courseNumber":"4200","CRN":"26442"},
{"label":"NUR 4200 - 005 Peripheral IV insertion CRN: 26443","subject":"NUR","courseNumber":"4200","CRN":"26443"},
{"label":"NUR 4200 - 006 Mindfulness Based Meditation CRN: 26479","subject":"NUR","courseNumber":"4200","CRN":"26479"},
{"label":"NUR 4200 - 007 TOP:Peripheral IV insertion CRN: 26480","subject":"NUR","courseNumber":"4200","CRN":"26480"},
{"label":"NUR 4200 - 009 Peds Chronic Illness CRN: 26482","subject":"NUR","courseNumber":"4200","CRN":"26482"},
{"label":"NUR 4200 - 010 AdvancePeripheral IV insertion CRN: 26483","subject":"NUR","courseNumber":"4200","CRN":"26483"},
{"label":"NUR 7070 - DL1 Nutrition and Global Health CRN: 25538","subject":"NUR","courseNumber":"7070","CRN":"25538"},
{"label":"NUR 1102 - 001 Intro to Prof Nursing CRN: 25496","subject":"NUR","courseNumber":"1102","CRN":"25496"},
{"label":"NUR 1102 - 002 Intro to Prof Nursing CRN: 25497","subject":"NUR","courseNumber":"1102","CRN":"25497"},
{"label":"NUR 1102 - 003 Intro to Prof Nursing CRN: 25498","subject":"NUR","courseNumber":"1102","CRN":"25498"},
{"label":"NUR 1102 - 004 Intro to Prof Nursing CRN: 25499","subject":"NUR","courseNumber":"1102","CRN":"25499"},
{"label":"NUR 1102 - 010 Intro to Prof Nursing CRN: 26427","subject":"NUR","courseNumber":"1102","CRN":"26427"},
{"label":"NUR 1102 - NUR Intro Nursing Placeholder CRN: 26615","subject":"NUR","courseNumber":"1102","CRN":"26615"},
{"label":"NUR 1104 - 001 Intro to Prof Nursing Practice CRN: 26470","subject":"NUR","courseNumber":"1104","CRN":"26470"},
{"label":"NUR 1105 - 001 Soc Justice&Hlth Eqty Prof Nsg CRN: 25500","subject":"NUR","courseNumber":"1105","CRN":"25500"},
{"label":"NUR 1105 - 002 Soc Justice&Hlth Eqty Prof Nsg CRN: 25501","subject":"NUR","courseNumber":"1105","CRN":"25501"},
{"label":"NUR 1105 - 003 Soc Justice&Hlth Eqty Prof Nsg CRN: 25502","subject":"NUR","courseNumber":"1105","CRN":"25502"},
{"label":"NUR 2204 - 001 Health Assessment CRN: 25503","subject":"NUR","courseNumber":"2204","CRN":"25503"},
{"label":"NUR 2204 - 002 Health Assessment CRN: 25504","subject":"NUR","courseNumber":"2204","CRN":"25504"},
{"label":"NUR 2204 - 020 Health Assessment CRN: 26428","subject":"NUR","courseNumber":"2204","CRN":"26428"},
{"label":"NUR 2205 - 001 Practicum in Health Assessment CRN: 25507","subject":"NUR","courseNumber":"2205","CRN":"25507"},
{"label":"NUR 2205 - 002 Practicum in Health Assessment CRN: 25508","subject":"NUR","courseNumber":"2205","CRN":"25508"},
{"label":"NUR 2205 - 003 Practicum in Health Assessment CRN: 25509","subject":"NUR","courseNumber":"2205","CRN":"25509"},
{"label":"NUR 2205 - 020 Practicum in Health Assessment CRN: 26429","subject":"NUR","courseNumber":"2205","CRN":"26429"},
{"label":"NUR 2206 - 001 Essentials of Nursing Practice CRN: 26430","subject":"NUR","courseNumber":"2206","CRN":"26430"},
{"label":"NUR 2208 - 001 Practicum in Essen of Nsg Prac CRN: 25511","subject":"NUR","courseNumber":"2208","CRN":"25511"},
{"label":"NUR 3030 - 002 Basic Conc Pharmacology CRN: 25513","subject":"NUR","courseNumber":"3030","CRN":"25513"},
{"label":"NUR 3030 - 003 Basic Conc Pharmacology CRN: 25514","subject":"NUR","courseNumber":"3030","CRN":"25514"},
{"label":"NUR 3030 - 004 Basic Conc Pharmacology CRN: 25515","subject":"NUR","courseNumber":"3030","CRN":"25515"},
{"label":"NUR 3108 - 001 Pathophysiology CRN: 25516","subject":"NUR","courseNumber":"3108","CRN":"25516"},
{"label":"NUR 3108 - 002 Pathophysiology CRN: 25517","subject":"NUR","courseNumber":"3108","CRN":"25517"},
{"label":"NUR 3114 - 002 Nsg Care Adults & Older Adults CRN: 25520","subject":"NUR","courseNumber":"3114","CRN":"25520"},
{"label":"NUR 3115 - 001 Prac Nsg Adults & Older Adults CRN: 25521","subject":"NUR","courseNumber":"3115","CRN":"25521"},
{"label":"NUR 3115 - 002 Prac Nsg Adults & Older Adults CRN: 25522","subject":"NUR","courseNumber":"3115","CRN":"25522"},
{"label":"NUR 3122 - 001 Imper for Global & Pub Health CRN: 25523","subject":"NUR","courseNumber":"3122","CRN":"25523"},
{"label":"NUR 3122 - 002 Imper for Global & Pub Health CRN: 25524","subject":"NUR","courseNumber":"3122","CRN":"25524"},
{"label":"NUR 4114 - 001 Nursing and Health Policy CRN: 25527","subject":"NUR","courseNumber":"4114","CRN":"25527"},
{"label":"NUR 4114 - 002 Nursing and Health Policy CRN: 25528","subject":"NUR","courseNumber":"4114","CRN":"25528"},
{"label":"NUR 4114 - 003 Nursing and Health Policy CRN: 25529","subject":"NUR","courseNumber":"4114","CRN":"25529"},
{"label":"NUR 4114 - 004 Nursing and Health Policy CRN: 25530","subject":"NUR","courseNumber":"4114","CRN":"25530"},
{"label":"NUR 4114 - 005 Nursing and Health Policy CRN: 25531","subject":"NUR","courseNumber":"4114","CRN":"25531"},
{"label":"NUR 4200 - 008 QualityImprovmt&PatientSafety CRN: 26481","subject":"NUR","courseNumber":"4200","CRN":"26481"},
{"label":"NUR 4900 - 001 Guided Study in Nursing CRN: 25532","subject":"NUR","courseNumber":"4900","CRN":"25532"},
{"label":"NUR 4901 - 001 Guided Study in Nursing CRN: 25533","subject":"NUR","courseNumber":"4901","CRN":"25533"},
{"label":"NUR 4902 - 001 Guided Study in Nursing CRN: 25534","subject":"NUR","courseNumber":"4902","CRN":"25534"},
{"label":"NUR 4902 - 002 Guided Study in Nursing CRN: 25535","subject":"NUR","courseNumber":"4902","CRN":"25535"},
{"label":"NUR 4902 - 003 Guided Study in Nursing CRN: 25536","subject":"NUR","courseNumber":"4902","CRN":"25536"},
{"label":"NUR 4902 - 004 Guided Study in Nursing CRN: 25537","subject":"NUR","courseNumber":"4902","CRN":"25537"},
{"label":"NUR 7070 - 001 Nutrition and Global Health CRN: 25539","subject":"NUR","courseNumber":"7070","CRN":"25539"},
{"label":"NUR 7090 - 001 Planetary Hlth for Global Pop CRN: 25540","subject":"NUR","courseNumber":"7090","CRN":"25540"},
{"label":"NUR 8402 - DL1 PMH Issue&PsyPharmLifespan ll CRN: 25541","subject":"NUR","courseNumber":"8402","CRN":"25541"},
{"label":"NUR 8511 - 001 Clinical Mgmt in Adulthood ll CRN: 25542","subject":"NUR","courseNumber":"8511","CRN":"25542"},
{"label":"NUR 8512 - 001 Prac:Clin Mgmt Adulthood Il CRN: 25543","subject":"NUR","courseNumber":"8512","CRN":"25543"},
{"label":"NUR 8512 - 002 Prac:Clin Mgmt Adulthood Il CRN: 25544","subject":"NUR","courseNumber":"8512","CRN":"25544"},
{"label":"NUR 8611 - 001 Clin. Mgt. Family Care Il CRN: 25545","subject":"NUR","courseNumber":"8611","CRN":"25545"},
{"label":"NUR 8612 - 001 Pract Clin Mgt Family Care Il CRN: 25547","subject":"NUR","courseNumber":"8612","CRN":"25547"},
{"label":"NUR 8612 - 002 Pract Clin Mgt Family Care Il CRN: 25548","subject":"NUR","courseNumber":"8612","CRN":"25548"},
{"label":"NUR 8615 - DL1 Issues Across the Lifespan CRN: 25550","subject":"NUR","courseNumber":"8615","CRN":"25550"},
{"label":"NUR 8811 - 001 Clin Mgt Ped ll CRN: 25551","subject":"NUR","courseNumber":"8811","CRN":"25551"},
{"label":"NUR 8812 - 001 Prac Ped Primary Care ll CRN: 25552","subject":"NUR","courseNumber":"8812","CRN":"25552"},
{"label":"NUR 8812 - 002 Prac Ped Primary Care ll CRN: 25553","subject":"NUR","courseNumber":"8812","CRN":"25553"},
{"label":"NUR 8863 - DL1 Health Prom Growing Child CRN: 25554","subject":"NUR","courseNumber":"8863","CRN":"25554"},
{"label":"NUR 8901 - 001 Advanced Pathophysiology CRN: 25555","subject":"NUR","courseNumber":"8901","CRN":"25555"},
{"label":"NUR 8902 - DL1 Development of Nursing Science CRN: 25556","subject":"NUR","courseNumber":"8902","CRN":"25556"},
{"label":"NUR 8903 - DL1 Advanced Pharmacology CRN: 25557","subject":"NUR","courseNumber":"8903","CRN":"25557"},
{"label":"NUR 8904 - DL1 Nursing Research CRN: 25558","subject":"NUR","courseNumber":"8904","CRN":"25558"},
{"label":"NUR 8904 - DL2 Nursing Research CRN: 25559","subject":"NUR","courseNumber":"8904","CRN":"25559"},
{"label":"NUR 8906 - DL1 Leadership Strategies CRN: 25560","subject":"NUR","courseNumber":"8906","CRN":"25560"},
{"label":"NUR 8910 - 001 Advanced Physical Assessment CRN: 25561","subject":"NUR","courseNumber":"8910","CRN":"25561"},
{"label":"NUR 8910 - 002 Advanced Physical Assessment CRN: 26690","subject":"NUR","courseNumber":"8910","CRN":"26690"},
{"label":"NUR 8910 - DL1 Advanced Physical Assessment CRN: 26541","subject":"NUR","courseNumber":"8910","CRN":"26541"},
{"label":"NUR 8950 - DL1 Curriculum in Nursing CRN: 26542","subject":"NUR","courseNumber":"8950","CRN":"26542"},
{"label":"NUR 8950 - DL2 Curriculum in Nursing CRN: 26513","subject":"NUR","courseNumber":"8950","CRN":"26513"},
{"label":"NUR 8952 - DL1 Teaching Strategies CRN: 26514","subject":"NUR","courseNumber":"8952","CRN":"26514"},
{"label":"NUR 8954 - DL1 Teaching Practicum CRN: 25563","subject":"NUR","courseNumber":"8954","CRN":"25563"},
{"label":"NUR 8964 - DL1 Clinical Ethics CRN: 25564","subject":"NUR","courseNumber":"8964","CRN":"25564"},
{"label":"NUR 9002 - 001 Independent Study CRN: 25565","subject":"NUR","courseNumber":"9002","CRN":"25565"},
{"label":"NUR 9004 - 001 Advanced Study in Nursing CRN: 25566","subject":"NUR","courseNumber":"9004","CRN":"25566"},
{"label":"NUR 9101 - 001 Anesthesia Clin. Practice I CRN: 26559","subject":"NUR","courseNumber":"9101","CRN":"26559"},
{"label":"NUR 9104 - 001 Anesthesia Clin. Practice lV CRN: 26563","subject":"NUR","courseNumber":"9104","CRN":"26563"},
{"label":"NUR 9401 - DL1 DNP Project Seminar I CRN: 25567","subject":"NUR","courseNumber":"9401","CRN":"25567"},
{"label":"NUR 9401 - DL2 DNP Project Seminar I CRN: 25568","subject":"NUR","courseNumber":"9401","CRN":"25568"},
{"label":"NUR 9402 - 001 DNP Project Seminar II CRN: 26564","subject":"NUR","courseNumber":"9402","CRN":"26564"},
{"label":"NUR 9404 - DL1 DNP Project Seminar IV CRN: 25569","subject":"NUR","courseNumber":"9404","CRN":"25569"},
{"label":"NUR 9404 - DL2 DNP Project Seminar IV CRN: 25570","subject":"NUR","courseNumber":"9404","CRN":"25570"},
{"label":"NUR 9407 - DL1 Qulty. Improvement & PT Safety CRN: 26543","subject":"NUR","courseNumber":"9407","CRN":"26543"},
{"label":"NUR 9411 - 001 Epi Apprch to Hlth Disparities CRN: 26566","subject":"NUR","courseNumber":"9411","CRN":"26566"},
{"label":"NUR 9411 - DL1 Epi Apprch to Hlth Disparities CRN: 26515","subject":"NUR","courseNumber":"9411","CRN":"26515"},
{"label":"NUR 9413 - DL1 Special Topics CRN: 26516","subject":"NUR","courseNumber":"9413","CRN":"26516"},
{"label":"NUR 9420 - 001 Intro to Nurse Anesthesia CRN: 26560","subject":"NUR","courseNumber":"9420","CRN":"26560"},
{"label":"NUR 9421 - 001 Foundation Nurse Anesthesia l CRN: 26561","subject":"NUR","courseNumber":"9421","CRN":"26561"},
{"label":"NUR 9422 - 001 Fundamentals of NA Practice CRN: 26562","subject":"NUR","courseNumber":"9422","CRN":"26562"},
{"label":"NUR 9427 - 001 Case Studies in Nurse Anesth CRN: 26565","subject":"NUR","courseNumber":"9427","CRN":"26565"},
{"label":"NUR 9430 - 001 Adv Dimensions in NA Prac V CRN: 26567","subject":"NUR","courseNumber":"9430","CRN":"26567"},
{"label":"NUR 9503 - DL1 Quantitative Res Des & Methods CRN: 26557","subject":"NUR","courseNumber":"9503","CRN":"26557"},
{"label":"NUR 9505 - DL1 Statistical Analysis I CRN: 26558","subject":"NUR","courseNumber":"9505","CRN":"26558"},
{"label":"NUR 9508 - DL1 Doctoral Dissertation Continua CRN: 25571","subject":"NUR","courseNumber":"9508","CRN":"25571"},
{"label":"NUR 9508 - DL2 Doctoral Dissertation Continua CRN: 25572","subject":"NUR","courseNumber":"9508","CRN":"25572"},
{"label":"NUR 9511 - DL1 Dissertation Seminar I CRN: 25573","subject":"NUR","courseNumber":"9511","CRN":"25573"},
{"label":"NUR 9512 - DL1 Dissertation Seminar II CRN: 25574","subject":"NUR","courseNumber":"9512","CRN":"25574"},
{"label":"NUR 9513 - DL1 Dissertation Seminar III CRN: 25575","subject":"NUR","courseNumber":"9513","CRN":"25575"},
{"label":"NUR 9515 - DL1 Foundations in Nursing Science CRN: 25576","subject":"NUR","courseNumber":"9515","CRN":"25576"},
{"label":"NUR 9518 - DL1 Scientific and Grant Writing CRN: 25577","subject":"NUR","courseNumber":"9518","CRN":"25577"},
{"label":"NTR 2120 - 001 Principles of Nutrition CRN: 25494","subject":"NTR","courseNumber":"2120","CRN":"25494"},
{"label":"ODM 1000 - DL1 Foundation of Organiz Behavior CRN: 26444","subject":"ODM","courseNumber":"1000","CRN":"26444"},
{"label":"ODM 2000 - DL1 Essentials of Finance & Acct CRN: 26484","subject":"ODM","courseNumber":"2000","CRN":"26484"},
{"label":"ODM 2020 - DL1 Ethics, Regulations & Soc Resp CRN: 26445","subject":"ODM","courseNumber":"2020","CRN":"26445"},
{"label":"ODM 2040 - DL1 Strategic Oper & Process Impro CRN: 26485","subject":"ODM","courseNumber":"2040","CRN":"26485"},
{"label":"SL 1000 - 001 Serv Learning 4th Hour Seminar CRN: 25887","subject":"PJ","courseNumber":"1000","CRN":"25887"},
{"label":"SL 1000 - 004 Serv Learning 4th Hour Seminar CRN: 25890","subject":"PJ","courseNumber":"1000","CRN":"25890"},
{"label":"SL 1000 - 008 Serv Learning 4th Hour Seminar CRN: 25894","subject":"PJ","courseNumber":"1000","CRN":"25894"},
{"label":"PA 2000 - 001 Public Policy CRN: 25579","subject":"PJ","courseNumber":"2000","CRN":"25579"},
{"label":"PJ 2250 - 001 Violence & Justice in the Wrld CRN: 25723","subject":"PJ","courseNumber":"2250","CRN":"25723"},
{"label":"PSY 2900 - 002 TOP:Psy of Power & Privilege CRN: 25811","subject":"PJ","courseNumber":"2900","CRN":"25811"},
{"label":"THL 5004 - 002 THM: THL Capital & Injustice CRN: 26128","subject":"PJ","courseNumber":"5004","CRN":"26128"},
{"label":"SL 1000 - 002 Serv Learning 4th Hour Seminar CRN: 25888","subject":"PJ","courseNumber":"1000","CRN":"25888"},
{"label":"SL 1000 - 003 Serv Learning 4th Hour Seminar CRN: 25889","subject":"PJ","courseNumber":"1000","CRN":"25889"},
{"label":"SL 1000 - 005 Serv Learning 4th Hour Seminar CRN: 25891","subject":"PJ","courseNumber":"1000","CRN":"25891"},
{"label":"SL 1000 - 006 Serv Learning 4th Hour Seminar CRN: 25892","subject":"PJ","courseNumber":"1000","CRN":"25892"},
{"label":"SL 1000 - 007 Serv Learning 4th Hour Seminar CRN: 25893","subject":"PJ","courseNumber":"1000","CRN":"25893"},
{"label":"SL 1000 - 009 Serv Learning 4th Hour Seminar CRN: 25895","subject":"PJ","courseNumber":"1000","CRN":"25895"},
{"label":"PA 1050 - 001 Public Administration CRN: 25578","subject":"PJ","courseNumber":"1050","CRN":"25578"},
{"label":"PHI 2121 - 001 Environmental Ethics CRN: 25625","subject":"PJ","courseNumber":"2121","CRN":"25625"},
{"label":"PHI 2121 - 002 Environmental Ethics CRN: 25626","subject":"PJ","courseNumber":"2121","CRN":"25626"},
{"label":"PJ 2993 - 001 Internship CRN: 25727","subject":"PJ","courseNumber":"2993","CRN":"25727"},
{"label":"PJ 2996 - 001 Internship CRN: 25728","subject":"PJ","courseNumber":"2996","CRN":"25728"},
{"label":"PJ 3000 - 001 TOP:RJDC:Agitating for Justice CRN: 25729","subject":"PJ","courseNumber":"3000","CRN":"25729"},
{"label":"PHI 3720 - 001 Marx & Marxism CRN: 25641","subject":"PJ","courseNumber":"3720","CRN":"25641"},
{"label":"PJ 5000 - 001 TOP:PovertyPolicy,Law,&Sustby CRN: 25731","subject":"PJ","courseNumber":"5000","CRN":"25731"},
{"label":"THL 5003 - 002 THM:The Church&Transformation CRN: 26124","subject":"PJ","courseNumber":"5003","CRN":"26124"},
{"label":"THL 5003 - 004 THM:PopeFrancisVisionofChurch CRN: 26126","subject":"PJ","courseNumber":"5003","CRN":"26126"},
{"label":"PJ 5600 - 001 Independent Study CRN: 25733","subject":"PJ","courseNumber":"5600","CRN":"25733"},
{"label":"PHI 1000 - 001 Knowledge, Reality, Self CRN: 25584","subject":"PHI","courseNumber":"1000","CRN":"25584"},
{"label":"PHI 1000 - 003 Knowledge, Reality, Self CRN: 25586","subject":"PHI","courseNumber":"1000","CRN":"25586"},
{"label":"PHI 1000 - 005 Knowledge, Reality, Self CRN: 25588","subject":"PHI","courseNumber":"1000","CRN":"25588"},
{"label":"PHI 1000 - 006 Knowledge, Reality, Self CRN: 25589","subject":"PHI","courseNumber":"1000","CRN":"25589"},
{"label":"PHI 1000 - 007 Knowledge, Reality, Self CRN: 25590","subject":"PHI","courseNumber":"1000","CRN":"25590"},
{"label":"PHI 1000 - 008 Knowledge, Reality, Self CRN: 25591","subject":"PHI","courseNumber":"1000","CRN":"25591"},
{"label":"PHI 1000 - 009 Knowledge, Reality, Self CRN: 25592","subject":"PHI","courseNumber":"1000","CRN":"25592"},
{"label":"PHI 1000 - 010 Knowledge, Reality, Self CRN: 25593","subject":"PHI","courseNumber":"1000","CRN":"25593"},
{"label":"PHI 1000 - 011 Knowledge, Reality, Self CRN: 25594","subject":"PHI","courseNumber":"1000","CRN":"25594"},
{"label":"PHI 1000 - 012 Knowledge, Reality, Self CRN: 25595","subject":"PHI","courseNumber":"1000","CRN":"25595"},
{"label":"PHI 1000 - 013 Knowledge, Reality, Self CRN: 25596","subject":"PHI","courseNumber":"1000","CRN":"25596"},
{"label":"PHI 1000 - 014 Knowledge, Reality, Self CRN: 25597","subject":"PHI","courseNumber":"1000","CRN":"25597"},
{"label":"PHI 1000 - 015 Knowledge, Reality, Self CRN: 25598","subject":"PHI","courseNumber":"1000","CRN":"25598"},
{"label":"PHI 1000 - 016 Knowledge, Reality, Self CRN: 25599","subject":"PHI","courseNumber":"1000","CRN":"25599"},
{"label":"PHI 1000 - 017 Knowledge, Reality, Self CRN: 25600","subject":"PHI","courseNumber":"1000","CRN":"25600"},
{"label":"PHI 1000 - 018 Knowledge, Reality, Self CRN: 25601","subject":"PHI","courseNumber":"1000","CRN":"25601"},
{"label":"PHI 1000 - 019 Knowledge, Reality, Self CRN: 25602","subject":"PHI","courseNumber":"1000","CRN":"25602"},
{"label":"PHI 1000 - 020 Knowledge, Reality, Self CRN: 25603","subject":"PHI","courseNumber":"1000","CRN":"25603"},
{"label":"PHI 1000 - 021 Knowledge, Reality, Self CRN: 25604","subject":"PHI","courseNumber":"1000","CRN":"25604"},
{"label":"PHI 1000 - 022 Knowledge, Reality, Self CRN: 25605","subject":"PHI","courseNumber":"1000","CRN":"25605"},
{"label":"PHI 1000 - 023 Knowledge, Reality, Self CRN: 25606","subject":"PHI","courseNumber":"1000","CRN":"25606"},
{"label":"PHI 1000 - 024 Knowledge, Reality, Self CRN: 25607","subject":"PHI","courseNumber":"1000","CRN":"25607"},
{"label":"PHI 1000 - 025 Knowledge, Reality, Self CRN: 25608","subject":"PHI","courseNumber":"1000","CRN":"25608"},
{"label":"PHI 1000 - 026 Knowledge, Reality, Self CRN: 25609","subject":"PHI","courseNumber":"1000","CRN":"25609"},
{"label":"PHI 1000 - 027 Knowledge, Reality, Self CRN: 25610","subject":"PHI","courseNumber":"1000","CRN":"25610"},
{"label":"PHI 1000 - 028 Knowledge, Reality, Self CRN: 25611","subject":"PHI","courseNumber":"1000","CRN":"25611"},
{"label":"PHI 1000 - 029 Knowledge, Reality, Self CRN: 25612","subject":"PHI","courseNumber":"1000","CRN":"25612"},
{"label":"PHI 1000 - 030 Knowledge, Reality, Self CRN: 25613","subject":"PHI","courseNumber":"1000","CRN":"25613"},
{"label":"PHI 1000 - 031 Knowledge, Reality, Self CRN: 25614","subject":"PHI","courseNumber":"1000","CRN":"25614"},
{"label":"PHI 2990 - 001 TOP:Yoga & Philosophy CRN: 25633","subject":"PHI","courseNumber":"2990","CRN":"25633"},
{"label":"PHI 2990 - 002 TOP:Yoga & Philosophy CRN: 25634","subject":"PHI","courseNumber":"2990","CRN":"25634"},
{"label":"PHI 3020 - 001 History of Ancient Philosophy CRN: 25638","subject":"PHI","courseNumber":"3020","CRN":"25638"},
{"label":"PHI 1000 - 002 Knowledge, Reality, Self CRN: 25585","subject":"PHI","courseNumber":"1000","CRN":"25585"},
{"label":"PHI 1000 - 004 Knowledge, Reality, Self CRN: 25587","subject":"PHI","courseNumber":"1000","CRN":"25587"},
{"label":"PHI 1000 - DL1 Knowledge, Reality, Self CRN: 26447","subject":"PHI","courseNumber":"1000","CRN":"26447"},
{"label":"PHI 2010 - 001 Logic & Critical Thinking CRN: 25618","subject":"PHI","courseNumber":"2010","CRN":"25618"},
{"label":"PHI 2190 - 001 Freedom CRN: 25629","subject":"PHI","courseNumber":"2190","CRN":"25629"},
{"label":"PHI 2940 - 001 Indian & Tibetan Buddhist Phil CRN: 25632","subject":"PHI","courseNumber":"2940","CRN":"25632"},
{"label":"PHI 3000 - 001 SEM:Philosophy of Art CRN: 25636","subject":"PHI","courseNumber":"3000","CRN":"25636"},
{"label":"PHI 3000 - 002 SEM:Narcissism CRN: 25637","subject":"PHI","courseNumber":"3000","CRN":"25637"},
{"label":"PHI 3030 - 001 History of Medieval Philosophy CRN: 25639","subject":"PHI","courseNumber":"3030","CRN":"25639"},
{"label":"PHI 3050 - 001 Kant & 19th Cent Philosophy CRN: 25640","subject":"PHI","courseNumber":"3050","CRN":"25640"},
{"label":"PHI 4990 - 001 Independent Study & Research CRN: 25644","subject":"PHI","courseNumber":"4990","CRN":"25644"},
{"label":"PHI 5000 - 001 SEM:Augustine,Buddha & the Tao CRN: 25645","subject":"PHI","courseNumber":"5000","CRN":"25645"},
{"label":"PHI 5000 - 002 SEM:Democracy & Neoliberalism CRN: 25646","subject":"PHI","courseNumber":"5000","CRN":"25646"},
{"label":"PHI 6000 - 001 Senior Thesis CRN: 25647","subject":"PHI","courseNumber":"6000","CRN":"25647"},
{"label":"PHI 7220 - 001 Aristotle: Theoret Phil CRN: 25648","subject":"PHI","courseNumber":"7220","CRN":"25648"},
{"label":"PHI 7610 - 001 TOP: Early Mod Phil CRN: 25649","subject":"PHI","courseNumber":"7610","CRN":"25649"},
{"label":"PHI 7910 - 001 Hegel's Phenom of Spirit CRN: 25650","subject":"PHI","courseNumber":"7910","CRN":"25650"},
{"label":"PHI 8250 - 001 Merleau-Ponty CRN: 25651","subject":"PHI","courseNumber":"8250","CRN":"25651"},
{"label":"PHI 8350 - 001 Foucault CRN: 25652","subject":"PHI","courseNumber":"8350","CRN":"25652"},
{"label":"PHI 8710 - 001 SEM: Post & Transhumanism CRN: 25653","subject":"PHI","courseNumber":"8710","CRN":"25653"},
{"label":"PHI 8710 - 002 SEM: Bioethics CRN: 25654","subject":"PHI","courseNumber":"8710","CRN":"25654"},
{"label":"PHI 8830 - 001 Independent Study I CRN: 25655","subject":"PHI","courseNumber":"8830","CRN":"25655"},
{"label":"PHI 8835 - 001 Independent Study II CRN: 25656","subject":"PHI","courseNumber":"8835","CRN":"25656"},
{"label":"PHI 8870 - 001 Consortium I CRN: 25657","subject":"PHI","courseNumber":"8870","CRN":"25657"},
{"label":"PHI 8875 - 001 Consortium II CRN: 25658","subject":"PHI","courseNumber":"8875","CRN":"25658"},
{"label":"PHI 8920 - 001 Teaching Internship CRN: 25659","subject":"PHI","courseNumber":"8920","CRN":"25659"},
{"label":"PHI 9010 - 001 Dissertation CRN: 25660","subject":"PHI","courseNumber":"9010","CRN":"25660"},
{"label":"PHI 9081 - 001 Dissertation Continuation CRN: 25661","subject":"PHI","courseNumber":"9081","CRN":"25661"},
{"label":"PHY 1100 - 001 General Physics I CRN: 25662","subject":"PHY","courseNumber":"1100","CRN":"25662"},
{"label":"PHY 1100 - 002 General Physics I CRN: 25663","subject":"PHY","courseNumber":"1100","CRN":"25663"},
{"label":"PHY 1100 - 003 General Physics I CRN: 25664","subject":"PHY","courseNumber":"1100","CRN":"25664"},
{"label":"PHY 1100 - 004 General Physics I CRN: 25665","subject":"PHY","courseNumber":"1100","CRN":"25665"},
{"label":"PHY 1100 - 005 General Physics I CRN: 25666","subject":"PHY","courseNumber":"1100","CRN":"25666"},
{"label":"PHY 1100 - 006 General Physics I CRN: 25667","subject":"PHY","courseNumber":"1100","CRN":"25667"},
{"label":"PHY 1100 - 007 General Physics I CRN: 25668","subject":"PHY","courseNumber":"1100","CRN":"25668"},
{"label":"PHY 1100 - 008 General Physics I CRN: 25669","subject":"PHY","courseNumber":"1100","CRN":"25669"},
{"label":"PHY 1100 - 100 General Physics I CRN: 25670","subject":"PHY","courseNumber":"1100","CRN":"25670"},
{"label":"PHY 1100 - 101 General Physics I CRN: 25671","subject":"PHY","courseNumber":"1100","CRN":"25671"},
{"label":"PHY 1101 - 002 General Physics I Lab CRN: 25673","subject":"PHY","courseNumber":"1101","CRN":"25673"},
{"label":"PHY 1101 - 003 General Physics I Lab CRN: 25674","subject":"PHY","courseNumber":"1101","CRN":"25674"},
{"label":"PHY 1101 - 005 General Physics I Lab CRN: 25676","subject":"PHY","courseNumber":"1101","CRN":"25676"},
{"label":"PHY 1101 - 006 General Physics I Lab CRN: 25677","subject":"PHY","courseNumber":"1101","CRN":"25677"},
{"label":"PHY 1101 - 007 General Physics I Lab CRN: 25678","subject":"PHY","courseNumber":"1101","CRN":"25678"},
{"label":"PHY 1101 - 008 General Physics I Lab CRN: 25679","subject":"PHY","courseNumber":"1101","CRN":"25679"},
{"label":"PHY 1101 - 100 General Physics I Lab CRN: 25680","subject":"PHY","courseNumber":"1101","CRN":"25680"},
{"label":"PHY 1101 - 101 General Physics I Lab CRN: 25681","subject":"PHY","courseNumber":"1101","CRN":"25681"},
{"label":"PHY 2402 - 002 Physics II Elec & Magnet CRN: 25686","subject":"PHY","courseNumber":"2402","CRN":"25686"},
{"label":"PHY 2402 - 007 Physics II Elec & Magnet CRN: 25691","subject":"PHY","courseNumber":"2402","CRN":"25691"},
{"label":"PHY 2402 - 100 Physics II Elec & Magnet CRN: 25692","subject":"PHY","courseNumber":"2402","CRN":"25692"},
{"label":"PHY 2403 - 002 Phy Lab for Engineering CRN: 25694","subject":"PHY","courseNumber":"2403","CRN":"25694"},
{"label":"PHY 2403 - 003 Phy Lab for Engineering CRN: 25695","subject":"PHY","courseNumber":"2403","CRN":"25695"},
{"label":"PHY 2403 - 004 Phy Lab for Engineering CRN: 25696","subject":"PHY","courseNumber":"2403","CRN":"25696"},
{"label":"PHY 2403 - 005 Phy Lab for Engineering CRN: 25697","subject":"PHY","courseNumber":"2403","CRN":"25697"},
{"label":"PHY 2415 - 001 Lab: Thermodynamics CRN: 25703","subject":"PHY","courseNumber":"2415","CRN":"25703"},
{"label":"PHY 2417 - 002 Lab:Modern Physics CRN: 25706","subject":"PHY","courseNumber":"2417","CRN":"25706"},
{"label":"PHY 2417 - 003 Lab:Modern Physics CRN: 25707","subject":"PHY","courseNumber":"2417","CRN":"25707"},
{"label":"PHY 4000 - 001 Elec & Magnetism I CRN: 25715","subject":"PHY","courseNumber":"4000","CRN":"25715"},
{"label":"PHY 4100 - 001 Mechanics I CRN: 25716","subject":"PHY","courseNumber":"4100","CRN":"25716"},
{"label":"PHY 1101 - 001 General Physics I Lab CRN: 25672","subject":"PHY","courseNumber":"1101","CRN":"25672"},
{"label":"PHY 1101 - 004 General Physics I Lab CRN: 25675","subject":"PHY","courseNumber":"1101","CRN":"25675"},
{"label":"PHY 1101 - 102 General Physics I Lab CRN: 25682","subject":"PHY","courseNumber":"1101","CRN":"25682"},
{"label":"PHY 1101 - 103 General Physics I Lab CRN: 25683","subject":"PHY","courseNumber":"1101","CRN":"25683"},
{"label":"PHY 2400 - 001 Physics I Mechanics CRN: 25684","subject":"PHY","courseNumber":"2400","CRN":"25684"},
{"label":"PHY 2402 - 001 Physics II Elec & Magnet CRN: 25685","subject":"PHY","courseNumber":"2402","CRN":"25685"},
{"label":"PHY 2402 - 003 Physics II Elec & Magnet CRN: 25687","subject":"PHY","courseNumber":"2402","CRN":"25687"},
{"label":"PHY 2402 - 004 Physics II Elec & Magnet CRN: 25688","subject":"PHY","courseNumber":"2402","CRN":"25688"},
{"label":"PHY 2402 - 005 Physics II Elec & Magnet CRN: 25689","subject":"PHY","courseNumber":"2402","CRN":"25689"},
{"label":"PHY 2402 - 006 Physics II Elec & Magnet CRN: 25690","subject":"PHY","courseNumber":"2402","CRN":"25690"},
{"label":"PHY 2403 - 001 Phy Lab for Engineering CRN: 25693","subject":"PHY","courseNumber":"2403","CRN":"25693"},
{"label":"PHY 2403 - 006 Phy Lab for Engineering CRN: 25698","subject":"PHY","courseNumber":"2403","CRN":"25698"},
{"label":"PHY 2403 - 100 Phy Lab for Engineering CRN: 25699","subject":"PHY","courseNumber":"2403","CRN":"25699"},
{"label":"PHY 2410 - 001 University Phy:Mechanics CRN: 25700","subject":"PHY","courseNumber":"2410","CRN":"25700"},
{"label":"PHY 2411 - 001 Lab: Mechanics CRN: 25701","subject":"PHY","courseNumber":"2411","CRN":"25701"},
{"label":"PHY 2411 - 002 Lab: Mechanics CRN: 25702","subject":"PHY","courseNumber":"2411","CRN":"25702"},
{"label":"PHY 2415 - 002 Lab: Thermodynamics CRN: 25704","subject":"PHY","courseNumber":"2415","CRN":"25704"},
{"label":"PHY 2417 - 001 Lab:Modern Physics CRN: 25705","subject":"PHY","courseNumber":"2417","CRN":"25705"},
{"label":"PHY 2420 - 001 Matter and Interactions I CRN: 25708","subject":"PHY","courseNumber":"2420","CRN":"25708"},
{"label":"PHY 2421 - 001 Lab: Matter and Interactions I CRN: 25709","subject":"PHY","courseNumber":"2421","CRN":"25709"},
{"label":"PHY 2421 - 002 Lab: Matter and Interactions I CRN: 25710","subject":"PHY","courseNumber":"2421","CRN":"25710"},
{"label":"PHY 2601 - 001 Computational Phy Lab I CRN: 25711","subject":"PHY","courseNumber":"2601","CRN":"25711"},
{"label":"PHY 3200 - 001 Thermo, Optics and Waves CRN: 25712","subject":"PHY","courseNumber":"3200","CRN":"25712"},
{"label":"PHY 3310 - 001 Electronics CRN: 25713","subject":"PHY","courseNumber":"3310","CRN":"25713"},
{"label":"PHY 3311 - 001 Electronics Lab CRN: 25714","subject":"PHY","courseNumber":"3311","CRN":"25714"},
{"label":"PHY 4202 - 001 Mathematical Physics II CRN: 25717","subject":"PHY","courseNumber":"4202","CRN":"25717"},
{"label":"PHY 4301 - 100 Experimental Methods I CRN: 26739","subject":"PHY","courseNumber":"4301","CRN":"26739"},
{"label":"PHY 5100 - 001 Quantum Mechanics CRN: 25719","subject":"PHY","courseNumber":"5100","CRN":"25719"},
{"label":"PHY 6450 - 001 Supervised Study in Physics CRN: 25720","subject":"PHY","courseNumber":"6450","CRN":"25720"},
{"label":"PHY 6500 - 001 Supervised Study in Physics CRN: 25721","subject":"PHY","courseNumber":"6500","CRN":"25721"},
{"label":"PHY 6600 - 001 Supervised Study in Physics CRN: 25722","subject":"PHY","courseNumber":"6600","CRN":"25722"},
{"label":"PSC 1100 - 001 American Government CRN: 25734","subject":"PSC","courseNumber":"1100","CRN":"25734"},
{"label":"PSC 1200 - 004 International Relations CRN: 25740","subject":"PSC","courseNumber":"1200","CRN":"25740"},
{"label":"PSC 1300 - 001 Comparative Politics CRN: 25742","subject":"PSC","courseNumber":"1300","CRN":"25742"},
{"label":"PSC 1300 - 003 Comparative Politics CRN: 25744","subject":"PSC","courseNumber":"1300","CRN":"25744"},
{"label":"PSC 1300 - 004 Comparative Politics CRN: 25745","subject":"PSC","courseNumber":"1300","CRN":"25745"},
{"label":"PSC 1400 - 002 Political Theory CRN: 25747","subject":"PSC","courseNumber":"1400","CRN":"25747"},
{"label":"PSC 1400 - 004 Political Theory CRN: 25749","subject":"PSC","courseNumber":"1400","CRN":"25749"},
{"label":"PSC 1900 - 001 Research Seminar CRN: 25750","subject":"PSC","courseNumber":"1900","CRN":"25750"},
{"label":"PSC 1900 - 002 Research Seminar CRN: 25751","subject":"PSC","courseNumber":"1900","CRN":"25751"},
{"label":"PSC 1900 - 003 Research Seminar CRN: 25752","subject":"PSC","courseNumber":"1900","CRN":"25752"},
{"label":"PSC 2230 - 001 International Organization CRN: 25753","subject":"PSC","courseNumber":"2230","CRN":"25753"},
{"label":"PSC 6900 - 001 SEM:USPublicOpin&PolBehavior CRN: 25772","subject":"PSC","courseNumber":"6900","CRN":"25772"},
{"label":"PSC 6900 - 002 SEM:U.S. Intelligence CRN: 25773","subject":"PSC","courseNumber":"6900","CRN":"25773"},
{"label":"PA 1050 - DL1 Public Administration CRN: 26446","subject":"PSC","courseNumber":"1050","CRN":"26446"},
{"label":"PSC 1100 - 002 American Government CRN: 25735","subject":"PSC","courseNumber":"1100","CRN":"25735"},
{"label":"PSC 1100 - 003 American Government CRN: 25736","subject":"PSC","courseNumber":"1100","CRN":"25736"},
{"label":"PSC 1200 - 001 International Relations CRN: 25737","subject":"PSC","courseNumber":"1200","CRN":"25737"},
{"label":"PSC 1200 - 002 International Relations CRN: 25738","subject":"PSC","courseNumber":"1200","CRN":"25738"},
{"label":"PSC 1200 - 003 International Relations CRN: 25739","subject":"PSC","courseNumber":"1200","CRN":"25739"},
{"label":"PSC 1300 - 002 Comparative Politics CRN: 25743","subject":"PSC","courseNumber":"1300","CRN":"25743"},
{"label":"PSC 1400 - 001 Political Theory CRN: 25746","subject":"PSC","courseNumber":"1400","CRN":"25746"},
{"label":"PSC 1400 - 003 Political Theory CRN: 25748","subject":"PSC","courseNumber":"1400","CRN":"25748"},
{"label":"PSC 2235 - 001 United Nations CRN: 25754","subject":"PSC","courseNumber":"2235","CRN":"25754"},
{"label":"PSC 2410 - 001 Early Political Theories CRN: 25756","subject":"PSC","courseNumber":"2410","CRN":"25756"},
{"label":"PSC 3165 - 001 Pol Econ of Black America CRN: 25758","subject":"PSC","courseNumber":"3165","CRN":"25758"},
{"label":"PSC 3210 - 001 American Foreign Policy CRN: 25759","subject":"PSC","courseNumber":"3210","CRN":"25759"},
{"label":"PSC 3410 - 001 Theories of War and Peace CRN: 25760","subject":"PSC","courseNumber":"3410","CRN":"25760"},
{"label":"PSC 3440 - 001 Politics and Religion CRN: 25761","subject":"PSC","courseNumber":"3440","CRN":"25761"},
{"label":"PSC 4175 - 001 TOP:IntroductionDataScience CRN: 25762","subject":"PSC","courseNumber":"4175","CRN":"25762"},
{"label":"PSC 4275 - 001 TOP:Human Rights CRN: 25763","subject":"PSC","courseNumber":"4275","CRN":"25763"},
{"label":"PSC 4275 - 002 TOP:Global  Leadership CRN: 25764","subject":"PSC","courseNumber":"4275","CRN":"25764"},
{"label":"PSC 4375 - 001 TOP:Democracy&Immigration CRN: 25766","subject":"PSC","courseNumber":"4375","CRN":"25766"},
{"label":"PA 4500 - 001 Research Methods in Public Svc CRN: 25581","subject":"PSC","courseNumber":"4500","CRN":"25581"},
{"label":"PSC 5110 - 001 Ind Study in Am Gov & Politics CRN: 25767","subject":"PSC","courseNumber":"5110","CRN":"25767"},
{"label":"PSC 5210 - 001 Ind Study in Intern'l Rel CRN: 25768","subject":"PSC","courseNumber":"5210","CRN":"25768"},
{"label":"PSC 5310 - 001 Ind Study in Comparative Pol CRN: 25769","subject":"PSC","courseNumber":"5310","CRN":"25769"},
{"label":"PSC 5410 - 001 Ind Study in Political Theory CRN: 25770","subject":"PSC","courseNumber":"5410","CRN":"25770"},
{"label":"PSC 6503 - 001 Internship Elective CRN: 25771","subject":"PSC","courseNumber":"6503","CRN":"25771"},
{"label":"PSC 6900 - 003 SEM:Ukrainian Politics CRN: 25774","subject":"PSC","courseNumber":"6900","CRN":"25774"},
{"label":"PSC 7000 - 001 Research Concepts & Approaches CRN: 25776","subject":"PSC","courseNumber":"7000","CRN":"25776"},
{"label":"PSC 7175 - 001 TOP:Genocide&MassKilling CRN: 25777","subject":"PSC","courseNumber":"7175","CRN":"25777"},
{"label":"PSC 7175 - 002 TOP:British Politics CRN: 25778","subject":"PSC","courseNumber":"7175","CRN":"25778"},
{"label":"PSC 7175 - 003 TOP:PoliticsClimateChange CRN: 25779","subject":"PSC","courseNumber":"7175","CRN":"25779"},
{"label":"PSC 7175 - 004 TOP:U.S.NatlSecurityPolicy CRN: 25780","subject":"PSC","courseNumber":"7175","CRN":"25780"},
{"label":"PSC 7175 - 005 TOP:Cold War CRN: 25781","subject":"PSC","courseNumber":"7175","CRN":"25781"},
{"label":"PSC 9079 - 001 Thesis Direction CRN: 25782","subject":"PSC","courseNumber":"9079","CRN":"25782"},
{"label":"PSC 9080 - 001 Thesis Continuation CRN: 25783","subject":"PSC","courseNumber":"9080","CRN":"25783"},
{"label":"PSC 9800 - 001 Read-Resrch Polit Scien CRN: 25784","subject":"PSC","courseNumber":"9800","CRN":"25784"},
{"label":"THL 1000 - DL1 Faith, Reason, & Culture CRN: 26488","subject":"CPS","courseNumber":"1000","CRN":"26488"},
{"label":"CPS 1001 - DL1 Collge&Prof Success Strategies CRN: 24495","subject":"CPS","courseNumber":"1001","CRN":"24495"},
{"label":"CPS 1100 - 100 RISE Communitas l CRN: 24496","subject":"CPS","courseNumber":"1100","CRN":"24496"},
{"label":"CPS 3400 - 100 Internship Practicum CRN: 24497","subject":"CPS","courseNumber":"3400","CRN":"24497"},
{"label":"PA 4100 - DL1 Public Budgeting CRN: 26486","subject":"CPS","courseNumber":"4100","CRN":"26486"},
{"label":"CPS 4400 - 100 Internship Practicum CRN: 24498","subject":"CPS","courseNumber":"4400","CRN":"24498"},
{"label":"THL 5003 - DL1 THM:SpiritualityinEverday Life CRN: 26489","subject":"CPS","courseNumber":"5003","CRN":"26489"},
{"label":"CPS 5400 - 100 Internship Practicum Part 2 CRN: 24499","subject":"CPS","courseNumber":"5400","CRN":"24499"},
{"label":"PSY 1000 - 001 General Psychology CRN: 25785","subject":"PSY","courseNumber":"1000","CRN":"25785"},
{"label":"PSY 1000 - 002 General Psychology CRN: 25786","subject":"PSY","courseNumber":"1000","CRN":"25786"},
{"label":"PSY 1000 - 003 General Psychology CRN: 25787","subject":"PSY","courseNumber":"1000","CRN":"25787"},
{"label":"PSY 1000 - 004 General Psychology CRN: 25788","subject":"PSY","courseNumber":"1000","CRN":"25788"},
{"label":"PSY 1000 - 005 General Psychology CRN: 25789","subject":"PSY","courseNumber":"1000","CRN":"25789"},
{"label":"PSY 1000 - 006 General Psychology CRN: 25790","subject":"PSY","courseNumber":"1000","CRN":"25790"},
{"label":"PSY 1000 - 007 General Psychology CRN: 25791","subject":"PSY","courseNumber":"1000","CRN":"25791"},
{"label":"PSY 1000 - 008 General Psychology CRN: 25792","subject":"PSY","courseNumber":"1000","CRN":"25792"},
{"label":"PSY 1000 - 009 General Psychology CRN: 25793","subject":"PSY","courseNumber":"1000","CRN":"25793"},
{"label":"PSY 1000 - 100 General Psychology CRN: 25794","subject":"PSY","courseNumber":"1000","CRN":"25794"},
{"label":"PSY 2400 - 001 Cross-Cultural Psychology CRN: 25808","subject":"PSY","courseNumber":"2400","CRN":"25808"},
{"label":"PSY 2900 - 004 TOP:ParapsychologyCritAnalysis CRN: 25813","subject":"PSY","courseNumber":"2900","CRN":"25813"},
{"label":"PSY 2900 - 100 TOP:Forensic Psychology CRN: 25814","subject":"PSY","courseNumber":"2900","CRN":"25814"},
{"label":"PSY 5150 - 001 Foundations of Modern Psych CRN: 25832","subject":"PSY","courseNumber":"5150","CRN":"25832"},
{"label":"PSY 5250 - 001 Contemp Research Issues in Psy CRN: 25833","subject":"PSY","courseNumber":"5250","CRN":"25833"},
{"label":"PSY 5250 - 002 Contemp Research Issues in Psy CRN: 25834","subject":"PSY","courseNumber":"5250","CRN":"25834"},
{"label":"PSY 8225 - 001 Psychopathology CRN: 26691","subject":"PSY","courseNumber":"8225","CRN":"26691"},
{"label":"PSY 9033 - 001 Thesis Direction II CRN: 25843","subject":"PSY","courseNumber":"9033","CRN":"25843"},
{"label":"PSY 1001 - 001 Intro to Brain and Behavior CRN: 25796","subject":"PSY","courseNumber":"1001","CRN":"25796"},
{"label":"PSY 2150 - 001 Undergrad Research Experience CRN: 25807","subject":"PSY","courseNumber":"2150","CRN":"25807"},
{"label":"PSY 2700 - 100 Industrial/Organizationl Psy CRN: 25809","subject":"PSY","courseNumber":"2700","CRN":"25809"},
{"label":"PSY 2993 - 001 Psychology Internship CRN: 25815","subject":"PSY","courseNumber":"2993","CRN":"25815"},
{"label":"PSY 5900 - 001 Independent Research Project CRN: 25835","subject":"PSY","courseNumber":"5900","CRN":"25835"},
{"label":"PSY 8000 - 001 Seminar CRN: 25836","subject":"PSY","courseNumber":"8000","CRN":"25836"},
{"label":"PSY 8050 - 001 Biopsychology CRN: 25837","subject":"PSY","courseNumber":"8050","CRN":"25837"},
{"label":"PSY 8175 - 001 Animal Learning & Cognition CRN: 25838","subject":"PSY","courseNumber":"8175","CRN":"25838"},
{"label":"PSY 8900 - 001 TOP: Intimate Relationships CRN: 25839","subject":"PSY","courseNumber":"8900","CRN":"25839"},
{"label":"PSY 9031 - 001 Individual Research CRN: 25841","subject":"PSY","courseNumber":"9031","CRN":"25841"},
{"label":"PSY 9032 - 001 Thesis Direction I CRN: 25842","subject":"PSY","courseNumber":"9032","CRN":"25842"},
{"label":"PSY 9080 - 001 Thesis Continuation CRN: 25844","subject":"PSY","courseNumber":"9080","CRN":"25844"},
{"label":"MPA 8010 - 031 Management Internship CRN: 26546","subject":"MPA","courseNumber":"8010","CRN":"26546"},
{"label":"MPA 8011 - 031 Public Administration Theory CRN: 26547","subject":"MPA","courseNumber":"8011","CRN":"26547"},
{"label":"MPA 8011 - D11 Public Administration Theory CRN: 26505","subject":"MPA","courseNumber":"8011","CRN":"26505"},
{"label":"MPA 8012 - 031 Individual Research I CRN: 26548","subject":"MPA","courseNumber":"8012","CRN":"26548"},
{"label":"MPA 8013 - 031 Individual Research II CRN: 26549","subject":"MPA","courseNumber":"8013","CRN":"26549"},
{"label":"MPA 8021 - 031 Leadership Ethics CRN: 26550","subject":"MPA","courseNumber":"8021","CRN":"26550"},
{"label":"MPA 8031 - D11 Financial Management CRN: 26710","subject":"MPA","courseNumber":"8031","CRN":"26710"},
{"label":"MPA 8041 - D11 Human Center Management CRN: 26506","subject":"MPA","courseNumber":"8041","CRN":"26506"},
{"label":"MPA 8051 - D21 Applied Data Analysis CRN: 26533","subject":"MPA","courseNumber":"8051","CRN":"26533"},
{"label":"MPA 8061 - 031 Organization Theory CRN: 26811","subject":"MPA","courseNumber":"8061","CRN":"26811"},
{"label":"MPA 8199 - 031 TOP: Adv. Statistical Analysis CRN: 26552","subject":"MPA","courseNumber":"8199","CRN":"26552"},
{"label":"MPA 8200 - D21 Strategic Communication CRN: 26534","subject":"MPA","courseNumber":"8200","CRN":"26534"},
{"label":"MPA 8299 - 031 TOP:Effective Public Affairs CRN: 26553","subject":"MPA","courseNumber":"8299","CRN":"26553"},
{"label":"MPA 8299 - 032 TOP:PublicBudgeting Gvt & NPOs CRN: 26554","subject":"MPA","courseNumber":"8299","CRN":"26554"},
{"label":"MPA 8400 - D21 Strategic Planning CRN: 26535","subject":"MPA","courseNumber":"8400","CRN":"26535"},
{"label":"MPA 8500 - D11 Effective City Management CRN: 26508","subject":"MPA","courseNumber":"8500","CRN":"26508"},
{"label":"MPA 8550 - 031 Urban Politics and Government CRN: 26555","subject":"MPA","courseNumber":"8550","CRN":"26555"},
{"label":"MPA 8600 - D21 Effective Non-Prof Mgmt CRN: 26536","subject":"MPA","courseNumber":"8600","CRN":"26536"},
{"label":"MPA 8700 - D11 Fundraising for Non-Prof Org CRN: 26509","subject":"MPA","courseNumber":"8700","CRN":"26509"},
{"label":"MPA 8800 - 031 Managing Public Networks CRN: 26556","subject":"MPA","courseNumber":"8800","CRN":"26556"},
{"label":"PA 3000 - 001 Overview of Non-Profit Sector CRN: 25580","subject":"PA","courseNumber":"3000","CRN":"25580"},
{"label":"PA 5100 - 001 Independent Study CRN: 25582","subject":"PA","courseNumber":"5100","CRN":"25582"},
{"label":"PA 6100 - 001 PSA Internship CRN: 25583","subject":"PA","courseNumber":"6100","CRN":"25583"},
{"label":"RES 2250 - 001 Real Estate Law CRN: 25848","subject":"RES","courseNumber":"2250","CRN":"25848"},
{"label":"RES 2250 - 002 Real Estate Law CRN: 25849","subject":"RES","courseNumber":"2250","CRN":"25849"},
{"label":"RES 3150 - 002 Real Estate Investments CRN: 25852","subject":"RES","courseNumber":"3150","CRN":"25852"},
{"label":"RES 2150 - 001 Real Estate Fundamentals CRN: 25845","subject":"RES","courseNumber":"2150","CRN":"25845"},
{"label":"RES 2150 - 002 Real Estate Fundamentals CRN: 25846","subject":"RES","courseNumber":"2150","CRN":"25846"},
{"label":"RES 2150 - 003 Real Estate Fundamentals CRN: 25847","subject":"RES","courseNumber":"2150","CRN":"25847"},
{"label":"RES 3001 - 001 Real Estate Internship CRN: 25850","subject":"RES","courseNumber":"3001","CRN":"25850"},
{"label":"RES 3150 - 001 Real Estate Investments CRN: 25851","subject":"RES","courseNumber":"3150","CRN":"25851"},
{"label":"RES 3150 - 003 Real Estate Investments CRN: 26837","subject":"RES","courseNumber":"3150","CRN":"26837"},
{"label":"RES 3250 - 001 Advanced Real Estate Modeling CRN: 25853","subject":"RES","courseNumber":"3250","CRN":"25853"},
{"label":"RES 4150 - 001 Real Estate Development CRN: 25855","subject":"RES","courseNumber":"4150","CRN":"25855"},
{"label":"RUS 1111 - 001 Basic Russian I CRN: 25856","subject":"RUS","courseNumber":"1111","CRN":"25856"},
{"label":"RUS 1111 - 002 Basic Russian I CRN: 25857","subject":"RUS","courseNumber":"1111","CRN":"25857"},
{"label":"RUS 1111 - 003 Basic Russian I CRN: 25858","subject":"RUS","courseNumber":"1111","CRN":"25858"},
{"label":"RUS 1111 - 004 Basic Russian I CRN: 25859","subject":"RUS","courseNumber":"1111","CRN":"25859"},
{"label":"RUS 1123 - 001 Intermediate Russian I CRN: 25860","subject":"RUS","courseNumber":"1123","CRN":"25860"},
{"label":"RUS 1131 - 001 Conversation Composition CRN: 25861","subject":"RUS","courseNumber":"1131","CRN":"25861"},
{"label":"RUS 5900 - 001 Russian:Independent Study CRN: 25862","subject":"RUS","courseNumber":"5900","CRN":"25862"},
{"label":"THL 5005 - 006 THM:Russian Religious Cultures CRN: 26137","subject":"RAS","courseNumber":"5005","CRN":"26137"},
{"label":"VSB 2000 - 001 Backpack-to-Briefcase:SO Sem CRN: 26207","subject":"VSB","courseNumber":"2000","CRN":"26207"},
{"label":"VSB 2000 - 002 Backpack-to-Briefcase:SO Sem CRN: 26208","subject":"VSB","courseNumber":"2000","CRN":"26208"},
{"label":"VSB 2000 - 003 Backpack-to-Briefcase:SO Sem CRN: 26209","subject":"VSB","courseNumber":"2000","CRN":"26209"},
{"label":"VSB 2000 - 004 Backpack-to-Briefcase:SO Sem CRN: 26210","subject":"VSB","courseNumber":"2000","CRN":"26210"},
{"label":"VSB 2000 - 005 Backpack-to-Briefcase:SO Sem CRN: 26211","subject":"VSB","courseNumber":"2000","CRN":"26211"},
{"label":"VSB 2000 - 006 Backpack-to-Briefcase:SO Sem CRN: 26212","subject":"VSB","courseNumber":"2000","CRN":"26212"},
{"label":"VSB 2000 - 007 Backpack-to-Briefcase:SO Sem CRN: 26213","subject":"VSB","courseNumber":"2000","CRN":"26213"},
{"label":"VSB 2000 - 008 Backpack-to-Briefcase:SO Sem CRN: 26214","subject":"VSB","courseNumber":"2000","CRN":"26214"},
{"label":"VSB 2000 - 011 Backpack-to-Briefcase:SO Sem CRN: 26217","subject":"VSB","courseNumber":"2000","CRN":"26217"},
{"label":"VSB 2000 - 013 Backpack-to-Briefcase:SO Sem CRN: 26219","subject":"VSB","courseNumber":"2000","CRN":"26219"},
{"label":"VSB 2000 - 100 Backpack-to-Briefcase:SO Sem CRN: 26220","subject":"VSB","courseNumber":"2000","CRN":"26220"},
{"label":"VSB 2006 - 002 Introduction to MIS CRN: 26223","subject":"VSB","courseNumber":"2006","CRN":"26223"},
{"label":"VSB 2006 - 003 Introduction to MIS CRN: 26224","subject":"VSB","courseNumber":"2006","CRN":"26224"},
{"label":"VSB 2006 - 004 Introduction to MIS CRN: 26225","subject":"VSB","courseNumber":"2006","CRN":"26225"},
{"label":"VSB 2006 - 005 Introduction to MIS CRN: 26226","subject":"VSB","courseNumber":"2006","CRN":"26226"},
{"label":"VSB 2006 - 006 Introduction to MIS CRN: 26227","subject":"VSB","courseNumber":"2006","CRN":"26227"},
{"label":"VSB 2006 - 009 Introduction to MIS CRN: 26229","subject":"VSB","courseNumber":"2006","CRN":"26229"},
{"label":"VSB 2007 - 003 Corp Respon & Regulation CRN: 26234","subject":"VSB","courseNumber":"2007","CRN":"26234"},
{"label":"VSB 2007 - 006 Corp Respon & Regulation CRN: 26237","subject":"VSB","courseNumber":"2007","CRN":"26237"},
{"label":"VSB 2008 - 005 Business Analytics CRN: 26248","subject":"VSB","courseNumber":"2008","CRN":"26248"},
{"label":"VSB 2008 - 006 Business Analytics CRN: 26249","subject":"VSB","courseNumber":"2008","CRN":"26249"},
{"label":"VSB 2009 - 001 Principles of Finance CRN: 26252","subject":"VSB","courseNumber":"2009","CRN":"26252"},
{"label":"VSB 2009 - 002 Principles of Finance CRN: 26253","subject":"VSB","courseNumber":"2009","CRN":"26253"},
{"label":"VSB 2009 - 003 Principles of Finance CRN: 26254","subject":"VSB","courseNumber":"2009","CRN":"26254"},
{"label":"VSB 2009 - 004 Principles of Finance CRN: 26255","subject":"VSB","courseNumber":"2009","CRN":"26255"},
{"label":"VSB 2009 - 005 Principles of Finance CRN: 26256","subject":"VSB","courseNumber":"2009","CRN":"26256"},
{"label":"VSB 2009 - 006 Principles of Finance CRN: 26257","subject":"VSB","courseNumber":"2009","CRN":"26257"},
{"label":"VSB 2009 - 007 Principles of Finance CRN: 26258","subject":"VSB","courseNumber":"2009","CRN":"26258"},
{"label":"VSB 2009 - 008 Principles of Finance CRN: 26259","subject":"VSB","courseNumber":"2009","CRN":"26259"},
{"label":"VSB 2009 - 009 Principles of Finance CRN: 26260","subject":"VSB","courseNumber":"2009","CRN":"26260"},
{"label":"VSB 2009 - 010 Principles of Finance CRN: 26261","subject":"VSB","courseNumber":"2009","CRN":"26261"},
{"label":"VSB 2009 - 012 Principles of Finance CRN: 26263","subject":"VSB","courseNumber":"2009","CRN":"26263"},
{"label":"VSB 2014 - 001 Principles of Managerial Acct CRN: 26265","subject":"VSB","courseNumber":"2014","CRN":"26265"},
{"label":"VSB 2014 - 002 Principles of Managerial Acct CRN: 26266","subject":"VSB","courseNumber":"2014","CRN":"26266"},
{"label":"VSB 2014 - 003 Principles of Managerial Acct CRN: 26267","subject":"VSB","courseNumber":"2014","CRN":"26267"},
{"label":"VSB 2014 - 004 Principles of Managerial Acct CRN: 26268","subject":"VSB","courseNumber":"2014","CRN":"26268"},
{"label":"VSB 2014 - 005 Principles of Managerial Acct CRN: 26269","subject":"VSB","courseNumber":"2014","CRN":"26269"},
{"label":"VSB 2014 - 006 Principles of Managerial Acct CRN: 26270","subject":"VSB","courseNumber":"2014","CRN":"26270"},
{"label":"VSB 2014 - 007 Principles of Managerial Acct CRN: 26271","subject":"VSB","courseNumber":"2014","CRN":"26271"},
{"label":"VSB 2014 - 008 Principles of Managerial Acct CRN: 26272","subject":"VSB","courseNumber":"2014","CRN":"26272"},
{"label":"VSB 2014 - 009 Principles of Managerial Acct CRN: 26273","subject":"VSB","courseNumber":"2014","CRN":"26273"},
{"label":"VSB 2014 - 010 Principles of Managerial Acct CRN: 26274","subject":"VSB","courseNumber":"2014","CRN":"26274"},
{"label":"VSB 2014 - 011 Principles of Managerial Acct CRN: 26275","subject":"VSB","courseNumber":"2014","CRN":"26275"},
{"label":"VSB 2020 - 002 Competitive Effectiveness CRN: 26280","subject":"VSB","courseNumber":"2020","CRN":"26280"},
{"label":"VSB 2020 - 003 Competitive Effectiveness CRN: 26281","subject":"VSB","courseNumber":"2020","CRN":"26281"},
{"label":"VSB 2020 - 004 Competitive Effectiveness CRN: 26282","subject":"VSB","courseNumber":"2020","CRN":"26282"},
{"label":"VSB 3000 - 001 Backpack-to-Briefcase:JR Sem CRN: 26284","subject":"VSB","courseNumber":"3000","CRN":"26284"},
{"label":"VSB 3000 - 002 Backpack-to-Briefcase:JR Sem CRN: 26285","subject":"VSB","courseNumber":"3000","CRN":"26285"},
{"label":"VSB 3000 - 003 Backpack-to-Briefcase:JR Sem CRN: 26286","subject":"VSB","courseNumber":"3000","CRN":"26286"},
{"label":"VSB 3000 - 004 Backpack-to-Briefcase:JR Sem CRN: 26287","subject":"VSB","courseNumber":"3000","CRN":"26287"},
{"label":"VSB 3000 - 005 Backpack-to-Briefcase:JR Sem CRN: 26288","subject":"VSB","courseNumber":"3000","CRN":"26288"},
{"label":"VSB 3000 - 100 Backpack-to-Briefcase:JR Sem CRN: 26289","subject":"VSB","courseNumber":"3000","CRN":"26289"},
{"label":"VSB 3008 - 002 Operations&Supply Chain Mgmt CRN: 26291","subject":"VSB","courseNumber":"3008","CRN":"26291"},
{"label":"VSB 3008 - 003 Operations&Supply Chain Mgmt CRN: 26292","subject":"VSB","courseNumber":"3008","CRN":"26292"},
{"label":"VSB 3008 - 008 Operations&Supply Chain Mgmt CRN: 26297","subject":"VSB","courseNumber":"3008","CRN":"26297"},
{"label":"VSB 4002 - 001 Strategic Think & Implem CRN: 26302","subject":"VSB","courseNumber":"4002","CRN":"26302"},
{"label":"VSB 4002 - 003 Strategic Think & Implem CRN: 26304","subject":"VSB","courseNumber":"4002","CRN":"26304"},
{"label":"VSB 4002 - 004 Strategic Think & Implem CRN: 26305","subject":"VSB","courseNumber":"4002","CRN":"26305"},
{"label":"VSB 4002 - 005 Strategic Think & Implem CRN: 26306","subject":"VSB","courseNumber":"4002","CRN":"26306"},
{"label":"VSB 0099 - 001 B2B:FR Experience CRN: 26175","subject":"VSB","courseNumber":"0099","CRN":"26175"},
{"label":"VSB 0099 - 002 B2B:FR Experience CRN: 26176","subject":"VSB","courseNumber":"0099","CRN":"26176"},
{"label":"VSB 1000 - DL1 Information Technology CRN: 26177","subject":"VSB","courseNumber":"1000","CRN":"26177"},
{"label":"VSB 1000 - DL2 Information Technology CRN: 26178","subject":"VSB","courseNumber":"1000","CRN":"26178"},
{"label":"VSB 1000 - DL3 Information Technology CRN: 26179","subject":"VSB","courseNumber":"1000","CRN":"26179"},
{"label":"VSB 1000 - DL4 Information Technology CRN: 26180","subject":"VSB","courseNumber":"1000","CRN":"26180"},
{"label":"VSB 1000 - DL5 Information Technology CRN: 26181","subject":"VSB","courseNumber":"1000","CRN":"26181"},
{"label":"VSB 1000 - DL6 Information Technology CRN: 26182","subject":"VSB","courseNumber":"1000","CRN":"26182"},
{"label":"VSB 1000 - DL7 Information Technology CRN: 26183","subject":"VSB","courseNumber":"1000","CRN":"26183"},
{"label":"VSB 1000 - DL8 Information Technology CRN: 26184","subject":"VSB","courseNumber":"1000","CRN":"26184"},
{"label":"VSB 1000 - DL9 Information Technology CRN: 26185","subject":"VSB","courseNumber":"1000","CRN":"26185"},
{"label":"VSB 1000 - VSB SB Info Tech Placeholder CRN: 26621","subject":"VSB","courseNumber":"1000","CRN":"26621"},
{"label":"VSB 1015 - 001 Business Dynamics CRN: 26186","subject":"VSB","courseNumber":"1015","CRN":"26186"},
{"label":"VSB 1015 - 002 Business Dynamics CRN: 26187","subject":"VSB","courseNumber":"1015","CRN":"26187"},
{"label":"VSB 1015 - 003 Business Dynamics CRN: 26188","subject":"VSB","courseNumber":"1015","CRN":"26188"},
{"label":"VSB 1015 - 004 Business Dynamics CRN: 26189","subject":"VSB","courseNumber":"1015","CRN":"26189"},
{"label":"VSB 1015 - 005 Business Dynamics CRN: 26190","subject":"VSB","courseNumber":"1015","CRN":"26190"},
{"label":"VSB 1015 - 006 Business Dynamics CRN: 26191","subject":"VSB","courseNumber":"1015","CRN":"26191"},
{"label":"VSB 1015 - 007 Business Dynamics CRN: 26192","subject":"VSB","courseNumber":"1015","CRN":"26192"},
{"label":"VSB 1015 - 008 Business Dynamics CRN: 26193","subject":"VSB","courseNumber":"1015","CRN":"26193"},
{"label":"VSB 1015 - 009 Business Dynamics CRN: 26194","subject":"VSB","courseNumber":"1015","CRN":"26194"},
{"label":"VSB 1015 - 010 Business Dynamics CRN: 26195","subject":"VSB","courseNumber":"1015","CRN":"26195"},
{"label":"VSB 1015 - 011 Business Dynamics CRN: 26196","subject":"VSB","courseNumber":"1015","CRN":"26196"},
{"label":"VSB 1015 - 012 Business Dynamics CRN: 26197","subject":"VSB","courseNumber":"1015","CRN":"26197"},
{"label":"VSB 1015 - 013 Business Dynamics CRN: 26198","subject":"VSB","courseNumber":"1015","CRN":"26198"},
{"label":"VSB 1015 - 014 Business Dynamics CRN: 26199","subject":"VSB","courseNumber":"1015","CRN":"26199"},
{"label":"VSB 1015 - 015 Business Dynamics CRN: 26200","subject":"VSB","courseNumber":"1015","CRN":"26200"},
{"label":"VSB 1015 - 016 Business Dynamics CRN: 26201","subject":"VSB","courseNumber":"1015","CRN":"26201"},
{"label":"VSB 1015 - 017 Business Dynamics CRN: 26202","subject":"VSB","courseNumber":"1015","CRN":"26202"},
{"label":"VSB 1015 - 018 Business Dynamics CRN: 26203","subject":"VSB","courseNumber":"1015","CRN":"26203"},
{"label":"VSB 1015 - 019 Business Dynamics CRN: 26204","subject":"VSB","courseNumber":"1015","CRN":"26204"},
{"label":"VSB 1015 - VSB Bus Dyn Placeholder CRN: 26620","subject":"VSB","courseNumber":"1015","CRN":"26620"},
{"label":"VSB 2000 - 009 Backpack-to-Briefcase:SO Sem CRN: 26215","subject":"VSB","courseNumber":"2000","CRN":"26215"},
{"label":"VSB 2000 - 010 Backpack-to-Briefcase:SO Sem CRN: 26216","subject":"VSB","courseNumber":"2000","CRN":"26216"},
{"label":"VSB 2000 - 012 Backpack-to-Briefcase:SO Sem CRN: 26218","subject":"VSB","courseNumber":"2000","CRN":"26218"},
{"label":"VSB 2000 - 014 Backpack-to-Briefcase:SO Sem CRN: 26652","subject":"VSB","courseNumber":"2000","CRN":"26652"},
{"label":"VSB 2004 - 001 Financial Accounting CRN: 26221","subject":"VSB","courseNumber":"2004","CRN":"26221"},
{"label":"VSB 2006 - 001 Introduction to MIS CRN: 26222","subject":"VSB","courseNumber":"2006","CRN":"26222"},
{"label":"VSB 2006 - 007 Introduction to MIS CRN: 26228","subject":"VSB","courseNumber":"2006","CRN":"26228"},
{"label":"VSB 2006 - 010 Introduction to MIS CRN: 26230","subject":"VSB","courseNumber":"2006","CRN":"26230"},
{"label":"VSB 2006 - 011 Introduction to MIS CRN: 26231","subject":"VSB","courseNumber":"2006","CRN":"26231"},
{"label":"VSB 2007 - 001 Corp Respon & Regulation CRN: 26232","subject":"VSB","courseNumber":"2007","CRN":"26232"},
{"label":"VSB 2007 - 002 Corp Respon & Regulation CRN: 26233","subject":"VSB","courseNumber":"2007","CRN":"26233"},
{"label":"VSB 2007 - 004 Corp Respon & Regulation CRN: 26235","subject":"VSB","courseNumber":"2007","CRN":"26235"},
{"label":"VSB 2007 - 005 Corp Respon & Regulation CRN: 26236","subject":"VSB","courseNumber":"2007","CRN":"26236"},
{"label":"VSB 2007 - 007 Corp Respon & Regulation CRN: 26238","subject":"VSB","courseNumber":"2007","CRN":"26238"},
{"label":"VSB 2007 - 008 Corp Respon & Regulation CRN: 26239","subject":"VSB","courseNumber":"2007","CRN":"26239"},
{"label":"VSB 2007 - 009 Corp Respon & Regulation CRN: 26240","subject":"VSB","courseNumber":"2007","CRN":"26240"},
{"label":"VSB 2007 - 010 Corp Respon & Regulation CRN: 26241","subject":"VSB","courseNumber":"2007","CRN":"26241"},
{"label":"VSB 2007 - 100 Corp Respon & Regulation CRN: 26242","subject":"VSB","courseNumber":"2007","CRN":"26242"},
{"label":"VSB 2008 - 001 Business Analytics CRN: 26244","subject":"VSB","courseNumber":"2008","CRN":"26244"},
{"label":"VSB 2008 - 002 Business Analytics CRN: 26245","subject":"VSB","courseNumber":"2008","CRN":"26245"},
{"label":"VSB 2008 - 003 Business Analytics CRN: 26246","subject":"VSB","courseNumber":"2008","CRN":"26246"},
{"label":"VSB 2008 - 004 Business Analytics CRN: 26247","subject":"VSB","courseNumber":"2008","CRN":"26247"},
{"label":"VSB 2008 - 007 Business Analytics CRN: 26250","subject":"VSB","courseNumber":"2008","CRN":"26250"},
{"label":"VSB 2009 - 011 Principles of Finance CRN: 26262","subject":"VSB","courseNumber":"2009","CRN":"26262"},
{"label":"VSB 2014 - 012 Principles of Managerial Acct CRN: 26276","subject":"VSB","courseNumber":"2014","CRN":"26276"},
{"label":"VSB 2020 - 001 Competitive Effectiveness CRN: 26279","subject":"VSB","courseNumber":"2020","CRN":"26279"},
{"label":"VSB 2020 - 005 Competitive Effectiveness CRN: 26283","subject":"VSB","courseNumber":"2020","CRN":"26283"},
{"label":"VSB 3008 - 001 Operations&Supply Chain Mgmt CRN: 26290","subject":"VSB","courseNumber":"3008","CRN":"26290"},
{"label":"VSB 3008 - 004 Operations&Supply Chain Mgmt CRN: 26293","subject":"VSB","courseNumber":"3008","CRN":"26293"},
{"label":"VSB 3008 - 005 Operations&Supply Chain Mgmt CRN: 26294","subject":"VSB","courseNumber":"3008","CRN":"26294"},
{"label":"VSB 3008 - 006 Operations&Supply Chain Mgmt CRN: 26295","subject":"VSB","courseNumber":"3008","CRN":"26295"},
{"label":"VSB 3008 - 007 Operations&Supply Chain Mgmt CRN: 26296","subject":"VSB","courseNumber":"3008","CRN":"26296"},
{"label":"VSB 3500 - 001 TOP:Race, Justice & Leadership CRN: 26298","subject":"VSB","courseNumber":"3500","CRN":"26298"},
{"label":"VSB 4000 - 100 B2B:SR LeaderAcad:Negotiations CRN: 26448","subject":"VSB","courseNumber":"4000","CRN":"26448"},
{"label":"VSB 4000 - 101 B2B:SR LeaderAcad:Negotiations CRN: 26449","subject":"VSB","courseNumber":"4000","CRN":"26449"},
{"label":"VSB 4000 - 102 B2B:SRLeaderAcad:Impact Comm I CRN: 26301","subject":"VSB","courseNumber":"4000","CRN":"26301"},
{"label":"VSB 4002 - 002 Strategic Think & Implem CRN: 26303","subject":"VSB","courseNumber":"4002","CRN":"26303"},
{"label":"SOC 1000 - 001 Intro to Sociology CRN: 25896","subject":"SOC","courseNumber":"1000","CRN":"25896"},
{"label":"SOC 1000 - 002 Intro to Sociology CRN: 25897","subject":"SOC","courseNumber":"1000","CRN":"25897"},
{"label":"SOC 1000 - 003 Intro to Sociology CRN: 25898","subject":"SOC","courseNumber":"1000","CRN":"25898"},
{"label":"SOC 1000 - 004 Intro to Sociology CRN: 25899","subject":"SOC","courseNumber":"1000","CRN":"25899"},
{"label":"SOC 1000 - 006 Intro to Sociology CRN: 25901","subject":"SOC","courseNumber":"1000","CRN":"25901"},
{"label":"SOC 1000 - 007 Intro to Sociology CRN: 25902","subject":"SOC","courseNumber":"1000","CRN":"25902"},
{"label":"SOC 1000 - 008 Intro to Sociology CRN: 25903","subject":"SOC","courseNumber":"1000","CRN":"25903"},
{"label":"SOC 1000 - 009 Intro to Sociology CRN: 25904","subject":"SOC","courseNumber":"1000","CRN":"25904"},
{"label":"SOC 1000 - 010 Intro to Sociology CRN: 26740","subject":"SOC","courseNumber":"1000","CRN":"26740"},
{"label":"SOC 3700 - 001 Urban Sociology CRN: 25911","subject":"SOC","courseNumber":"3700","CRN":"25911"},
{"label":"SOC 3800 - 001 Social Movements CRN: 25912","subject":"SOC","courseNumber":"3800","CRN":"25912"},
{"label":"SOC 1000 - 005 Intro to Sociology CRN: 25900","subject":"SOC","courseNumber":"1000","CRN":"25900"},
{"label":"SOC 2993 - 001 Internship CRN: 25906","subject":"SOC","courseNumber":"2993","CRN":"25906"},
{"label":"SOC 5050 - 001 Soc Theory & Public Policy CRN: 25914","subject":"SOC","courseNumber":"5050","CRN":"25914"},
{"label":"SOC 6000 - 001 Sociology Independent Study CRN: 25918","subject":"SOC","courseNumber":"6000","CRN":"25918"},
{"label":"SPA 1121 - 012 Intermediate Spanish I CRN: 25936","subject":"SPA","courseNumber":"1121","CRN":"25936"},
{"label":"SPA 1122 - 002 Intermediate Spanish II CRN: 25949","subject":"SPA","courseNumber":"1122","CRN":"25949"},
{"label":"SPA 1131 - 004 Conversation & Composition I CRN: 25962","subject":"SPA","courseNumber":"1131","CRN":"25962"},
{"label":"SPA 3254 - 001 Spanish Lyric Tradition CRN: 25976","subject":"SPA","courseNumber":"3254","CRN":"25976"},
{"label":"SPA 3412 - 002 TOP:Medieval Folktale CRN: 25977","subject":"SPA","courseNumber":"3412","CRN":"25977"},
{"label":"SPA 1111 - 001 Introductory Spanish I CRN: 25919","subject":"SPA","courseNumber":"1111","CRN":"25919"},
{"label":"SPA 1111 - 002 Introductory Spanish I CRN: 25920","subject":"SPA","courseNumber":"1111","CRN":"25920"},
{"label":"SPA 1112 - 001 Introductory Spanish II CRN: 25921","subject":"SPA","courseNumber":"1112","CRN":"25921"},
{"label":"SPA 1112 - 002 Introductory Spanish II CRN: 25922","subject":"SPA","courseNumber":"1112","CRN":"25922"},
{"label":"SPA 1112 - 003 Introductory Spanish II CRN: 25923","subject":"SPA","courseNumber":"1112","CRN":"25923"},
{"label":"SPA 1112 - 004 Introductory Spanish II CRN: 25924","subject":"SPA","courseNumber":"1112","CRN":"25924"},
{"label":"SPA 1121 - 001 Intermediate Spanish I CRN: 25925","subject":"SPA","courseNumber":"1121","CRN":"25925"},
{"label":"SPA 1121 - 002 Intermediate Spanish I CRN: 25926","subject":"SPA","courseNumber":"1121","CRN":"25926"},
{"label":"SPA 1121 - 003 Intermediate Spanish I CRN: 25927","subject":"SPA","courseNumber":"1121","CRN":"25927"},
{"label":"SPA 1121 - 004 Intermediate Spanish I CRN: 25928","subject":"SPA","courseNumber":"1121","CRN":"25928"},
{"label":"SPA 1121 - 005 Intermediate Spanish I CRN: 25929","subject":"SPA","courseNumber":"1121","CRN":"25929"},
{"label":"SPA 1121 - 006 Intermediate Spanish I CRN: 25930","subject":"SPA","courseNumber":"1121","CRN":"25930"},
{"label":"SPA 1121 - 007 Intermediate Spanish I CRN: 25931","subject":"SPA","courseNumber":"1121","CRN":"25931"},
{"label":"SPA 1121 - 008 Intermediate Spanish I CRN: 25932","subject":"SPA","courseNumber":"1121","CRN":"25932"},
{"label":"SPA 1121 - 009 Intermediate Spanish I CRN: 25933","subject":"SPA","courseNumber":"1121","CRN":"25933"},
{"label":"SPA 1121 - 010 Intermediate Spanish I CRN: 25934","subject":"SPA","courseNumber":"1121","CRN":"25934"},
{"label":"SPA 1121 - 011 Intermediate Spanish I CRN: 25935","subject":"SPA","courseNumber":"1121","CRN":"25935"},
{"label":"SPA 1121 - 013 Intermediate Spanish I CRN: 25937","subject":"SPA","courseNumber":"1121","CRN":"25937"},
{"label":"SPA 1121 - 014 Intermediate Spanish I CRN: 25938","subject":"SPA","courseNumber":"1121","CRN":"25938"},
{"label":"SPA 1121 - 015 Intermediate Spanish I CRN: 25939","subject":"SPA","courseNumber":"1121","CRN":"25939"},
{"label":"SPA 1121 - 016 Intermediate Spanish I CRN: 25940","subject":"SPA","courseNumber":"1121","CRN":"25940"},
{"label":"SPA 1121 - 017 Intermediate Spanish I CRN: 25941","subject":"SPA","courseNumber":"1121","CRN":"25941"},
{"label":"SPA 1121 - 018 Intermediate Spanish I CRN: 25942","subject":"SPA","courseNumber":"1121","CRN":"25942"},
{"label":"SPA 1121 - 019 Intermediate Spanish I CRN: 25943","subject":"SPA","courseNumber":"1121","CRN":"25943"},
{"label":"SPA 1121 - 020 Intermediate Spanish I CRN: 25944","subject":"SPA","courseNumber":"1121","CRN":"25944"},
{"label":"SPA 1121 - 021 Intermediate Spanish I CRN: 25945","subject":"SPA","courseNumber":"1121","CRN":"25945"},
{"label":"SPA 1121 - 022 Intermediate Spanish I CRN: 25946","subject":"SPA","courseNumber":"1121","CRN":"25946"},
{"label":"SPA 1121 - 023 Intermediate Spanish I CRN: 25947","subject":"SPA","courseNumber":"1121","CRN":"25947"},
{"label":"SPA 1122 - 001 Intermediate Spanish II CRN: 25948","subject":"SPA","courseNumber":"1122","CRN":"25948"},
{"label":"SPA 1122 - 003 Intermediate Spanish II CRN: 25950","subject":"SPA","courseNumber":"1122","CRN":"25950"},
{"label":"SPA 1122 - 004 Intermediate Spanish II CRN: 25951","subject":"SPA","courseNumber":"1122","CRN":"25951"},
{"label":"SPA 1122 - 005 Intermediate Spanish II CRN: 25952","subject":"SPA","courseNumber":"1122","CRN":"25952"},
{"label":"SPA 1122 - 006 Intermediate Spanish II CRN: 25953","subject":"SPA","courseNumber":"1122","CRN":"25953"},
{"label":"SPA 1122 - 007 Intermediate Spanish II CRN: 25954","subject":"SPA","courseNumber":"1122","CRN":"25954"},
{"label":"SPA 1122 - 008 Intermediate Spanish II CRN: 25955","subject":"SPA","courseNumber":"1122","CRN":"25955"},
{"label":"SPA 1122 - 009 Intermediate Spanish II CRN: 25956","subject":"SPA","courseNumber":"1122","CRN":"25956"},
{"label":"SPA 1125 - 001 Exp Lrn in Spanish Spk Phila CRN: 25957","subject":"SPA","courseNumber":"1125","CRN":"25957"},
{"label":"SPA 1130 - 001 Span. for Heritage Speakers I CRN: 25958","subject":"SPA","courseNumber":"1130","CRN":"25958"},
{"label":"SPA 1131 - 001 Conversation & Composition I CRN: 25959","subject":"SPA","courseNumber":"1131","CRN":"25959"},
{"label":"SPA 1131 - 002 Conversation & Composition I CRN: 25960","subject":"SPA","courseNumber":"1131","CRN":"25960"},
{"label":"SPA 1131 - 003 Conversation & Composition I CRN: 25961","subject":"SPA","courseNumber":"1131","CRN":"25961"},
{"label":"SPA 1131 - 005 Conversation & Composition I CRN: 25963","subject":"SPA","courseNumber":"1131","CRN":"25963"},
{"label":"SPA 1131 - 006 Conversation & Composition I CRN: 25964","subject":"SPA","courseNumber":"1131","CRN":"25964"},
{"label":"SPA 1131 - 007 Conversation & Composition I CRN: 25965","subject":"SPA","courseNumber":"1131","CRN":"25965"},
{"label":"SPA 1132 - 001 Conversation & Composition II CRN: 25966","subject":"SPA","courseNumber":"1132","CRN":"25966"},
{"label":"SPA 1132 - 002 Conversation & Composition II CRN: 25967","subject":"SPA","courseNumber":"1132","CRN":"25967"},
{"label":"SPA 1132 - 003 Conversation & Composition II CRN: 25968","subject":"SPA","courseNumber":"1132","CRN":"25968"},
{"label":"SPA 1138 - 001 Advanced Spanish CRN: 25969","subject":"SPA","courseNumber":"1138","CRN":"25969"},
{"label":"SPA 1140 - 001 Writing & Stylistics in Span. CRN: 25970","subject":"SPA","courseNumber":"1140","CRN":"25970"},
{"label":"SPA 2220 - 001 Literature & Culture of Spain CRN: 25971","subject":"SPA","courseNumber":"2220","CRN":"25971"},
{"label":"SPA 2220 - 002 Literature & Culture of Spain CRN: 25972","subject":"SPA","courseNumber":"2220","CRN":"25972"},
{"label":"SPA 3970 - 001 Research Seminar CRN: 25978","subject":"SPA","courseNumber":"3970","CRN":"25978"},
{"label":"SAR 2010 - 100 Intro to Calligraphy CRN: 25863","subject":"SAR","courseNumber":"2010","CRN":"25863"},
{"label":"SAR 2010 - 101 Intro to Calligraphy CRN: 25864","subject":"SAR","courseNumber":"2010","CRN":"25864"},
{"label":"SAR 2020 - 001 Basic Watercolor Techniques CRN: 25865","subject":"SAR","courseNumber":"2020","CRN":"25865"},
{"label":"SAR 2020 - 100 Basic Watercolor Techniques CRN: 25866","subject":"SAR","courseNumber":"2020","CRN":"25866"},
{"label":"SAR 2021 - 001 Basic Drawing Techniques CRN: 25867","subject":"SAR","courseNumber":"2021","CRN":"25867"},
{"label":"SAR 2021 - 002 Basic Drawing Techniques CRN: 25868","subject":"SAR","courseNumber":"2021","CRN":"25868"},
{"label":"SAR 2021 - 003 Basic Drawing Techniques CRN: 25869","subject":"SAR","courseNumber":"2021","CRN":"25869"},
{"label":"SAR 2021 - 100 Basic Drawing Techniques CRN: 25870","subject":"SAR","courseNumber":"2021","CRN":"25870"},
{"label":"SAR 2022 - 100 Basic Oil Painting CRN: 25871","subject":"SAR","courseNumber":"2022","CRN":"25871"},
{"label":"SAR 2100 - 001 Music Theory I CRN: 25872","subject":"SAR","courseNumber":"2100","CRN":"25872"},
{"label":"SAR 2113 - 002 The Charm of Music CRN: 25875","subject":"SAR","courseNumber":"2113","CRN":"25875"},
{"label":"SAR 2114 - 001 Songwriting: Words & Music CRN: 25876","subject":"SAR","courseNumber":"2114","CRN":"25876"},
{"label":"SAR 2119 - 001 Jazz:America's Music CRN: 25877","subject":"SAR","courseNumber":"2119","CRN":"25877"},
{"label":"SAR 3031 - 100 TOP:Fashion in Costume Design CRN: 25878","subject":"SAR","courseNumber":"3031","CRN":"25878"},
{"label":"SAR 3031 - 102 TOP:Digital Photography CRN: 25879","subject":"SAR","courseNumber":"3031","CRN":"25879"},
{"label":"SAR 5004 - 001 Basic Printmaking CRN: 25880","subject":"SAR","courseNumber":"5004","CRN":"25880"},
{"label":"SAR 2110 - 001 TOP:History of Western Music  CRN: 25873","subject":"SAR","courseNumber":"2110","CRN":"25873"},
{"label":"SAR 2113 - 001 The Charm of Music CRN: 25874","subject":"SAR","courseNumber":"2113","CRN":"25874"},
{"label":"SUSE 2110 - 001 Sus Eng: Risk & Opportunities CRN: 26021","subject":"SUSE","courseNumber":"2110","CRN":"26021"},
{"label":"SUSE 7110 - 001 Fundamentals-Sustainable Engr CRN: 26022","subject":"SUSE","courseNumber":"7110","CRN":"26022"},
{"label":"SUSE 7110 - DL1 Fundamentals-Sustainable Engr CRN: 26023","subject":"SUSE","courseNumber":"7110","CRN":"26023"},
{"label":"SUSE 7112 - 001 Econ/Social Equity Integrators CRN: 26024","subject":"SUSE","courseNumber":"7112","CRN":"26024"},
{"label":"SUSE 7112 - DL1 Econ/Social Equity Integrators CRN: 26025","subject":"SUSE","courseNumber":"7112","CRN":"26025"},
{"label":"SUSE 7113 - 001 Sustainable Materials & Design CRN: 26026","subject":"SUSE","courseNumber":"7113","CRN":"26026"},
{"label":"SUSE 7113 - DL1 Sustainable Materials & Design CRN: 26027","subject":"SUSE","courseNumber":"7113","CRN":"26027"},
{"label":"SUSE 7120 - 001 Intro to Sus Eng for Intl Dev CRN: 26028","subject":"SUSE","courseNumber":"7120","CRN":"26028"},
{"label":"SUSE 7120 - DL1 Intro to Sus Eng for Intl Dev CRN: 26029","subject":"SUSE","courseNumber":"7120","CRN":"26029"},
{"label":"SUSE 8111 - 001 ADV LCA & Intro to Prod Design CRN: 26030","subject":"SUSE","courseNumber":"8111","CRN":"26030"},
{"label":"SUSE 8111 - DL1 ADV LCA & Intro to Prod Design CRN: 26031","subject":"SUSE","courseNumber":"8111","CRN":"26031"},
{"label":"SUSE 8132 - 001 GHG Mitigation-Strategy&Tech CRN: 26032","subject":"SUSE","courseNumber":"8132","CRN":"26032"},
{"label":"SUSE 8132 - DL1 GHG Mitigation-Strategy&Tech CRN: 26033","subject":"SUSE","courseNumber":"8132","CRN":"26033"},
{"label":"SUSE 9020 - 001 Sust Eng Independent Study CRN: 26034","subject":"SUSE","courseNumber":"9020","CRN":"26034"},
{"label":"SUSE 9024 - 001 Sust Eng Thesis I CRN: 26035","subject":"SUSE","courseNumber":"9024","CRN":"26035"},
{"label":"SUSE 9025 - 001 Sust Eng Thesis II CRN: 26036","subject":"SUSE","courseNumber":"9025","CRN":"26036"},
{"label":"SUSE 9080 - 001 Sust Eng Thesis Continuation CRN: 26037","subject":"SUSE","courseNumber":"9080","CRN":"26037"},
{"label":"SUSE 9085 - 001 Sust Eng Ind Study Contin CRN: 26038","subject":"SUSE","courseNumber":"9085","CRN":"26038"},
{"label":"SUSE 9095 - 001 Sust Engr - Graduate Practicum CRN: 26039","subject":"SUSE","courseNumber":"9095","CRN":"26039"},
{"label":"SUSE 9100 - 001 SUSE Seminar CRN: 26040","subject":"SUSE","courseNumber":"9100","CRN":"26040"},
{"label":"THE 2029 - 002 Fund Princ of Acting CRN: 26045","subject":"THE","courseNumber":"2029","CRN":"26045"},
{"label":"THE 2029 - 005 Fund Princ of Acting CRN: 26048","subject":"THE","courseNumber":"2029","CRN":"26048"},
{"label":"THE 2029 - 100 Fund Princ of Acting CRN: 26049","subject":"THE","courseNumber":"2029","CRN":"26049"},
{"label":"THE 2032 - 001 Elements of Dance CRN: 26051","subject":"THE","courseNumber":"2032","CRN":"26051"},
{"label":"THE 2032 - 002 Elements of Dance CRN: 26052","subject":"THE","courseNumber":"2032","CRN":"26052"},
{"label":"THE 2019 - 100 The Theatrical Expernce CRN: 26042","subject":"THE","courseNumber":"2019","CRN":"26042"},
{"label":"THE 2025 - 001 Improvisational Theatre CRN: 26043","subject":"THE","courseNumber":"2025","CRN":"26043"},
{"label":"THE 2029 - 001 Fund Princ of Acting CRN: 26044","subject":"THE","courseNumber":"2029","CRN":"26044"},
{"label":"THE 2029 - 003 Fund Princ of Acting CRN: 26046","subject":"THE","courseNumber":"2029","CRN":"26046"},
{"label":"THE 2029 - 004 Fund Princ of Acting CRN: 26047","subject":"THE","courseNumber":"2029","CRN":"26047"},
{"label":"THE 2030 - 001 Advan Princ of Acting CRN: 26050","subject":"THE","courseNumber":"2030","CRN":"26050"},
{"label":"THE 2040 - 001 Theatre Practicum CRN: 26053","subject":"THE","courseNumber":"2040","CRN":"26053"},
{"label":"THE 2041 - 001 Theatre Practicum CRN: 26717","subject":"THE","courseNumber":"2041","CRN":"26717"},
{"label":"THE 2051 - 001 Creativity CRN: 26054","subject":"THE","courseNumber":"2051","CRN":"26054"},
{"label":"THE 4011 - 001 Directing the Play CRN: 26056","subject":"THE","courseNumber":"4011","CRN":"26056"},
{"label":"THE 7100 - 001 Principles of Acting CRN: 26057","subject":"THE","courseNumber":"7100","CRN":"26057"},
{"label":"THE 7110 - 001 Script Analysis CRN: 26058","subject":"THE","courseNumber":"7110","CRN":"26058"},
{"label":"THE 7120 - 001 Dramaturgy-Classic CRN: 26059","subject":"THE","courseNumber":"7120","CRN":"26059"},
{"label":"THE 7140 - 001 Dramtc Vis & Frm Classic CRN: 26060","subject":"THE","courseNumber":"7140","CRN":"26060"},
{"label":"THE 7200 - 001 Production Practicum I CRN: 26061","subject":"THE","courseNumber":"7200","CRN":"26061"},
{"label":"THE 7210 - 001 Production Practicum II CRN: 26062","subject":"THE","courseNumber":"7210","CRN":"26062"},
{"label":"THE 7220 - 001 Production Practicum III CRN: 26063","subject":"THE","courseNumber":"7220","CRN":"26063"},
{"label":"THE 8130 - 001 Solo Performance CRN: 26064","subject":"THE","courseNumber":"8130","CRN":"26064"},
{"label":"THE 8170 - 001 Directing CRN: 26065","subject":"THE","courseNumber":"8170","CRN":"26065"},
{"label":"THE 9200 - 001 Spec Stud - Production CRN: 26066","subject":"THE","courseNumber":"9200","CRN":"26066"},
{"label":"THE 9210 - 001 Spec Stud - Research CRN: 26067","subject":"THE","courseNumber":"9210","CRN":"26067"},
{"label":"THE 9230 - 001 Spec Stud - Acting CRN: 26068","subject":"THE","courseNumber":"9230","CRN":"26068"},
{"label":"THE 9270 - 001 Spec Stud - Directing CRN: 26069","subject":"THE","courseNumber":"9270","CRN":"26069"},
{"label":"THE 9280 - 001 Spec Stud - Playwriting CRN: 26070","subject":"THE","courseNumber":"9280","CRN":"26070"},
{"label":"THL 1000 - 001 Faith, Reason & Culture CRN: 26071","subject":"THL","courseNumber":"1000","CRN":"26071"},
{"label":"THL 1000 - 003 Catholic Studies CRN: 26073","subject":"THL","courseNumber":"1000","CRN":"26073"},
{"label":"THL 1000 - 004 Faith, Reason & Culture CRN: 26074","subject":"THL","courseNumber":"1000","CRN":"26074"},
{"label":"THL 1000 - 005 Global Religious Experience CRN: 26075","subject":"THL","courseNumber":"1000","CRN":"26075"},
{"label":"THL 1000 - 006 Catholic Studies CRN: 26076","subject":"THL","courseNumber":"1000","CRN":"26076"},
{"label":"THL 1000 - 007 Catholic Studies CRN: 26077","subject":"THL","courseNumber":"1000","CRN":"26077"},
{"label":"THL 1000 - 008 Global Religious Experience CRN: 26078","subject":"THL","courseNumber":"1000","CRN":"26078"},
{"label":"THL 1000 - 009 Faith, Reason & Culture CRN: 26079","subject":"THL","courseNumber":"1000","CRN":"26079"},
{"label":"THL 1000 - 010 Faith, Reason & Culture CRN: 26080","subject":"THL","courseNumber":"1000","CRN":"26080"},
{"label":"THL 1000 - 011 Catholic Studies CRN: 26081","subject":"THL","courseNumber":"1000","CRN":"26081"},
{"label":"THL 1000 - 012 Catholic Studies CRN: 26082","subject":"THL","courseNumber":"1000","CRN":"26082"},
{"label":"THL 1000 - 013 Faith, Reason & Culture CRN: 26083","subject":"THL","courseNumber":"1000","CRN":"26083"},
{"label":"THL 1000 - 014 Faith, Reason & Culture CRN: 26084","subject":"THL","courseNumber":"1000","CRN":"26084"},
{"label":"THL 1000 - 015 Faith, Reason & Culture CRN: 26085","subject":"THL","courseNumber":"1000","CRN":"26085"},
{"label":"THL 1000 - 017 Faith, Reason & Culture CRN: 26087","subject":"THL","courseNumber":"1000","CRN":"26087"},
{"label":"THL 1000 - 018 Faith, Reason & Culture CRN: 26088","subject":"THL","courseNumber":"1000","CRN":"26088"},
{"label":"THL 1000 - 019 Faith, Reason & Culture CRN: 26089","subject":"THL","courseNumber":"1000","CRN":"26089"},
{"label":"THL 1000 - 020 Faith, Reason & Culture CRN: 26090","subject":"THL","courseNumber":"1000","CRN":"26090"},
{"label":"THL 1000 - 021 Global Religious Experience CRN: 26091","subject":"THL","courseNumber":"1000","CRN":"26091"},
{"label":"THL 1000 - 023 Faith, Reason, and Culture CRN: 26093","subject":"THL","courseNumber":"1000","CRN":"26093"},
{"label":"THL 1000 - 024 Catholic Studies CRN: 26094","subject":"THL","courseNumber":"1000","CRN":"26094"},
{"label":"THL 1000 - 026 Catholic Studies CRN: 26096","subject":"THL","courseNumber":"1000","CRN":"26096"},
{"label":"THL 1000 - 027 Catholic Studies CRN: 26097","subject":"THL","courseNumber":"1000","CRN":"26097"},
{"label":"THL 1000 - 028 Catholic Studies CRN: 26098","subject":"THL","courseNumber":"1000","CRN":"26098"},
{"label":"THL 1000 - 029 Faith, Reason & Culture CRN: 26099","subject":"THL","courseNumber":"1000","CRN":"26099"},
{"label":"THL 1000 - 031 Global Religious Experience CRN: 26101","subject":"THL","courseNumber":"1000","CRN":"26101"},
{"label":"THL 1000 - 032 Global Religious Experience CRN: 26102","subject":"THL","courseNumber":"1000","CRN":"26102"},
{"label":"THL 1000 - 034 Global Religious Experience CRN: 26104","subject":"THL","courseNumber":"1000","CRN":"26104"},
{"label":"THL 1000 - 035 Catholic Studies CRN: 26105","subject":"THL","courseNumber":"1000","CRN":"26105"},
{"label":"THL 1000 - 036 Catholic Studies CRN: 26106","subject":"THL","courseNumber":"1000","CRN":"26106"},
{"label":"THL 1000 - 038 Faith, Reason, & Culture CRN: 26108","subject":"THL","courseNumber":"1000","CRN":"26108"},
{"label":"THL 1000 - 100 Faith, Reason, and Culture CRN: 26109","subject":"THL","courseNumber":"1000","CRN":"26109"},
{"label":"THL 2490 - 001 TOP:ExodusinAfro-AmRelExprsn CRN: 26114","subject":"THL","courseNumber":"2490","CRN":"26114"},
{"label":"THL 2900 - 001 THM:Catholics as Polit Leaders CRN: 26115","subject":"THL","courseNumber":"2900","CRN":"26115"},
{"label":"THL 3400 - 001 Sacraments CRN: 26116","subject":"THL","courseNumber":"3400","CRN":"26116"},
{"label":"THL 3790 - 001 THM:What Am I? God,Self&World CRN: 26117","subject":"THL","courseNumber":"3790","CRN":"26117"},
{"label":"THL 3790 - 002 THM:Mary/Saints:ACourseonGrace CRN: 26118","subject":"THL","courseNumber":"3790","CRN":"26118"},
{"label":"THL 4550 - 001 The Contemplative Tradition CRN: 26120","subject":"THL","courseNumber":"4550","CRN":"26120"},
{"label":"THL 4990 - 001 THM:Sports and Spirituality CRN: 26121","subject":"THL","courseNumber":"4990","CRN":"26121"},
{"label":"THL 4990 - 002 THM:Contemplate,Create.Repeat CRN: 26122","subject":"THL","courseNumber":"4990","CRN":"26122"},
{"label":"THL 5001 - 001 THM Presence/Absence of God CRN: 26830","subject":"THL","courseNumber":"5001","CRN":"26830"},
{"label":"THL 5004 - 004 THM:Tech,Relign&HumanPerson CRN: 26130","subject":"THL","courseNumber":"5004","CRN":"26130"},
{"label":"THL 5004 - DL1 THM:Popular Spirituality CRN: 26131","subject":"THL","courseNumber":"5004","CRN":"26131"},
{"label":"THL 5005 - 001 THM: Religs Tradtns ofMid East CRN: 26132","subject":"THL","courseNumber":"5005","CRN":"26132"},
{"label":"THL 5005 - 002 THM:Orientalism and Religion CRN: 26133","subject":"THL","courseNumber":"5005","CRN":"26133"},
{"label":"THL 5005 - 004 THM:Lived SouthAsian Religions CRN: 26135","subject":"THL","courseNumber":"5005","CRN":"26135"},
{"label":"THL 5005 - 005 THM: Bible as Literature CRN: 26136","subject":"THL","courseNumber":"5005","CRN":"26136"},
{"label":"THL 5410 - 100 THM: Theology & Film CRN: 26141","subject":"THL","courseNumber":"5410","CRN":"26141"},
{"label":"THL 6000 - 001 THM:PowerDynamicisnNewTest CRN: 26142","subject":"THL","courseNumber":"6000","CRN":"26142"},
{"label":"THL 6000 - 002 THM:Bible and Sustainability CRN: 26143","subject":"THL","courseNumber":"6000","CRN":"26143"},
{"label":"THL 8900 - 001 THM:God and Space-Time CRN: 26815","subject":"THL","courseNumber":"8900","CRN":"26815"},
{"label":"THL 9000 - 003 THM: Ethics and Trauma CRN: 26828","subject":"THL","courseNumber":"9000","CRN":"26828"},
{"label":"THL 1000 - 002 Catholic Studies CRN: 26072","subject":"THL","courseNumber":"1000","CRN":"26072"},
{"label":"THL 1000 - 016 Catholic Studies CRN: 26086","subject":"THL","courseNumber":"1000","CRN":"26086"},
{"label":"THL 1000 - 022 Global Religious Experience CRN: 26092","subject":"THL","courseNumber":"1000","CRN":"26092"},
{"label":"THL 1000 - 025 Global Religious Experience CRN: 26095","subject":"THL","courseNumber":"1000","CRN":"26095"},
{"label":"THL 1000 - 030 Faith, Reason & Culture CRN: 26100","subject":"THL","courseNumber":"1000","CRN":"26100"},
{"label":"THL 1000 - 033 Global Religious Experience CRN: 26103","subject":"THL","courseNumber":"1000","CRN":"26103"},
{"label":"THL 1000 - 037 Catholic Studies CRN: 26107","subject":"THL","courseNumber":"1000","CRN":"26107"},
{"label":"THL 1000 - EGR EG THL Placeholder CRN: 26611","subject":"THL","courseNumber":"1000","CRN":"26611"},
{"label":"THL 1000 - NUR NU THL Placeholder CRN: 26613","subject":"THL","courseNumber":"1000","CRN":"26613"},
{"label":"THL 5003 - 001 THM:Theology of Thomas Aquinas CRN: 26123","subject":"THL","courseNumber":"5003","CRN":"26123"},
{"label":"THL 5005 - 003 THM:AfricanPoliticalTheology CRN: 26134","subject":"THL","courseNumber":"5005","CRN":"26134"},
{"label":"THL 6200 - 001 Service Learning Practicum CRN: 26709","subject":"THL","courseNumber":"6200","CRN":"26709"},
{"label":"THL 6400 - 001 Independent Study CRN: 26144","subject":"THL","courseNumber":"6400","CRN":"26144"},
{"label":"THL 6400 - 002 Independent Study CRN: 26145","subject":"THL","courseNumber":"6400","CRN":"26145"},
{"label":"THL 6400 - 003 Independent Study CRN: 26146","subject":"THL","courseNumber":"6400","CRN":"26146"},
{"label":"THL 8000 - 001 Foundation SYS CRN: 26147","subject":"THL","courseNumber":"8000","CRN":"26147"},
{"label":"THL 8001 - 001 Foundation BIB CRN: 26148","subject":"THL","courseNumber":"8001","CRN":"26148"},
{"label":"THL 8003 - 001 Foundation SPI CRN: 26149","subject":"THL","courseNumber":"8003","CRN":"26149"},
{"label":"THL 8004 - 001 Foundation ETH CRN: 26150","subject":"THL","courseNumber":"8004","CRN":"26150"},
{"label":"THL 8101 - 001 THM: New Testament Ethics CRN: 26151","subject":"THL","courseNumber":"8101","CRN":"26151"},
{"label":"THL 8101 - DL1 THM: New Testament Ethics CRN: 26819","subject":"THL","courseNumber":"8101","CRN":"26819"},
{"label":"THL 8145 - 001 THM: Jewish Law CRN: 26152","subject":"THL","courseNumber":"8145","CRN":"26152"},
{"label":"THL 8210 - 001 THM:Church&LayMinistry Vat II CRN: 26153","subject":"THL","courseNumber":"8210","CRN":"26153"},
{"label":"THL 8290 - 001 THM: Queer Religions CRN: 26154","subject":"THL","courseNumber":"8290","CRN":"26154"},
{"label":"THL 8560 - 001 THM:EarlyModSpirtualitySurv CRN: 26155","subject":"THL","courseNumber":"8560","CRN":"26155"},
{"label":"THL 8701 - 001 Theological Pedagogy CRN: 26156","subject":"THL","courseNumber":"8701","CRN":"26156"},
{"label":"THL 8803 - 001 Intro to Counseling Skills CRN: 26157","subject":"THL","courseNumber":"8803","CRN":"26157"},
{"label":"THL 8840 - 001 THM:Human Grwth/Faith Developm CRN: 26158","subject":"THL","courseNumber":"8840","CRN":"26158"},
{"label":"THL 8883 - 001 Ministry Field Education 3 CRN: 26159","subject":"THL","courseNumber":"8883","CRN":"26159"},
{"label":"THL 8883 - 002 Ministry Field Education 3 CRN: 26160","subject":"THL","courseNumber":"8883","CRN":"26160"},
{"label":"THL 8940 - 001 Integrative Portfolio CRN: 26161","subject":"THL","courseNumber":"8940","CRN":"26161"},
{"label":"THL 8999 - 001 Capstone CRN: 26162","subject":"THL","courseNumber":"8999","CRN":"26162"},
{"label":"THL 9000 - 001 THM:HistoryofChristianSpirtlty CRN: 26813","subject":"THL","courseNumber":"9000","CRN":"26813"},
{"label":"THL 9000 - 002 THM:Ethics,Theories,andMethods CRN: 26814","subject":"THL","courseNumber":"9000","CRN":"26814"},
{"label":"THL 9050 - 001 Theories & Methods of Culture CRN: 26163","subject":"THL","courseNumber":"9050","CRN":"26163"},
{"label":"THL 9105 - 001 THM: Prophets in Hebrew Bible CRN: 26164","subject":"THL","courseNumber":"9105","CRN":"26164"},
{"label":"THL 9200 - 001 THM Theology CRN: 26165","subject":"THL","courseNumber":"9200","CRN":"26165"},
{"label":"THL 9300 - 001 THM: Racism & Christian Ethics CRN: 26166","subject":"THL","courseNumber":"9300","CRN":"26166"},
{"label":"THL 9305 - 001 Major Voices in Christian ETH CRN: 26167","subject":"THL","courseNumber":"9305","CRN":"26167"},
{"label":"THL 9425 - 001 Ancient/Medieval Christianity CRN: 26168","subject":"THL","courseNumber":"9425","CRN":"26168"},
{"label":"THL 9520 - 001 THM: Inventing the Holy CRN: 26169","subject":"THL","courseNumber":"9520","CRN":"26169"},
{"label":"THL 9900 - 001 Dissertation Writing CRN: 26170","subject":"THL","courseNumber":"9900","CRN":"26170"},
{"label":"THL 9910 - 001 Synthesis Portfolio CRN: 26171","subject":"THL","courseNumber":"9910","CRN":"26171"},
{"label":"THL 9911 - 001 Evaluation Portfolio CRN: 26172","subject":"THL","courseNumber":"9911","CRN":"26172"},
{"label":"THL 9920 - 001 Proposal Colloquium CRN: 26173","subject":"THL","courseNumber":"9920","CRN":"26173"},
{"label":"THL 9930 - 001 Dissertation Colloquium CRN: 26174","subject":"THL","courseNumber":"9930","CRN":"26174"},
{"label":"VEXP 0001 - CA1 Caritas Service Time CRN: 26799","subject":"VEXP","courseNumber":"0001","CRN":"26799"},
{"label":"VEXP 0001 - CA2 Caritas Service Time CRN: 26800","subject":"VEXP","courseNumber":"0001","CRN":"26800"},
{"label":"VEXP 0001 - CA3 Caritas Service Time CRN: 26801","subject":"VEXP","courseNumber":"0001","CRN":"26801"},
{"label":"VEXP 0001 - CA4 Caritas Service Time CRN: 26802","subject":"VEXP","courseNumber":"0001","CRN":"26802"},
{"label":"VEXP 1000 - AC1 Art & Culture Communitas CRN: 26769","subject":"VEXP","courseNumber":"1000","CRN":"26769"},
{"label":"VEXP 1000 - AC2 Art & Culture Communitas CRN: 26770","subject":"VEXP","courseNumber":"1000","CRN":"26770"},
{"label":"VEXP 1000 - CA1 Caritas Communitas CRN: 26796","subject":"VEXP","courseNumber":"1000","CRN":"26796"},
{"label":"VEXP 1000 - CA2 Caritas Communitas CRN: 26797","subject":"VEXP","courseNumber":"1000","CRN":"26797"},
{"label":"VEXP 1000 - CA3 Caritas Communitas CRN: 26798","subject":"VEXP","courseNumber":"1000","CRN":"26798"},
{"label":"VEXP 1000 - CR1 Creative Writing Communitas CRN: 26771","subject":"VEXP","courseNumber":"1000","CRN":"26771"},
{"label":"VEXP 1000 - EN1 Environmental Communitas CRN: 26772","subject":"VEXP","courseNumber":"1000","CRN":"26772"},
{"label":"VEXP 1000 - EN2 Environmental Communitas CRN: 26773","subject":"VEXP","courseNumber":"1000","CRN":"26773"},
{"label":"VEXP 1000 - FR1 Faith and Reason Communitas CRN: 26774","subject":"VEXP","courseNumber":"1000","CRN":"26774"},
{"label":"VEXP 1000 - GL1 Global Perspective Communita CRN: 26775","subject":"VEXP","courseNumber":"1000","CRN":"26775"},
{"label":"VEXP 1000 - GL2 Global Perspective Communita CRN: 26776","subject":"VEXP","courseNumber":"1000","CRN":"26776"},
{"label":"VEXP 1000 - SI1 Sci, Inquiry, Self Communitas CRN: 26777","subject":"VEXP","courseNumber":"1000","CRN":"26777"},
{"label":"VEXP 1000 - SJ1 Social Justice and Inclusivity CRN: 26778","subject":"VEXP","courseNumber":"1000","CRN":"26778"},
{"label":"VEXP 1000 - V01 Leadership Communitas CRN: 26779","subject":"VEXP","courseNumber":"1000","CRN":"26779"},
{"label":"VEXP 1000 - V02 Leadership Communitas CRN: 26780","subject":"VEXP","courseNumber":"1000","CRN":"26780"},
{"label":"VEXP 1000 - V03 Leadership Communitas CRN: 26781","subject":"VEXP","courseNumber":"1000","CRN":"26781"},
{"label":"VEXP 1000 - V04 Leadership Communitas CRN: 26782","subject":"VEXP","courseNumber":"1000","CRN":"26782"},
{"label":"VEXP 1000 - V05 Leadership Communitas CRN: 26783","subject":"VEXP","courseNumber":"1000","CRN":"26783"},
{"label":"VEXP 1000 - V06 Leadership Communitas CRN: 26784","subject":"VEXP","courseNumber":"1000","CRN":"26784"},
{"label":"VEXP 1000 - V07 Leadership Communitas CRN: 26785","subject":"VEXP","courseNumber":"1000","CRN":"26785"},
{"label":"VEXP 1000 - V08 Leadership Communitas CRN: 26786","subject":"VEXP","courseNumber":"1000","CRN":"26786"},
{"label":"VEXP 1000 - V09 Leadership Communitas CRN: 26787","subject":"VEXP","courseNumber":"1000","CRN":"26787"},
{"label":"VEXP 1000 - V10 Leadership Communitas CRN: 26788","subject":"VEXP","courseNumber":"1000","CRN":"26788"},
{"label":"VEXP 1000 - V11 Leadership Communitas CRN: 26789","subject":"VEXP","courseNumber":"1000","CRN":"26789"},
{"label":"VEXP 1000 - V12 Leadership Communitas CRN: 26790","subject":"VEXP","courseNumber":"1000","CRN":"26790"},
{"label":"VEXP 1000 - V13 Leadership Communitas CRN: 26791","subject":"VEXP","courseNumber":"1000","CRN":"26791"},
{"label":"VEXP 1000 - V14 Leadership Communitas CRN: 26792","subject":"VEXP","courseNumber":"1000","CRN":"26792"},
{"label":"VEXP 1000 - VH1 Healthy Living Communitas CRN: 26793","subject":"VEXP","courseNumber":"1000","CRN":"26793"},
{"label":"VEXP 1000 - VH2 Healthy Living Communitas CRN: 26794","subject":"VEXP","courseNumber":"1000","CRN":"26794"},
{"label":"VEXP 1000 - VH3 Healthy Living Communitas CRN: 26795","subject":"VEXP","courseNumber":"1000","CRN":"26795"},
]