import { Button, IconButton, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSnackbar } from 'notistack';
import AuthContext from "../../../context/auth-context";
import DeletePhoneRequest from "../phoneRequest/DeletePhoneRequest";

const DeletePhoneButton = (props) => {
    const authCtx = useContext(AuthContext);
    const [needConfirm, setNeedConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    
    const confirmHandler = () => {
      setNeedConfirm(true);
    }
    const stopConfirm = () => {
      setNeedConfirm(false);
      //console.log("yur")
    }
    
    const clickHandler = async () => {
      const deleteResult = await DeletePhoneRequest(authCtx.token);
      if(deleteResult.Status === "Good"){
        authCtx.removePhoneNumber();
        enqueueSnackbar('Successfully deleted phone number!', {variant:'success'});
        setNeedConfirm(false);
      } else if(deleteResult.Status === "Error" || deleteResult === "Error"){
        enqueueSnackbar('Error deleting phone number, refresh and try again.', {variant:'error'});
        setNeedConfirm(false);
      } else if(deleteResult.Status === "Error2"){
        enqueueSnackbar('No phone number to delete.', {variant:'warning'});
        setNeedConfirm(false);
      }
    }
    
    return (
        <>
          {!needConfirm ? <IconButton onClick={confirmHandler} aria-label="delete phone number" sx={{float:"left", padding:"0px", paddingLeft:"10px", paddingTop:"3px"}} size="large"><img src="https://cdn-icons-png.flaticon.com/512/8848/8848798.png" alt="trash-can symbolizing deleting phone number" height="35px" fontSize="inherit" /></IconButton> : 
          <div style={{float:"left", paddingLeft:"10px"}}>
            <Typography color="white" sx={{fontFamily:"system-ui"}}>Are you sure you want to remove your phone number?</Typography>
            <Button variant="contained" onClick={clickHandler} aria-label="yes im sure i want to delete my phone number" sx={{backgroundColor:'#F07A3D', fontWeight:700, fontFamily:'system-ui', margin:"5px", '&:hover': {backgroundColor: '#af482d'}}}>Yes</Button>
            <Button variant="contained" onClick={stopConfirm} aria-label="no im not sure i want to delete my phone number" sx={{backgroundColor:'#F07A3D', fontWeight:700, fontFamily:'system-ui',  '&:hover': {backgroundColor: '#af482d'}}}>No</Button>
          </div>}
        </>
    )
    
};

export default DeletePhoneButton;