const SubmitPhoneRequest = async (phone, token) => {
    try {
        const response = await fetch('https://novasnatch.herokuapp.com/api/user/register-phone', {
        //const response = await fetch('http://localhost:5000/api/user/register-phone', {
        method: "POST",
        body: new URLSearchParams({
            phone: phone,
        }),
        headers:{
            'Authorization': "Bearer " + token,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        });
        if(response.status === 401){
            return "Expired";
        }
        if(response.status === 551){
            return "Error1"
        }
        if(response.status === 552){
            return "Error2"
        }
        if(response.status === 553){
            return "Error3";
        }
        if(response.status === 554){
            return "Error4";
        }
        //console.log(response);
        const data = await response.json();
        //console.log(data);
        return data;
    } catch (error) {
        console.log("phone request error 22", error);
        return "Error1";
    }
    //return null;
}

export default SubmitPhoneRequest;